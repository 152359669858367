/**
 * This file replaces remeeting.js when building for the browser
 *
 * It replaces the recognition.prototype.post with a browser specific
 * implementation to account for differences in posting data
 * and makes Remeeting available on the global scope
 */

const remeeting = require('./remeeting');
const browserPost = require('./recognition/recognition-post-browser');

remeeting.recognitionPost = browserPost;

window.Remeeting = window.Remeeting || remeeting;

module.exports = remeeting;
