/**
 * Take string in x.remeeting.jsons format, which is
 * a list of json objects seperated by new lines
 * and return a json array of objects as efficiently
 * as possible
 */
const parseJsons = (catsJsons = '') => {
  // handle duck typing for catsJsons being a string of some length
  if (!catsJsons.trim) {
    // if catsJsons isn't a string, parsing it just cant work, so must throw
    throw new Error('Cats.parseJsons must be called with a string or undefined');
  }
  if (!catsJsons.length) {
    return [];
  }
  // String transform - this is meaningfully faster than splitting and parsing
  return JSON.parse(`[${catsJsons.trim().replace(/\n/g, ',')}]`);
};

module.exports = {
  parse: parseJsons,
};
