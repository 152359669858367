import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Header,
  Icon,
  Popup,
  Segment,
} from 'semantic-ui-react';

import { infoType } from '../types';

import ChangePassword from './change_password';

import {
  clearError,
  getAccount,
  setAccountData,
  sendSaveAccount,
} from '../../modules/account';

/**
 * The reason the error timeout is longer is that the success/failure notification fades when
 * this.state.submitted is set to false. If error were to also be set to false at the same time
 * you would see the failure message turn to a success one as it fades, which looks bad. So we
 * set the error state update timeout to be slightly longer than the submitted state timeout.
 */
const SUBMITTED_STATE_UPDATE_TIMEOUT_MS = 2000;
const ERROR_STATE_UPDATE_TIMEOUT_MS = 3000;

/**
 * These variables store the identifiers to the timeout calls to reset the submitted/error states.
 * This helps us access the previous timeout call so we can reset the timer the next time it's
 * called. This is so the state isn't prematurely set by previous timeout calls if they happen in
 * quick succession.
 */
let setErrorTimeoutId;
let setSubmittedTimeoutId;

class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
      saveError: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { connectedGetAccount } = this.props;
    connectedGetAccount();
  }

  componentDidUpdate(prevProps) {
    const { connectedClearError, error, saveAccountRequestOut } = this.props;
    if (prevProps.saveAccountRequestOut === true && saveAccountRequestOut === false) {
      this.setState({ submitted: true }); // eslint-disable-line react/no-did-update-set-state

      clearTimeout(setSubmittedTimeoutId);
      setSubmittedTimeoutId = setTimeout(() => {
        this.setState({ submitted: false }); // eslint-disable-line react/no-did-update-set-state
      }, SUBMITTED_STATE_UPDATE_TIMEOUT_MS);
      if (error) {
        this.setState({ saveError: true }); // eslint-disable-line react/no-did-update-set-state

        connectedClearError();
        clearTimeout(setErrorTimeoutId);
        setErrorTimeoutId = setTimeout(() => {
          this.setState({ saveError: false }); // eslint-disable-line react/no-did-update-set-state
        }, ERROR_STATE_UPDATE_TIMEOUT_MS);
      }
    }
  }

  setAccountData = (event) => {
    const { connectedSetAccountData } = this.props;
    event.preventDefault();
    connectedSetAccountData(event.target.id, event.target.value);
    this.setState({ dirty: true });
  }

  updateAccountInformation = (event) => {
    const { connectedSendSaveAccount } = this.props;
    event.preventDefault();
    connectedSendSaveAccount()
      .then(() => this.setState({ dirty: false }));
  }

  isLoading = () => {
    const { getAccountRequestOut, info } = this.props;
    return getAccountRequestOut || !info;
  }

  formInputs = () => {
    const { info } = this.props;
    return [
      <Popup
        key="email-popup"
        trigger={(
          <Form.Input
            id="email"
            key="email"
            label="Email address"
            onChange={(e) => this.setAccountData(e)}
            readOnly
            type="text"
            value={info.email || ''}
          />
        )}
        hoverable
        on="hover"
        position="left center"
      >
        Contact&nbsp;
        <a href="mailto:help@remeeting.com">help@remeeting.com</a>
        &nbsp;to change your email address.
      </Popup>,
      <Popup
        key="name-popup"
        trigger={(
          <Form.Input
            id="name"
            key="name"
            label="Name"
            onChange={(e) => this.setAccountData(e)}
            type="text"
            value={info.name || ''}
          />
        )}
        hoverable
        on="hover"
        position="left center"
      >
        Enter your name as displayed in Zoom meetings.
        <br />
        This helps Remeeting identify you as a participant.
      </Popup>,
    ];
  }

  render() {
    const { info, saveAccountRequestOut } = this.props;
    const { dirty, saveError, submitted } = this.state;
    return (
      <Segment loading={this.isLoading()}>
        <div className="re is-border-bottom is-padding-bottom-16px is-flex is-flex-justify-space-between">
          <Header as="h2" className="re is-header-item">
            Profile
          </Header>
          <ChangePassword />
        </div>
        <Form className="re is-padding-top-16px" onSubmit={this.updateAccountInformation}>
          { info && this.formInputs() }
          <div className="re is-flex is-flex-align-baseline">
            <Button disabled={!dirty} loading={saveAccountRequestOut} primary={dirty} type="submit">
              Update profile
            </Button>
            <div
              className={
                submitted
                  ? 're is-opacity-1'
                  : 're is-transition-opacity-0'
              }
            >
              <Icon name={saveError ? 'x' : 'check'} />
              { saveError ? 'Failed! Try again later' : 'Success!'}
            </div>
          </div>
        </Form>
      </Segment>
    );
  }
}

Personal.propTypes = {
  error: PropTypes.shape({}),
  info: infoType,
  getAccountRequestOut: PropTypes.bool.isRequired,
  saveAccountRequestOut: PropTypes.bool.isRequired,
  connectedClearError: PropTypes.func.isRequired,
  connectedGetAccount: PropTypes.func.isRequired,
  connectedSetAccountData: PropTypes.func.isRequired,
  connectedSendSaveAccount: PropTypes.func.isRequired,
};

Personal.defaultProps = {
  error: undefined,
  info: null,
};

const mapStateToProps = (state) => ({
  error: state.account.error,
  info: state.account.info,
  getAccountRequestOut: state.account.getAccountRequestOut,
  saveAccountRequestOut: state.account.saveAccountRequestOut,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedGetAccount: getAccount,
  connectedSetAccountData: setAccountData,
  connectedSendSaveAccount: sendSaveAccount,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Personal);
