const call = require('../utils/call');
const Extendable = require('../utils/extendable');

const BASE_OPTIONS = Symbol();

class Richify extends Extendable {
  constructor(config, baseOptions) {
    super(config);
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource({ name: 'richify' }); // hack to get the right v1 URL
  }

  post(config) {
    const richify = this;
    if (config) {
      richify.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (richify.query === richify.wordsJson) {
        reject({ message: 'Must supply exactly one of query or query_formatted' });
        return;
      }

      const options = richify[BASE_OPTIONS].getOptions(config);
      options.method = 'POST';
      if (richify.query) {
        options.body = {
          text: richify.query,
        };
      } else {
        options.body = {
          words_json: JSON.stringify(richify.wordsJson),
        };
      }

      call(options, resolve, reject);
    });
  }
}

module.exports = Richify;
