import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import CustomizeTableItem from './customizeTableItem';
import '../../../stylesheets/customizeTable.css';
import { addWordToCustomize } from '../../../modules/customize';
import { keyCodes } from '../../../modules/utils';

class CustomizeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addWordText: '',
    };
  }

  onAddWordButtonClicked = () => {
    const { addWordText } = this.state;
    const { connectedAddWord } = this.props;

    connectedAddWord(addWordText);
    this.setState({ addWordText: '' });
  }

  validateNewWord = (word) => {
    const trimWord = word.trim();
    if (!trimWord.length) return false;
    if (trimWord.match(/\S+/g).length !== 1) return false;
    return true;
  }

  render() {
    const {
      addWordText,
    } = this.state;

    const {
      words,
      originalWords,
    } = this.props;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <button
              color="primary"
              onClick={() => {
                if (this.validateNewWord(addWordText)) {
                  this.onAddWordButtonClicked();
                }
              }}
              disabled={!this.validateNewWord(addWordText)}
              className="add-share-link-button"
              type="button"
            >
              +
            </button>
          </Grid>
          <Grid item xs={6}>
            <input
              type="text"
              placeholder="Word to edit..."
              onChange={(e) => { this.setState({ addWordText: e.target.value }); }}
              onKeyDown={
                (e) => {
                  if (e.keyCode === keyCodes.ENTER && this.validateNewWord(addWordText)) {
                    this.onAddWordButtonClicked();
                  }
                }
              }
              value={addWordText}
            />
          </Grid>
        </Grid>
        <br />
        {
          Object.keys(words).map((word) => (
            <CustomizeTableItem
              word={word}
              wordInfo={words[word]}
              originalWordInfo={originalWords[word]}
              key={word}
            />
          ))
        }
      </div>
    );
  }
}

CustomizeTable.propTypes = {
  connectedAddWord: PropTypes.func.isRequired,
  words: PropTypes.shape({}).isRequired,
  originalWords: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  words: state.customize.words,
  originalWords: state.customize.originalWords,
  lexicalLookupResults: state.customize.lexicalLookupResults,
  lexicalLookupRequestsOut: state.customize.lexicalLookupRequestsOut,
  lexicalLookupRequestsError: state.customize.lexicalLookupRequestsError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedAddWord: addWordToCustomize,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomizeTable);
