/* eslint-disable object-shorthand */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { SEARCH_PAGE_SIZE } from '../../modules/search';
import { hitType } from '../types';

class PaginationNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: 0,
      end: 0,
      totalResults: 0,
      isOlderDisabled: false,
      isNewerDisabled: false,
    };
  }

  componentDidMount() {
    this.setState(this.updateState());
  }

  componentDidUpdate(prevProps) {
    const { hits } = this.props;
    if (prevProps.hits !== hits) {
      this.setState(this.updateState());
    }
  }

  updateState = () => {
    const { hits, page } = this.props;
    const numHits = hits.total ? hits.total.value : 0;
    const start = 1 + ((page - 1) * SEARCH_PAGE_SIZE);
    const end = Math.min((page * SEARCH_PAGE_SIZE), numHits);
    const totalPages = Math.ceil(numHits / SEARCH_PAGE_SIZE);
    return ({
      start: start,
      end: end,
      totalResults: numHits,
      isNewerDisabled: (page === 1 || totalPages === 1),
      isOlderDisabled: (page === totalPages || totalPages === 1),
    });
  }

  handleClick = (direction) => {
    const { page, changePage } = this.props;
    let newPage;
    switch (direction) {
      case 'NEWER':
        newPage = Math.max(1, page - 1);
        changePage(newPage);
        break;
      case 'OLDER': {
        const { totalResults } = this.state;
        const totalPages = Math.ceil(totalResults / SEARCH_PAGE_SIZE);
        newPage = Math.min(page + 1, totalPages);
        changePage(newPage);
        break;
      }
      default:
    }
  }

  render() {
    const {
      start,
      end,
      totalResults,
      isOlderDisabled,
      isNewerDisabled,
    } = this.state;
    return (
      <div className="nav-btn-container">
        <div id="pagination-results">
          {`${start} - ${end} of ${totalResults.toLocaleString()}`}
        </div>
        <button
          className={`${isNewerDisabled ? 'disabled-btn' : 'pagination-btn'}`}
          type="button"
          title="Newer"
          onClick={() => this.handleClick('NEWER')}
          disabled={isNewerDisabled}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          className={`${isOlderDisabled ? 'disabled-btn' : 'pagination-btn'}`}
          type="button"
          title="Older"
          onClick={() => this.handleClick('OLDER')}
          disabled={isOlderDisabled}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    );
  }
}

PaginationNav.propTypes = {
  hits: PropTypes.shape({
    hits: PropTypes.arrayOf(hitType).isRequired,
    total: {
      relation: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    },
  }).isRequired,
  page: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default PaginationNav;
