import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import IntervalTree from 'node-interval-tree';
import Fade from '@material-ui/core/Fade';
import { Helmet } from 'react-helmet';

import {
  PopoverHeader,
  PopoverBody,
  Button,
} from 'reactstrap';

import { Dropdown } from 'semantic-ui-react';

import {
  errorType,
  meetingType,
  spanIdentifierType,
  speakerInfoType,
  richifiedTextType,
} from '../types';

import GoogleSttModal from './googleSttModal';
import MediaPlayer from './mediaPlayer';
import Segment from './segment';
import MeetingHeader from './meetingHeader';
import ShortcutModal from './shortcutModal';

import {
  colorFromModelCost,
  durationTimeString,
  keyCodes,
  mod,
  sortAlternativesByAmLmBalance,
  MultiFindResult,
} from '../../modules/utils';

import {
  seekToTimestamp,
} from '../../modules/mediaElement';

import {
  getRecognitionAudio,
  getRecognitionVideo,
  setAutohideTimeout,
  toggleControls,
  togglePlay,
  setVolumeIsMuted,
} from '../../modules/player';

import {
  clearError,
  clearMeeting,
  getMeeting,
  flushState,
  setFullScreen,
  setShowingCaptions,
  setPhraseAltsPopover,
  setShowSpanPopover,
  setRichificationEnabled,
  setDynamicRichificationEnabled,
  getMeetingSource,
  setShareKey,
  checkIfUserOwnsMeeting,
  getOCR,
  setVendor,
  setTimeQuery,
  cancelGetMeeting,
  cancelGetOCR,
  getMeetingTitle,
  getMetadata,
  toggleGoogleSttModal,
} from '../../modules/meeting';

import {
  setFindFocused,
  setFindQuery,
  setFindIsCurrent,
  setFindResultIndex,
  setFindResult,
} from '../../modules/find';

import '../../stylesheets/meeting.css';
import FakeProgress from './fakeProgress';
import { openCardAction, setCardAction } from '../../modules/recognitions';
import CardActionModal, { WARNING_UNEXPECTED_FAILURE } from '../recognitions/cardActionModal';

class Meeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jumboMode: true,
      lastScrollPosition: 0,
      ownsSharedMeeting: false,
      scrubbingScrollOverlay: false,
      windowHeight: 0,
      scrolledToTimeQuery: false,
      showingLoadScreen: true,
      requestingAssets: true,
      shortcutModalOpen: false,
      renderTitle: '',
    };
    this.segmentRefs = {};
    this.ocrMatchItemRefs = {};
    this.findBarRef = React.createRef();
    this.scrollOverlayRef = React.createRef();
    this.headerRef = React.createRef();
  }

  componentDidMount() {
    const {
      connectedClearError,
      connectedGetRecognitionAudio,
      connectedGetRecognitionVideo,
      connectedGetMeeting,
      connectedCheckIfUserOwnsMeeting,
      connectedSetShareKey,
      location,
      meeting,
      match,
      connectedGetOCR,
      connectedSetTimeQuery,
      history,
    } = this.props;

    const { meetingId, shareKey } = match.params;
    const {
      snapshot,
      s,
      t,
    } = queryString.parse(location.search);

    if (s) {
      history.push(`/r/${meetingId}/${s}`);
    }

    if (shareKey) {
      connectedSetShareKey(shareKey);
    }

    /* Set the title of the page if the meeting data has already been
       loaded. This is possible in the case of a user updating the
       meeting name from the dashboard and then clicking into it. */
    if (meeting && meeting.metadata) {
      this.setState({ renderTitle: getMeetingTitle(meeting) });
    }

    /* Similarily, create segment refs if we already have meeting data. */
    if (meeting && meeting.segments) {
      this.createSegmentRefs(meeting);
    }

    /* timeQuery is a one-time action that 1) forces the video to seek to a certain time, and
       2) forces the transcript to seek to the relevant part of the transcript. */
    if (t && parseFloat(t)) {
      connectedSetTimeQuery(parseFloat(t));
    }

    connectedClearError();

    this.setState({ showingLoadScreen: true });
    connectedGetMeeting(meetingId, snapshot);
    connectedGetRecognitionVideo(meetingId);
    connectedGetRecognitionAudio(meetingId);
    connectedGetOCR(meetingId, snapshot);

    connectedCheckIfUserOwnsMeeting(meetingId, shareKey)
      .then((owns) => {
        /* If the user owns the meeting, update the state so that we redirect
           to the correct URL on the next render. */
        this.setState({ ownsSharedMeeting: owns });
      });

    this.setState({ windowHeight: window.innerHeight });

    document.addEventListener('keydown', this.keyHandler, false);
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.windowResizeHandler, false);
  }

  static getDerivedStateFromProps(props) {
    const {
      meeting,
      getAudioRequestOut,
      getVideoRequestOut,
      getMeetingRequestOut,
      getCatsRequestOut,
      getMeetingRequestError,
    } = props;

    const isLoading = (
      (
        /* This should only happen if the meeting hasn't loaded yet. */
        !meeting.id

        || (
          /* Regular meeting asset requests (audio, video, and the recognition itself) need to
             finish before anything meaningful is rendered. */
          getAudioRequestOut
          || getVideoRequestOut
          || getMeetingRequestOut

          /* Cats aren't necessary for the app to render, but all new jobs have them, and
             on cats-enabled jobs this will usually be the last thing that loads. */
          || getCatsRequestOut
        )
      )
      && !getMeetingRequestError
    );

    const result = { isLoading };

    return {
      ...result,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      connectedSetFindResultIndex,
      findResult,
      isFullScreen,
      meeting,
      cats,
      phraseAltsPopover,
      findIsCurrent,
      getMeetingRequestOut,
      getCatsRequestOut,
      connectedSeekToTimestamp,
      mediaElementLoaded,
      timeQuery,
      duration,
      vendor: currentVendor,
    } = this.props;

    const {
      lastScrollPosition,
      scrolledToTimeQuery,
      isLoading,
    } = this.state;

    const {
      isLoading: wasLoading,
    } = prevState;

    if (prevProps.vendor !== currentVendor) {
      const {
        connectedClearError,
        connectedClearMeeting,
        connectedGetMeeting,
        connectedSetShareKey,
        connectedCancelGetMeeting,
        connectedSetVendor,
        location,
        match,
      } = this.props;

      connectedClearError();
      connectedCancelGetMeeting();
      connectedClearMeeting();
      connectedSetVendor(currentVendor);

      const { meetingId, shareKey } = match.params;
      const {
        snapshot,
      } = queryString.parse(location.search);

      if (shareKey) {
        connectedSetShareKey(shareKey);
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showingLoadScreen: true, requestingAssets: true });
      connectedGetMeeting(meetingId, snapshot);
    }

    /* If we're finished loading, add a small animation to get the progress bar to 100%. */
    if (!isLoading && wasLoading) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ requestingAssets: false });
      setTimeout(() => {
        this.setState({ showingLoadScreen: false });
      }, 1000);
    }

    if (!prevProps.meeting.segments && meeting.segments) {
      this.createSegmentRefs(meeting);
    }

    if (meeting.metadata && getMeetingTitle(meeting) !== getMeetingTitle(prevProps.meeting)) {
      this.setState({
        renderTitle: getMeetingTitle(meeting),
      });
    }

    if (cats === undefined) {
      return;
    }

    if (
      findResult
      && findIsCurrent
      && prevProps.findIsCurrent !== findIsCurrent
    ) {
      /* Scroll to the appropriate part of the transcript. */
      const matchIndex = this.getNearestMatchIndex();
      connectedSetFindResultIndex(matchIndex);

      const currentMatch = findResult.getMatchAtIndex(matchIndex);
      if (currentMatch) {
        if (currentMatch.getType() === 'cats') {
          this.scrollToSegment(currentMatch.data.utterance);
        } else if (currentMatch.getType() === 'ocr') {
          this.scrollToOCRMatchItem(matchIndex);
        }
        connectedSeekToTimestamp(currentMatch.getPosition());
      }
    }

    /* Weird effect of using our own popovers: We have to auto-scroll the alternatives
       back to zero sometimes */
    if (
      prevProps.phraseAltsPopover
      && (
        prevProps.phraseAltsPopover.showAllAlternatives
        !== phraseAltsPopover.showAllAlternatives
      )
    ) {
      const el = document.getElementById('popover-alternatives');
      if (el) {
        el.scrollTop = 0;
      }
    }

    /* Fix for issue #247: scroll to last known position when exiting from fullscreen */
    if (prevProps.isFullScreen && !isFullScreen) {
      window.scrollTo({ top: lastScrollPosition });
    }

    /* Long-winded way of checking whether
       1 - meeting data & cats are loaded
       2 - segments have been loaded by DOM */
    if (
      !getMeetingRequestOut
      && !getCatsRequestOut
      && meeting.segments
      && meeting.segments[meeting.segments.length - 1]
      && this.segmentRefs[meeting.segments[meeting.segments.length - 1].utterance]
      && this.segmentRefs[meeting.segments[meeting.segments.length - 1].utterance].current
      && mediaElementLoaded
      && !scrolledToTimeQuery
      && timeQuery !== undefined
      && timeQuery <= duration
      && timeQuery >= 0
    ) {
      this.bullsEyeHandler();
    }
  }

  componentWillUnmount() {
    const {
      connectedClearError,
      connectedCancelGetMeeting,
      connectedCancelGetOCR,
      connectedFlushState,
    } = this.props;
    connectedClearError();
    connectedCancelGetMeeting();
    connectedCancelGetOCR();
    connectedFlushState();

    document.removeEventListener('keydown', this.keyHandler, false);
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.windowResizeHandler, false);
  }

  /**
   * Create refs for each segment so they don't  get destroyed on each render.
   */
  createSegmentRefs = (meeting) => {
    meeting.segments.forEach((segment) => {
      this.segmentRefs[segment.utterance] = React.createRef();
    });
  }

  windowResizeHandler = () => {
    /* We should be aware that calling forceUpdate() will skip any calls to
       shouldComponentUpdate() in this component (but not in child components).
       So if we ever implement shouldComponentUpdate() in this file, we should
       remove this. */
    this.forceUpdate();
  }

  getNearestMatchIndex = () => {
    const {
      isFullScreen,
      findResult,
      currentTime,
    } = this.props;

    if (!findResult) {
      return null;
    }

    if (isFullScreen) {
      /* Jump to the match with the closest "position" */
      let minDiff = Infinity;
      let bestMatch = null;
      for (let i = 0; i < findResult.size(); i += 1) {
        const d = Math.abs(findResult.getMatchAtIndex(i).getPosition() - currentTime);
        if (d < minDiff) {
          minDiff = d;
          bestMatch = i;
        }
      }
      return bestMatch;
    }

    /* Jump to the segment or OCR label closest to the middle of the scroll window */
    let minDiff = Infinity;
    let bestMatch = null;

    for (let i = 0; i < findResult.size(); i += 1) {
      const currMatch = findResult.getMatchAtIndex(i);
      const minScrollHeight = this.headerRef.current.offsetTop - 56; /* header size */

      let item;
      if (currMatch.getType() === 'ocr') {
        item = this.ocrMatchItemRefs[i];
      } else if (currMatch.getType() === 'cats') {
        item = this.segmentRefs[currMatch.data.utterance].current;
      }

      if (item) {
        const itemTop = item.offsetTop;
        const windowAdjustment = window.innerHeight * 0.4;
        const scrollHeight = Math.max(minScrollHeight, itemTop - windowAdjustment);

        if (Math.abs(scrollHeight - window.scrollY) < minDiff) {
          minDiff = Math.abs(scrollHeight - window.scrollY);
          bestMatch = i;
        }
      }
    }

    return bestMatch;
  }

  updateScroll = () => {
    this.setState({ lastScrollPosition: window.scrollY });
  };

  keyHandler = (e) => {
    const {
      cardActionOpen,
      connectedTogglePlay,
      feedbackModalOpen,
      volumeFocused,
      audioUrl,
      getAudioError,
      getAudioRequestOut,
      getVideoError,
      isFullScreen,
      showingCaptions,
      connectedSetShowingCaptions,
      volumeIsMuted,
      connectedSetVolumeIsMuted,
      findQuery,
      settingsModalOpen,
      shareLinkModalOpen,
      helpModalOpen,
      connectedSetRichificationEnabled,
      richificationEnabled,
      findResult,
      playButtonFocused,
      findFocused,
    } = this.props;

    const {
      shortcutModalOpen,
    } = this.state;

    const {
      SPACEBAR, LEFT, RIGHT,
      ENTER, ESC, FWD_SLASH,
      B, C, M, F, R,
    } = keyCodes;

    // Support Command-F search on Mac, and Ctrl-F search on Windows.
    // This has the side effect of allowing Ctrl-F search on Mac as well.
    const modifierKey = e.metaKey || e.ctrlKey;
    const numMatches = findResult !== undefined ? findResult.size() : 0;

    /* Overall meeting experience */
    if (
      !modifierKey
      && !feedbackModalOpen
      && !cardActionOpen
      && !playButtonFocused
      && !volumeFocused
      && !findFocused
      && !helpModalOpen
      && !shortcutModalOpen
      && !shareLinkModalOpen
    ) {
      if (!settingsModalOpen) {
        if (e.which === SPACEBAR) {
          e.preventDefault();
          connectedTogglePlay();
        } else if (e.which === F) {
          e.preventDefault();
          const hasAudio = !!audioUrl && !getAudioError && !getAudioRequestOut;
          if (!(hasAudio && getVideoError) && hasAudio) {
            this.toggleFullscreen();
          }
        } else if (e.which === C) {
          e.preventDefault();
          connectedSetShowingCaptions(!showingCaptions);
        } else if (e.which === B) {
          e.preventDefault();
          this.bullsEyeHandler();
        } else if (e.which === LEFT) {
          e.preventDefault();
          this.seekDifference(-10);
        } else if (e.which === RIGHT) {
          e.preventDefault();
          this.seekDifference(10);
        } else if (e.which === M) {
          e.preventDefault();
          connectedSetVolumeIsMuted(!volumeIsMuted);
        } else if (e.which === R) {
          e.preventDefault();
          connectedSetRichificationEnabled(!richificationEnabled);
        }
      }
    }

    if (
      !modifierKey
      && !cardActionOpen
      && !feedbackModalOpen
      && !playButtonFocused
      && !volumeFocused
      && !findFocused
      && !helpModalOpen
      && !shareLinkModalOpen
    ) {
      if (!settingsModalOpen) {
        if (e.which === FWD_SLASH && e.shiftKey) {
          e.preventDefault();
          this.setState({ shortcutModalOpen: !shortcutModalOpen });
        }
      }
    }

    if (
      !modifierKey
      && !feedbackModalOpen
      && !shareLinkModalOpen
    ) {
      if (e.which === ENTER && !e.shiftKey && numMatches > 0 && findQuery.length > 0) {
        e.preventDefault();
        this.jumpToNextMatch();
      } else if (e.which === ENTER && e.shiftKey && numMatches > 0 && findQuery.length > 0) {
        e.preventDefault();
        this.jumpToPrevMatch();
      }
    }

    if (modifierKey && e.which === F && this.findBarRef.current) {
      e.preventDefault();
      this.enterFind();
    }

    /* Find bar experience */
    if (findFocused && !modifierKey) {
      if (e.which === ESC) {
        e.preventDefault();
        this.exitFind();
      }
    }

    /* Full screen experience */
    if (isFullScreen) {
      if (e.which === ESC && !findFocused) {
        this.toggleFullscreen();
      }
    }
  }

  scrollHandler = () => {
    /* since React batches state updates and applies them async,
       the updates to jumboMode could be jittery */
    const { jumboMode } = this.state;
    const { isFullScreen } = this.props;
    const headerPosition = this.headerRef.current.offsetTop - window.scrollY;

    if (headerPosition <= 56) {
      if (jumboMode) {
        this.setState({ jumboMode: false });
      }
    } else if (!jumboMode) {
      this.setState({ jumboMode: true });
    }

    if (!isFullScreen) {
      this.updateScroll();
    }
  }

  seekDifference = (diff) => {
    const {
      currentTime,
      connectedSeekToTimestamp,
      connectedSetAutohideTimeout,
      connectedToggleControls,
      shouldPlay,
      duration,
      isFullScreen,
      findFocused,
      findQuery,
      findSettingsOpen,
      showRatePopup,
    } = this.props;

    const newTime = Math.max(0, Math.min(currentTime + diff, duration));
    connectedSeekToTimestamp(newTime, shouldPlay);
    connectedToggleControls(true);
    if (!(isFullScreen
       && (findFocused || findQuery.length > 0 || findSettingsOpen || showRatePopup))) {
      connectedSetAutohideTimeout();
    }
  }

  scrollToSegment = (utteranceId) => {
    const { windowHeight } = this.state;
    const minScrollHeight = this.headerRef.current.offsetTop - 56; /* header size */
    const segmentTop = this.segmentRefs[utteranceId].current.offsetTop;
    const windowAdjustment = windowHeight * 0.4;
    const scrollHeight = Math.max(minScrollHeight, segmentTop - windowAdjustment);

    window.scrollTo({
      top: scrollHeight,
    });
  }

  scrollToOCRMatchItem = (findIndex) => {
    const minScrollHeight = this.headerRef.current.offsetTop - 56; /* header size */
    const item = this.ocrMatchItemRefs[findIndex];

    if (item) {
      const itemTop = item.offsetTop;
      const windowAdjustment = window.innerHeight * 0.4;
      const scrollHeight = Math.max(minScrollHeight, itemTop - windowAdjustment);

      window.scrollTo({
        top: scrollHeight,
      });
    }
  }

  toggleFullscreen = () => {
    const { isFullScreen, connectedSetFullScreen } = this.props;
    if (isFullScreen) {
      connectedSetFullScreen(false);
    } else {
      connectedSetFullScreen(true);
    }
  }

  /* this is a really hacky solution, because the bullseye button is in
     the MediaPlayer component tray but needs to fire actions in this element. */
  bullsEyeHandler = () => {
    const {
      segmentIntervalTree,
      currentTime: time,
      duration,
    } = this.props;

    const {
      scrolledToTimeQuery,
    } = this.state;

    if (segmentIntervalTree === undefined) return;

    const segments = segmentIntervalTree.search(time, duration);
    if (segments.length === 0) return;

    this.scrollToSegment(segments[0].utterance);

    if (!scrolledToTimeQuery) {
      this.setState({
        scrolledToTimeQuery: true,
      });
    }
  }

  setCurrentMatchIndex = (matchIndex) => {
    const {
      connectedSetFindResultIndex,
    } = this.props;

    connectedSetFindResultIndex(matchIndex);
  }

  jumpToRelativeMatch = (jumpDistance) => {
    const {
      connectedSeekToTimestamp,
      connectedSetFindResultIndex,
      findResultIndex,
      findResult,
    } = this.props;

    const newIndex = mod(findResultIndex + jumpDistance, findResult.size());
    connectedSetFindResultIndex(newIndex);
    const newMatch = findResult.getMatchAtIndex(newIndex);
    if (newMatch) {
      if (newMatch.getType() === 'cats') {
        this.scrollToSegment(newMatch.data.utterance);
      } else if (newMatch.getType() === 'ocr') {
        this.scrollToOCRMatchItem(newIndex);
      }
      connectedSeekToTimestamp(newMatch.getPosition());
    }
  }

  enterFind = () => {
    if (this.findBarRef.current) {
      this.findBarRef.current.focus();
    }
  }

  exitFind = () => {
    if (this.findBarRef.current) {
      this.findBarRef.current.blur();
    }
  }

  jumpToNextMatch = () => {
    this.jumpToRelativeMatch(1);
  }

  jumpToPrevMatch = () => {
    this.jumpToRelativeMatch(-1);
  }

  exitAndClearFind = () => {
    this.exitFind();

    const {
      connectedSetFindQuery,
      connectedSetFindIsCurrent,
      connectedSetFindResult,
      connectedSetFindResultIndex,
    } = this.props;

    connectedSetFindQuery('');
    connectedSetFindIsCurrent(true);
    connectedSetFindResult(undefined);
    connectedSetFindResultIndex(undefined);
  }

  scrollToMatchAtIndex = (matchIndex) => () => {
    const {
      findResult,
    } = this.props;

    const newMatch = findResult.getMatchAtIndex(matchIndex);
    if (newMatch.getType() === 'cats') {
      this.scrollToSegment(newMatch.data.utterance);
    } else if (newMatch.getType() === 'ocr') {
      this.scrollToOCRMatchItem(matchIndex);
    }

    this.setCurrentMatchIndex(matchIndex);
  }

  getScrollProportion = (scrollTop) => {
    const {
      windowHeight,
    } = this.state;

    const transcriptSection = document.getElementById('mtg-transcript');
    const header = document.getElementById('transcript-container-header');

    if (!transcriptSection || !header) {
      return 0;
    }

    const adjustment = (
      56 /* nav bar height */
      + header.scrollHeight
      - transcriptSection.offsetTop
    );

    return Math.max(0, Math.min(1, (
      (scrollTop + adjustment)
      / (document.documentElement.scrollHeight + adjustment - windowHeight)
    )));
  }

  getScrollPositionFromProportion = (proportion) => {
    const {
      windowHeight,
    } = this.state;

    const transcriptSection = document.getElementById('mtg-transcript');
    const header = document.getElementById('transcript-container-header');

    if (!transcriptSection || !header) {
      return 0;
    }

    const adjustment = (
      56 /* nav bar height */
      + header.scrollHeight
      - transcriptSection.offsetTop
    );

    return (
      proportion * (document.documentElement.scrollHeight + adjustment - windowHeight)
      - adjustment
    );
  }

  /* When a meeting is not in the "finished" state, we need to display something
     in the transcript area to message that to the user. */
  renderTranscriptMessage = () => {
    const {
      connectedOpenCardAction, connectedSetCardAction, meeting, getMeetingRequestError,
    } = this.props;

    if (
      getMeetingRequestError !== undefined
      && getMeetingRequestError.statusCode === 404
    ) {
      return (
        <div className="transcript-message error">
          <p>
            The requested URL could not be found.
          </p>
        </div>
      );
    }

    if (meeting.status === 'finished') {
      return null;
    }

    if (meeting.status === 'waiting' || meeting.status === 'processing') {
      return (
        <div className="transcript-message processing">
          <p>
            This recording is still being processed. Check back later
            to see your transcript.
          </p>
        </div>
      );
    }

    if (meeting.status === 'failed') {
      let errString = 'Unknown Error';
      if (meeting.details && meeting.details[0].error) {
        errString = meeting.details[0].error;
      }

      return (
        <>
          <CardActionModal deleteRecognition={() => null} />
          <div
            role="button"
            tabIndex={0}
            className={`transcript-message error ${meeting.owner_email ? '' : 'failed'}`}
            onClick={(e) => {
              if (!meeting.owner_email) {
                e.preventDefault();
                e.stopPropagation();
                connectedOpenCardAction();
                connectedSetCardAction(meeting, WARNING_UNEXPECTED_FAILURE);
              }
            }}
            onKeyUp={(event) => {
              if (event.code === 'Enter' && !meeting.owner_email) {
                event.preventDefault();
                event.stopPropagation();
                connectedOpenCardAction();
                connectedSetCardAction(meeting, WARNING_UNEXPECTED_FAILURE);
              }
            }}
          >
            <p>
              An error occurred while processing this recording (
              <code>{errString}</code>
              ).
            </p>
          </div>
        </>
      );
    }

    return null;
  }

  /*
   * Parses the Recognition.GET response to display the transcript
   */
  renderTranscript = (meeting) => {
    const {
      currentTime,
      speakerMetadata,
      cats,
      phraseAltsPopover,
      showSpanPopover,
      richifiedSegments,
      richificationRequestsError,
      richificationRequestsOut,

      findResult,
      findQuery,
      findResultIndex,
      findIsCurrent,
    } = this.props;

    const {
      windowHeight,
      isLoading,
    } = this.state;

    if (!meeting) { return null; }

    if (meeting.status !== 'completed') {
      return this.renderTranscriptMessage();
    }

    const { source } = getMeetingSource(meeting);

    const generateSegmentsMap = () => meeting.segments.map((result, index) => {
      let [start, end] = result.interval;
      const key = result.utterance === undefined ? `segment-${index}` : result.utterance;
      let shouldHighlight = start <= currentTime && currentTime <= end;

      /* Legacy: no cats */
      if (result.utterance === undefined) {
        return (
          <div
            className={`segment-wrapper${shouldHighlight ? ' selected' : ''}`}
            key={key}
            ref={this.segmentRefs[result.utterance]}
          >
            <Segment
              key={`${result.speaker}-${result.interval.toString()}`}
              speaker={speakerMetadata[result.speaker]}
              speakerIndex={result.speaker}
              segment={result}
            />
          </div>
        );
      }

      if (!cats) { return null; }

      const catsData = cats.getData();
      const utterance = catsData[result.utterance];

      /* Use timestamps from cats intervals instead of segment
         intervals to determine whether or not to highlight. */
      if (!isEmpty(utterance.cats)) {
        [start] = utterance.cats[0].interval;
        [, end] = utterance.cats[utterance.cats.length - 1].interval;
        shouldHighlight = start <= currentTime && currentTime <= end;
      }

      /* Instead of letting components control their own rendering
         using Redux state, we drill a prop down to the Span level telling
         it whether to be highlighted. When we have the CatsSpan consume Redux state
         the performance gets really bad */
      let selectedSpan;
      if (
        phraseAltsPopover !== undefined
        && phraseAltsPopover.utterance === result.utterance
        && showSpanPopover
      ) {
        selectedSpan = phraseAltsPopover.catsIndex;
      } else {
        selectedSpan = -1;
      }

      let matches;
      if (findResult !== undefined) {
        matches = [];
        const catsMatches = findResult.getMatchesByType('cats');
        for (let i = 0; i < catsMatches.length; i += 1) {
          if (catsMatches[i].data.utterance === result.utterance) {
            matches.push(catsMatches[i]);
          }
        }
      }
      /* Big performance speedup here, as segment components will see this as a non-update */
      if (matches && matches.length === 0) {
        matches = undefined;
      }

      let segmentCurrentMatch;
      if (findResult !== undefined) {
        const currentMatchObj = findResult.getMatchAtIndex(findResultIndex);
        if (currentMatchObj && currentMatchObj.data.utterance === result.utterance) {
          segmentCurrentMatch = currentMatchObj;
        }
      }

      let segmentWrapperClassName = 'segment-wrapper';
      if (shouldHighlight) {
        segmentWrapperClassName += ' selected';
      }

      let inViewport = false;
      if (!this.segmentRefs[result.utterance] || !this.segmentRefs[result.utterance].current) {
        /* allow the segments to load at first */
        inViewport = false;
      } else {
        const rect = this.segmentRefs[result.utterance].current.getBoundingClientRect();

        if (
          rect.top > -300
          && rect.bottom < windowHeight + 300
        ) {
          inViewport = true;
        }
      }

      const richificationResult = { ...richifiedSegments[result.utterance] };

      /* Show OCR matches in the transcript. An OCR match will be shown directly above
         this segment if the match occurs between the end of the last segment, and the end of
         this one. */
      const ocrMatchItems = [];

      if (meeting && findQuery && findResult && findIsCurrent) {
        const ocrMatches = findResult.getMatchesByType('ocr');
        for (let i = 0; i < ocrMatches.length; i += 1) {
          let ocrMatchItemClassName = 'ocr-match';
          if (findResultIndex === ocrMatches[i].getIndex()) {
            ocrMatchItemClassName += ' current-match';
          }
          const ocrMatchItem = (
            <div
              className="ocr-match-transcript-item"
              key={`ocr-match-transcript-item-${ocrMatches[i].getIndex()}`}
              ref={(ref) => { this.ocrMatchItemRefs[ocrMatches[i].getIndex()] = ref; }}
              onClick={() => {
                this.scrollToMatchAtIndex(ocrMatches[i].getIndex())();
              }}
              onKeyUp={(event) => event.code === 'Enter' && this.scrollToMatchAtIndex(ocrMatches[i].getIndex())()}
              role="button"
              tabIndex={0}
            >
              [Found&nbsp;
              <span className={ocrMatchItemClassName}>{findQuery}</span>
              &nbsp;in video]
            </div>
          );
          const ocrStart = ocrMatches[i].data.frames[0].interval[0];
          if (ocrStart <= result.interval[1]) {
            if (index === 0 || ocrStart > meeting.segments[index - 1].interval[1]) {
              /* Check that this OCR item will not be rendered anywhere else */
              let shouldRender = true;
              for (let j = index - 1; j >= 0; j -= 1) {
                if (meeting.segments[j].interval[1] >= ocrStart) {
                  shouldRender = false;
                  break;
                }
              }
              if (shouldRender) {
                ocrMatchItems.push(ocrMatchItem);
              }
            }
          }
        }
      }

      return [
        ...ocrMatchItems,
        <div
          className={segmentWrapperClassName}
          key={key}
          ref={this.segmentRefs[result.utterance]}
          id={`segment-${result.utterance}`}
        >
          <Segment
            key={`${result.speaker}-${result.interval.toString()}`}
            speaker={speakerMetadata[result.speaker]}
            speakerIndex={result.speaker}
            segment={result}
            utterance={utterance}
            selectedSpan={selectedSpan}
            findMatches={matches}
            currentMatch={segmentCurrentMatch}
            catsAbsoluteTimestamps={source === 'exec'}
            richified={richificationResult}
            richificationRequestOut={richificationRequestsOut[result.utterance]}
            richificationRequestError={richificationRequestsError[result.utterance]}
            inViewport={inViewport}
            loading={isLoading}
          />
        </div>,
      ];
    });

    if (meeting.segments) {
      const className = 'mtg-transcript';
      return (
        <div id={className} className={className}>
          { generateSegmentsMap(meeting) }
        </div>
      );
    }

    if (meeting.details) {
      return (
        <div>
          <h3>Transcript Unavailable</h3>
          <p>
            This recording failed to process and no transcript is available.
          </p>
        </div>
      );
    }
    return null;
  }

  renderDuration = (meeting) => {
    if (!meeting || !meeting.duration) { return null; }

    return (
      <p className="re is-marginLeft-auto">
        Duration:
        { ` ${durationTimeString(meeting)}` }
      </p>
    );
  }

  renderHeader = (props) => {
    const {
      isFullscreen,
      isJumbo,
    } = props;
    const {
      match, history, getMeetingRequestError, location,
    } = this.props;
    const { isLoading, jumboMode } = this.state;

    if (getMeetingRequestError) {
      return <br />;
    }

    /* Without ref forwarding, this is the only way to get React refs
       down to children. exitFind is also included, for code reuse
       purposes. They can't be included in Redux because they interact
       with the refs. Additionally we pass down jumpToRelativeMatch so
       the find navigation buttons can scroll the page. */
    return (
      <MeetingHeader
        isJumbo={isJumbo ?? jumboMode}
        isFullScreen={isFullscreen}
        loading={isLoading}
        exitFind={this.exitFind}
        jumpToRelativeMatch={this.jumpToRelativeMatch}
        headerRef={this.headerRef}
        findBarRef={this.findBarRef}
        segmentRefs={this.segmentRefs}
        match={match}
        location={location}
        history={history}
      />
    );
  }

  /*
   * "Fluff" is an empty div that takes up the space where the big video used to be,
   * so all the text doesn't jump up at once.
   */
  renderJumboFluff = () => {
    const { jumboMode } = this.state;
    const {
      audioUrl,
      videoUrl,
      getAudioRequestOut,
      getVideoRequestOut,
    } = this.props;

    if (!audioUrl && !videoUrl && !getAudioRequestOut && !getVideoRequestOut) {
      return null;
    }

    return (
      <div id="jumbo-media-player-fluff" className={`mtg ${jumboMode ? '' : 'display'}`}>
        <div id="player" className="mtg">
          <div id="player-container-outer" className="mtg">
            <div id="player-container-inner" className="mtg" />
          </div>
        </div>
      </div>
    );
  }

  renderPlayer = (jumboMode, isFullScreen) => {
    const {
      audioUrl,
      videoUrl,
      getAudioRequestOut,
      getVideoRequestOut,
    } = this.props;

    if (!audioUrl && !videoUrl && !getAudioRequestOut && !getVideoRequestOut) {
      return null;
    }

    let playerTag;
    if (isFullScreen) {
      playerTag = 'fullScreen';
    } else {
      playerTag = jumboMode ? 'jumbo' : 'mini';
    }

    return (
      <div id={`${playerTag}-media-player-container`} className="mtg">
        <div id={`${playerTag}-media-player-wrapper`} className="mtg">
          <MediaPlayer
            isMini={!jumboMode}
            isFullScreen={isFullScreen}
            bullsEyeHandler={this.bullsEyeHandler}
          />
        </div>
      </div>
    );
  }

  renderSpanPopover = () => {
    const {
      phraseAltsPopover,
      connectedSetShowSpanPopover,
      connectedSetPhraseAltsPopover,
      transcriptAmLmBalance,
      showSpanPopover,
      altsAmLmBalance,
      findResult,
      findResultIndex,
      cats,
    } = this.props;

    const { windowHeight } = this.state;

    if (cats === undefined) return '';
    const catsData = cats.getData();

    if (phraseAltsPopover !== undefined) {
      const { utterance, catsIndex, showAllAlternatives } = phraseAltsPopover;
      if (!utterance || (!catsIndex && catsIndex !== 0)) {
        return '';
      }

      const idKey = `transcript-${utterance}-${catsIndex}`;
      const { alternatives } = catsData[utterance].cats[catsIndex];

      /* Determine which alternative is being shown in the 1-best right now. */
      let displayedAlt;

      if (findResult) {
        /* Check if this find result is in the correct utterance + contains the right span */
        const match = findResult.getMatchAtIndex(findResultIndex);
        if (match && match.data.utterance === utterance) {
          for (let i = 0; i < match.data.match.positions.length; i += 1) {
            if (match.data.match.positions[i].span === catsIndex) {
              displayedAlt = match.data.match.positions[i].alt;
            }
          }
        }
      }

      if (!displayedAlt && findResult) {
        const catsMatches = findResult.getMatchesByType('cats');
        for (let i = 0; i < catsMatches.length; i += 1) {
          if (catsMatches[i].data.utterance === utterance) {
            const match = findResult.getMatchAtIndex(catsMatches[i].getIndex());
            if (match) {
              for (let j = 0; j < match.data.match.positions.length; j += 1) {
                if (match.data.match.positions[j].span === catsIndex) {
                  displayedAlt = match.data.match.positions[j].alt;
                  break;
                }
              }
            }
          }
        }
      }

      /* If there are no Find matches in this span, we need to determine what the 1-best would
         display here. */
      const sortedTranscriptAlts = sortAlternativesByAmLmBalance(
        alternatives,
        transcriptAmLmBalance,
      );

      if (displayedAlt) {
        displayedAlt = alternatives[displayedAlt].phrase;
      } else {
        displayedAlt = sortedTranscriptAlts[0].phrase;
      }

      const sortedAlts = sortAlternativesByAmLmBalance(
        alternatives,
        altsAmLmBalance,
      ).filter((alt) => alt.phrase !== displayedAlt);

      const popoverBody = [];

      if (!sortedAlts.length) {
        popoverBody.push(
          <span key="no-other-alts" style={{ color: 'grey' }}>
            <i>None</i>
          </span>,
        );
      } else {
        let last = sortedAlts.length - 1;

        if (!showAllAlternatives) {
          last = Math.min(last, 9);
        }

        for (let i = 0; i <= last; i += 1) {
          let { bias: { am, lm } } = sortedAlts[i];
          const { phrase } = sortedAlts[i];

          am *= 1;
          lm *= altsAmLmBalance * 2;
          popoverBody.push(
            <span key={`alt-${i}`} style={{ color: colorFromModelCost(am + lm) }}>
              {phrase || '[silence]'}
            </span>,
          );
          if (i < last) {
            popoverBody.push(<br key={`alt-br-${i}`} />);
          }
        }

        if (last < sortedAlts.length - 1) {
          popoverBody.push(<br key="alt-br-view-all" />);
          popoverBody.push(
            <Button
              color="link"
              id="popover-show-alts"
              onClick={() => {
                connectedSetPhraseAltsPopover(utterance, catsIndex, true);
              }}
              key="alt-button"
            >
              View all...
            </Button>,
          );
        }
      }

      let styles = {};
      if (showAllAlternatives) {
        styles = {
          maxHeight: '350px',
          overflowY: 'scroll',
        };
      }

      const el = document.getElementById(idKey);
      if (!el) {
        return '';
      }

      const spanRect = el.getBoundingClientRect();
      const popoverX = spanRect.x;

      const popoverElement = document.getElementById('span-popover');
      let popoverHeight = 350;
      if (popoverElement) {
        popoverHeight = popoverElement.getBoundingClientRect().height;
      }

      let popoverY = spanRect.y + spanRect.height + window.scrollY;
      if (
        popoverY + popoverHeight
        >= windowHeight + window.scrollY
      ) {
        popoverY -= popoverHeight + spanRect.height;
      }

      return (
        <div
          id="span-popover"
          className="span-popover"
          style={{
            position: 'absolute',
            left: popoverX,
            top: popoverY,
            visibility: showSpanPopover ? 'visible' : 'hidden',
          }}
          onMouseEnter={() => { connectedSetShowSpanPopover(true); }}
          onMouseLeave={() => {
            setTimeout(() => {
              connectedSetShowSpanPopover(false);
            }, 100);
          }}
        >
          <PopoverHeader>
            Other possible phrases
          </PopoverHeader>
          <PopoverBody>
            <div id="popover-alternatives" style={styles}>
              {popoverBody}
            </div>
          </PopoverBody>
        </div>
      );
    }

    return '';
  }

  renderScrollFindMarkers = () => {
    const {
      findResult,
      findResultIndex,
      meeting,
      cats,
    } = this.props;

    const {
      windowHeight,
    } = this.state;

    const result = [];

    if (
      meeting === undefined
      || meeting.segments === undefined
      || findResult === undefined
      || cats === undefined
    ) {
      return [];
    }

    for (let i = 0; i < findResult.size(); i += 1) {
      const match = findResult.getMatchAtIndex(i);
      let total;
      if (match.getType() === 'ocr' && this.ocrMatchItemRefs[i]) {
        total = this.ocrMatchItemRefs[i].offsetTop;
      } else if (match.getType() === 'cats' && this.segmentRefs[match.data.utterance].current) {
        total = this.segmentRefs[match.data.utterance].current.offsetTop;
      }
      total -= windowHeight * 0.4;
      total = this.getScrollProportion(total);
      total *= (this.scrollOverlayRef.current.offsetHeight - 5);

      let className = 'scroll-find-marker';
      if (findResultIndex === i) {
        className += ' current-match';
      }

      result.push(
        <div
          key={`find-marker-${i}`}
          className={className}
          style={{
            top: `${total}px`,
          }}
          onClick={this.scrollToMatchAtIndex(i)}
          onKeyUp={(event) => event.code === 'Enter' && this.scrollToMatchAtIndex(i)}
          role="button"
          tabIndex={-1}
          aria-label={`Scroll to find result ${i}`}
        />,
      );
    }

    return result;
  }

  renderOverlayScrubber = () => {
    const {
      lastScrollPosition,
    } = this.state;

    if (this.scrollOverlayRef.current) {
      const proportion = this.getScrollProportion(lastScrollPosition);

      return (
        <div
          className="overlay-scrubber"
          style={{
            position: 'absolute',
            top: `${proportion * (this.scrollOverlayRef.current.offsetHeight - 5)}px`,
          }}
        />
      );
    }

    return <div />;
  }

  onScrollOverlayMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', this.onScrollOverlayMouseMove, false);
    document.addEventListener('mouseup', this.onScrollOverlayMouseUp, false);
    this.setState({ scrubbingScrollOverlay: true });

    /* Get click position relative to scroll bar */
    if (this.scrollOverlayRef.current) {
      let proportion = (
        (e.clientY - this.scrollOverlayRef.current.offsetTop)
        / (this.scrollOverlayRef.current.offsetHeight - 5)
      );
      proportion = Math.min(1, Math.max(0, proportion));
      window.scrollTo({ top: this.getScrollPositionFromProportion(proportion) });
    }
  }

  onScrollOverlayMouseMove = (e) => {
    e.preventDefault();
    const {
      scrubbingScrollOverlay,
    } = this.state;

    /* Get click position relative to scroll bar */
    if (scrubbingScrollOverlay && this.scrollOverlayRef.current) {
      let proportion = (
        (e.y - this.scrollOverlayRef.current.offsetTop)
        / (this.scrollOverlayRef.current.offsetHeight - 5)
      );
      proportion = Math.min(1, Math.max(0, proportion));
      window.scrollTo({ top: this.getScrollPositionFromProportion(proportion) });
    }
  }

  onScrollOverlayMouseUp = () => {
    document.removeEventListener('mousemove', this.onScrollOverlayMouseMove, false);
    document.removeEventListener('mouseup', this.onScrollOverlayMouseUp, false);
    this.setState({ scrubbingScrollOverlay: false });
  }

  renderLoadingOverlay = () => {
    const { showingLoadScreen, requestingAssets } = this.state;

    let timeout;
    if (requestingAssets) {
      timeout = 0;
    }

    return (
      <Fade in={showingLoadScreen} timeout={timeout}>
        <div className="meeting-loading-overlay">
          <p>Loading recording...</p>
          <div className="meeting-loading-overlay-spinner">
            <FakeProgress isFull={!requestingAssets} speed={15} />
          </div>
        </div>
      </Fade>
    );
  }

  handleItemClick = (e, data) => {
    const { connectedSetVendor } = this.props;
    const { value } = data;
    connectedSetVendor(value);
  }

  renderDropdownVendors = () => {
    const {
      connectedToggleGoogleSttModal,
      connectedTogglePlay,
      meeting,
      signedIn,
      vendor,
    } = this.props;
    const hasGoogleStt = getMetadata(meeting, 'has_google_stt');

    if (meeting.status !== 'completed'
      || (!hasGoogleStt && (meeting.owner_email !== undefined || !signedIn))) {
      return null;
    }

    let currentVendor;

    switch (vendor) {
      case undefined:
        currentVendor = 'Mod9 ASR';
        break;
      case 'google-stt':
        currentVendor = 'Google STT';
        break;
      default:
    }

    return (
      <div style={{ width: 'fit-content', marginLeft: 'auto', marginBottom: '10px' }}>
        <h5 style={{ display: 'inline' }}>Transcript by </h5>
        <Dropdown
          className="selection"
          text={currentVendor}
          value={vendor}
          onClick={() => connectedTogglePlay(false)}
          style={{
            minWidth: 'unset',
            width: '150px',
          }}
        >
          <Dropdown.Menu>
            { !(currentVendor === 'Google STT') && (
              <Dropdown.Item
                text="Google STT"
                onClick={(e, data) => {
                  if (hasGoogleStt) {
                    this.handleItemClick(e, data);
                  } else {
                    connectedToggleGoogleSttModal(true);
                  }
                }}
                value="google-stt"
              />
            )}
            { !(currentVendor === 'Mod9 ASR') && (
              <Dropdown.Item
                text="Mod9 ASR"
                onClick={this.handleItemClick}
                value={undefined}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
        { !hasGoogleStt && (
          <GoogleSttModal meeting={meeting} />
        )}
      </div>

    );
  }

  /* Primary render function */
  render() {
    const {
      audioUrl,
      getAudioError,
      getAudioRequestOut,
      getVideoError,
      meeting,
      isFullScreen,
      signedIn,
      location,
      getMeetingRequestError,
      match,
    } = this.props;

    const { meetingId, shareKey } = match.params;

    const {
      ownsSharedMeeting,
      shortcutModalOpen,
      renderTitle,
      showingLoadScreen,
      lastScrollPosition,
    } = this.state;

    if (getMeetingRequestError && getMeetingRequestError.statusCode === 403) {
      /* If there is a share token, but it is invalid, tell the user */
      return (
        <div className="share-error">
          <p>
            You do not have permission to access this URL.
          </p>
        </div>
      );
    }

    if (!signedIn) {
      /* If the user is not signed in, there needs to be a valid share token. */
      if (!shareKey) {
        /* If there is no share token, redirect to login. */
        return (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        );
      }
    } else if (shareKey && ownsSharedMeeting) {
      return (
        <Redirect
          to={{
            pathname: `/r/${meetingId}`,
            search: location.search,
          }}
        />
      );
    }

    const { jumboMode } = this.state;

    const hasAudio = !!audioUrl && !getAudioError && !getAudioRequestOut;

    let className = 're is-main is-full-height is-flex is-flexDirection-column';
    if (isFullScreen) {
      className += ' is-full-screen-video';
    }

    const headerProps = {};
    if (isFullScreen) {
      headerProps.isFullScreen = true;
      headerProps.isJumbo = false;
    }

    return (
      <div className={className}>
        { this.renderSpanPopover() }
        <ShortcutModal
          open={shortcutModalOpen}
          onClose={() => { this.setState({ shortcutModalOpen: false }); }}
        />
        <Helmet>
          <title>
            {`${renderTitle ? `${renderTitle} \u2014 ` : ''}Remeeting`}
          </title>
        </Helmet>
        <div
          id="scroll-overlay"
          ref={this.scrollOverlayRef}
          onMouseDown={this.onScrollOverlayMouseDown}
          onMouseMove={this.onScrollOverlayMouseMove}
          onMouseUp={this.onScrollOverlayMouseUp}
          role="slider"
          aria-valuenow={lastScrollPosition}
          tabIndex={-1}
        >
          <div id="scroll-find-overlay">
            { this.renderScrollFindMarkers() }
            { this.renderOverlayScrubber() }
          </div>
        </div>
        <div
          id="rmtg"
          className={`re is-full-height ${isFullScreen ? 'is-full-screen' : ''}`}
          audio={getVideoError && hasAudio ? '' : undefined}
        >
          { this.renderJumboFluff() }
          { this.renderPlayer(jumboMode, isFullScreen) }
          <div id="transcript-container">
            { this.renderHeader(headerProps) }
            { this.renderDropdownVendors() }
            { this.renderTranscript(meeting) }
          </div>
        </div>
        { showingLoadScreen && this.renderLoadingOverlay() }
      </div>
    );
  }
}

Meeting.propTypes = {
  audioUrl: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  getMeetingRequestError: errorType,
  connectedClearError: PropTypes.func.isRequired,
  connectedClearMeeting: PropTypes.func.isRequired,
  connectedGetMeeting: PropTypes.func.isRequired,
  connectedGetRecognitionAudio: PropTypes.func.isRequired,
  connectedGetRecognitionVideo: PropTypes.func.isRequired,
  connectedSetFullScreen: PropTypes.func.isRequired,
  connectedOpenCardAction: PropTypes.func.isRequired,
  connectedSetCardAction: PropTypes.func.isRequired,
  getAudioError: errorType,
  getAudioRequestOut: PropTypes.bool.isRequired,
  getVideoError: errorType,
  match: PropTypes.shape({
    params: PropTypes.shape({
      meetingId: PropTypes.node,
      shareKey: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  meeting: meetingType.isRequired,
  connectedFlushState: PropTypes.func.isRequired,
  connectedTogglePlay: PropTypes.func.isRequired,
  feedbackModalOpen: PropTypes.bool.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  volumeFocused: PropTypes.bool.isRequired,
  richificationEnabled: PropTypes.bool.isRequired,
  playButtonFocused: PropTypes.bool.isRequired,
  showingCaptions: PropTypes.bool.isRequired,
  showRatePopup: PropTypes.bool.isRequired,
  connectedSetShowingCaptions: PropTypes.func.isRequired,
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  connectedSeekToTimestamp: PropTypes.func.isRequired,
  shouldPlay: PropTypes.bool,
  connectedSetAutohideTimeout: PropTypes.func.isRequired,
  connectedToggleControls: PropTypes.func.isRequired,
  connectedSetVolumeIsMuted: PropTypes.func.isRequired,
  volumeIsMuted: PropTypes.bool,
  speakerMetadata: PropTypes.arrayOf(speakerInfoType),
  connectedSetTimeQuery: PropTypes.func.isRequired,
  phraseAltsPopover: spanIdentifierType,
  showSpanPopover: PropTypes.bool,
  connectedSetShowSpanPopover: PropTypes.func.isRequired,
  getVideoRequestOut: PropTypes.bool.isRequired,
  getCatsRequestOut: PropTypes.bool.isRequired,
  getMeetingRequestOut: PropTypes.bool.isRequired,
  vendor: PropTypes.string,

  connectedSetFindQuery: PropTypes.func.isRequired,
  connectedCheckIfUserOwnsMeeting: PropTypes.func.isRequired,
  connectedSetFindIsCurrent: PropTypes.func.isRequired,
  settingsModalOpen: PropTypes.bool.isRequired,
  mediaElementLoaded: PropTypes.bool.isRequired,
  helpModalOpen: PropTypes.bool.isRequired,
  findResult: PropTypes.instanceOf(MultiFindResult),
  findResultIndex: PropTypes.number,

  findQuery: PropTypes.string.isRequired,
  findFocused: PropTypes.bool.isRequired,
  findSettingsOpen: PropTypes.bool.isRequired,
  timeQuery: PropTypes.number,
  connectedSetPhraseAltsPopover: PropTypes.func.isRequired,
  connectedSetRichificationEnabled: PropTypes.func.isRequired,
  altsAmLmBalance: PropTypes.number.isRequired,
  transcriptAmLmBalance: PropTypes.number.isRequired,
  richifiedSegments: PropTypes.objectOf(richifiedTextType).isRequired,
  richificationRequestsError: PropTypes.objectOf(errorType).isRequired,
  richificationRequestsOut: PropTypes.objectOf(PropTypes.bool).isRequired,
  connectedSetShareKey: PropTypes.func.isRequired,
  connectedGetOCR: PropTypes.func.isRequired,
  signedIn: PropTypes.bool.isRequired,
  findIsCurrent: PropTypes.bool.isRequired,
  connectedSetFindResultIndex: PropTypes.func.isRequired,
  connectedSetFindResult: PropTypes.func.isRequired,
  connectedSetVendor: PropTypes.func.isRequired,
  segmentIntervalTree: PropTypes.instanceOf(IntervalTree),
  connectedCancelGetMeeting: PropTypes.func.isRequired,
  connectedCancelGetOCR: PropTypes.func.isRequired,
  connectedToggleGoogleSttModal: PropTypes.func.isRequired,
  cats: PropTypes.shape({
    getData: PropTypes.func,
  }),
  cardActionOpen: PropTypes.bool.isRequired,
  shareLinkModalOpen: PropTypes.bool.isRequired,
};

Meeting.defaultProps = {
  cats: undefined,
  getAudioError: undefined,
  getVideoError: undefined,
  getMeetingRequestError: undefined,
  shouldPlay: false,
  speakerMetadata: [],
  volumeIsMuted: false,
  phraseAltsPopover: undefined,
  showSpanPopover: false,
  match: { params: {} },
  location: { search: '' },
  findResult: undefined,
  findResultIndex: undefined,
  segmentIntervalTree: undefined,
  timeQuery: undefined,
  vendor: undefined,
};

// TODO: Add getRecognitionRequestError handling
const mapStateToProps = (state) => ({
  audioUrl: state.player.audioUrl,
  cardActionOpen: state.recognitions.cardActionOpen,
  videoUrl: state.player.videoUrl,
  playButtonFocused: state.meeting.playButtonFocused,
  getAudioError: state.player.getAudioError,
  getAudioRequestOut: state.player.getAudioRequestOut,
  getVideoError: state.player.getVideoError,
  meeting: state.meeting.meeting,
  recognition: state.meeting.currentRecognition,
  feedbackModalOpen: state.meeting.feedbackModalOpen,
  volumeFocused: state.player.volumeFocused,
  isFullScreen: state.meeting.isFullScreen,
  showRatePopup: state.player.showRatePopup,
  showingCaptions: state.meeting.showingCaptions,
  currentTime: state.mediaElement.currentTime,
  duration: state.mediaElement.duration,
  segmentIntervalTree: state.meeting.segmentIntervalTree,
  shouldPlay: state.player.shouldPlay,
  volumeIsMuted: state.player.volumeIsMuted,
  date: state.meeting.date,
  speakerMetadata: state.meeting.speakerMetadata,
  cats: state.meeting.cats,
  phraseAltsPopover: state.meeting.phraseAltsPopover,
  showSpanPopover: state.meeting.showSpanPopover,
  getVideoRequestOut: state.player.getVideoRequestOut,
  getMeetingRequestOut: state.meeting.getMeetingRequestOut,
  getCatsRequestOut: state.meeting.getCatsRequestOut,
  getMeetingRequestError: state.meeting.getMeetingRequestError,
  settingsModalOpen: state.meeting.settingsModalOpen,
  helpModalOpen: state.meeting.helpModalOpen,
  richificationRequestsError: state.meeting.richificationRequestsError,
  richificationRequestsOut: state.meeting.richificationRequestsOut,
  shareKey: state.meeting.shareKey,
  shareLinkModalOpen: state.meeting.shareLinkModalOpen,
  findQuery: state.find.findQuery,
  findFocused: state.find.findFocused,
  findIsCurrent: state.find.findIsCurrent,
  findSettingsOpen: state.find.findSettingsOpen,
  altsAmLmBalance: state.meeting.altsAmLmBalance,
  transcriptAmLmBalance: state.meeting.transcriptAmLmBalance,
  richifiedSegments: state.meeting.richifiedSegments,
  richificationEnabled: state.meeting.richificationEnabled,
  dynamicRichificationEnabled: state.meeting.dynamicRichificationEnabled,
  signedIn: state.session.signedIn,
  findResult: state.find.findResult,
  findResultIndex: state.find.findResultIndex,
  timeQuery: state.meeting.timeQuery,
  mediaElementLoaded: state.player.mediaElementLoaded,
  vendor: state.meeting.vendor,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedClearMeeting: clearMeeting,
  connectedGetMeeting: getMeeting,
  connectedGetRecognitionAudio: getRecognitionAudio,
  connectedGetRecognitionVideo: getRecognitionVideo,
  connectedSetFullScreen: setFullScreen,
  conectedPush: push,
  connectedOpenCardAction: openCardAction,
  connectedSetCardAction: setCardAction,
  connectedFlushState: flushState,
  connectedTogglePlay: togglePlay,
  connectedSetAutohideTimeout: setAutohideTimeout,
  connectedSetShowingCaptions: setShowingCaptions,
  connectedSeekToTimestamp: seekToTimestamp,
  connectedSetVolumeIsMuted: setVolumeIsMuted,
  connectedToggleControls: toggleControls,
  connectedSetPhraseAltsPopover: setPhraseAltsPopover,
  connectedSetShowSpanPopover: setShowSpanPopover,
  connectedSetFindFocused: setFindFocused,
  connectedSetFindQuery: setFindQuery,
  connectedSetFindIsCurrent: setFindIsCurrent,
  connectedSetRichificationEnabled: setRichificationEnabled,
  connectedSetDynamicRichificationEnabled: setDynamicRichificationEnabled,
  connectedSetShareKey: setShareKey,
  connectedCheckIfUserOwnsMeeting: checkIfUserOwnsMeeting,
  connectedGetOCR: getOCR,
  connectedSetFindResultIndex: setFindResultIndex,
  connectedSetFindResult: setFindResult,
  connectedSetVendor: setVendor,
  connectedSetTimeQuery: setTimeQuery,
  connectedCancelGetMeeting: cancelGetMeeting,
  connectedCancelGetOCR: cancelGetOCR,
  connectedToggleGoogleSttModal: toggleGoogleSttModal,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Meeting);
