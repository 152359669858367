import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import {
  Button, Divider, Message, Modal,
} from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faTrashAlt,
  faCopy,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import Grid from '@material-ui/core/Grid';

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from 'reactstrap';

import { Switch } from '@material-ui/core';

import { push } from 'connected-react-router';

import ConsoleErrorMessage from '../utils/console-error-message';
import InputSlider from './inputSlider';
import CustomizeTable from './customizeTable';

import {
  clearError,
  sendFeedback,
  toggleSettingsModal,
  setTranscriptAmLmBalance,
  setAltsAmLmBalance,
  getMeetingTitle,
  getMeeting,
  getMetadata,
  updateMetadata,
  getSpeakerNameAtIndex,
  getShareLinks,
  createShareLinkWithName,
  deleteShareLinkWithKey,
  setSettingsPane,
  setDynamicRichificationEnabled,
  setRichificationEnabled,
  deleteMeeting,
} from '../../modules/meeting';

import {
  colorWithAlpha,
  speakerLabelToAbbrev,
  sanitizeHtmlAttr,
  copyToClipboard,
  keyCodes,
} from '../../modules/utils';

import {
  determineRemeetingApiBaseUrl,
} from '../../modules/rmi';

import '../../stylesheets/tabs.css';

import {
  meetingType,
  speakerInfoType,
  errorType,
  customizationWordType,
} from '../types';
import RemeetingUserFriendlyDate from '../utils/remeeting-user-friendly-date';
import { removeWordFromCustomize } from '../../modules/customize';
import { setFindSetting } from '../../modules/find';

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingForSubmit: false,

      ref: null,
      transcriptBalance: 0.5,
      prevTranscriptBalance: undefined,
      altsBalance: 0.25,
      applyButtonActive: false,

      meetingTitleText: '',

      speakerMetadata: [],

      shareLinkNameText: '',
      shareLinkNotifyCopy: undefined,
      shareLinkDeleteNotified: false,
      shareLinkDeleteNotification: undefined,

      dynamicRichChecked: true,

      requiresReprocess: false,

      regexMatch: false,
      maxSilencesToSkip: 2,
      maxSilenceSecondsToSkip: 1.0,
      maxRankOfSilenceToSkip: 3,

      formatTranscript: true,
    };
    this.panesShared = {
      general: false,
      sharing: false,
      customization: false,
      // snapshots: true,
      advanced: true,
    };
  }

  componentDidMount() {
    const { connectedGetShareLinks, meeting, shared } = this.props;
    connectedGetShareLinks(meeting.id);

    if (shared) {
      for (let i = 0; i < Object.keys(this.panesShared).length; i += 1) {
        if (this.panesShared[Object.keys(this.panesShared)[i]]) {
          /* First openable tab in the settings modal */
          this.setSettingsPane(i);
          break;
        }
      }
    } else {
      this.setSettingsPane(0);
    }
  }

  componentDidUpdate(prevProps) {
    /* Conditionally enable the Cancel button. */
    const { customizationWords, settingsModalOpen } = this.props;
    if (prevProps.customizationWords !== customizationWords) this.toggleApplyButton();
    /* Make sure to populate the settings every time the modal opens. */
    if (prevProps.settingsModalOpen !== settingsModalOpen) {
      this.populateSettings();
      if (this.customizeChanged) {
        this.toggleApplyButton();
      }
    }
  }

  handleCloseModal = () => {
    const {
      connectedClearError,
      connectedToggleSettingsModal,
    } = this.props;
    connectedToggleSettingsModal(false);
    connectedClearError();

    this.setState({
      applyButtonActive: false,
      shareLinkDeleteNotification: undefined,
      shareLinkDeleteNotified: false,
    });
  }

  handleOpenModal = () => {
    const { connectedToggleSettingsModal } = this.props;

    this.populateSettings();
    connectedToggleSettingsModal(true);
  }

  focusOnInput = () => {
    /**
     * Set timeout of 10 ms to give the modal time to mount to the DOM.
     * Otherwise it will be called .focus() on an undefined object.
     */
    setTimeout(() => {
      const { ref } = this.state;
      if (ref) {
        ref.focus();
      }
    }, 10);
  }

  setRef = (ref) => {
    this.setState({ ref });
  }

  renderError = (error) => (
    <Message error>
      <ConsoleErrorMessage error={error} />
    </Message>
  );

  populateSettings = () => {
    const {
      meeting,
      speakerMetadata,
      transcriptAmLmBalance,
      altsAmLmBalance,
      dynamicRichificationEnabled,
      findSettings,
      richificationEnabled,
    } = this.props;

    const newState = {
      dynamicRichChecked: dynamicRichificationEnabled,
      prevTranscriptBalance: 0.5,
      meetingTitleText: getMeetingTitle(meeting),
      speakerMetadata: speakerMetadata.map((item, index) => ({
        ...speakerMetadata[index],
        label: getSpeakerNameAtIndex(meeting, speakerMetadata, index),
      })),
      formatTranscript: richificationEnabled,
      ...findSettings,
    };

    if (meeting.metadata) {
      newState.transcriptBalance = (
        meeting.metadata.transcript_am_lm_balance
        || transcriptAmLmBalance
      );
      newState.altsBalance = (
        meeting.metadata.alternatives_am_lm_balance
        || altsAmLmBalance
      );
    }

    this.setState(newState);
  }

  onDynamicRichChanged = () => {
    const { dynamicRichChecked, transcriptBalance, prevTranscriptBalance } = this.state;

    if (!dynamicRichChecked === false) {
      /* Force transcript AM/LM balance to be 1. */
      this.setState({
        dynamicRichChecked: !dynamicRichChecked,
        transcriptBalance: 0.5,
        prevTranscriptBalance: transcriptBalance,
      }, this.toggleApplyButton);
    } else {
      this.setState({
        dynamicRichChecked: !dynamicRichChecked,
        transcriptBalance: prevTranscriptBalance,
        prevTranscriptBalance: undefined,
      }, this.toggleApplyButton);
    }
  }

  amLmBalanceChanged = () => {
    const { transcriptAmLmBalance, altsAmLmBalance } = this.props;
    const { transcriptBalance, altsBalance } = this.state;

    return (
      transcriptBalance !== transcriptAmLmBalance
      || altsBalance !== altsAmLmBalance
    );
  }

  dynamicRichChanged = () => {
    const { dynamicRichChecked, formatTranscript } = this.state;
    const { dynamicRichificationEnabled, richificationEnabled } = this.props;

    return (
      dynamicRichChecked !== dynamicRichificationEnabled
      || formatTranscript !== richificationEnabled
    );
  }

  validateAmLmBalance = () => true;

  meetingTitleChanged = () => {
    const { meeting } = this.props;
    const { meetingTitleText } = this.state;
    return getMeetingTitle(meeting) !== meetingTitleText;
  }

  validateMeetingTitle = () => true;

  speakerLabelsChanged = () => {
    const { speakerMetadata } = this.state;
    const { speakerMetadata: currentSpeakerMetadata, meeting } = this.props;

    return currentSpeakerMetadata.some((_, index) => {
      if (!speakerMetadata[index]) {
        return false;
      }
      return (
        getSpeakerNameAtIndex(meeting, currentSpeakerMetadata, index)
        !== speakerMetadata[index].label
      );
    });
  }

  validateSpeakerLabels = () => {
    const { speakerMetadata } = this.state;
    return speakerMetadata.every((item) => item.label.length > 0);
  }

  customizeChanged = () => {
    const { customizationWords, shared } = this.props;
    if (shared) return false;

    return Object.values(customizationWords).some((item) => (item.changed || item.toDelete));
  }

  validateCustomize = () => {
    const { customizationWords } = this.props;
    return Object.values(customizationWords).every(
      (item) => item.pronunciations && item.pronunciations.length > 0,
    );
  }

  findChanged = () => {
    const { findSettings } = this.props;
    const keys = Object.keys(findSettings);
    for (let i = 0; i < keys.length; i += 1) {
      // There doesn't appear to be a way to refactor the following
      // line to follow the ESLint rule react/destructuring-assignment
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state[keys[i]] !== findSettings[keys[i]]) {
        return true;
      }
    }
    return false;
  }

  validateFind = () => {
    const {
      maxSilencesToSkip,
      maxSilenceSecondsToSkip,
      maxRankOfSilenceToSkip,
    } = this.state;

    const isInt = (num) => Number.isInteger(parseFloat(num));
    if (
      isInt(maxSilencesToSkip)
      && isInt(maxSilenceSecondsToSkip)
      && isInt(maxRankOfSilenceToSkip)
    ) {
      return true;
    }

    return false;
  }

  settingsChanged = () => (
    this.amLmBalanceChanged()
    || this.meetingTitleChanged()
    || this.speakerLabelsChanged()
    || this.customizeChanged()
    || this.dynamicRichChanged()
    || this.findChanged()
  );

  settingsValidated = () => (
    this.validateAmLmBalance()
    && this.validateMeetingTitle()
    && this.validateSpeakerLabels()
    && this.validateCustomize()
    && this.validateFind()
  );

  handleSubmit = async () => {
    this.setState({
      waitingForSubmit: true,
    });

    const {
      meeting,
      connectedUpdateMetadata,
      speakerMetadata: currentSpeakerMetadata,
      transcriptAmLmBalance,
      altsAmLmBalance,
      shared,
      connectedSetAltsAmLmBalance,
      connectedSetTranscriptAmLmBalance,
      customizationWords,
      connectedSetDynamicRichificationEnabled,
      connectedRemoveCustomizationWord,
      connectedSendFeedback,
      connectedSetFindSetting,
      connectedSetRichificationEnabled,
    } = this.props;

    const {
      transcriptBalance,
      altsBalance,
      meetingTitleText,
      speakerMetadata,
      dynamicRichChecked,

      maxRankOfSilenceToSkip,
      maxSilenceSecondsToSkip,
      maxSilencesToSkip,
      regexMatch,
      formatTranscript,
    } = this.state;

    if (this.settingsValidated()) {
      connectedSetFindSetting('maxRankOfSilenceToSkip', maxRankOfSilenceToSkip);
      connectedSetFindSetting('maxSilenceSecondsToSkip', maxSilenceSecondsToSkip);
      connectedSetFindSetting('maxSilencesToSkip', maxSilencesToSkip);
      connectedSetFindSetting('regexMatch', regexMatch);

      /* newMetadata is the DIFF between the state of the settings modal
         (i.e. the fields the user intends to change), and the state reflected
         by the API. if two users update different fields, we should be able
         to update them both without one value overwriting another. */
      const newMetadata = {};

      /* reprocessConfig is a dict of parameters to send to the API as reprocessing args. */
      const reprocessConfig = {};

      const words = Object.keys(customizationWords);
      if (this.customizeChanged()) {
        /* Deep clone the customization dict and send it to the API. */
        reprocessConfig.customize_words = {};
        for (let i = 0; i < words.length; i += 1) {
          if (customizationWords[words[i]].changed) {
            reprocessConfig.customize_words[words[i]] = customizationWords[words[i]];
          }
        }
      }

      if (transcriptBalance !== transcriptAmLmBalance) {
        newMetadata.transcript_am_lm_balance = transcriptBalance;
      }
      if (altsBalance !== altsAmLmBalance) {
        newMetadata.alternatives_am_lm_balance = altsBalance;
      }

      if (this.meetingTitleChanged()) {
        newMetadata.meeting_title = meetingTitleText;
      }

      if (this.dynamicRichChanged()) {
        connectedSetDynamicRichificationEnabled(dynamicRichChecked);
        connectedSetRichificationEnabled(formatTranscript);
      }

      /* speaker_names[i] is null if that speaker label is unchanged by the
         user, and therefore won't overwrite previous unchecked updates. this
         allows us to be granular about speaker label updates at the level of
         individual fields */
      newMetadata.speaker_names = [];
      for (let i = 0; i < speakerMetadata.length; i += 1) {
        if (
          speakerMetadata[i].label
          !== getSpeakerNameAtIndex(meeting, currentSpeakerMetadata, i)
          && !speakerMetadata[i].label.toLowerCase().includes('(shared audio)')
        ) {
          newMetadata.speaker_names.push(speakerMetadata[i].label);
        } else {
          newMetadata.speaker_names.push(null);
        }
      }

      if (!shared) {
        await connectedUpdateMetadata(
          (oldMetadata) => {
            const result = {
              ...oldMetadata,
              ...newMetadata,
              speaker_names: [...newMetadata.speaker_names], /* deep copy */
            };

            /* fill in the existing, unchanged speaker labels */
            for (let i = 0; i < result.speaker_names.length; i += 1) {
              if (result.speaker_names[i] === null) {
                if (oldMetadata && oldMetadata.speaker_names) {
                  result.speaker_names[i] = oldMetadata.speaker_names[i];
                } else {
                  result.speaker_names[i] = getSpeakerNameAtIndex(
                    meeting, currentSpeakerMetadata, i,
                  );
                }
              }
            }

            return result;
          },
          {
            updated: meeting.updated,
            retries: 5,
          },
        );

        /* HACK: For now, submit email thru /feedback endpoint so we can put word suggestions into
           mrpdict. */
        if (this.customizeChanged()) {
          await connectedSendFeedback({
            jobId: meeting.id,
            message: reprocessConfig,
          });
        }

        /* TODO: Comment this out until we have a way to reprocess meetings with a new model. */
        // if (requiresReprocess) {
        //   await connectedReprocessMeeting({
        //     id: meeting.id,
        //     body: reprocessConfig,
        //   });
        //   connectedUpdateRecognition(meeting.id);
        // }

        /* Remove all the customization words */
        for (let i = 0; i < words.length; i += 1) {
          connectedRemoveCustomizationWord(words[i]);
        }
      } else {
        /* We cannot update metadata in shared mode. However we allow people to
           change AM/LM ratio even when they are shared users. So instead of
           updating metadata and forcing the meeting to be refreshed and stored
           in Redux, we update the state directly so all changes are local. */
        connectedSetAltsAmLmBalance(altsBalance);
        connectedSetTranscriptAmLmBalance(transcriptBalance);
      }

      this.setState({
        waitingForSubmit: false,
      });
    }

    this.handleCloseModal();
  }

  toggleApplyButton = () => {
    this.setState({
      applyButtonActive: this.settingsChanged() && this.settingsValidated(),
      requiresReprocess: false, // this.customizeChanged(),
    });
  }

  onTranscriptBalanceChange = (val) => {
    this.setState({
      transcriptBalance: val / 2,
    }, this.toggleApplyButton);
  }

  onAltsBalanceChange = (val) => {
    this.setState({
      altsBalance: val / 2,
    }, this.toggleApplyButton);
  }

  togglePane = (newPane) => {
    this.setSettingsPane(newPane);
  }

  onMeetingTitleTextChanged = (e) => {
    this.setState({
      meetingTitleText: e.target.value,
    }, this.toggleApplyButton);
  };

  onSpeakerFieldTextChanged = (e) => {
    const index = e.target.id.slice('speaker-field-'.length);
    const { speakerMetadata } = this.state;
    const newSpeakerMetadata = [...speakerMetadata];
    newSpeakerMetadata[index] = { ...newSpeakerMetadata[index], label: e.target.value };
    this.setState({
      speakerMetadata: newSpeakerMetadata,
    }, this.toggleApplyButton);
  }

  onShareButtonClicked = () => {
    const {
      connectedCreateShareLinkWithName,
      meeting,
    } = this.props;

    const { shareLinkNameText } = this.state;

    connectedCreateShareLinkWithName(meeting.id, shareLinkNameText);

    this.setState({
      shareLinkNameText: '',
    });
  }

  onDeleteShareLinkButtonClicked = (link) => {
    const {
      connectedDeleteShareLinkWithKey,
      meeting,
    } = this.props;

    const {
      shareLinkDeleteNotified,
      shareLinkDeleteNotification,
    } = this.state;

    const { share_key: key } = link;

    return () => {
      if (!shareLinkDeleteNotified
          || (shareLinkDeleteNotified && shareLinkDeleteNotification !== key)
      ) {
        /* Notify a user if they want to delete a link */
        this.setState({
          shareLinkDeleteNotified: true,
          shareLinkDeleteNotification: key,
        });
      } else {
        this.setState({
          shareLinkDeleteNotified: false,
          shareLinkDeleteNotification: undefined,
        });
        connectedDeleteShareLinkWithKey(meeting.id, key);
      }
    };
  }

  setSettingsPane = (pane) => {
    const {
      connectedSetSettingsPane,
    } = this.props;

    connectedSetSettingsPane(pane);
  }

  deleteRecognition = (event, id) => {
    const { connectedDeleteMeeting, connectedPush } = this.props;
    event.preventDefault();
    connectedDeleteMeeting(id).then(() => { connectedPush('/'); });
  }

  renderDeleteButton = () => {
    const {
      deleteModalOpen,
    } = this.state;

    const {
      meeting,
      shared,
    } = this.props;

    if (shared) {
      return null;
    }

    return (
      <>
        <Divider />
        <Modal
          trigger={(
            <Button
              className="re is-marginTop-16px"
              key="trash-header-button"
              onClick={() => { this.setState({ deleteModalOpen: true }); }}
              color="red"
              title="Delete recording"
            >
              Delete recording
            </Button>
          )}
          open={deleteModalOpen}
          size="mini"
          onClose={() => { this.setState({ deleteModalOpen: false }); }}
          onClick={(e) => { e.stopPropagation(); }}
        >
          <Modal.Header content="Are you sure that you want to delete this recording?" />
          <Modal.Actions>
            <Button
              onClick={() => { this.setState({ deleteModalOpen: false }); }}
            >
              Cancel
            </Button>
            <Button
              color="red"
              onClick={(e) => this.deleteRecognition(e, meeting.id)}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }

  renderGeneralSettings = () => {
    const {
      meetingTitleText,
      speakerMetadata,
    } = this.state;

    const { speakerMetadata: currentSpeakerMetadata, meeting } = this.props;
    const isZoomOptimized = getMetadata(meeting, 'optimized_zoom') !== false;

    /* NOTE(Elliot): Before I refactored it, the event handler on the
       <input> was an onInput, which differs from onChange DOM
       behavior. However in React, it appears to be the case that onInput
       and onChange behave the same.
       See https://stackoverflow.com/a/40003179
     */
    return (
      <div className="settings-pane">
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={2}>
            <b>Title:</b>
          </Grid>
          <Grid item xs={10}>
            <input
              type="text"
              value={meetingTitleText}
              onChange={this.onMeetingTitleTextChanged}
              placeholder="Give this recording a title..."
            />
          </Grid>
        </Grid>
        {
          isZoomOptimized
          && (
          <>
            <div className="settings-pane-subheader">
              <b>Participants:</b>
            </div>
            <Grid
              container
              spacing={2}
            >
              {
                speakerMetadata && speakerMetadata.length > 0 && (
                  speakerMetadata.map(
                    (speaker, index) => {
                      const key = `${sanitizeHtmlAttr(currentSpeakerMetadata[index].label)}-${index}`;
                      if (!currentSpeakerMetadata[index].label.includes('(Shared Audio)')) {
                        return (
                          <React.Fragment key={key}>
                            <Grid item xs={2}>
                              <div
                                className="speaker-label-badge"
                                id={`speaker-label-${key}`}
                                key={`speaker-label-key-${key}`}
                                style={{
                                  color: colorWithAlpha(speaker.hue, 1),
                                  borderColor: colorWithAlpha(speaker.hue, 1),
                                }}
                              >
                                { speakerLabelToAbbrev(speaker.label) }
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <input
                                id={`speaker-field-${index}`}
                                type="text"
                                value={speaker.label}
                                onChange={this.onSpeakerFieldTextChanged}
                                placeholder="This speaker's name..."
                              />
                            </Grid>
                          </React.Fragment>
                        );
                      }
                      return null;
                    },
                  )
                )
              }
            </Grid>
          </>
          )
        }
      </div>
    );
  }

  renderSharingSettings = () => {
    const {
      shareLinks,
      meeting,
      getShareLinksRequestOut,
      deleteShareLinkRequestOut,
      createShareLinkRequestOut,
      getShareLinksRequestError,
      createShareLinkRequestError,
      deleteShareLinkRequestError,
    } = this.props;

    const {
      shareLinkNameText,
      shareLinkNotifyCopy,
      shareLinkDeleteNotification,
    } = this.state;

    const hn = window.location.origin;

    const displayedError = (
      getShareLinksRequestError
      || createShareLinkRequestError
      || deleteShareLinkRequestError
    );

    return (
      <div className="settings-pane">
        <p>Manage who can view this recording by creating and deleting share links.</p>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <button
              color="primary"
              disabled={(
                deleteShareLinkRequestOut
                || createShareLinkRequestOut
                || getShareLinksRequestOut
              )}
              onClick={this.onShareButtonClicked}
              className="add-share-link-button"
              type="button"
            >
              {
                createShareLinkRequestOut
                  ? <Spinner size="sm" />
                  : '+'
              }
            </button>
          </Grid>
          <Grid item xs={6}>
            <input
              type="text"
              placeholder="Custom text in link (optional)"
              onChange={(e) => { this.setState({ shareLinkNameText: e.target.value }); }}
              onKeyDown={
                (e) => {
                  if (e.keyCode === keyCodes.ENTER && shareLinkNameText.length) {
                    this.onShareButtonClicked();
                  }
                }
              }
              value={shareLinkNameText}
            />
          </Grid>
        </Grid>
        {
          displayedError && (
            displayedError.error
              ? <p className="share-link-error">{displayedError.error.error}</p>
              : (
                <p className="share-link-error">
                  An error occurred performing the action you requested.
                </p>
              )
          )
        }
        <br />
        <Grid
          container
          spacing={2}
          className="share-links-grid"
        >
          {
            !shareLinks && getShareLinksRequestOut
              ? 'Loading all sharing links...'
              : shareLinks && [...shareLinks].reverse().map((link) => {
                let shareURL;
                if (link.short_url) {
                  if (link.short_url.includes('mtg.re')) {
                    /* Short URL is included already */
                    shareURL = link.short_url;
                  } else {
                    /* Prepend short URL path with correct base URL */
                    const apiURL = determineRemeetingApiBaseUrl(window.location.hostname);
                    if (apiURL.includes('api.remeeting.com')) {
                      /* Since we target the Prod API we use the prod shortener */
                      shareURL = `mtg.re/${link.short_url}`;
                    } else {
                      shareURL = `dev.mtg.re/${link.short_url}`;
                    }
                  }
                } else {
                  shareURL = `${hn}/app/r/${meeting.id}?s=${link.share_key}`;
                }

                /* For even more short URLs, we remove the URL scheme from the beginning */
                if (shareURL.startsWith('https://')) {
                  shareURL = shareURL.slice('https://'.length);
                }
                return (
                  <Grid item xs={12} key={link.pw}>
                    <div className="share-link-item">
                      <div className="share-link-trash">
                        <button
                          onClick={this.onDeleteShareLinkButtonClicked(link)}
                          className="share-link-delete-button"
                          disabled={shareLinkDeleteNotification === link.share_key
                                    || deleteShareLinkRequestOut}
                          type="button"
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                      <div className="share-link-info">
                        <div
                          className="share-link-name"
                          onMouseLeave={
                            () => { this.setState({ shareLinkNotifyCopy: undefined }); }
                          }
                        >
                          <input
                            className="share-link-url"
                            value={shareURL}
                            title={shareURL}
                            onClick={(e) => (e.target.select())}
                            spellCheck="false"
                          />
                          <div className="share-link-copy-container">
                            { shareLinkNotifyCopy
                              ? (
                                <div className="share-link-copy">
                                  <div className="share-link-copy-done">
                                    <FontAwesomeIcon icon={faCheckCircle} className="share-link-check-circle" />
                                  </div>
                                  <span className="share-link-copied">Copied!</span>
                                </div>
                              )
                              : (
                                <button
                                  className="share-link-copy-button"
                                  type="button"
                                  title="Copy"
                                  onClick={() => {
                                    copyToClipboard(shareURL);
                                    this.setState({ shareLinkNotifyCopy: link.share_key });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCopy} />
                                </button>
                              )}
                          </div>
                        </div>
                        <p className="share-link-created">
                          Created&nbsp;
                          <RemeetingUserFriendlyDate
                            date={link.created}
                            capitalizeBeginning={false}
                          />
                        </p>
                        {
                          shareLinkDeleteNotification === link.share_key && (
                            <>
                              <p className="share-link-notice">
                                Are you sure you want to delete this share link?
                                <br />
                                Anyone who was given this link will lose access to this recording.
                              </p>
                              <Button
                                onClick={() => {
                                  this.setState({
                                    shareLinkDeleteNotification: undefined,
                                    shareLinkDeleteNotified: false,
                                  });
                                }}
                                size="tiny"
                              >
                                Cancel
                              </Button>
                              <Button
                                color="red"
                                size="tiny"
                                onClick={this.onDeleteShareLinkButtonClicked(link)}
                                disabled={deleteShareLinkRequestOut}
                              >
                                Delete
                              </Button>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </Grid>
                );
              })
          }
        </Grid>
      </div>
    );
  }

  renderCustomizationSettings = () => (
    <div className="settings-pane">
      {/* TODO: Remove the first line when we can reprocess meetings with a new model */}
      <p>
        Submit new words and pronunciations to add to Remeeting&apos;s global dictionary.
        <br />
        Clicking &quot;Cancel&quot; will not discard your pending changes.
      </p>
      <CustomizeTable />
    </div>
  );

  renderSnapshotSettings = () => {
    const { meeting } = this.props;

    const snapshotLinks = [];

    let { snapshot } = queryString.parse(window.location.search.slice(1));
    if (!snapshot) {
      snapshot = meeting.snapshot;
    }

    const { shareKey } = meeting;

    const goToCurrentHandler = () => {
      let shareQuery = '';
      if (shareKey) {
        shareQuery = `?s=${shareKey}`;
      }
      window.location.href = `/app/r/${meeting.id}${shareQuery}`;
    };

    if (snapshot) {
      snapshotLinks.push(
        <div
          className="snapshot-link"
          onClick={goToCurrentHandler}
          onKeyDown={(e) => { if (e.which === 13) goToCurrentHandler(); }}
          role="button"
          tabIndex={0}
          key="snapshot-link-current"
        >
          View latest
        </div>,
      );
    } else {
      snapshotLinks.push(
        <div
          className="snapshot-link selected"
          key="snapshot-link-current"
        >
          Viewing latest
        </div>,
      );
    }

    if (meeting.snapshots) {
      snapshotLinks.push(...meeting.snapshots.map((date) => {
        let currSnapshot = false;

        if (window.location.search) {
          if (snapshot === date) {
            currSnapshot = true;
          }
        }

        if (currSnapshot) {
          return (
            <div
              className="snapshot-link selected"
              key={`snapshot-link-${encodeURIComponent(date)}`}
            >
              <RemeetingUserFriendlyDate date={date} />
              {'  '}
              <span className="snapshot-link-subhead">(viewing)</span>
            </div>
          );
        }

        const encodedDate = encodeURIComponent(date);
        const clickHandler = () => {
          let shareQuery = '';
          if (shareKey) {
            shareQuery = `&s=${shareKey}`;
          }
          window.location.href = `/app/r/${meeting.id}?snapshot=${encodedDate}${shareQuery}`;
        };

        return (
          <div
            className="snapshot-link"
            onClick={clickHandler}
            onKeyDown={(e) => { if (e.which === 13) clickHandler(); }}
            role="button"
            tabIndex={0}
            key={`snapshot-link-${encodeURIComponent(date)}`}
          >
            <RemeetingUserFriendlyDate date={date} />
          </div>
        );
      }));
    }

    return (
      <div className="settings-pane">
        <h3>Snapshots</h3>
        { snapshotLinks }
      </div>
    );
  }

  onRichifyEnabledChange = () => {
    const { formatTranscript } = this.state;
    this.setState({ formatTranscript: !formatTranscript }, this.toggleApplyButton);
  }

  onFindSettingChanged = (key, value) => () => {
    this.setState({ [key]: value }, this.toggleApplyButton);
  }

  renderAdvancedSettings = () => {
    const {
      transcriptBalance,
      altsBalance,
      dynamicRichChecked,
      // regexMatch,
      // maxRankOfSilenceToSkip,
      // maxSilenceSecondsToSkip,
      // maxSilencesToSkip,
      formatTranscript,
    } = this.state;

    return (
      <div className="settings-pane">
        <h3>AM/LM scale</h3>
        <p>
          Speech recognition will consider specific&nbsp;
          <em>sounds</em>
          &nbsp;and probable&nbsp;
          <em>phrases</em>
          .
          <br />
          (More technically: it combines an&nbsp;
          <em>acoustic model</em>
          &nbsp;with a&nbsp;
          <em>language model</em>
          .)
        </p>
        <p>
          Balance the system&apos;s relative attention between these factors when:
        </p>
        <Grid
          container
          spacing={5}
        >
          <Grid item xs={4}>
            <b>Displaying a transcript:</b>
          </Grid>
          <Grid item xs={7}>
            <InputSlider
              onChange={this.onTranscriptBalanceChange}
              value={transcriptBalance * 2}
              leftLabel="Sounds"
              rightLabel="Phrases"
              leftInputLabel={<>AM / LM&nbsp;&nbsp;=</>}
              rightInputLabel={<>1&nbsp;/&nbsp;</>}
              sliderMax={2.00}
              sliderMin={0.01}
              sliderStep={0.01}
              disabled={!dynamicRichChecked && formatTranscript}
            />
          </Grid>
          <Grid item xs={4}>
            <b>Ranking alternatives:</b>
          </Grid>
          <Grid item xs={7}>
            <InputSlider
              onChange={this.onAltsBalanceChange}
              value={altsBalance * 2}
              leftLabel="Sounds"
              rightLabel="Phrases"
              leftInputLabel={<>AM / LM&nbsp;&nbsp;=</>}
              rightInputLabel={<>1&nbsp;/&nbsp;</>}
              sliderMax={2.00}
              sliderMin={0.01}
              sliderStep={0.01}
            />
          </Grid>
        </Grid>
        <h3>Formatted transcript</h3>
        <p>
          When enabled, adds punctuation, capitalization, and other formatting features
          to the transcript. This can be automatically toggled with the &quot;R&quot; key.
          Phrase alternatives can be viewed by disabling this feature.
        </p>
        <div>
          <Switch
            checked={formatTranscript}
            onChange={this.onRichifyEnabledChange}
            id="richify-toggle"
            color="primary"
          />
          <label htmlFor="richify-toggle">Format transcript</label>
        </div>
        <Switch
          checked={dynamicRichChecked}
          onChange={this.onDynamicRichChanged}
          id="dynamic-richify-toggle"
          color="primary"
          disabled={!formatTranscript}
        />
        <label htmlFor="dynamic-richify-toggle">Re-format transcript dynamically (when AM/LM scale is not 1/1)</label>
        { this.renderDeleteButton() }
        {/* <h3>Advanced Find</h3>
        <div>
          <Checkbox
            checked={regexMatch}
            onChange={this.onFindSettingChanged('regexMatch', !regexMatch)}
            name="checkedA"
            color="primary"
            id="regex-match"
          />
          <label htmlFor="regex-match">Find regular expression</label>
        </div>
        <div>
          <Grid container spacing={2} style={{ padding: '0 15px' }}>
            <Grid item xs={4}>
              Maximum phrase rank
              <br />
              of silence to skip:
            </Grid>
            <Grid item xs={8}>
              <input
                type="text"
                style={{ height: '100%', width: 100 }}
                value={maxRankOfSilenceToSkip}
                onChange={(e) => {
                  this.onFindSettingChanged('maxRankOfSilenceToSkip', e.target.value)();
                }}
              />
            </Grid>

            <Grid item xs={4}>
              Maximum seconds
              <br />
              of silence to skip:
            </Grid>
            <Grid item xs={8}>
              <input
                type="text"
                style={{ height: '100%', width: 100 }}
                value={maxSilenceSecondsToSkip}
                onChange={(e) => {
                  this.onFindSettingChanged('maxSilenceSecondsToSkip', e.target.value)();
                }}
              />
            </Grid>

            <Grid item xs={4}>
              Maximum number of
              <br />
              silent phrases to skip:
            </Grid>
            <Grid item xs={8}>
              <input
                type="text"
                style={{ height: '100%', width: 100 }}
                value={maxSilencesToSkip}
                onChange={(e) => {
                  this.onFindSettingChanged('maxSilencesToSkip', e.target.value)();
                }}
              />
            </Grid>
          </Grid>
        </div> */}
      </div>
    );
  }

  renderPaneNumber = (i) => {
    switch (i) {
      case 0:
        return this.renderGeneralSettings();
      case 1:
        return this.renderSharingSettings();
      case 2:
        return this.renderCustomizationSettings();
      // case 3:
      //   return this.renderSnapshotSettings();
      case 3:
        return this.renderAdvancedSettings();
      default:
        return '';
    }
  }

  renderTabs = () => {
    const {
      shared,
      settingsPane,
    } = this.props;

    const paneChanged = (paneName) => {
      switch (paneName) {
        case 'general':
          return (
            this.meetingTitleChanged()
            || this.speakerLabelsChanged()
          );
        case 'advanced':
          return (
            this.dynamicRichChanged()
            || this.amLmBalanceChanged()
            || this.findChanged()
          );
        case 'customization':
          return this.customizeChanged();
        default:
          return false;
      }
    };

    return (
      <div>
        <Nav tabs>
          {
            Object.keys(this.panesShared).map((paneNameArg, index) => {
              let paneName = paneNameArg; // cf. eslint: no-param-reassign
              if (paneName === 'general') paneName = 'meeting';
              return (
                (!shared || this.panesShared[paneName]) && (
                  <NavItem key={`${paneName}-nav-item`}>
                    <NavLink
                      className={settingsPane === index ? 'active' : ''}
                      onClick={() => { this.togglePane(index); }}
                    >
                      {`${
                        paneName.charAt(0).toUpperCase()
                      }${
                        paneName.slice(1)
                      }${
                        paneChanged(paneName) ? '*' : ''
                      }`}
                    </NavLink>
                  </NavItem>
                )
              );
            })
          }
        </Nav>
        <br />
        <TabContent activeTab={settingsPane}>
          {
            Object.keys(this.panesShared).map((paneName, index) => (
              <TabPane key={`${paneName}-tab-pane`} tabId={index}>
                { this.renderPaneNumber(index) }
              </TabPane>
            ))
          }
        </TabContent>
      </div>
    );
  }

  render() {
    const {
      settingsModalOpen,
    } = this.props;

    const {
      applyButtonActive,
      waitingForSubmit,
      requiresReprocess,
    } = this.state;

    let applyText = 'Apply';
    if (requiresReprocess) applyText = 'Apply & Reprocess';

    return (
      <Modal
        className="settings-modal"
        onClose={this.handleCloseModal}
        open={settingsModalOpen}
        closeOnDimmerClick={false}
        style={{ width: '650px' }}
        trigger={(
          <button
            className="transcript-header-button"
            onClick={this.handleOpenModal}
            title="Settings"
            type="button"
          >
            <FontAwesomeIcon icon={faCog} />
          </button>
        )}
      >
        <Modal.Header>Settings</Modal.Header>
        <Modal.Content>
          { this.renderTabs() }
        </Modal.Content>
        <Modal.Actions>
          { this.settingsChanged() && (
            <div style={{ color: '#6a6a6a', float: 'left' }}>
              * Pending changes
            </div>
          ) }
          <Button
            onClick={this.handleCloseModal}
            disabled={waitingForSubmit}
          >
            {
              /* Doesn't make sense to "cancel" in the these panes. */
              this.settingsChanged()
                ? 'Cancel'
                : 'Close'
            }
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="settings-modal-apply"
            primary
            disabled={!applyButtonActive || waitingForSubmit}
            type="submit"
          >
            {
              waitingForSubmit
                ? <Spinner size="sm" />
                : applyText
            }
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SettingsModal.propTypes = {
  connectedClearError: PropTypes.func.isRequired,
  connectedToggleSettingsModal: PropTypes.func.isRequired,
  settingsModalOpen: PropTypes.bool.isRequired,
  transcriptAmLmBalance: PropTypes.number.isRequired,
  altsAmLmBalance: PropTypes.number.isRequired,
  meeting: meetingType,
  speakerMetadata: PropTypes.arrayOf(speakerInfoType),
  connectedUpdateMetadata: PropTypes.func.isRequired,
  connectedGetShareLinks: PropTypes.func.isRequired,
  getShareLinksRequestOut: PropTypes.bool.isRequired,
  getShareLinksRequestError: errorType,
  richificationEnabled: PropTypes.bool.isRequired,
  createShareLinkRequestOut: PropTypes.bool.isRequired,
  deleteShareLinkRequestOut: PropTypes.bool.isRequired,
  dynamicRichificationEnabled: PropTypes.bool.isRequired,
  deleteShareLinkRequestError: errorType,
  createShareLinkRequestError: errorType,
  shareLinks: PropTypes.arrayOf(
    PropTypes.shape({
      share_key: PropTypes.string,
      name: PropTypes.string,
      created: PropTypes.string,
    }),
  ).isRequired,
  connectedCreateShareLinkWithName: PropTypes.func.isRequired,
  connectedSetDynamicRichificationEnabled: PropTypes.func.isRequired,
  connectedDeleteShareLinkWithKey: PropTypes.func.isRequired,
  connectedSetTranscriptAmLmBalance: PropTypes.func.isRequired,
  connectedSetAltsAmLmBalance: PropTypes.func.isRequired,
  connectedRemoveCustomizationWord: PropTypes.func.isRequired,
  connectedSetRichificationEnabled: PropTypes.func.isRequired,
  connectedSetSettingsPane: PropTypes.func.isRequired,
  connectedSendFeedback: PropTypes.func.isRequired,
  connectedSetFindSetting: PropTypes.func.isRequired,
  customizationWords: PropTypes.objectOf(customizationWordType).isRequired,
  shared: PropTypes.bool,
  settingsPane: PropTypes.number.isRequired,
  connectedDeleteMeeting: PropTypes.func.isRequired,
  connectedPush: PropTypes.func.isRequired,
  findSettings: PropTypes.shape({}).isRequired,
};

SettingsModal.defaultProps = {
  meeting: undefined,
  speakerMetadata: [],
  deleteShareLinkRequestError: undefined,
  createShareLinkRequestError: undefined,
  getShareLinksRequestError: undefined,
  shared: undefined,
};

const mapStateToProps = (state) => ({
  settingsModalOpen: state.meeting.settingsModalOpen,
  meeting: state.meeting.meeting,
  postFeedbackError: state.meeting.postFeedbackError,
  postFeedbackRequestOut: state.meeting.postFeedbackRequestOut,
  transcriptAmLmBalance: state.meeting.transcriptAmLmBalance,
  dynamicRichificationEnabled: state.meeting.dynamicRichificationEnabled,
  altsAmLmBalance: state.meeting.altsAmLmBalance,
  speakerMetadata: state.meeting.speakerMetadata,
  getShareLinksRequestError: state.meeting.getShareLinksRequestError,
  getShareLinksRequestOut: state.meeting.getShareLinksRequestOut,
  shareLinks: state.meeting.shareLinks,
  shareUser: state.meeting.shareUser,
  createShareLinkRequestOut: state.meeting.createShareLinkRequestOut,
  deleteShareLinkRequestOut: state.meeting.deleteShareLinkRequestOut,
  deleteShareLinkRequestError: state.meeting.deleteShareLinkRequestError,
  createShareLinkRequestError: state.meeting.createShareLinkRequestError,
  customizationWords: state.customize.words,
  settingsPane: state.meeting.settingsPane,
  richificationEnabled: state.meeting.richificationEnabled,
  findSettings: state.find.findSettings,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedSendFeedback: sendFeedback,
  connectedToggleSettingsModal: toggleSettingsModal,
  connectedSetTranscriptAmLmBalance: setTranscriptAmLmBalance,
  connectedSetAltsAmLmBalance: setAltsAmLmBalance,
  connectedGetMeeting: getMeeting,
  connectedUpdateMetadata: updateMetadata,
  connectedGetShareLinks: getShareLinks,
  connectedCreateShareLinkWithName: createShareLinkWithName,
  connectedDeleteShareLinkWithKey: deleteShareLinkWithKey,
  connectedSetSettingsPane: setSettingsPane,
  connectedSetDynamicRichificationEnabled: setDynamicRichificationEnabled,
  connectedRemoveCustomizationWord: removeWordFromCustomize,
  connectedSetRichificationEnabled: setRichificationEnabled,
  connectedDeleteMeeting: deleteMeeting,
  connectedSetFindSetting: setFindSetting,
  connectedPush: push,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsModal);
