import { FLUSH_STATE } from './meeting';

import { MultiFind } from './utils';

const SET_FIND_FOCUSED = 'FIND::SET_FIND_FOCUSED';
const SET_FIND_QUERY = 'FIND::SET_FIND_QUERY';
const SET_FIND_IS_CURRENT = 'FIND::SET_FIND_IS_CURRENT';
const SET_FIND_RESULT = 'FIND::SET_FIND_RESULT';
const SET_FIND_RESULT_INDEX = 'FIND::SET_FIND_RESULT_INDEX';
const SET_FIND_SETTING = 'FIND::SET_FIND_SETTING';
const SET_FIND_SETTINGS_OPEN = 'FIND::SET_FIND_SETTINGS_OPEN';

const initialState = {
  multiFind: new MultiFind(),
  findResult: undefined,
  findResultIndex: undefined,

  findFocused: false,
  findQuery: '',
  findMatches: undefined,
  findIsCurrent: false,

  findSettings: {
    wholeWordMatch: true,
    regexMatch: false,
    findOCR: false,
    maxSilencesToSkip: 2,
    maxSilenceSecondsToSkip: 1.0,
    /* If maxRankOfSilenceToSkip is set > 1 (i.e., not just 1best), keep in mind we italicize
        text in the UI when it's a search phrase match but not part of the 1best, which won't
        be visible for silences if SILENCE_MARKER in span.jsx is characters (e.g., '...') which
        look the same whether or not they are italicized. */
    maxRankOfSilenceToSkip: 3,
  },
  findSettingsOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLUSH_STATE:
      return { ...initialState };
    case SET_FIND_FOCUSED:
      return { ...state, findFocused: action.findFocused };
    case SET_FIND_QUERY:
      return { ...state, findQuery: action.findQuery };
    case SET_FIND_IS_CURRENT:
      return { ...state, findIsCurrent: action.findIsCurrent };
    case SET_FIND_RESULT:
      return { ...state, findResult: action.findResult };
    case SET_FIND_RESULT_INDEX:
      return { ...state, findResultIndex: action.index };
    case SET_FIND_SETTING:
      return {
        ...state,
        findSettings: {
          ...state.findSettings,
          [action.setting]: action.value,
        },
      };
    case SET_FIND_SETTINGS_OPEN:
      return { ...state, findSettingsOpen: action.shouldOpen };
    default:
      return { ...state };
  }
};

export const setFindFocused = (findFocused) => ({
  type: SET_FIND_FOCUSED,
  findFocused,
});

export const setFindQuery = (findQuery) => ({
  type: SET_FIND_QUERY,
  findQuery,
});

export const setFindIsCurrent = (findIsCurrent) => ({
  type: SET_FIND_IS_CURRENT,
  findIsCurrent,
});

export const setFindResult = (findResult) => ({
  type: SET_FIND_RESULT,
  findResult,
});

export const setFindResultIndex = (index) => ({
  type: SET_FIND_RESULT_INDEX,
  index,
});

export const setFindSetting = (setting, value) => ({
  type: SET_FIND_SETTING,
  setting,
  value,
});

export const setFindSettingsOpen = (shouldOpen) => ({
  type: SET_FIND_SETTINGS_OPEN,
  shouldOpen,
});
