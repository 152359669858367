import React from 'react';
import PropTypes from 'prop-types';

import { smartDate } from '../../modules/utils';

const RemeetingUserFriendlyDate = ({ date, includeTime, capitalizeBeginning }) => (
  <span>{smartDate(date, includeTime, capitalizeBeginning)}</span>
);

RemeetingUserFriendlyDate.propTypes = {
  capitalizeBeginning: PropTypes.bool,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)]),
  includeTime: PropTypes.bool,
};

RemeetingUserFriendlyDate.defaultProps = {
  capitalizeBeginning: true,
  date: '',
  includeTime: true,
};

export default RemeetingUserFriendlyDate;
