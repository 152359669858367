import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dimmer,
  Dropdown,
  Grid,
  Icon,
  Image,
  Label,
  List,
  Loader,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { meetingType } from '../types';

import RemeetingUserFriendlyDate from '../utils/remeeting-user-friendly-date';
import { formatHoursMinutesSeconds, smartDate } from '../../modules/utils';
import {
  getMeetingStartTime,
  getMetadata,
  getMetadataHasTrackException,
  getMeetingParticipants,
  getMeetingTitle,
  openShareLinkModal,
} from '../../modules/meeting';

import {
  clearError,
  listRecognitions,
  pageRecognitions,
  updateRecognitions,
  getThumbnail,
  openCardAction,
  setCardAction,
} from '../../modules/recognitions';

import {
  WARNING_OPTIMIZE_ZOOM,
  WARNING_UNEXPECTED_FAILURE,
  WARNING_CORRUPTED_ZOOM_RECORDING,
  RECOGNITION_DELETE,
  RECOGNITION_UPDATE_METADATA,
} from './cardActionModal';

import successfulMeetingImage from '../../images/successful-meeting-756x424.png';
import processingMeetingImage from '../../images/processing-meeting-756x424.png';

export const Processing = (recog) => recog.status && recog.status !== 'completed' && recog.status !== 'failed';

class RecognitionTableItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };
  }

  componentDidMount() {
    const { connectedGetThumbnail, meetingThumbnails, recognition } = this.props;
    if (!(recognition.id in meetingThumbnails)) {
      connectedGetThumbnail(recognition.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { connectedGetThumbnail, recognition } = this.props;
    if (prevProps.recognition !== recognition
        && (prevProps.recognition.status !== recognition.status || Processing(recognition))) {
      connectedGetThumbnail(recognition.id);
    }
  }

  renderMeetingDuration = (recognition) => {
    const duration = getMetadata(recognition, 'recording_duration');
    if (duration) {
      return formatHoursMinutesSeconds(duration, false, true);
    }
    return '';
  }

  renderRecognitionItemOptions = (recognition, view) => {
    const {
      connectedOpenCardAction,
      connectedSetCardAction,
      connectedOpenShareLinkModal,
    } = this.props;
    const { isHovering } = this.state;
    const dropdownMenuStyle = view === 'grid'
      ? { top: '-10px', right: '5px' } : { top: '-50px', right: '5px' };
    const disableItem = recognition.status === 'waiting' || recognition.status === 'processing';

    return (
      <Dropdown
        className="recognition-item-ellipsis-icon"
        icon="ellipsis vertical"
        pointing="right"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ visibility: isHovering ? 'visible' : 'hidden' }}
      >
        <Dropdown.Menu
          className={view === 'grid' ? '' : 'dropdown-table-view'}
          style={dropdownMenuStyle}
        >
          <Dropdown.Item
            id="dropdown-item-pencil"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              connectedOpenCardAction();
              connectedSetCardAction(cloneDeep(recognition), RECOGNITION_UPDATE_METADATA);
            }}
          >
            <FontAwesomeIcon
              id="recognition-item-pencil-icon"
              className="ui pencil icon"
              icon={faPen}
              size="sm"
            />
            Edit Title
          </Dropdown.Item>
          <Dropdown.Item
            id="dropdown-item-share"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              connectedOpenShareLinkModal(recognition.id);
            }}
          >
            <Icon
              id="recognition-item-share-icon"
              name="share"
            />
            Share
          </Dropdown.Item>
          <Dropdown.Item
            id="dropdown-item-trash"
            disabled={disableItem}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              connectedOpenCardAction();
              connectedSetCardAction(recognition, RECOGNITION_DELETE);
            }}
          >
            <Icon
              id="recognition-item-trash-icon"
              name="trash"
            />
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderMeetingTitle = (recognition, view) => {
    let title = getMeetingTitle(recognition);

    if (!title) { title = getMeetingStartTime(recognition); }

    if (!title) { title = recognition.id.substring(0, 6); }

    let meetingTitle = title;
    if (title === getMeetingStartTime(recognition)) {
      meetingTitle = smartDate(title, true, true);
    }

    return (
      <div className="card-title-wrapper">
        <div
          id={`card-title-${recognition.id}`}
          className="rec-meeting-title"
          title={meetingTitle}
        >
          {
            (title === getMeetingStartTime(recognition))
              ? <RemeetingUserFriendlyDate date={title} />
              : title
          }
        </div>
        {this.renderRecognitionItemOptions(recognition, view)}
      </div>
    );
  }

  renderThumbnailImage = (recognition) => {
    const { meetingThumbnails } = this.props;
    if (!meetingThumbnails[recognition.id]) {
      if (recognition.status !== 'completed') {
        return processingMeetingImage;
      }
      return successfulMeetingImage;
    }
    return meetingThumbnails[recognition.id];
  }

  thumbnailLabelWithPopup = (recognition) => {
    const isZoomOptimized = getMetadata(recognition, 'optimized_zoom') !== false;
    const hasTrackException = getMetadataHasTrackException(recognition);
    const isZoomExpected = isZoomOptimized && !hasTrackException;
    const { connectedOpenCardAction, connectedSetCardAction } = this.props;
    if (recognition.status === 'failed') {
      return (
        <Popup
          basic
          className="thumbnail-popup"
          content="An error occured while processing this recording."
          inverted
          position="right center"
          size="tiny"
          trigger={(
            <Label
              className="zoom-warning-label failure"
              color="red"
              corner="left"
              icon="exclamation triangle"
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                connectedOpenCardAction();
                connectedSetCardAction(recognition, WARNING_UNEXPECTED_FAILURE);
              }}
            />
          )}
        />
      );
    }
    const messageTitle = hasTrackException ? 'This Zoom recording was corrupted.'
      : 'Configure Zoom to record separate audio files';
    if (!isZoomExpected && recognition.status !== 'failed') {
      return (
        <Popup
          basic
          className="thumbnail-popup"
          content={messageTitle}
          inverted
          position="right center"
          size="tiny"
          trigger={(
            <Label
              className="zoom-warning-label"
              color="yellow"
              corner="left"
              icon="exclamation triangle"
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                connectedOpenCardAction();
                if (hasTrackException) {
                  connectedSetCardAction(recognition, WARNING_CORRUPTED_ZOOM_RECORDING);
                } else {
                  connectedSetCardAction(recognition, WARNING_OPTIMIZE_ZOOM);
                }
              }}
            />
          )}
        />
      );
    }
    return null;
  }

  renderThumbnail = (recognition) => {
    const { meetingsView } = this.props;
    return (
      <Segment className={`ui re ${meetingsView} segment`}>
        {(recognition.status === 'waiting' || recognition.status === 'processing')
          && <Dimmer active className="ui thumbnail dimmer"><Loader className="ui thumbnail loader" active indeterminate /></Dimmer>}
        <Image
          className="thumbnail-image"
          src={this.renderThumbnailImage(recognition)}
        />
        { this.thumbnailLabelWithPopup(recognition) }
      </Segment>
    );
  }

  renderTopics = (recognition) => {
    let asrKeywords;
    if (getMetadata(recognition, 'top_keywords')) {
      asrKeywords = getMetadata(recognition, 'top_keywords');
    }

    if (getMetadata(recognition, 'top_keywords_asr')) {
      asrKeywords = getMetadata(recognition, 'top_keywords_asr');
    }

    if (asrKeywords) {
      return asrKeywords.join(', ').replace(/\._/g, '.').replace(/_'/g, "'").replace(/_/g, '\u00A0');
    }

    return '';
  }

  handleMouseHoverOpen = () => {
    this.setState({ isHovering: true });
  }

  handleMouseHoverClose = () => {
    this.setState({ isHovering: false });
  }

  render() {
    const {
      recognition,
      meetingsView,
    } = this.props;

    if (meetingsView !== 'grid') {
      /**
       * NOTE: Wrapping table elements in a <a> tag is invalid HTML
       * ... but we don't really care.
       */
      return (
        <Link
          className="recognitions-item"
          to={`/r/${recognition.id}`}
          key={recognition.id}
          onMouseOver={this.handleMouseHoverOpen}
          onMouseLeave={this.handleMouseHoverClose}
        >
          <Table.Cell collapsing textAlign="center">
            <Segment className="ui duration segment">
              {
                (this.renderMeetingDuration(recognition) !== '')
                  && (
                    <div className="ui bottom left attached duration label">
                      {this.renderMeetingDuration(recognition)}
                    </div>
                  )
              }
              {this.renderThumbnail(recognition)}
            </Segment>
          </Table.Cell>
          <Table.Cell>
            <div style={{ position: 'relative' }}>
              {this.renderMeetingTitle(recognition, meetingsView)}
            </div>
          </Table.Cell>
          <Table.Cell>
            <RemeetingUserFriendlyDate
              date={getMeetingStartTime(recognition)}
            />
          </Table.Cell>
          <Table.Cell>
            <span>{getMeetingParticipants(recognition)}</span>
          </Table.Cell>
          <Table.Cell className="recognition-topics">
            {this.renderTopics(recognition)}
          </Table.Cell>
        </Link>
      );
    }

    if (meetingsView === 'grid') {
      return (
        <List
          horizontal
        >
          <Link
            to={`/r/${recognition.id}`}
            className="recognitions-item"
            onMouseOver={this.handleMouseHoverOpen}
            onMouseLeave={this.handleMouseHoverClose}
          >
            <div
              key={recognition.id}
              className="ui meetings center aligned compact segment"
            >
              <Grid columns="2">
                <Grid.Row className="ui meetings image grid" verticalAlign="middle">
                  <Segment className="ui duration segment">
                    {
                      (this.renderMeetingDuration(recognition) !== '')
                        && (
                          <div className="ui bottom left attached duration label">
                            {this.renderMeetingDuration(recognition)}
                          </div>
                        )
                    }
                    {this.renderThumbnail(recognition)}
                  </Segment>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row className="ui meetings grid" verticalAlign="middle">
                  <Grid.Column textAlign="center">
                    {this.renderMeetingTitle(recognition, meetingsView)}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row verticalAlign="middle">
                  <Grid.Column>
                    <div className="rec-meeting-body">
                      <div>
                        <span style={{ color: '#262626' }}>
                          <RemeetingUserFriendlyDate
                            date={getMeetingStartTime(recognition)}
                          />
                        </span>
                      </div>
                      {(getMeetingParticipants(recognition))
                        && (
                          <span>
                            <span style={{ color: '#262626' }}>{getMeetingParticipants(recognition)}</span>
                          </span>
                        )}
                      {(this.renderTopics(recognition))
                        && (
                          <div>
                            <span className="recognition-topics">{this.renderTopics(recognition)}</span>
                          </div>
                        )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Link>
        </List>
      );
    }

    /* To silence ESLint */
    return '';
  }
}

RecognitionTableItem.propTypes = {
  connectedGetThumbnail: PropTypes.func.isRequired,
  connectedOpenCardAction: PropTypes.func.isRequired,
  connectedOpenShareLinkModal: PropTypes.func.isRequired,
  connectedSetCardAction: PropTypes.func.isRequired,
  meetingThumbnails: PropTypes.shape({}).isRequired,
  recognition: meetingType.isRequired,
  meetingsView: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.recognitions.error,
  listRecognitionsRequestOut: state.recognitions.listRecognitionsRequestOut,
  meetingThumbnails: state.recognitions.meetingThumbnails,
  pageRecognitionsRequestOut: state.recognitions.pageRecognitionsRequestOut,
  recognitionsLoaded: state.recognitions.recognitionsLoaded,
  rmi: state.rmi,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedListRecognitions: listRecognitions,
  connectedOpenCardAction: openCardAction,
  connectedOpenShareLinkModal: openShareLinkModal,
  connectedPageRecognitions: pageRecognitions,
  connectedSetCardAction: setCardAction,
  connectedUpdateRecognitions: updateRecognitions,
  connectedGetThumbnail: getThumbnail,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecognitionTableItem);
