import Remeeting from 'remeeting';

/* RMI stands for Remeeting Instance, and exports the singleton
 * remeeting instance that the rest of the app uses. This will replace
 * all of the individual instances in the other modules.
 *
 * For right now, the rmi will be stored in redux so that it is easy to
 * get a handle on in containers and modules, but it may be correct to have
 * this be a raw js module.
 *
 * The saved state from local storage will not include a Remeeting Instance,
 * as it will store authentication information in the session store. This
 * increases predictablity and isolates that behavoir to the session actions.
 *
 * REALLY IMPORTANT NOTE:
 * DO NOT DO ANYTHING EVEN REMOTELY RELATED TO AUTHENTICATION OR IDENTITY
 * MANAGEMENT OR SESSION STATE HERE. See mrp-www/auth.
 */

/**
 * determineRemeetingApiBaseUrl
 * @param hostname String
 * @return baseUrl String
 * @NOTES: This is useful for developing against the dev api most of the time,
 *   which is nice because it means you can develop docker-less. However, it
 *   lets a developer develop against their local api, by using dockerhost.
 */
export const determineRemeetingApiBaseUrl = (hostname) => {
  // When developing locally or when testing www-dev.remeeting.com use the dev api
  if (hostname === 'localhost' || hostname.startsWith('www-')
      // ... or when testing from our dev servers (e.g. octo1.mod9.com or o2.mod9.co)
      || hostname.startsWith('octo') || hostname.endsWith('mod9.co')) {
    return 'https://api-dev.remeeting.com';
  }

  // When developing locally against dockerhost (which is the same as localhost as of 2021),
  // use the local dockerized api (which could also be localhost:8082)
  if (hostname === 'dockerhost') {
    return 'http://dockerhost:8082';
  }

  // Otherwise, use the production api
  return 'https://api.remeeting.com';
};

const initialState = new Remeeting({
  baseUrl: determineRemeetingApiBaseUrl(window.location.hostname),
});
initialState.origin = window.location.origin;

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
