import queryString from 'query-string';
import UAParser from 'ua-parser-js';

/**
 * Session
 *
 * This slice of the redux store is intended to be the single place where authentication
 * information is set/retrieved. This allows for the rmi to be easily instantiated, without
 * ever storing the rmi in local storage, it also isolates knowlege of the email/password,
 * though email may be moved to the account store.
 */

/**
 * ##############################################
 * # ACTION CONSTANTS
 * ##############################################
 */
export const CLEAR_BASIC_AUTH = 'SESSION::CLEAR_BASIC_AUTH';
export const CLEAR_ERROR = 'SESSION::CLEAR_ERROR';
// NOTE: see intercom.js for non-imported definition of this same constant.
export const END_SESSION = 'SESSION::END_SESSION';
export const REFRESH_TOKEN_FAIL = 'SESSION::REFRESH_TOKEN_FAIL';
export const RMI_AUTH_SET = 'SESSION::RMI_AUTH_SET';
export const SET_USER_AGENT = 'SESSION::SET_USER_AGENT';
export const SESSION_ERROR = 'SESSION::SET_SESSION_ERROR';

/**
 * ##############################################
 * # INITIAL STATE - STATE SIGNATURE
 * ##############################################
 */
const initialState = {
  email: '',
  error: undefined,
  rmiAuthSet: false,
  signedIn: false,
  userAgent: {},
};

/**
 * ##############################################
 * # REDUCER
 * ##############################################
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERROR:
      return { ...state, error: undefined, verificationFail: false };
    case END_SESSION:
      return { ...initialState };
    case REFRESH_TOKEN_FAIL:
      return { ...state, error: action.error };
    case RMI_AUTH_SET:
      return { ...state, rmiAuthSet: true };
    case SESSION_ERROR:
      return { ...state, error: action.error };
    case SET_USER_AGENT:
      return { ...state, userAgent: action.userAgent };
    default:
      return state;
  }
};

/**
 * ##############################################
 * # ACTIONS
 * ##############################################
 *
 * NOTES:
 * 1. In a not very redux-y way, most of the actions in session have a side effect that manages the
 *    authentication state of the RMI
 * 2. To make the behavior of #1 less unbearable, session actions should be the ONLY place in the
 *    review app that rmi auth is managed.
 * 3. sessionStore.rmiAuthSet is generally to be trusted, but could be out of sync during small
 *    parts of the authentication cycle, for example, between when sendSignInRequest is called, and
 *    when the request resolves, the rmi is authenticated (ish), but rmiAuthSet is false. Also,
 *    when loading from local storage, there will be a time that rmiAuthSet could be 'true', even
 *    though the rmi hasn't been instantiated yet.
 */
export const clearError = () => ({
  type: CLEAR_ERROR,
});

/**
 * This will serve as the error callback function to remeeting-heartbeat
 */
export const refreshTokenFail = (error) => ({
  type: REFRESH_TOKEN_FAIL,
  error,
});

export const endSession = () => (dispatch, getState) => {
  const { rmi } = getState();
  rmi.setAuth({});
  dispatch({ type: END_SESSION });

  let fromPath = '/app';
  if (getState().router.location && getState().router.location.pathname) {
    fromPath += getState().router.location.pathname;
  }

  let redirectPath = `/auth/signout?from=${fromPath}`;
  if (getState().router.location.search) {
    const { e } = queryString.parse(getState().router.location.search);
    if (e) {
      redirectPath += `&e=${e}`;
    }
  }

  window.location.href = redirectPath;
};

export const clearBasicAuth = () => (dispatch, getState) => {
  const { rmi } = getState();
  const key = getState().session.temporaryKey;

  // This will clear the auth information in the rmi object
  // while setting the session token
  rmi.setAuth({ key, clearAuth: true });
  dispatch({ type: CLEAR_BASIC_AUTH });
};

export const setRmiAuth = (config = {}) => (dispatch, getState) => {
  const { rmi } = getState();
  const { email } = getState().session;
  const key = getState().session.temporaryKey;
  const password = config.password || undefined;

  rmi.setAuth({ email, password, key });
  dispatch({ type: RMI_AUTH_SET });
};

export const setUserAgent = () => ({
  type: SET_USER_AGENT,
  userAgent: UAParser(window.navigator.userAgent),
});
