import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { Icon } from 'semantic-ui-react';

import {
  setVolume,
  setVolumeFocused,
  setVolumeSliderFocused,
  setVolumeHovering,
  setVolumeIsMuted,
} from '../../../modules/player';

class VolumeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSeeking: false,
      volumePanelRef: null,
      volumeSliderRef: null,
    };
  }

  onButtonBlur = () => {
    const { connectedSetVolumeFocused } = this.props;
    connectedSetVolumeFocused(false);
  }

  onButtonFocus = () => {
    const { connectedSetVolumeFocused } = this.props;
    connectedSetVolumeFocused(true);
  }

  onPanelBlur = () => {
    const { connectedSetVolumeSliderFocused } = this.props;
    connectedSetVolumeSliderFocused(false);
  }

  onPanelFocus = () => {
    const { connectedSetVolumeSliderFocused } = this.props;
    connectedSetVolumeSliderFocused(true);
  }

  onMouseOver = () => {
    const { connectedSetVolumeHovering } = this.props;
    connectedSetVolumeHovering(true);
  }

  onMouseLeave = () => {
    const { connectedSetVolumeHovering } = this.props;
    connectedSetVolumeHovering(false);
  }

  onSliderMouseDown = (e) => {
    const {
      connectedSetVolume,
      connectedSetVolumeSliderFocused,
      connectedSetVolumeIsMuted,
      isFullScreen,
    } = this.props;
    e.preventDefault();
    const { volumePanelRef, volumeSliderRef } = this.state;
    if (!volumeSliderRef) return;

    const sliderWidth = isFullScreen ? 78 : 52;

    this.setState({ isSeeking: true });
    document.addEventListener('mousemove', this.onSliderMouseMove, false);
    document.addEventListener('mouseup', this.onSliderMouseUp, false);
    let relativeX = e.pageX - volumeSliderRef.getBoundingClientRect().left;
    relativeX = Math.min(Math.max(relativeX, 0), volumeSliderRef.clientWidth);
    const volume = (relativeX / sliderWidth) * 100;

    connectedSetVolumeSliderFocused(true);
    if (volumePanelRef) {
      volumePanelRef.focus(); // Manually focus volume panel because we prevented default behavior.
    }
    connectedSetVolumeIsMuted(false);
    connectedSetVolume(volume);
  }

  onSliderMouseMove = (e) => {
    const { isSeeking } = this.state;

    if (isSeeking) {
      const { connectedSetVolume, isFullScreen } = this.props;
      const { volumeSliderRef } = this.state;
      if (!volumeSliderRef) return;

      const sliderWidth = isFullScreen ? 78 : 52;

      let relativeX = e.pageX - volumeSliderRef.getBoundingClientRect().left;
      relativeX = Math.min(Math.max(relativeX, 0), volumeSliderRef.clientWidth);
      const volume = (relativeX / sliderWidth) * 100;
      connectedSetVolume(volume);
    }
  }

  onSliderMouseUp = () => {
    this.setState({ isSeeking: false });
    document.removeEventListener('mousemove', this.onSliderMouseMove, false);
    document.removeEventListener('mouseup', this.onSliderMouseUp, false);
  }

  setVolumePanelRef = (volumePanelRef) => {
    this.setState({ volumePanelRef });
  }

  setVolumeSliderRef = (volumeSliderRef) => {
    this.setState({ volumeSliderRef });
  }

  /**
   * Toggle the volume to quickly turn it on or off. If volume is already 0, just set it to 42.
   */
  toggleMute = () => {
    const { connectedSetVolumeIsMuted, volumeIsMuted } = this.props;
    connectedSetVolumeIsMuted(!volumeIsMuted);
  }

  render() {
    const {
      volume,
      volumeIsMuted,
      isFullScreen,
      isMini,
    } = this.props;
    const computedVolume = volumeIsMuted ? 0 : volume;
    /* The integer term was caluclated as volume panel width - volume
     * slider handle width.
     */
    const sliderHandleLeftPx = isFullScreen
      ? (computedVolume / 100) * 60
      : (computedVolume / 100) * 40;

    const fontSize = isFullScreen ? '1.8em' : '1.25em';

    let volumeIconObj;
    if (computedVolume > 50) {
      volumeIconObj = {
        name: 'volume up',
        style: {
          fontSize,
          margin: '0',
          transform: 'unset',
        },
      };
    } else if (computedVolume > 0 && computedVolume <= 50) {
      volumeIconObj = {
        name: 'volume down',
        style: {
          fontSize,
          margin: '0',
          transform: 'translateX(-2.9px)',
        },
      };
      if (isFullScreen) volumeIconObj.style.transform = 'translateX(-4px)';
      if (isMini) volumeIconObj.style.transform = 'translateX(-4px)';
    } else {
      volumeIconObj = {
        name: 'volume off',
        style: {
          fontSize,
          margin: '0',
          transform: 'translateX(-4.7px)',
        },
      };
      if (isFullScreen) volumeIconObj.style.transform = 'translateX(-6.6px)';
      if (isMini) volumeIconObj.style.transform = 'translateX(-6.7px)';
    }
    return (
      <span
        onMouseLeave={this.onMouseLeave}
        onBlur={this.onMouseLeave}
        onMouseOver={this.onMouseOver}
        onFocus={this.onMouseOver}
      >
        <button
          className="mtg-mute-button mtg-button"
          onBlur={this.onButtonBlur}
          onClick={this.toggleMute}
          onFocus={this.onButtonFocus}
          type="button"
          title={volumeIsMuted ? 'Unmute' : 'Mute'}
        >
          <Icon
            name={volumeIconObj.name}
            style={volumeIconObj.style}
          />
        </button>
        <div
          className="mtg-volume-panel"
          onBlur={this.onPanelBlur}
          onFocus={this.onPanelFocus}
          ref={this.setVolumePanelRef}
          tabIndex={-1}
        >
          <div
            className="mtg-volume-slider"
            ref={this.setVolumeSliderRef}
            onMouseDown={this.onSliderMouseDown}
            onMouseMove={this.onSliderMouseMove}
            onMouseUp={this.onSliderMouseUp}
            role="slider"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={computedVolume}
            tabIndex={0}
          >
            <div
              className="mtg-volume-slider-handle"
              style={{ left: `${sliderHandleLeftPx}px` }}
            />
          </div>
        </div>
      </span>
    );
  }
}

VolumeSlider.propTypes = {
  connectedSetVolume: PropTypes.func.isRequired,
  connectedSetVolumeFocused: PropTypes.func.isRequired,
  connectedSetVolumeSliderFocused: PropTypes.func.isRequired,
  connectedSetVolumeHovering: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
  volumeIsMuted: PropTypes.bool,
  isFullScreen: PropTypes.bool.isRequired,
  isMini: PropTypes.bool.isRequired,
  connectedSetVolumeIsMuted: PropTypes.func.isRequired,
};

VolumeSlider.defaultProps = {
  volumeIsMuted: false,
};

const mapStateToProps = (state) => ({
  volume: state.player.volume,
  isFullScreen: state.meeting.isFullScreen,
  volumeIsMuted: state.player.volumeIsMuted,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSetVolume: setVolume,
  connectedSetVolumeFocused: setVolumeFocused,
  connectedSetVolumeSliderFocused: setVolumeSliderFocused,
  connectedSetVolumeHovering: setVolumeHovering,
  connectedSetVolumeIsMuted: setVolumeIsMuted,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VolumeSlider);
