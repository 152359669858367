import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Dropdown,
  Icon,
  Menu,
  Image,
} from 'semantic-ui-react';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { endSession } from '../modules/session';

import '../stylesheets/navbar.css';
import Logo from '../images/remeeting-logo-blue-gray.svg';

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  signOut = () => {
    const { connectedEndSession } = this.props;
    connectedEndSession();
  }

  go = (path) => {
    const { connectedPush } = this.props;
    connectedPush(path);
  }

  gatherItems = () => {
    let items = [this.logoMenuItem()];
    const { email } = this.props;
    const { isHovering } = this.state;
    items = items.concat([
      <Menu.Item
        key="2"
        active={false}
        style={{
          cursor: 'default',
          paddingRight: 0,
          visibility: isHovering ? 'visible' : 'hidden',
        }}
      >
        { email }
      </Menu.Item>,
    ]);
    items = items.concat(this.itemsList());
    return items;
  }

  logoMenuItem = () => (
    <Menu.Item
      as={Link}
      to="/"
      key="1"
      className="re is-margin-right-100"
      active={false}
      title="Remeeting"
      onClick={() => (window.scrollTo(0, 0))}
    >
      <Image className="navbar-logo" src={Logo} alt="remeeting asr" />
    </Menu.Item>
  )

  accountDropdownItems = () => {
    const { signedIn } = this.props;

    if (signedIn) {
      return [
        <Dropdown.Item
          as={Link}
          key="account-item"
          content="Account"
          to="/account"
        />,
        <Dropdown.Item
          as="a"
          key="mac-item"
          content="Mac app"
          href="/mac"
          target="_blank"
        />,
        <Dropdown.Item
          key="signout-item"
          content="Sign out"
          onClick={() => { this.signOut(); }}
        />,
      ];
    }

    return [
      <Dropdown.Item
        key="signin-item"
        content="Sign In"
        onClick={() => { this.go('/signin'); }}
      />,
    ];
  }

  itemsList = () => ([
    <Menu.Item
      key="3"
      active={false}
      onMouseEnter={() => this.setState({ isHovering: true })}
      onMouseLeave={() => this.setState({ isHovering: false })}
    >
      <Dropdown
        className="navbar-user-logo"
        direction="left"
        floating
        icon={<Icon name="user circle" size="big" />}
      >
        <Dropdown.Menu>
          { this.accountDropdownItems() }
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>,
  ])

  render() {
    return (
      <Menu
        fixed="top"
        borderless
        items={this.gatherItems()}
        className="re is-flex-row-justify-end navbar"
      />
    );
  }
}

HeaderBar.propTypes = {
  connectedEndSession: PropTypes.func.isRequired,
  connectedPush: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  signedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  signedIn: state.session.signedIn,
  email: state.session.email,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedEndSession: endSession,
  connectedPush: push,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderBar);
