const call = require('../utils/call');
const IsoDate = require('../utils/iso-date');
const messages = require('../utils/messages');
const Recognition = require('.');

const BASE_OPTIONS = Symbol();
/** RecognitionsList is a convient object for
 * managing querie requests to the api for groups
 * of Recognitions, and easily managing paging when
 * the list is over 100 items.
 */
class RecognitionsList {
  constructor(config, baseOptions) {
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(RecognitionsList);
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}recognitions`;
    this.limit = config.limit ? config.limit : 99;
    this.status = config.status;
    this.before = config.before;
    this.indexed = config.indexed;
    this.items = [];
  }

  /**
   * list
   * Make a query network request with correct query params,
   * also used internally as part of self.page.
   * @params config Obj - optional, properites to be used to define list constraints
   * @returns native Promise - resolves RecognitionsList with items updated, rejects error obj
   */
  list(config) {
    const list = this;

    config = config || {};

    const limit = config.limit || list.limit;

    const before = config.before || list.before;

    const status = config.status || list.status;

    const indexed = config.indexed || list.indexed;

    const startingLength = list.items.length;

    return new Promise((resolve, reject) => {
      const options = list[BASE_OPTIONS].getOptions();
      if (!options) {
        reject({ message: messages.authRequired });
        return;
      }

      if (limit > 99) {
        reject({ message: messages.maxLimit });
        return;
      }
      options.url += `?limit=${limit}`;

      if (before) {
        if (IsoDate.isValid(before)) {
          options.url += `&before=${before}`;
        } else {
          reject({ message: messages.isoInvalid });
          return;
        }
      }

      if (status) {
        if (!status.match(/^(waiting|processing|completed|failed)$/)) {
          reject({ message: messages.invalidStatus });
          return;
        }
        options.url += `&status=${status}`;
      }

      if (indexed) {
        options.url += '&indexed';
      }

      const rq = call(options, undefined, reject);

      rq.then((data) => {
        const items = data.recognitions.sort(
          decsCreated,
        ).map((data) => new Recognition(data, list[BASE_OPTIONS].clone()));
        list.items.push.apply(list.items, items);
        if (startingLength == list.items.length) {
          list.complete = true;
        }
        resolve(list);
      });

      function decsCreated(a, b) {
        const sort = indexed ? 'indexed' : 'created';
        if (a[sort] > b[sort]) {
          return 1;
        }
        if (a[sort] < b[sort]) {
          return -1;
        }
        return 0;
      }
    });
  }

  /**
   * page
   * Make a query network request with correct query params,
   * which will get the next n (limit) items for the list based on
   * desc create date time. Currently, paging can only be done by geting older recognitions.
   * @returns native Promise - resolves RecognitionsList with items updated, rejects error obj
   */
  page() {
    const list = this;

    let before;
    if (list.items.length) {
      before = list.items[list.items.length - 1].created;
    }

    return list.list({ before });
  }
}

module.exports = RecognitionsList;
