import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  removePronunciationFromWord,
  getPronunciationAudio,
} from '../../../modules/customize';

class PronunciationItem extends Component {
  componentDidMount() {
    const { phonemes, connectedGetPronunciationAudio } = this.props;
    connectedGetPronunciationAudio(phonemes);
  }

  onPronDelete = () => {
    const {
      word,
      connectedRemovePronunciationFromWord,
      phonemes,
    } = this.props;

    connectedRemovePronunciationFromWord(word, phonemes);
  }

  onPronPlayClicked = () => {
    const {
      phonemes,
      pronAudioResults,
    } = this.props;

    if (pronAudioResults[phonemes] !== undefined) {
      const audio = pronAudioResults[phonemes];
      audio.play();
    }
  }

  render() {
    const {
      phonemes,
      status,
      disabled,
    } = this.props;

    return (
      <div className="pronunciation-item">
        <div
          className="delete-pron"
          onClick={this.onPronDelete}
          role="button"
          onKeyDown={(e) => { if (e.keyCode === 13) this.onPronDelete(); }}
          tabIndex={disabled ? -1 : 0}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div
          className="play-pron"
          onClick={this.onPronPlayClicked}
          role="button"
          onKeyDown={(e) => { if (e.keyCode === 13) this.onPronPlayClicked(); }}
          tabIndex={disabled ? -1 : 0}
        >
          <FontAwesomeIcon icon={faVolumeUp} />
        </div>
        <div className="pronunciation-phonemes">
          {phonemes}
        </div>
        <div className="pron-status">
          { status && `(${status})` }
        </div>
      </div>
    );
  }
}

PronunciationItem.propTypes = {
  phonemes: PropTypes.string.isRequired,
  word: PropTypes.string.isRequired,
  status: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  connectedRemovePronunciationFromWord: PropTypes.func.isRequired,
  connectedGetPronunciationAudio: PropTypes.func.isRequired,
  pronAudioResults: PropTypes.shape({
    play: PropTypes.func,
  }),
};

PronunciationItem.defaultProps = {
  status: '',
  pronAudioResults: undefined,
};

const mapStateToProps = (state) => ({
  pronAudioRequestsError: state.customize.pronAudioRequestsError,
  pronAudioRequestsOut: state.customize.pronAudioRequestsOut,
  pronAudioResults: state.customize.pronAudioResults,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedRemovePronunciationFromWord: removePronunciationFromWord,
  connectedGetPronunciationAudio: getPronunciationAudio,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PronunciationItem);
