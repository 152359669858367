import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import isFinite from 'lodash/isFinite';

import {
  setPlaying,
  setBuffered,
  setCurrentTime,
  setDuration,
  setIsBuffering,
  flushMediaElementState,
} from '../../../modules/mediaElement';

import { errorType, meetingType } from '../../types';
import {
  setMediaElementLoaded, getRecognitionAudio, getRecognitionVideo, togglePlay,
} from '../../../modules/player';
import { getThumbnail } from '../../../modules/recognitions';

class MediaElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingForSeek: false,
      videoRef: undefined,
      audioRef: undefined,
      outOfSyncIterations: 0,
      initialLoad: true,
    };
  }

  componentDidMount() {
    const { connectedGetThumbnail, location, meetingThumbnails } = this.props;
    const meetingId = location.pathname.split('/')[2];
    if (!(meetingId in meetingThumbnails)) {
      connectedGetThumbnail(meetingId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      seekRequest,
      shouldPlay,
      videoWidth,
      videoHeight,
      volume,
      volumeIsMuted,
      isFullScreen,
      buffered,
      connectedSetIsBuffering,
      currentTime,
      playbackRate,
    } = this.props;

    const { audioRef, videoRef, waitingForSeek } = this.state;

    if (prevProps.isFullScreen !== isFullScreen) {
      if (isFullScreen) {
        this.setVideoSize(window.innerHeight, window.innerWidth);
      } else {
        this.setVideoSize(videoHeight, videoWidth);
      }
    }

    if (prevProps.seekRequest !== seekRequest && seekRequest.time !== undefined) {
      this.seek(seekRequest);
    }
    if (prevProps.shouldPlay !== shouldPlay) {
      this.play(shouldPlay);
    }
    if (prevProps.playbackRate !== playbackRate) {
      this.setPlaybackRate(playbackRate);
    }
    if (prevProps.videoWidth !== videoWidth || prevProps.videoHeight !== videoHeight) {
      this.setVideoSize(videoHeight, videoWidth);
    }
    if (prevProps.volume !== volume || prevProps.volumeIsMuted !== volumeIsMuted) {
      this.setVolume(volume, volumeIsMuted);
    }

    /**
     * NOTE(Elliot 2021/02/05): We have consistently observed that there exists some meetings where
     * it's possible to achieve a state where the right-most buffered interval doesn't extend to the
     * full duration of the video, even if the video was allowed to play to the end.
     *
     * One way to reproduce this is to open a meeting longer than 10m, seeking to within 3-5s of the
     * end, and pressing play. This has been observed on some meetings on ubaldo@rmtg.co's dev
     * account.
     *
     * One solution to this is to check the `ended` attribute on the media element and always set
     * `isBuffering` to false if `ended` is true.
     */
    if (buffered && buffered.length) {
      let isInBufferZone = false;
      const currTime = videoRef ? videoRef.currentTime : currentTime;
      for (let i = 0; i < buffered.length; i += 1) {
        if (currTime >= buffered[i][0] && currTime <= buffered[i][1]) {
          isInBufferZone = true;
          break;
        }
      }

      let ended;
      /* eslint-disable prefer-destructuring */
      if (videoRef) {
        ended = videoRef.ended;
      } else if (audioRef) {
        ended = audioRef.ended;
      }
      /* eslint-enable prefer-destructuring */
      if ((isInBufferZone && !waitingForSeek) || ended) {
        connectedSetIsBuffering(false);
      } else {
        connectedSetIsBuffering(true);
      }
    }
  }

  componentWillUnmount() {
    const { connectedFlushMediaElementState } = this.props;
    connectedFlushMediaElementState();

    /* Unload and destroy audio and video */
    const { audioRef, videoRef } = this.state;
    const refs = [audioRef, videoRef];

    for (let i = 0; i < refs.length; i += 1) {
      if (refs[i]) {
        const el = refs[i];
        el.pause();

        /* Remove sources from el */
        while (el.lastElementChild) {
          el.removeChild(el.lastElementChild);
        }

        /* Reset the media player by re-loading with no sources */
        el.load();

        /* Remove from DOM */
        el.remove();
      }
    }
  }

  onDurationChangeHandler = () => {
    const { connectedSetDuration } = this.props;
    const { audioRef } = this.state;

    let duration = 0;
    if (audioRef) {
      duration = audioRef.duration;
    }

    if (isFinite(duration)) connectedSetDuration(duration);
  }

  onPauseHandler = () => {
    const {
      connectedSetIsBuffering,
      connectedSetPlaying,
    } = this.props;
    connectedSetPlaying(false);
    connectedSetIsBuffering(false);
  }

  onLoadedDataHandler = () => {
    const {
      onFinishedLoadingData,
      videoHeight,
      videoWidth,
      isMini,
      timeQuery,
      connectedSetMediaElementLoaded,
      mediaElementLoaded,
      isPlaying,
      currentTime,
    } = this.props;

    onFinishedLoadingData();

    if (isMini) {
      this.setVideoSize(108, 192);
    } else {
      this.setVideoSize(videoHeight, videoWidth);
    }

    if (!mediaElementLoaded) {
      this.seek({
        time: timeQuery || 0,
        shouldPlay: false,
      });

      connectedSetMediaElementLoaded();
    } else {
      this.seek({
        time: currentTime,
        shouldPlay: isPlaying,
      });
    }
  }

  onPlayingHandler = () => {
    const { connectedSetPlaying } = this.props;
    connectedSetPlaying(true);
    this.setState({ initialLoad: false });
  }

  onTimeUpdateHandler = () => {
    const {
      connectedSetBuffered,
      connectedSetCurrentTime,
      isPlaying,
    } = this.props;

    const { videoRef, audioRef } = this.state;

    if (audioRef) {
      connectedSetCurrentTime(audioRef.currentTime);
    }

    const bufferedIntervals = [];
    if (videoRef) {
      for (let i = 0; i < videoRef.buffered.length; i += 1) {
        bufferedIntervals.push([videoRef.buffered.start(i), videoRef.buffered.end(i)]);
      }
    } else if (audioRef) {
      for (let i = 0; i < audioRef.buffered.length; i += 1) {
        bufferedIntervals.push([audioRef.buffered.start(i), audioRef.buffered.end(i)]);
      }
    }

    connectedSetBuffered(bufferedIntervals);

    if (audioRef && videoRef && isPlaying) {
      /* In separating audio vs video streams, we can allow audio to play freely and force
         the video to catch up/slow down accordingly since video isn't as important.

         The issue is that this may cause a "buffer loop". If we force the video to seek to
         the audio's position, and the video hasn't loaded there, we will have to wait a few
         seconds for the video to buffer, by which point the audio has moved and the video
         will have to jump forward again, to a new, probably unbuffered position; starting
         the buffering process over again.

         We can fix this by moving the video to a position *ahead of* where the audio is,
         to account for the extra time it might take for the video to buffer at that point.
         We don't know how long it takes for video to buffer (this is dependent on network
         conditions) but we can get an approximation by comparing the audio seek position
         to the video seek position, and the difference tells us approx how long it took to
         buffer whichever stream was behind.

         Lastly, we introduce a sort of exponential backoff: if we find that the above
         strategy still leads to a buffer loop (i.e. we haven't moved the video far enough
         ahead of the audio to give it enough time to buffer) then we move it even further
         forward, calculated exponentially using the number of cycles of the buffer loop
         that have occurred. */
      const { isBuffering } = this.props;
      const { outOfSyncIterations } = this.state;

      if (audioRef.currentTime - videoRef.currentTime > 0.5) {
        /* If audio is ahead of video, we move the video ahead of the audio */
        const videoDiff = audioRef.currentTime - videoRef.currentTime;
        videoRef.currentTime = audioRef.currentTime + (videoDiff * (1.1 ** outOfSyncIterations));
        this.setState({
          outOfSyncIterations: outOfSyncIterations + 1,
        });
      } else if (audioRef.currentTime - videoRef.currentTime < -0.5 && !isBuffering) {
        /* If video is ahead of audio, we move the video back to the audio */
        videoRef.currentTime = audioRef.currentTime;
      } else {
        this.setState({
          outOfSyncIterations: 0,
        });
      }
    }
  }

  onWaitingHandler = () => {
    this.setState({ waitingForSeek: true });
  }

  onCanPlayHandler = () => {
    this.setState({ waitingForSeek: false });
  }

  setVolume = (volume, mute) => {
    const { audioRef } = this.state;
    audioRef.volume = mute ? 0 : volume / 100.0;
  }

  setPlaybackRate = (rate) => {
    const { audioRef, videoRef } = this.state;
    if (audioRef) {
      audioRef.playbackRate = rate;
    }
    if (videoRef) {
      videoRef.playbackRate = rate;
    }
  }

  play = (shouldPlay) => {
    const { videoRef, audioRef } = this.state;

    if (audioRef && videoRef) {
      audioRef.currentTime = videoRef.currentTime;
    }

    if (shouldPlay) {
      if (videoRef) {
        videoRef.play();
      }
      if (audioRef) {
        audioRef.play();
      }
    } else {
      if (videoRef) videoRef.pause();
      if (audioRef) audioRef.pause();
    }
  }

  playAudio = () => {
    const { audioRef, videoRef, audioPlaying } = this.state;
    /* These checks are here because audio's .play() is asynchronous. If we call play()
       and then immediately call pause(), the call to audioRef.play() below will result
       in an uncaught DOMException. */
    if (audioRef && !audioPlaying && audioRef.paused) {
      audioRef.currentTime = videoRef.currentTime;

      /* Keep attempting to play audio until the request is finally uninterrupted */
      audioRef.play();
    }
  }

  pauseAudio = () => {
    const { audioPlaying, audioRef } = this.state;
    if (audioRef && audioPlaying && !audioRef.paused) {
      audioRef.pause();
    }
  }

  seek = (seekRequest) => {
    const { videoRef, audioRef } = this.state;
    if (!videoRef && !audioRef) return;

    let ts = seekRequest.time;
    if (ts < 0) ts = 0;

    if (videoRef) {
      if (ts > videoRef.duration) ts = videoRef.duration;
    } else if (audioRef) {
      if (ts > audioRef.duration) ts = audioRef.duration;
    }

    if (videoRef) videoRef.currentTime = ts;
    if (audioRef) audioRef.currentTime = ts;

    if (seekRequest.shouldPlay) {
      if (videoRef) {
        videoRef.play();
      }
      if (audioRef) {
        audioRef.play();
      }
    }
  }

  /**
   * Set the width and height of the video element based off of the dimensions of the element
   * `video-player`.
   */
  setVideoSize = (height, width) => {
    const { videoRef } = this.state;
    if (videoRef) {
      videoRef.style.setProperty('height', `${height}px`);
      videoRef.style.setProperty('width', `${width}px`);
    }
  }

  setVideoRef = (videoRef) => {
    this.setState({ videoRef });

    /* To make this compatible with "legacy" meetings where the video file
       actually contained an audio track */
    const { isAudioOnly } = this.props;
    if (!isAudioOnly && videoRef) {
      const vid = videoRef;
      vid.volume = 0;
    }
  }

  setAudioRef = (audioRef) => {
    this.setState({ audioRef });
  }

  onMediaError = (e) => {
    const {
      audioRef,
      videoRef,
    } = this.state;

    const {
      onErrorHandler,
      meeting,
      connectedGetRecognitionVideo,
      connectedGetRecognitionAudio,
      connectedTogglePlay,
      connectedSetPlaying,
    } = this.props;

    let error;
    if (videoRef.error) {
      error = videoRef.error;
    } else if (audioRef.error) {
      error = audioRef.error;
    }

    if (error && error.code === 2 /* MEDIA_ERR_NETWORK */) {
      connectedTogglePlay(false);
      connectedSetPlaying(false);
      connectedGetRecognitionVideo(meeting.id);
      connectedGetRecognitionAudio(meeting.id);
    } else {
      onErrorHandler(e);
    }
  }

  render() {
    const {
      videoUrl,
      audioUrl,
      getVideoError,
      getAudioError,
      getVideoRequestOut,
      getAudioRequestOut,
      meeting,
      meetingThumbnails,
      timeQuery,
      videoHeight,
    } = this.props;

    const { initialLoad } = this.state;

    const hasVideo = !!videoUrl && !getVideoError && !getVideoRequestOut;
    const hasAudio = !!audioUrl && !getAudioError && !getAudioRequestOut;

    const shouldRenderThumbnail = initialLoad
                               && meetingThumbnails[meeting.id]
                               && meetingThumbnails[meeting.id] !== 'no thumbnail'
                               && !timeQuery;
    const videoClassName = shouldRenderThumbnail
      ? 're is-display-none'
      : 'video-element html5-main-video';

    return (
      <div>
        { shouldRenderThumbnail && (
        <img
          id="thumbnail-overlay"
          alt="thumbnail"
          src={meetingThumbnails[meeting.id]}
          style={{
            height: videoHeight,
          }}
        />
        )}
        {
          hasVideo && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              id="video-element"
              className={videoClassName}
              ref={this.setVideoRef}
              onError={this.onMediaError}
              onDurationChange={this.onDurationChangeHandler}
              onEnded={this.onPauseHandler}
              onLoadedData={this.onLoadedDataHandler}
              onPause={this.onPauseHandler}
              onPlaying={this.onPlayingHandler}
              onTimeUpdate={this.onTimeUpdateHandler}
              onWaiting={this.onWaitingHandler}
              onCanPlay={this.onCanPlayHandler}
              preload="auto"
            >
              <source src={videoUrl} />
            </video>
          )
        }
        {
          hasAudio && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <audio
              preload="auto"
              onError={this.onMediaError}
              ref={this.setAudioRef}
              onPlaying={this.onPlayingHandler}
              onPause={this.onPauseHandler}
              onDurationChange={this.onDurationChangeHandler}
              onLoadedData={this.onLoadedDataHandler}
              onTimeUpdate={this.onTimeUpdateHandler}
              onEnded={this.onPauseHandler}
              onWaiting={this.onWaitingHandler}
              onCanPlay={this.onCanPlayHandler}
            >
              <source src={audioUrl} type="audio/mp4" />
            </audio>
          )
        }
      </div>
    );
  }
}

MediaElement.propTypes = {
  meeting: meetingType,
  meetingThumbnails: PropTypes.shape({}),
  videoUrl: PropTypes.string.isRequired,
  audioUrl: PropTypes.string.isRequired,
  getVideoError: errorType,
  getAudioError: errorType,
  getVideoRequestOut: PropTypes.bool.isRequired,
  getAudioRequestOut: PropTypes.bool.isRequired,
  shouldPlay: PropTypes.bool,
  playbackRate: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,

  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,

  seekRequest: PropTypes.shape({
    shouldPlay: PropTypes.bool,
    time: PropTypes.number,
  }),
  currentTime: PropTypes.number.isRequired,
  videoWidth: PropTypes.number.isRequired,
  videoHeight: PropTypes.number.isRequired,
  isMini: PropTypes.bool,
  isFullScreen: PropTypes.bool.isRequired,

  connectedSetMediaElementLoaded: PropTypes.func.isRequired,
  mediaElementLoaded: PropTypes.bool.isRequired,
  connectedGetRecognitionVideo: PropTypes.func.isRequired,
  connectedGetRecognitionAudio: PropTypes.func.isRequired,
  connectedSetDuration: PropTypes.func.isRequired,
  connectedSetPlaying: PropTypes.func.isRequired,
  connectedSetIsBuffering: PropTypes.func.isRequired,
  connectedSetCurrentTime: PropTypes.func.isRequired,
  connectedSetBuffered: PropTypes.func.isRequired,
  onFinishedLoadingData: PropTypes.func,
  onErrorHandler: PropTypes.func,
  connectedFlushMediaElementState: PropTypes.func.isRequired,
  volumeIsMuted: PropTypes.bool,
  buffered: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  isPlaying: PropTypes.bool.isRequired,
  isBuffering: PropTypes.bool,
  isAudioOnly: PropTypes.bool,
  timeQuery: PropTypes.number,
  connectedTogglePlay: PropTypes.func.isRequired,
  connectedGetThumbnail: PropTypes.func.isRequired,
};

MediaElement.defaultProps = {
  meeting: undefined,
  meetingThumbnails: undefined,
  isAudioOnly: false,
  getAudioError: undefined,
  getVideoError: undefined,
  shouldPlay: false,
  seekRequest: {
    time: 0,
    shouldPlay: false,
  },
  onFinishedLoadingData: () => { },
  onErrorHandler: () => { },
  isMini: false,
  volumeIsMuted: false,
  buffered: [],
  isBuffering: false,
  timeQuery: undefined,
};

const mapStateToProps = (state) => ({
  meeting: state.meeting.meeting,
  videoUrl: state.player.videoUrl,
  audioUrl: state.player.audioUrl,
  getVideoError: state.player.getVideoError,
  getAudioError: state.player.getAudioError,
  getVideoRequestOut: state.player.getVideoRequestOut,
  getAudioRequestOut: state.player.getAudioRequestOut,
  shouldPlay: state.player.shouldPlay,
  playbackRate: state.player.playbackRate,
  volume: state.player.volume,
  volumeIsMuted: state.player.volumeIsMuted,
  isFullScreen: state.meeting.isFullScreen,
  mediaElementLoaded: state.player.mediaElementLoaded,
  meetingThumbnails: state.recognitions.meetingThumbnails,

  seekRequest: state.mediaElement.seekRequest,
  currentTime: state.mediaElement.currentTime,
  videoWidth: state.mediaElement.videoWidth,
  videoHeight: state.mediaElement.videoHeight,
  isBuffering: state.mediaElement.isBuffering,
  buffered: state.mediaElement.buffered,
  isPlaying: state.mediaElement.isPlaying,
  timeQuery: state.meeting.timeQuery,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSetDuration: setDuration,
  connectedSetPlaying: setPlaying,
  connectedSetIsBuffering: setIsBuffering,
  connectedSetCurrentTime: setCurrentTime,
  connectedSetBuffered: setBuffered,
  connectedFlushMediaElementState: flushMediaElementState,
  connectedSetMediaElementLoaded: setMediaElementLoaded,
  connectedGetRecognitionAudio: getRecognitionAudio,
  connectedGetRecognitionVideo: getRecognitionVideo,
  connectedGetThumbnail: getThumbnail,
  connectedTogglePlay: togglePlay,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaElement));
