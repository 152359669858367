import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { Checkbox } from '@material-ui/core';
import { Popover, PopoverBody } from 'reactstrap';
import { Button } from 'semantic-ui-react';

import { setFindSetting } from '../../modules/find';

class FindSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      findOCR: false,
      wholeWordMatch: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { findSettingsOpen } = this.props;
    /* Populate find settings every time the popover opens */
    if (prevProps.findSettingsOpen !== findSettingsOpen) this.populateSettings();
  }

  findChanged = () => {
    const { findSettings } = this.props;
    const keys = Object.keys(findSettings);
    for (let i = 0; i < keys.length; i += 1) {
      // There doesn't appear to be a way to refactor the following
      // line to follow the ESLint rule react/destructuring-assignment
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state[keys[i]] !== findSettings[keys[i]]) {
        return true;
      }
    }
    return false;
  }

  populateSettings = () => {
    const { findSettings } = this.props;
    this.setState({ ...findSettings });
  }

  onApplyClick = () => {
    const { connectedSetFindSetting } = this.props;
    const {
      findOCR,
      wholeWordMatch,
    } = this.state;
    connectedSetFindSetting('findOCR', findOCR);
    connectedSetFindSetting('wholeWordMatch', wholeWordMatch);
  }

  onFindSettingChanged = (key, value) => () => {
    this.setState({ [key]: value });
  }

  render() {
    const {
      findOCR,
      wholeWordMatch,
    } = this.state;
    const { findSettingsOpen, isFullScreen } = this.props;
    return (
      <Popover
        className={isFullScreen ? 'fullscreen-popover' : ''}
        target="find-settings-button"
        isOpen={findSettingsOpen}
        placement="bottom-end"
      >
        <PopoverBody>
          <div className="find-settings-popover-body">
            <div>
              <Checkbox
                checked={!wholeWordMatch}
                onChange={this.onFindSettingChanged('wholeWordMatch', !wholeWordMatch)}
                name="checkedA"
                color="primary"
                id="whole-word-match"
              />
              <label htmlFor="whole-word-match">Match partial words</label>
            </div>
            <div>
              <Checkbox
                checked={findOCR}
                onChange={this.onFindSettingChanged('findOCR', !findOCR)}
                name="checkedA"
                color="primary"
                id="find-ocr"
              />
              <label htmlFor="find-ocr">Find in video</label>
            </div>
            <Button
              onClick={this.onApplyClick}
              color={isFullScreen ? 'grey' : 'blue'}
              disabled={!this.findChanged()}
              type="submit"
            >
              Apply
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    );
  }
}

FindSettings.propTypes = {
  connectedSetFindSetting: PropTypes.func.isRequired,
  findSettings: PropTypes.shape({
    wholeWordMatch: PropTypes.bool.isRequired,
    findOCR: PropTypes.bool.isRequired,
  }).isRequired,
  findSettingsOpen: PropTypes.bool.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  findSettings: state.find.findSettings,
  findSettingsOpen: state.find.findSettingsOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSetFindSetting: setFindSetting,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FindSettings);
