const call = require('./utils/call');
const Extendable = require('./utils/extendable');

const BASE_OPTIONS = Symbol(); // eslint-disable-line symbol-description

/** Search
 * Search is not really a resource per se so much as it is an interface to the v1 /search endpoint,
 * which in itself is not a traditional resource but an interface to Elasticsearch.
 * This endpoint only accepts get requests.
 */
class Search extends Extendable {
  constructor(config, baseOptions) {
    super(config);
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Search);
  }

  /**
   * get
   * @params config Obj - optional, properties to be copied before network request
   * @returns native Promise - resolve self, reject error obj
   */
  get(config) {
    const search = this;
    if (config) {
      search.extend(config);
    }

    return new Promise((resolve, reject) => {
      const options = search[BASE_OPTIONS].getOptions({ authRequired: true });
      const queryParams = Object.entries(search)
        .map(([key, val]) => `${key}=${encodeURIComponent(val).replace('%20', '+')}`)
        .join('&');
      if (queryParams) options.url += `?${queryParams}`;
      call(options, resolve, reject);
    });
  }
}

Search.resourceName = 'search';

module.exports = Search;
