import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import {
  togglePlay,
} from '../../../modules/player';

import {
  seekToTimestamp,
} from '../../../modules/mediaElement';

import {
  meetingType,
  speakerInfoType,
} from '../../types';

import {
  colorWithAlpha,
} from '../../../modules/utils';

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSeeking: false,
      wasPlaying: false,
    };
    this.audioPlayerRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResizeHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeHandler, false);
  }

  getRelativeX = (e) => {
    if (!this.audioPlayerRef.current) {
      return 0;
    }

    let relativeX = e.pageX - this.audioPlayerRef.current.getBoundingClientRect().left;
    relativeX = Math.min(Math.max(relativeX, 0), this.audioPlayerRef.current.clientWidth);
    return relativeX;
  }

  onMouseDownHandler = (e) => {
    e.preventDefault();
    const {
      connectedSeekToTimestamp,
      connectedTogglePlay,
      duration,
      isBuffering,
      isPlaying,
    } = this.props;

    if (!this.audioPlayerRef.current) {
      return;
    }

    document.addEventListener('mousemove', this.onMouseMoveHandler, false);
    document.addEventListener('mouseup', this.onMouseUpHandler, false);
    const relativeX = this.getRelativeX(e);
    const time = (relativeX / this.audioPlayerRef.current.clientWidth) * duration;
    if (isPlaying || isBuffering) {
      connectedTogglePlay(false);
      this.setState({ wasPlaying: true });
    } else {
      this.setState({ wasPlaying: false });
    }
    connectedSeekToTimestamp(time, false);
    this.setState({ isSeeking: true });
  }

  onMouseMoveHandler = (e) => {
    e.preventDefault();
    const { connectedSeekToTimestamp, duration } = this.props;
    const { isSeeking } = this.state;

    const relativeX = this.getRelativeX(e);
    const time = (relativeX / this.audioPlayerRef.current.clientWidth) * duration;
    if (isSeeking) {
      connectedSeekToTimestamp(time, false);
    }
  }

  onMouseUpHandler = () => {
    const { connectedSeekToTimestamp, currentTime } = this.props;
    const { wasPlaying } = this.state;
    document.removeEventListener('mousemove', this.onMouseMoveHandler, false);
    document.removeEventListener('mouseup', this.onMouseUpHandler, false);
    connectedSeekToTimestamp(currentTime, wasPlaying);
    this.setState({ isSeeking: false, wasPlaying: false });
  }

  renderProgressOverlays = () => {
    const { meeting, duration, speakerMetadata } = this.props;
    if (!meeting || !meeting.segments) {
      return null;
    }

    return meeting.segments.map((segment) => {
      const left = (segment.interval[0] / duration) * 100;
      const width = ((segment.interval[1] - segment.interval[0]) / duration) * 100;
      const speaker = speakerMetadata[segment.speaker];

      if (!speaker) {
        return null;
      }

      return (
        <div
          className="progress-overlay"
          style={{
            width: `${width}%`,
            left: `${left}%`,
            backgroundColor: colorWithAlpha(speaker.hue, 1),
          }}
        />
      );
    });
  }

  render() {
    const { currentTime, duration } = this.props;
    const scrubberLeft = (currentTime / duration) * 100;
    return (
      <div
        id="audio-player"
        onMouseDown={this.onMouseDownHandler}
        onMouseMove={this.onMouseMoveHandler}
        onMouseUp={this.onMouseUpHandler}
        ref={this.audioPlayerRef}
        role="button"
        tabIndex={0}
      >
        <div className="progress-underlay" />
        <div className="progress-bar">
          { this.renderProgressOverlays() }
        </div>
        <div
          className="progress-scrubber"
          style={{
            left: `${scrubberLeft}%`,
          }}
        />
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  connectedSeekToTimestamp: PropTypes.func.isRequired,
  connectedTogglePlay: PropTypes.func.isRequired,
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  isBuffering: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  meeting: meetingType,
  speakerMetadata: PropTypes.arrayOf(speakerInfoType),
};

AudioPlayer.defaultProps = {
  meeting: undefined,
  speakerMetadata: [],
};

const mapStateToProps = (state) => ({
  isPlaying: state.mediaElement.isPlaying,
  currentTime: state.mediaElement.currentTime,
  isBuffering: state.mediaElement.isBuffering,
  duration: state.mediaElement.duration,
  meeting: state.meeting.meeting,
  speakerMetadata: state.meeting.speakerMetadata,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSeekToTimestamp: seekToTimestamp,
  connectedTogglePlay: togglePlay,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioPlayer);
