const call = require('../utils/call');
const messages = require('../utils/messages');

const BASE_OPTIONS = Symbol();

class CustomizationLexicon {
  /*
   * Create a new association between a lexicon and a customization.
   */

  constructor(config, baseOptions) {
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(CustomizationLexicon);
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}customizations`;
    this.extend(config);
  }

  extend(obj) {
    return Object.assign(this, obj);
  }

  /*
   * Create a new association between a customization and a lexicon. The customization_uuid
   * and the lexicon_uuid are required. Returns a promise that will receive the newly created
   * object.
   *
   * Example:
   *
   * post({customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781',
   *       lexicon_uuid: 'fbf92ba4-3636-4823-8701-b779c6a09d00'})
   */

  post(config) {
    const customizationlexicon = this;
    config = config || {};
    customizationlexicon.extend(config);

    return new Promise((resolve, reject) => {
      if (!customizationlexicon.customization_uuid) {
        reject({ message: messages.customization_uuidRequired });
        return;
      }

      if (!customizationlexicon.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      const options = customizationlexicon[BASE_OPTIONS].getOptions({ method: 'POST' });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.url += `/${customizationlexicon.customization_uuid}/lexicon`;

      options.body = {
        lexicon_uuid: customizationlexicon.lexicon_uuid,
      };

      call(options, resolve, reject, customizationlexicon);
    });
  }

  /*
   * Delete a customizationlexicon. This operation cannot be undone!
   */

  /*
   *
   * Example:
   *
   * delete({customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781',
   *         lexicon_uuid: 'fbf92ba4-3636-4823-8701-b779c6a09d00'})}) ->
   *  CustomizationLexicon {
   *    customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781',
   *    lexicon_uuid: 'fbf92ba4-3636-4823-8701-b779c6a09d00'
   *  }
   */

  delete(config) {
    const customizationlexicon = this;
    config = config || {};
    customizationlexicon.extend(config);

    return new Promise((resolve, reject) => {
      if (!customizationlexicon.customization_uuid) {
        reject({ message: messages.customization_uuidRequired });
        return;
      }

      if (!customizationlexicon.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      const options = customizationlexicon[BASE_OPTIONS].getOptions({ method: 'DELETE' });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.url += `/${customizationlexicon.customization_uuid}/lexicon`;

      options.body = {
        lexicon_uuid: customizationlexicon.lexicon_uuid,
      };

      call(options, resolve, reject, customizationlexicon);
    });
  }
}

/*
 * Return a promise that will receive a list of customizationlexicons
 * for the given customization_uuid.
 */

CustomizationLexicon.list = (config, baseOptions) => {
  config = config || {};

  baseOptions.setResource = CustomizationLexicon;
  baseOptions.resourceUrl = `${baseOptions.apiUrl}customization`;

  return new Promise((resolve, reject) => {
    if (!config.customization_uuid) {
      reject({ message: messages.customization_uuidRequired });
      return;
    }

    const options = baseOptions.getOptions();
    if (!options) {
      reject({ message: messages.missingAuth });
      return;
    }

    options.url += `/${config.customization_uuid}/lexicon`;

    const rq = call(options, undefined, reject);
    rq.then((data) => {
      resolve(data.map((lexData) => new CustomizationLexicon(lexData, baseOptions.clone())));
    });
  });
};

module.exports = CustomizationLexicon;
