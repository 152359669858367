import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Checkbox,
  Dropdown,
  Loader,
  Modal,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { copyToClipboard, formatHoursMinutesSeconds, smartDate } from '../../modules/utils';
import {
  createShareLinkWithName,
  getShareLinks,
  closeShareLinkModal,
  setSettingsPane,
  toggleSettingsModal,
} from '../../modules/meeting';
import {
  determineRemeetingApiBaseUrl,
} from '../../modules/rmi';
import { meetingType } from '../types';

class ShareLinkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShareTime: undefined,
      dropdownLinkOptions: [],
      isTimeAdded: false,
      linkCreationDate: undefined,
      linkReadyForCopy: undefined,
      userClickedCopy: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      connectedGetShareLinks,
      connectedCreateShareLinkWithName,
      getShareLinksRequestOut,
      shareLinks: currentShareLinks,
      shareLinkModalOpen,
      shareModalRecordingId,
    } = this.props;
    if (prevProps.shareLinks !== currentShareLinks) {
      this.generateDropdownLinkOptions();
    }
    if (prevProps.shareLinkModalOpen !== shareLinkModalOpen && shareLinkModalOpen) {
      this.handleShareModalOpen();
      connectedGetShareLinks(shareModalRecordingId, '');
    }
    if (shareLinkModalOpen
      && isEmpty(currentShareLinks)
      && prevProps.getShareLinksRequestOut && !getShareLinksRequestOut) {
      connectedCreateShareLinkWithName(shareModalRecordingId, '');
    }
  }

  generateDropdownLinkOptions = () => {
    const { meeting, shareLinks } = this.props;
    const { linkReadyForCopy } = this.state;

    if (!isEmpty(shareLinks)) {
      const shareURLOptions = [...shareLinks].reverse().map((link) => {
        let shareLink;
        if (link.short_url) {
          if (link.short_url.includes('mtg.re')) {
            /* Short URL is included already */
            shareLink = link.short_url;
          } else {
            /* Prepend short URL path with correct base URL */
            const apiURL = determineRemeetingApiBaseUrl(window.location.hostname);
            if (apiURL.includes('api.remeeting.com')) {
              /* Since we target the Prod API we use the prod shortener */
              shareLink = `mtg.re/${link.short_url}`;
            } else {
              shareLink = `dev.mtg.re/${link.short_url}`;
            }
          }
        } else {
          shareLink = `${window.location.host}/app/r/${meeting.id}?s=${link.share_key}`;
        }
        return {
          key: link.share_key,
          text: shareLink,
          value: shareLink,
          date: link.created,
        };
      });

      if (!linkReadyForCopy) {
        this.setState({
          linkReadyForCopy: shareURLOptions[0].value,
          linkCreationDate: shareURLOptions[0].date,
        });
      }

      this.setState({
        dropdownLinkOptions: shareURLOptions,
      });
    } else {
      this.setState({ linkReadyForCopy: undefined, dropdownLinkOptions: [] });
    }
  };

  handleDropdownChange = (e, data) => {
    const { dropdownLinkOptions } = this.state;
    const currentOption = dropdownLinkOptions.find((option) => option.value === data.value);
    this.setState({ linkReadyForCopy: data.value, linkCreationDate: currentOption.date });
  }

  renderCopyButton = () => {
    const {
      userClickedCopy,
      linkReadyForCopy,
      isTimeAdded,
      currentShareTime,
    } = this.state;
    return (userClickedCopy
      ? (
        <div className="share-link-copy">
          <div className="share-link-copy-done">
            <FontAwesomeIcon icon={faCheckCircle} className="share-link-check-circle" />
          </div>
          <span className="share-link-copied">Copied!</span>
        </div>
      )
      : (
        <button
          className="share-link-copy-button"
          style={{ height: '45px', width: '45px' }}
          type="button"
          title="Copy"
          onClick={() => {
            copyToClipboard(`${linkReadyForCopy}${isTimeAdded ? `?t=${currentShareTime}` : ''}`);
            this.setState({ userClickedCopy: true });
          }}
        >
          <FontAwesomeIcon icon={faCopy} />
        </button>
      ));
  }

  renderTimeStartCheckbox = () => {
    const { currentShareTime, isTimeAdded } = this.state;
    return (
      <Checkbox
        label={`Start at ${formatHoursMinutesSeconds(currentShareTime, true)}`}
        checked={isTimeAdded}
        onClick={() => {
          this.setState({ isTimeAdded: !isTimeAdded, userClickedCopy: false }, () => {
            document.getElementById('share-link-group-input-id').focus();
          });
        }}
      />
    );
  }

  renderInMeetingOptions = () => (
    <div className="re is-marginTop-1em">
      { this.renderTimeStartCheckbox() }
      <button
        className="re is-float-right popover-modal-button"
        onClick={this.handleCheckboxClick}
        type="button"
      >
        More settings
      </button>
    </div>
  );

  handleCheckboxClick = () => {
    const { connectedSetSettingsPane, connectedToggleSettingsModal } = this.props;
    this.handleShareModalClose();
    connectedToggleSettingsModal(true);
    connectedSetSettingsPane(1);
  }

  handleShareModalOpen = () => {
    const { currentTime: currentMediaTime } = this.props;
    this.setState({
      currentShareTime: Math.round(currentMediaTime),
      linkCreationDate: undefined,
      linkReadyForCopy: undefined,
    });
  }

  handleShareModalClose = () => {
    const { connectedCloseShareLinkModal } = this.props;
    this.setState({
      isTimeAdded: false,
      linkCreationDate: undefined,
      linkReadyForCopy: undefined,
      userClickedCopy: false,
    });
    connectedCloseShareLinkModal();
  }

  render() {
    const { createShareLinkRequestOut, getShareLinksRequestOut, dropdownMode } = this.props;
    const {
      currentShareTime,
      dropdownLinkOptions,
      linkCreationDate,
      linkReadyForCopy,
      isTimeAdded,
    } = this.state;

    const { shareLinkModalOpen } = this.props;
    /**
     * TODO: issue #444, the share link modal should show what recording is being shared on the
     * dashboard page.
     */
    return (
      <Modal
        size="tiny"
        open={shareLinkModalOpen}
        onClose={this.handleShareModalClose}
        onMouseLeave={() => this.setState({ userClickedCopy: false })}
      >
        <Modal.Content>
          <h3>Share a link</h3>
          <p>
            This recording can be viewed at the following link
            {`${dropdownLinkOptions.length > 1 ? 's' : ''}:`}
          </p>
          <div className="share-link-group">
            <Dropdown
              className="icon share-link-group-dropdown"
              scrolling
              options={dropdownLinkOptions}
              direction="right"
              button
              floating
              icon="caret down"
              trigger={<></>}
              onClick={() => this.setState({ userClickedCopy: false })}
              onChange={(e, data) => this.handleDropdownChange(e, data)}
              disabled={
                dropdownLinkOptions.length === 1
                || createShareLinkRequestOut || getShareLinksRequestOut
              }
            />
            <input
              id="share-link-group-input-id"
              className="share-link-group-input"
              placeholder="Share Links..."
              value={linkReadyForCopy
                && `${linkReadyForCopy}${isTimeAdded && currentShareTime !== 0
                  ? `?t=${currentShareTime}` : ''}`}
              type="text"
              spellCheck={false}
              onClick={(e) => e.target.select()}
              readOnly
            />
          </div>
          <div
            className="share-link-group-action"
          >
            {
              !createShareLinkRequestOut && !getShareLinksRequestOut
                ? this.renderCopyButton()
                : (
                  <Loader
                    active
                    size="tiny"
                  />
                )
            }
          </div>
          <div className="share-link-created-container">
            <p className="share-link-created">
              {
                !(createShareLinkRequestOut || getShareLinksRequestOut)
                && (
                <>
                  This link was created&nbsp;
                  { smartDate(linkCreationDate, true) }
                  .
                </>
                )
              }
            </p>
          </div>
          { !dropdownMode && this.renderInMeetingOptions() }
        </Modal.Content>
        <Modal.Actions style={{ padding: '8px 1rem' }}>
          <Button
            content="Close"
            onClick={this.handleShareModalClose}
            type="button"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ShareLinkModal.propTypes = {
  connectedCloseShareLinkModal: PropTypes.func.isRequired,
  connectedCreateShareLinkWithName: PropTypes.func.isRequired,
  connectedGetShareLinks: PropTypes.func.isRequired,
  connectedSetSettingsPane: PropTypes.func.isRequired,
  connectedToggleSettingsModal: PropTypes.func.isRequired,
  createShareLinkRequestOut: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  dropdownMode: PropTypes.bool,
  getShareLinksRequestOut: PropTypes.bool.isRequired,
  shareLinkModalOpen: PropTypes.bool.isRequired,
  shareModalRecordingId: PropTypes.string.isRequired,
  meeting: meetingType,
  shareLinks: PropTypes.arrayOf(
    PropTypes.shape({
      share_key: PropTypes.string,
      name: PropTypes.string,
      created: PropTypes.string,
    }),
  ).isRequired,
};

ShareLinkModal.defaultProps = {
  dropdownMode: false,
  meeting: undefined,
};

const mapStateToProps = (state) => ({
  currentTime: state.mediaElement.currentTime,
  meeting: state.meeting.meeting,
  getShareLinksRequestError: state.meeting.getShareLinksRequestError,
  getShareLinksRequestOut: state.meeting.getShareLinksRequestOut,
  shareLinks: state.meeting.shareLinks,
  createShareLinkRequestOut: state.meeting.createShareLinkRequestOut,
  createShareLinkRequestError: state.meeting.createShareLinkRequestError,
  shareLinkModalOpen: state.meeting.shareLinkModalOpen,
  shareModalRecordingId: state.meeting.shareModalRecordingId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedCloseShareLinkModal: closeShareLinkModal,
  connectedCreateShareLinkWithName: createShareLinkWithName,
  connectedGetShareLinks: getShareLinks,
  connectedSetSettingsPane: setSettingsPane,
  connectedToggleSettingsModal: toggleSettingsModal,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareLinkModal);
