/** Extendable
 * A utility base class for resources in the rmi that
 * implement themselves as extendable (mutable) objs.
 */
class Extendable {
  constructor(config) {
    this.extend(config);
  }

  /**
   * extend
   * Make the instance an extend-able object
   * @params obj Obj - source object to be copied onto instance
   * @returns self - Instance
   */
  extend(obj) {
    return Object.assign(this, obj);
  }
}

module.exports = Extendable;
