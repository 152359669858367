const Account = require('./account');
const BaseOptions = require('./utils/base-options');
const Customization = require('./customization');
const CustomizationLexicon = require('./customization/customizationlexicon');
const Key = require('./key');
const Lexentry = require('./lexicon/lexentry');
const Lexicon = require('./lexicon');
const RecognitionsList = require('./recognition/recognitions-list');
const Recognition = require('./recognition');
const sendFeedback = require('./feedback');
const setAuth = require('./utils/setAuth');
const Search = require('./search');
const Status = require('./status');
const Richify = require('./richification');
const Lookup = require('./lookup');

const AUTH = Symbol();
/**
 * Defines the base remeeting object.
 * This currently exposes configuration properties
 * like apiVersion and auth, as well as the recognitions
 * object that provides convienence functions.
 * @params config Obj - optional, properties used to configure remeeting instance
 * @returns Remeeting Instance
 */
class Remeeting {
  constructor(config) {
    const instance = this;

    config = config || {};

    // build the instance[AUTH] object as a protected-ish property
    instance.setAuth(config);

    // mostly a developer convience when developing internally
    // new Remeeting({ baseUrl: 'http://dockerhost:8082'})
    // or for an OEM implementation
    // TODO: clean the inputs for these, like removing excess forward slashes
    instance.apiVersion = config.apiVersion || 'asr/v1';
    instance.baseUrl = config.baseUrl || 'https://api.remeeting.com';
    instance.apiUrl = config.apiUrl || (`${instance.baseUrl}/${instance.apiVersion}/`);

    // in order for the Recognition post to switch between browser
    // and node environment, the Recognition.post has to be set here
    Recognition.prototype.post = Remeeting.recognitionPost(generateBaseOptions(instance));

    instance.accounts = {
      create(config) {
        config = config || {};
        return new Account(config, generateBaseOptions(instance));
      },
      get(config) {
        config = config || {};
        return new Account(config, generateBaseOptions(instance)).get();
      },
      post(config) {
        config = config || {};
        return new Account(config, generateBaseOptions(instance)).post();
      },
      put(config) {
        config = config || {};
        return new Account(config, generateBaseOptions(instance)).put();
      },
      setPassword(config) {
        config = config || {};
        return Account.setPassword(config, generateBaseOptions(instance));
      },
      getAccountSettings(config) {
        config = config || {};
        return Account.getAccountSettings(config, generateBaseOptions(instance));
      },
      setAccountSetting(config) {
        config = config || {};
        return Account.setAccountSetting(config, generateBaseOptions(instance));
      },
      sendVerificationToken(config) {
        config = config || {};
        return Account.sendVerificationToken(config, generateBaseOptions(instance));
      },
      verifyVerificationToken(config) {
        config = config || {};
        return Account.verifyVerificationToken(config, generateBaseOptions(instance));
      },
      getVerificationToken(config) {
        config = config || {};
        return Account.getVerificationToken(config, generateBaseOptions(instance));
      },
      getCard(config) {
        return Account.getCard(config, generateBaseOptions(instance));
      },
      getDaemonInfo(config) {
        return Account.getDaemonInfo(config, generateBaseOptions(instance));
      },
      getPlan(config) {
        return Account.getPlan(config, generateBaseOptions(instance));
      },
    };

    instance.customizations = {
      create(config) {
        config = config || {};
        return new Customization(config, generateBaseOptions(instance));
      },
      get(config) {
        config = config || {};
        return new Customization(config, generateBaseOptions(instance)).get();
      },
      post(config) {
        config = config || {};
        return new Customization(config, generateBaseOptions(instance)).post();
      },
      put(config) {
        config = config || {};
        return new Customization(config, generateBaseOptions(instance)).put();
      },
      delete(config) {
        config = config || {};
        return new Customization(config, generateBaseOptions(instance)).delete();
      },
      build(config) {
        config = config || {};
        return new Customization(config, generateBaseOptions(instance)).build();
      },
      list(config) {
        config = config || {};
        return Customization.list(config, generateBaseOptions(instance));
      },
    };

    instance.customizationlexicons = {
      create(config) {
        config = config || {};
        return new CustomizationLexicon(config, generateBaseOptions(instance));
      },
      post(config) {
        config = config || {};
        return new CustomizationLexicon(config, generateBaseOptions(instance)).post();
      },
      delete(config) {
        config = config || {};
        return new CustomizationLexicon(config, generateBaseOptions(instance)).delete();
      },
      list(config) {
        config = config || {};
        return CustomizationLexicon.list(config, generateBaseOptions(instance));
      },
    };

    instance.keys = {
      create(config) {
        config = config || {};
        return new Key(config, generateBaseOptions(instance));
      },
      get(config) {
        config = config || {};
        return new Key(config, generateBaseOptions(instance)).get();
      },
      post(config) {
        config = config || {};
        return new Key(config, generateBaseOptions(instance)).post();
      },
      put(config) {
        config = config || {};
        return new Key(config, generateBaseOptions(instance)).put();
      },
      list(config) {
        config = config || {};
        return Key.list(config, generateBaseOptions(instance));
      },
      delete(config) {
        config = config || {};
        return new Key(config, generateBaseOptions(instance)).delete();
      },
    };

    instance.lexicons = {
      create(config) {
        config = config || {};
        return new Lexicon(config, generateBaseOptions(instance));
      },
      get(config) {
        config = config || {};
        return new Lexicon(config, generateBaseOptions(instance)).get();
      },
      post(config) {
        config = config || {};
        return new Lexicon(config, generateBaseOptions(instance)).post();
      },
      put(config) {
        config = config || {};
        return new Lexicon(config, generateBaseOptions(instance)).put();
      },
      delete(config) {
        config = config || {};
        return new Lexicon(config, generateBaseOptions(instance)).delete();
      },
      list(config) {
        config = config || {};
        return Lexicon.list(config, generateBaseOptions(instance));
      },
      getPhonemes(graphemes, config) {
        return Lexicon.getPhonemes(graphemes, generateBaseOptions(instance), config);
      },
      pronunciationAudio(phonemes, config) {
        return Lexicon.pronunciationAudio(phonemes, generateBaseOptions(instance), config);
      },
    };

    instance.lexentry = {
      create(config) {
        config = config || {};
        return new Lexentry(config, generateBaseOptions(instance));
      },
      get(config) {
        config = config || {};
        return new Lexentry(config, generateBaseOptions(instance)).get();
      },
      post(config) {
        config = config || {};
        return new Lexentry(config, generateBaseOptions(instance)).post();
      },
      put(config) {
        config = config || {};
        return new Lexentry(config, generateBaseOptions(instance)).put();
      },
      delete(config) {
        config = config || {};
        return new Lexentry(config, generateBaseOptions(instance)).delete();
      },
      list(config) {
        config = config || {};
        return Lexentry.list(config, generateBaseOptions(instance));
      },
    };

    instance.recognitions = {
      create(config) {
        // return a new instance of Recognition with config passed in
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance));
      },
      list(config) {
        config = config || {};
        return new RecognitionsList(config, generateBaseOptions(instance)).list();
      },
      get(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).get();
      },
      post(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).post();
      },
      delete(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).delete();
      },
      getCats(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).getCats();
      },
      getSnapshots(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).getSnapshots();
      },
      getThumbnail(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).getThumbnail();
      },
      getTracks(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).getTracks();
      },
      updateMetadata(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).updateMetadata();
      },
      createShareLink(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).createShareLink();
      },
      deleteShareLink(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).deleteShareLink();
      },
      reprocess(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).reprocess();
      },
      createSnapshot(config) {
        config = config || {};
        return new Recognition(config, generateBaseOptions(instance)).createSnapshot();
      },
    };

    instance.lookup = {
      lexicon(config) {
        config = config || {};
        return new Lookup(config, generateBaseOptions(instance)).lexicon({ authRequired: false });
      },
      lm(config) {
        config = config || {};
        return new Lookup(config, generateBaseOptions(instance)).lm({ authRequired: false });
      },
      pronunciation(config) {
        config = config || {};
        return new Lookup(
          config,
          generateBaseOptions(instance),
        ).pronunciation({ authRequired: false });
      },
    };
  }

  sendFeedback(config) {
    return sendFeedback(config, generateBaseOptions(this));
  }

  setAuth(config) {
    return setAuth(this, AUTH, config);
  }

  richify(config) {
    config = config || {};
    return new Richify(config, generateBaseOptions(this)).post({ authRequired: false });
  }

  search(config) {
    config = config || {};
    return new Search(config, generateBaseOptions(this)).get();
  }

  /**
   * status calls the apiUrl to get the current state of the api
   * @params config
   * @return native Promise - resolves(Status instance), rejects(Error instance)
   */
  status(config) {
    return new Status(config, generateBaseOptions(this)).get();
  }
}
/**
 * generateBaseOptions returns an instance of the BaseOptions class
 * this is used by all of the instances for authentication, apiUrl, and
 * any other configuration needed. This makes it possible to share auth across
 * instances. The base options instance exposes getters for properties that
 * are considered protected and should always be stored by a protected Symbol
 * so that they aren't accessable-ish to outside code
 * @param instance - a Remeeting instance
 * @param config Obj - any extra settings
 * @returns baseOptions instance or undefined
 */
function generateBaseOptions(instance) {
  return new BaseOptions(instance[AUTH], instance.apiUrl);
}

module.exports = Remeeting;
