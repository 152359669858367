import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import E404 from '../images/E404.png';

const NoMatch = (props) => {
  const { sessionEmail, signedIn } = props;
  return (
    <div className="re is-main-top is-full-height is-flex is-flexDirection-column is-flex-align-center is-marginTop-16px">
      <div>
        <h1 className="re is-textAlign-center">404: Page Not Found</h1>
        <img alt="not-found" className="re is-margin-auto is-display-block" src={E404} />
        <h3 className="re is-textAlign-center">
          { 'We could not find the resource at ' }
          <code>{ window.location.pathname }</code>
        </h3>
        { signedIn ? (
          <div>
            <h3 className="re is-textAlign-center">
              {`You are currently signed in as ${sessionEmail}`}
              <div className="re is-flex is-flex-row is-flex-justify-space-between signedin-prompt">
                <a href={`${window.location.origin}/app`}>View my recordings</a>
                <a href={`${window.location.origin}/auth/signout`}>Sign out</a>
              </div>
            </h3>
          </div>
        ) : (
          <h3 className="re is-textAlign-center"><a href={window.location.origin}>Go back to remeeting.com</a></h3>
        ) }
      </div>
    </div>
  );
};

NoMatch.propTypes = {
  sessionEmail: PropTypes.string.isRequired,
  signedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  sessionEmail: state.session.email,
  signedIn: state.session.signedIn,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoMatch);
