import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { seekToTimestamp } from '../../modules/mediaElement';
import {
  formatHoursMinutesSeconds,
  colorWithAlpha,
  sortAlternativesByAmLmBalance,
  areEqualShallow,
  getMappedRanges,
} from '../../modules/utils';

import {
  getMetadata,
  getMetadataHasTrackException,
  getSpeakerNameAtIndex,
  richify,
} from '../../modules/meeting';

import CatsSpan from './span';
import RichSpan from './richSpan';

import {
  segmentType,
  speakerInfoType,
  catsItemType,
  errorType,
  meetingType,
  richifiedTextType,
  matchType,
  currentMatchType,
} from '../types';

class Segment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bestText: '',
      catsItems: [],
      catsInfo: [],
    };
  }

  componentDidMount() {
    const { inViewport, isCaption } = this.props;

    if (isCaption && inViewport) {
      this.setState((state) => state);
    }
  }

  /* Performance optimization: only update a segment when it shows up in the viewport */
  shouldComponentUpdate(nextProps, nextState) {
    const {
      inViewport: wasVisible,
      findMatches: oldFindResult,
    } = this.props;

    const {
      inViewport: isVisible,
      findMatches: findResult,
    } = nextProps;

    if (findResult !== oldFindResult) {
      return true;
    }

    if (!wasVisible && !isVisible) {
      return false;
    }

    return !(
      areEqualShallow(
        this.props,
        nextProps,
        ['inViewport'],
      )
      && areEqualShallow(this.state, nextState)
    );
  }

  componentDidUpdate() {
    const {
      richificationEnabled,
      richified,
      richificationRequestOut,
      richificationRequestError,
      connectedRichify,
      segment,
      dynamicRichificationEnabled,
      transcriptAmLmBalance,
      findMatches,
    } = this.props;

    const { bestText, catsInfo } = this.state;

    /* check if original text has changed */
    const { original } = richified || {};

    if (
      bestText.length
      && richificationEnabled
      && !richificationRequestOut
      && !richificationRequestError
      && dynamicRichificationEnabled
      && bestText !== original
    ) {
      connectedRichify(
        segment.utterance,
        bestText,
        isEmpty(catsInfo) ? undefined : catsInfo,
        !findMatches && transcriptAmLmBalance === 0.5,
      );
    }
  }

  static getDerivedStateFromProps(props) {
    const {
      segment,
      utterance,
      selectedSpan,
      findMatches,
      isCaption,
      currentTime,
      richified,
      richificationEnabled,
      richificationRequestOut,
      transcriptAmLmBalance,
      catsAbsoluteTimestamps,
      currentMatch,
      dynamicRichificationEnabled,
    } = props;

    if (!utterance) {
      /* This method isn't useful unless cats exist. On legacy meetings
         we should not update this. */
      return {};
    }

    const { cats } = utterance;
    const { words_formatted: wordsFormatted } = segment;
    let richWordsFormatted;
    if (richified) {
      richWordsFormatted = richified.wordsFormatted;
    }

    const catsResult = [];
    const catsPreFormatted = [];
    let highlightedWords = [];
    let selectedWords = [];
    const italicizedWords = [];
    let bestText = '';
    const captionWordRanges = isCaption ? [] : undefined;
    let consumedWords = 0;

    let currChunkStart = 0;
    let currChunkTotal = 0;
    let segmentChunkTotal = 0;

    const loading = (
      richificationRequestOut
      && richificationEnabled
    );

    const speardPhrase = (phrase, interval) => {
      const words = phrase.split(/\s+/);
      const [startTime, endTime] = interval;
      const timeDelta = (endTime - startTime) / words.length;
      const resultingSpread = [];
      for (let i = 0; i < words.length; i += 1) {
        resultingSpread.push({
          word: words[i],
          interval: [startTime + (i * timeDelta), startTime + (i + 1) * timeDelta],
        });
      }
      return resultingSpread;
    };

    for (let j = 0; j < cats.length; j += 1) {
      const catsItem = cats[j];
      const spanIndex = j;

      /* Pass down to the span, the matches that overlap with that span. */
      const matchesInThisSpan = [];
      if (findMatches !== undefined) {
        for (let i = 0; i < findMatches.length; i += 1) {
          const positionsInThisMatch = findMatches[i].data.match.positions;
          for (let p = 0; p < positionsInThisMatch.length; p += 1) {
            if (positionsInThisMatch[p].span === spanIndex) {
              matchesInThisSpan.push(findMatches[i]);
              break;
            }
          }
        }
      }

      let spanContainsCurrentMatch = false;
      if (currentMatch !== undefined && findMatches !== undefined) {
        for (let p = 0; p < currentMatch.data.match.positions.length; p += 1) {
          if (currentMatch.data.match.positions[p].span === spanIndex) {
            spanContainsCurrentMatch = true;
            break;
          }
        }
      }

      let currentSpanMatch;
      if (spanContainsCurrentMatch) {
        currentSpanMatch = currentMatch;
      }
      const spanKey = `${segment.utterance}-${spanIndex}`;

      let displayedMatch;
      if (currentSpanMatch) {
        displayedMatch = currentSpanMatch;
      } else if (matchesInThisSpan && matchesInThisSpan.length) {
        [displayedMatch] = matchesInThisSpan;
      }
      const sortedAlts = sortAlternativesByAmLmBalance(
        catsItem.alternatives,
        transcriptAmLmBalance,
      );

      let alt = 0;
      let bestCat;

      if (displayedMatch) {
        for (let k = 0; k < displayedMatch.data.match.positions.length; k += 1) {
          if (displayedMatch.data.match.positions[k].span === spanIndex) {
            alt = displayedMatch.data.match.positions[k].alt;
            break;
          }
        }

        bestCat = `${catsItem.alternatives[alt].phrase} `;
        catsPreFormatted.push(...speardPhrase(bestCat.trim(), catsItem.interval));

        /* Get highlight ranges of the matched words. */
        for (let i = 0; i < displayedMatch.data.match.positions.length; i += 1) {
          const pos = displayedMatch.data.match.positions[i];
          if (pos.span === spanIndex) {
            const newWordIndex = consumedWords + pos.word;
            if (i === 0) {
              if (currentMatch && displayedMatch.getIndex() === currentMatch.getIndex()) {
                selectedWords.push(newWordIndex);
              }
              highlightedWords.push([newWordIndex]);
            } else {
              if (currentMatch && displayedMatch.getIndex() === currentMatch.getIndex()) {
                selectedWords.push(newWordIndex);
              }
              highlightedWords[highlightedWords.length - 1].push(newWordIndex);
            }
            if (catsItem.alternatives[pos.alt] !== sortedAlts[0]) {
              italicizedWords.push(newWordIndex);
            }
          }
        }
      } else {
        bestCat = `${sortedAlts[0].phrase} `;
        if (sortedAlts[0].phrase !== '') {
          // Do not include silence.
          catsPreFormatted.push(...speardPhrase(bestCat.trim(), catsItem.interval));
        }
      }

      bestText += bestCat;

      const bestCatLength = (bestCat.match(/\S+/g) || []).length;
      consumedWords += bestCatLength;
      currChunkTotal += bestCatLength;

      if (isCaption && currChunkTotal >= 20) {
        captionWordRanges.push({
          timeInterval: [
            cats[currChunkStart].interval[0],
            cats[j].interval[1],
          ],
          wordInterval: [
            segmentChunkTotal,
            segmentChunkTotal + currChunkTotal,
          ],
          spanInterval: [
            currChunkStart,
            j + 1,
          ],
        });

        segmentChunkTotal += currChunkTotal;
        currChunkTotal = 0;
        currChunkStart = j + 1;
      }

      catsResult.push(
        <React.Fragment key={spanKey}>
          <CatsSpan
            catsItem={catsItem}
            segmentInterval={segment.interval}
            identifier={{
              utterance: segment.utterance,
              catsIndex: spanIndex,
            }}
            selected={selectedSpan === spanIndex}
            spanMatches={matchesInThisSpan}
            spanIndex={spanIndex}
            currentMatch={currentSpanMatch}
            idPrefix={isCaption ? 'caption-' : 'transcript-'}
            isLoading={loading}
            absoluteTimestamps={catsAbsoluteTimestamps}
          />
          {' '}
        </React.Fragment>,
      );
    }

    bestText = bestText.trim();

    if (isCaption && currChunkStart !== cats.length) {
      captionWordRanges.push({
        timeInterval: [
          cats[currChunkStart].interval[0],
          cats[cats.length - 1].interval[1],
        ],
        wordInterval: [
          segmentChunkTotal,
          consumedWords,
        ],
        spanInterval: [
          currChunkStart,
          cats.length,
        ],
      });
    }

    /* For better UX, we add a half-second to the beginning and end of the
       first/last caption chunk. */
    if (isCaption && captionWordRanges && captionWordRanges.length) {
      captionWordRanges[0].timeInterval[0] -= 0.5;
      captionWordRanges[captionWordRanges.length - 1].timeInterval[1] += 0.5;
    }

    /* If this is a caption, determine which range to render. */
    let renderRange = isCaption ? [0, 0] : [0, consumedWords];
    let renderCatsRange = isCaption ? [0, 0] : [0, cats.length];

    if (isCaption && currentTime !== undefined) {
      for (let i = 0; i < captionWordRanges.length; i += 1) {
        const { wordInterval, timeInterval, spanInterval } = captionWordRanges[i];
        if (currentTime > timeInterval[0] && currentTime <= timeInterval[1]) {
          renderRange = wordInterval;
          renderCatsRange = spanInterval;
        }
      }
    }

    /* Turn sequences of adjacent indices into ranges to be highlighted */
    highlightedWords = highlightedWords.map((list) => [list[0], list[list.length - 1] + 1]);
    selectedWords = selectedWords.length
      ? [selectedWords[0], selectedWords[selectedWords.length - 1] + 1]
      : undefined;

    let italicizedRanges = [];
    if (italicizedWords.length) {
      italicizedRanges.push([italicizedWords[0]]);
    }
    for (let i = 1; i < italicizedWords.length; i += 1) {
      if (italicizedWords[i] === italicizedWords[i - 1] + 1) {
        /* If groups of italicized words are not adjacent */
        italicizedRanges[italicizedRanges.length - 1].push(italicizedWords[i]);
      } else {
        italicizedRanges.push([italicizedWords[i]]);
      }
    }
    italicizedRanges = italicizedRanges.map((list) => [list[0], list[list.length - 1] + 1]);

    const joinWordElements = (words) => {
      const result = [];
      for (let i = 0; i < words.length; i += 1) {
        result.push(words[i]);
        if (i !== words.length - 1) {
          result.push(' ');
        }
      }
      return result;
    };

    const joinFormattedWords = (
      formattedWordsArray,
      highlight = undefined,
      italics = new Set(),
    ) => {
      const richSpanArray = [];
      for (let i = 0; i < formattedWordsArray.length; i += 1) {
        const [startTime, endTime] = formattedWordsArray[i].interval;
        richSpanArray.push(
          <React.Fragment key={`fragment-${segment.utterance}-${startTime}-${endTime}`}>
            <RichSpan
              formattedWord={formattedWordsArray[i]}
              highlight={highlight}
              identifier={`${segment.utterance}-${startTime}-${endTime}`}
              italics={italics}
            />
            {' '}
          </React.Fragment>,
        );
      }
      return richSpanArray;
    };

    const renderRichSpanHighlighting = (
      formattedWordsArray,
      highlights,
      italics,
      selected = null,
    ) => {
      if (!highlights || !highlights.length) {
        return joinFormattedWords(formattedWordsArray);
      }

      const italicIndices = new Set();
      if (italics) {
        for (let i = 0; i < italics.length; i += 1) {
          for (let j = italics[i][0]; j < italics[i][1]; j += 1) {
            italicIndices.add(j);
          }
        }
      }

      const results = [];

      results.push(...joinFormattedWords(formattedWordsArray.slice(0, highlights[0][0])));

      for (let i = 0; i < highlights.length; i += 1) {
        const [hTo, hFrom] = highlights[i];
        let currentFormattedWord = '';
        let startTimeInterval;
        let endTimeInterval;

        const highlightClass = selected === i
          ? 'richified-highlighted selected'
          : 'richified-highlighted';

        const italicIndiciesInSpan = new Set();
        let relativeSpanIndex = 0;
        for (let highlightIndex = hTo; highlightIndex < hFrom; highlightIndex += 1) {
          if (highlightIndex === hTo) {
            [startTimeInterval] = formattedWordsArray[highlightIndex].interval;
          }
          if (highlightIndex === hFrom - 1) {
            [, endTimeInterval] = formattedWordsArray[highlightIndex].interval;
          }
          if (italicIndices.has(highlightIndex)) {
            italicIndiciesInSpan.add(relativeSpanIndex);
          }
          relativeSpanIndex += 1;
          currentFormattedWord += `${formattedWordsArray[highlightIndex].word} `;
        }
        results.push(...joinFormattedWords(
          [{ interval: [startTimeInterval, endTimeInterval], word: currentFormattedWord.trim() }],
          highlightClass,
          italicIndiciesInSpan,
        ));
        if (i !== highlights.length - 1) {
          results.push(
            ...joinFormattedWords(formattedWordsArray.slice(hFrom, highlights[i + 1][0])),
          );
        }
      }
      results.push(...joinFormattedWords(
        formattedWordsArray.slice(highlights[highlights.length - 1][1], formattedWordsArray.length),
      ));

      return results;
    };

    const renderHighlightedText = (
      text,
      highlights,
      italics,
      selected = null,
      currentRange = undefined,
    ) => {
      const words = text.split(/\s+/) || [];
      const wordElements = [];
      if (!currentRange) return '';

      const italicIndices = new Set();
      if (italics) {
        for (let i = 0; i < italics.length; i += 1) {
          for (let j = italics[i][0]; j < italics[i][1]; j += 1) {
            if (j < currentRange[1] && j >= currentRange[0]) {
              italicIndices.add(j);
            }
          }
        }
      }

      for (let i = currentRange[0]; i < currentRange[1]; i += 1) {
        if (italicIndices.has(i)) {
          wordElements.push(
            <span
              className="richified-italicized"
              key={`rich-italized-${i}`}
            >
              {words[i]}
            </span>,
          );
        } else {
          wordElements.push(words[i]);
        }
      }

      if (!highlights || !highlights.length) {
        return joinWordElements(wordElements);
      }

      const result = [];

      /* For the window defined by RANGE, get the indices of the highlighted ranges
         relative to the start of RANGE. */
      const relativeHighlights = highlights.map(
        (interval) => [interval[0] - currentRange[0], interval[1] - currentRange[0]],
      );

      /* Find the first highlight that ends inside the currentRange */
      let firstHighlightIndex = 0;
      while (
        firstHighlightIndex < highlights.length
        && relativeHighlights[firstHighlightIndex][1] <= 0
      ) {
        firstHighlightIndex += 1;
      }

      /* Find the last highlight that begins inside the currentRange */
      let lastHighlightIndex = highlights.length - 1;
      while (
        lastHighlightIndex >= 0
        && relativeHighlights[lastHighlightIndex][0] > wordElements.length
      ) {
        lastHighlightIndex -= 1;
      }

      if (firstHighlightIndex > lastHighlightIndex) {
        return joinWordElements(wordElements);
      }

      relativeHighlights[firstHighlightIndex][0] = Math.max(
        0, relativeHighlights[firstHighlightIndex][0],
      );

      relativeHighlights[lastHighlightIndex][1] = Math.min(
        wordElements.length, relativeHighlights[lastHighlightIndex][1],
      );

      result.push(...wordElements.slice(0, relativeHighlights[firstHighlightIndex][0]));

      for (let i = firstHighlightIndex; i <= lastHighlightIndex; i += 1) {
        const [hFrom, hTo] = relativeHighlights[i];

        const highlightClass = selected === i
          ? 'richified-highlighted selected'
          : 'richified-highlighted';

        result.push(
          <span className={highlightClass} key={`highlighted-text-${i}`}>
            { joinWordElements(wordElements.slice(hFrom, hTo)) }
          </span>,
        );

        if (i !== lastHighlightIndex) {
          result.push(...wordElements.slice(hTo, relativeHighlights[i + 1][0]));
        }
      }

      result.push(
        ...wordElements.slice(
          relativeHighlights[lastHighlightIndex][1],
          wordElements.length,
        ),
      );

      return joinWordElements(result);
    };

    let formattedText;
    if (!dynamicRichificationEnabled && !highlightedWords.length) {
      /* Dynamic richification only receives transcript_formatted if there are no Find matches */
      formattedText = segment.transcript_formatted;
    } else if (dynamicRichificationEnabled && richified && richified.transcript) {
      formattedText = richified.transcript;
    }

    let richResult;
    let shouldRender = true;

    if (richificationEnabled && formattedText) {
      if (formattedText) {
        /* If there is a search query, and the transcript is richified, we can get
           a range of indices of words to highlight within the richified text. */
        const { selectedIndex, resultRanges: highlightIndices } = highlightedWords.length
          ? getMappedRanges(
            richified.alignment,
            selectedWords,
            highlightedWords,
          ) || {}
          : {};

        const { resultRanges: mappedItalicsRanges } = italicizedRanges.length
          ? getMappedRanges(
            richified.alignment,
            null,
            italicizedRanges,
          ) || {}
          : {};

        const { resultRanges: [mappedRenderRange] } = getMappedRanges(
          richified.alignment,
          null,
          [renderRange],
        );

        if (
          isCaption
          && (
            !mappedRenderRange
            || mappedRenderRange[1] === mappedRenderRange[0]
            || renderRange[1] === renderRange[0]
            || renderCatsRange[1] === renderCatsRange[0]
            || !formattedText.length
          )
        ) {
          shouldRender = false;
        }

        if (
          highlightIndices !== undefined
          && (highlightIndices[0] !== highlightIndices[1])
        ) {
          if (loading) {
            richResult = renderHighlightedText(
              bestText,
              highlightedWords,
              italicizedRanges,
              selectedIndex,
              renderRange,
            );
          } else if (richWordsFormatted && !isCaption) {
            richResult = renderRichSpanHighlighting(
              richWordsFormatted,
              highlightIndices,
              mappedItalicsRanges,
              selectedIndex,
            );
          } else if (wordsFormatted && !isCaption) {
            richResult = renderRichSpanHighlighting(
              wordsFormatted,
              highlightIndices,
              mappedItalicsRanges,
              selectedIndex,
            );
          } else {
            richResult = renderHighlightedText(
              formattedText,
              highlightIndices,
              mappedItalicsRanges,
              selectedIndex,
              mappedRenderRange,
            );
          }
        } else if (highlightedWords.length) {
          /* this means highlighting failed somehow... revert to showing, essentially,
             the cats in this scenario */
          richResult = renderHighlightedText(
            bestText,
            highlightedWords,
            italicizedRanges,
            selectedIndex,
            renderRange,
          );
        } else {
          let textResult;
          if (richWordsFormatted) {
            textResult = joinFormattedWords(richWordsFormatted);
          } else if (wordsFormatted) {
            textResult = joinFormattedWords(wordsFormatted);
          } else {
            textResult = formattedText;
          }
          richResult = isCaption
            ? formattedText.match(/\S+/g).slice(...mappedRenderRange || [0, 0]).join(' ')
            : textResult;
        }
      }

      return {
        catsItems: richResult,
        bestText: bestText.trim(),
        catsInfo: catsPreFormatted,
        shouldRender,
        loading,
      };
    }

    if (richificationEnabled && loading && (!richified || !formattedText)) {
      return {
        catsItems: (
          isCaption
            ? bestText.match(/\S+/g).slice(...renderRange).join(' ')
            : bestText
        ),
        bestText,
        catsInfo: catsPreFormatted,
        shouldRender,
        loading,
      };
    }

    if (richificationEnabled && richified && (richified.transcript === '' || isEmpty(richified))) {
      return {
        catsItems: '',
        bestText,
        catsInfo: catsPreFormatted,
        shouldRender,
        loading,
      };
    }

    return {
      catsItems: (
        isCaption
          ? catsResult.slice(...renderCatsRange)
          : catsResult
      ),
      bestText,
      catsInfo: catsPreFormatted,
      shouldRender,
      loading,
    };
  }

  getSegmentStartTime = () => {
    const {
      catsAbsoluteTimestamps,
      utterance,
      segment,
    } = this.props;

    if (utterance && utterance.cats && utterance.cats[0]) {
      if (catsAbsoluteTimestamps) {
        return utterance.cats[0].interval[0];
      }

      return utterance.cats[0].interval[0] + segment.interval[0];
    }

    return segment.interval[0];
  }

  clickHandler = () => {
    const {
      richificationEnabled,
      connectedSeekToTimestamp,
      segment,
    } = this.props;

    if (richificationEnabled && segment) {
      connectedSeekToTimestamp(this.getSegmentStartTime(), true);
    }
  }

  render() {
    const {
      segment,
      utterance,
      speakerIndex,
      isCaption,
      meeting,
      speakerMetadata,
      richified,
      richificationEnabled,
    } = this.props;
    // TODO move style hacks to CSS file

    const { catsItems: spans, shouldRender, loading } = this.state;

    if (spans.length === 0 || !shouldRender) {
      return '';
    }
    const isZoomOptimized = getMetadata(meeting, 'optimized_zoom') !== false;
    const hasTrackException = getMetadataHasTrackException(meeting);
    const isZoomExpected = isZoomOptimized && !hasTrackException;
    const speakerName = isZoomExpected ? getSpeakerNameAtIndex(meeting, speakerMetadata, speakerIndex) : '';
    const speakerObj = speakerMetadata[speakerIndex];

    let speakerLabelStyle = {};

    if (speakerObj) {
      const speakerColor = speakerObj.hue;
      speakerLabelStyle = {
        color: colorWithAlpha(speakerColor, 1),
      };
    }

    const key = segment.utterance !== undefined
      ? segment.utterance
      : `${segment.interval[0]}-${segment.interval[1]}`;

    if (utterance === undefined) {
      /* For older meetings that don't have segments with utterance IDs */
      const tf = segment.transcript_formatted || '';
      if (tf.length === 0) {
        return '';
      }

      return (
        <div
          onClick={this.clickHandler}
          onKeyUp={(event) => event.code === 'Enter' && this.clickHandler()}
          role="button"
          tabIndex={0}
        >
          {
            isCaption && speakerIndex !== undefined
              ? (
                <div
                  className="caption"
                  key={key}
                >
                  <p className="re is-margin-none">
                    <span className="caption-speaker-name">
                      { speakerName }
                    </span>
                    <br />
                    <span className={loading ? 'grey-loading' : ''}>
                      { spans }
                    </span>
                  </p>
                </div>
              )
              : (
                <>
                  <div className="transcript-segment-left">
                    <div className="segment-time">
                      { formatHoursMinutesSeconds(segment.interval[0], true) }
                    </div>
                  </div>
                  <div className="transcript-segment-right">
                    <div className="transcript-segment-content">
                      <div
                        className="transcript-segment-speaker-name"
                        style={speakerLabelStyle}
                      >
                        { speakerName }
                      </div>
                      <span className="transcript-segment-text">
                        { segment.transcript_formatted }
                      </span>
                    </div>
                  </div>
                </>
              )
          }
        </div>
      );
    }

    if (richificationEnabled && richified.transcript === '') {
      return '';
    }

    return (
      <div
        className="transcript-segment"
      >
        {
          isCaption && speakerIndex !== undefined
            ? (
              <div
                className="caption"
                key={key}
              >
                <p className="re is-margin-none">
                  {
                    isZoomOptimized
                    && (
                      <>
                        <span className="caption-speaker-name">
                          { speakerName }
                        </span>
                        <br />
                      </>
                    )
                  }
                  <span className={loading ? 'grey-loading' : ''}>
                    { spans }
                  </span>
                </p>
              </div>
            )
            : (
              <>
                <div
                  className="transcript-segment-left"
                  onClick={this.clickHandler}
                  onKeyUp={(event) => event.code === 'Enter' && this.clickHandler(event)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="segment-time">
                    { formatHoursMinutesSeconds(this.getSegmentStartTime(), true) }
                  </div>
                </div>
                <div className="transcript-segment-right">
                  <div className="transcript-segment-content">
                    <div
                      className="transcript-segment-speaker-name"
                      style={speakerLabelStyle}
                      onClick={this.clickHandler}
                      onKeyUp={(event) => event.code === 'Enter' && this.clickHandler(event)}
                      role="button"
                      tabIndex={0}
                    >
                      { speakerName }
                    </div>
                    <span className="transcript-segment-text">
                      <span className={loading ? 'grey-loading' : ''}>
                        { spans }
                      </span>
                    </span>
                  </div>
                </div>
              </>
            )
        }
      </div>
    );
  }
}

Segment.propTypes = {
  segment: segmentType.isRequired,
  utterance: catsItemType.isRequired,
  speakerIndex: PropTypes.number,
  speakerMetadata: PropTypes.arrayOf(speakerInfoType),
  meeting: meetingType,

  richified: richifiedTextType,
  richificationRequestError: errorType,
  richificationRequestOut: PropTypes.bool,
  isCaption: PropTypes.bool,
  connectedRichify: PropTypes.func.isRequired,
  connectedSeekToTimestamp: PropTypes.func.isRequired,
  richificationEnabled: PropTypes.bool.isRequired,
  inViewport: PropTypes.bool.isRequired,
  catsAbsoluteTimestamps: PropTypes.bool,
  dynamicRichificationEnabled: PropTypes.bool.isRequired,
  transcriptAmLmBalance: PropTypes.number.isRequired,
  currentTime: PropTypes.number,
  findMatches: PropTypes.arrayOf(matchType),
  currentMatch: currentMatchType,
  selectedSpan: PropTypes.number,
};

Segment.defaultProps = {
  selectedSpan: undefined,
  catsAbsoluteTimestamps: true, // Assume exec by default, which uses abs timestamps
  currentTime: undefined,
  findMatches: undefined,
  currentMatch: undefined,
  isCaption: false,
  speakerIndex: -1,
  speakerMetadata: [],
  meeting: undefined,
  richified: {},
  richificationRequestError: undefined,
  richificationRequestOut: false,
};

const mapStateToProps = (state) => ({
  meeting: state.meeting.meeting,
  speakerMetadata: state.meeting.speakerMetadata,
  richificationEnabled: state.meeting.richificationEnabled,
  segmentIntervalTree: state.meeting.segmentIntervalTree,
  transcriptAmLmBalance: state.meeting.transcriptAmLmBalance,
  dynamicRichificationEnabled: state.meeting.dynamicRichificationEnabled,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSeekToTimestamp: seekToTimestamp,
  connectedRichify: richify,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Segment);
