import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import {
  setProgressBarHovering,
  togglePlay,
} from '../../../modules/player';

import {
  seekToTimestamp,
} from '../../../modules/mediaElement';

import {
  intervalType, meetingType,
} from '../../types';

import { MultiFindResult } from '../../../modules/utils';

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bufferValue: 0,
      hoverScale: 0,
      isSeeking: false,
      progressBarRef: null,
      wasPlaying: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { currentTime } = this.props;
    if (prevProps.currentTime !== currentTime) {
      this.checkBufferedRange();
    }
  }

  checkBufferedRange = () => {
    const { buffered, currentTime } = this.props;
    if (!buffered) return;

    const numRanges = buffered.length;
    for (let i = 0; i < numRanges; i += 1) {
      if (currentTime >= buffered[i][0] && currentTime <= buffered[i][1]) {
        this.setState({ bufferValue: buffered[i][1] });
      }
    }
  }

  getScrubberPx = () => {
    const { currentTime, duration } = this.props;
    const { progressBarRef } = this.state;
    if (!progressBarRef) return 0;

    return (currentTime / duration) * progressBarRef.clientWidth;
  }

  getRelativeX = (e) => {
    const { progressBarRef } = this.state;
    if (!progressBarRef) return 0;

    let relativeX = e.pageX - progressBarRef.getBoundingClientRect().left;
    relativeX = Math.min(Math.max(relativeX, 0), progressBarRef.clientWidth);
    return relativeX;
  }

  onMouseDownHandler = (e) => {
    e.preventDefault();
    const {
      connectedSeekToTimestamp, connectedTogglePlay, duration, isBuffering, isPlaying,
    } = this.props;
    const { progressBarRef } = this.state;
    if (!progressBarRef) return;
    this.setState({ hoverScale: 0 });

    document.addEventListener('mousemove', this.onMouseMoveHandler, false);
    document.addEventListener('mouseup', this.onMouseUpHandler, false);
    const relativeX = this.getRelativeX(e);
    const time = (relativeX / progressBarRef.clientWidth) * duration;
    if (isPlaying || isBuffering) {
      connectedTogglePlay(false);
      this.setState({ wasPlaying: true });
    } else {
      this.setState({ wasPlaying: false });
    }
    connectedSeekToTimestamp(time, false);
    this.setState({ isSeeking: true });
  }

  onMouseLeaveHandler = () => {
    const { connectedSetProgressBarHovering } = this.props;
    connectedSetProgressBarHovering(false);
    this.setState({ hoverScale: 0 });
  }

  onMouseMoveHandler = (e) => {
    e.preventDefault();
    const { connectedSeekToTimestamp, duration } = this.props;
    const { isSeeking, progressBarRef } = this.state;
    if (!progressBarRef) return;

    const relativeX = this.getRelativeX(e);
    const scrubberPx = this.getScrubberPx();
    const relativeXScrubber = relativeX - scrubberPx;
    this.setState({ hoverScale: Math.max(relativeXScrubber, 0) / progressBarRef.clientWidth });
    const time = (relativeX / progressBarRef.clientWidth) * duration;
    if (isSeeking) {
      connectedSeekToTimestamp(time, false);
    }
  }

  onMouseOverHandler = (e) => {
    const { connectedSetProgressBarHovering } = this.props;
    const { progressBarRef } = this.state;
    if (!progressBarRef) return;

    connectedSetProgressBarHovering(true);
    const relativeX = this.getRelativeX(e);
    const scrubberPx = this.getScrubberPx();
    const relativeXScrubber = relativeX - scrubberPx;
    this.setState({ hoverScale: Math.max(relativeXScrubber, 0) / progressBarRef.clientWidth });
  }

  onMouseUpHandler = () => {
    const { connectedSeekToTimestamp, currentTime } = this.props;
    const { wasPlaying } = this.state;
    document.removeEventListener('mousemove', this.onMouseMoveHandler, false);
    document.removeEventListener('mouseup', this.onMouseUpHandler, false);
    connectedSeekToTimestamp(currentTime, wasPlaying);
    this.setState({ isSeeking: false, wasPlaying: false });
  }

  setProgressBarRef = (progressBarRef) => {
    this.setState({ progressBarRef });
  }

  renderFindOverlay = () => {
    const {
      meeting,
      duration,
      cats,
      showFindMarkers,
      findResult,
      findResultIndex,
    } = this.props;

    const result = [];

    if (
      meeting === undefined
      || findResult === undefined
      || cats === undefined
    ) {
      return [];
    }

    if (!showFindMarkers) {
      return [];
    }

    for (let i = 0; i < findResult.size(); i += 1) {
      const isCurrentMatch = i === findResultIndex;
      let className = 'mtg-find-marker';
      if (isCurrentMatch) {
        className += ' current-match';
      }

      result.push(
        <div
          key={`progress-marker-${i}`}
          className={className}
          style={{
            left: `${(findResult.getMatchAtIndex(i).getPosition() / duration) * 100}%`,
          }}
        />,
      );
    }

    return result;
  }

  render() {
    const { currentTime, duration } = this.props;
    const {
      bufferValue, hoverScale,
    } = this.state;
    const bufferedDecimal = bufferValue / duration;
    const progressDecimal = currentTime / duration;
    const scrubberPx = this.getScrubberPx();
    return (
      <div className="mtg-progress-bar-container">
        <div
          className="mtg-progress-bar"
          role="slider"
          aria-valuemax={duration}
          aria-valuemin={0}
          aria-valuenow={currentTime}
          onMouseDown={this.onMouseDownHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onBlur={this.onMouseLeaveHandler}
          onMouseMove={this.onMouseMoveHandler}
          onMouseOver={this.onMouseOverHandler}
          onFocus={this.onMouseOverHandler}
          onMouseUp={this.onMouseUpHandler}
          ref={this.setProgressBarRef}
          tabIndex={0}
        >
          <div className="mtg-progress-bar-padding" />
          <div className="mtg-progress-list">
            <div
              className="mtg-play-progress mtg-swatch-background-color"
              style={{
                left: '0px',
                transform: `scaleX(${progressDecimal})`,
              }}
            />
            <div
              className="mtg-load-progress"
              style={{
                left: '0px',
                transform: `scaleX(${bufferedDecimal})`,
              }}
            />
            <div
              className="mtg-hover-progress mtg-hover-progress-light"
              style={{
                left: `${scrubberPx}px`,
                transform: `scaleX(${hoverScale})`,
              }}
            />
            <div className="mtg-progress-overlay">
              { this.renderFindOverlay() }
            </div>
          </div>
          <div
            className="mtg-scrubber-container"
            style={{
              transform: `translateX(${scrubberPx}px)`,
            }}
          >
            <div className="mtg-scrubber-button mtg-swatch-background-color">
              <div className="mtg-scrubber-pull-indicator" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  buffered: PropTypes.arrayOf(intervalType),
  connectedSeekToTimestamp: PropTypes.func.isRequired,
  connectedSetProgressBarHovering: PropTypes.func.isRequired,
  connectedTogglePlay: PropTypes.func.isRequired,
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  isBuffering: PropTypes.bool,
  isPlaying: PropTypes.bool.isRequired,
  meeting: meetingType,
  showFindMarkers: PropTypes.bool,
  findResult: PropTypes.instanceOf(MultiFindResult),
  findResultIndex: PropTypes.number,
  cats: PropTypes.shape({}),
};

ProgressBar.defaultProps = {
  buffered: [],
  isBuffering: false,
  meeting: undefined,
  showFindMarkers: false,
  findResult: undefined,
  findResultIndex: undefined,
  cats: undefined,
};

const mapStateToProps = (state) => ({
  duration: state.mediaElement.duration,
  isBuffering: state.mediaElement.isBuffering,
  buffered: state.mediaElement.buffered,
  isPlaying: state.mediaElement.isPlaying,
  currentTime: state.mediaElement.currentTime,

  currentMatchIndex: state.find.currentMatchIndex,
  findMatches: state.find.findMatches,
  meeting: state.meeting.meeting,
  currentMatch: state.find.currentMatch,
  cats: state.meeting.cats,

  findResult: state.find.findResult,
  findResultIndex: state.find.findResultIndex,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSeekToTimestamp: seekToTimestamp,
  connectedSetProgressBarHovering: setProgressBarHovering,
  connectedTogglePlay: togglePlay,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgressBar);
