import { makeCancelable } from './utils';

const FLUSH_PLAYER_STATE = 'PLAYER::FLUSH_PLAYER_STATE';

export const GET_AUDIO_REQUEST_SENT = 'PLAYER::GET_AUDIO_REQUEST_SENT';
export const GET_AUDIO_REQUEST_SUCCESS = 'PLAYER::GET_AUDIO_REQUEST_SUCCESS';
export const GET_AUDIO_REQUEST_FAIL = 'PLAYER::GET_AUDIO_REQUEST_FAIL';
export const CANCEL_GET_AUDIO = 'PLAYER::CANCEL_GET_AUDIO_REQUEST';

export const GET_MEDIA_REQUEST_SENT = 'PLAYER::GET_MEDIA_REQUEST_SENT';
export const GET_MEDIA_REQUEST_SUCCESS = 'PLAYER::GET_MEDIA_REQUEST_SUCCESS';
export const GET_MEDIA_REQUEST_FAIL = 'PLAYER::GET_MEDIA_REQUEST_FAIL';
export const CANCEL_GET_MEDIA = 'PLAYER::CANCEL_GET_MEDIA_REQUEST';

export const GET_VIDEO_REQUEST_SENT = 'PLAYER::GET_VIDEO_REQUEST_SENT';
export const GET_VIDEO_REQUEST_SUCCESS = 'PLAYER::GET_VIDEO_REQUEST_SUCCESS';
export const GET_VIDEO_REQUEST_FAIL = 'PLAYER::GET_VIDEO_REQUEST_FAIL';
export const CANCEL_GET_VIDEO = 'PLAYER::CANCEL_GET_VIDEO_REQUEST';

export const TOGGLE_PLAY = 'PLAYER::TOGGLE_PLAY';

const SET_PLAYBACK_RATE = 'PLAYER::SET_PLAYBACK_RATE';
const SET_PROGRESS_BAR_HOVERING = 'PLAYER::SET_PROGRESS_BAR_HOVERING';
const SET_VOLUME = 'PLAYER::SET_VOLUME';
const SET_VOLUME_FOCUSED = 'PLAYER::SET_VOLUME_FOCUSED';
const SET_VOLUME_HOVERING = 'PLAYER::SET_VOLUME_HOVERING';
const SET_VOLUME_SLIDER_FOCUSED = 'PLAYER::SET_VOLUME_SLIDER_FOCUSED';
const SET_SHOW_RATE_POPUP = 'PLAYER::SET_SHOW_RATE_POPUP';
const SET_SHOW_VIDEO_CONTROLS = 'PLAYER::SET_SHOW_VIDEO_CONTROLS';
const SET_VOLUME_IS_MUTED = 'PLAYER::SET_VOLUME_IS_MUTED';
const SET_AUTOHIDE_TIMEOUT = 'PLAYER::SET_AUTOHIDE_TIMEOUT';
const SET_MEDIA_ELEMENT_LOADED = 'PLAYER::SET_MEDIA_ELEMENT_LOADED';

const initialState = {
  audioUrl: '',
  getAudioError: undefined,
  getAudioRequestOut: false,
  getMediaError: undefined,
  getMediaRequestOut: false,
  getVideoError: undefined,
  getVideoRequestOut: false,
  mediaUrl: '',
  shouldPlay: false,
  playbackRate: 1.0,
  progressBarHovering: false,
  videoUrl: '',
  volume: 100,
  volumeFocused: false,
  volumeHovering: false,
  volumeSliderFocused: false,
  showRatePopup: false,
  showVideoControls: true,
  autohideTimeout: null,
  mediaElementLoaded: false,
  getVideoPromise: undefined,
  getAudioPromise: undefined,
  getMediaPromise: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLUSH_PLAYER_STATE:
      return { ...initialState };
    case CANCEL_GET_AUDIO: {
      const { getAudioPromise } = state;
      if (getAudioPromise) getAudioPromise.cancel();
      return state;
    }
    case CANCEL_GET_MEDIA: {
      const { getMediaPromise } = state;
      if (getMediaPromise) getMediaPromise.cancel();
      return state;
    }
    case CANCEL_GET_VIDEO: {
      const { getVideoPromise } = state;
      if (getVideoPromise) getVideoPromise.cancel();
      return state;
    }
    case GET_AUDIO_REQUEST_SENT:
      return {
        ...state,
        getAudioRequestOut: true,
        getAudioError: undefined,
        getAudioPromise: action.getAudioPromise,
      };
    case GET_AUDIO_REQUEST_SUCCESS:
      return { ...state, getAudioRequestOut: false, audioUrl: action.url };
    case GET_AUDIO_REQUEST_FAIL:
      return { ...state, getAudioRequestOut: false, getAudioError: action.error };
    case GET_MEDIA_REQUEST_SENT:
      return {
        ...state,
        getMediaRequestOut: true,
        getMediaError: undefined,
        mediaUrl: '',
        getMediaPromise: action.getMediaPromise,
      };
    case GET_MEDIA_REQUEST_SUCCESS:
      return { ...state, getMediaRequestOut: false, mediaUrl: action.url };
    case GET_MEDIA_REQUEST_FAIL:
      return { ...state, getMediaRequestOut: false, getMediaError: action.error };
    case GET_VIDEO_REQUEST_SENT:
      return {
        ...state,
        getVideoRequestOut: true,
        getVideoError: undefined,
        getVideoPromise: action.getVideoPromise,
      };
    case GET_VIDEO_REQUEST_SUCCESS:
      return { ...state, getVideoRequestOut: false, videoUrl: action.url };
    case GET_VIDEO_REQUEST_FAIL:
      return { ...state, getVideoRequestOut: false, getVideoError: action.error };
    case SET_PLAYBACK_RATE:
      return { ...state, playbackRate: action.rate };
    case SET_PROGRESS_BAR_HOVERING:
      return { ...state, progressBarHovering: action.isHovering };
    case SET_VOLUME:
      return { ...state, volume: action.volume };
    case SET_VOLUME_FOCUSED:
      return { ...state, volumeFocused: action.shouldFocus };
    case SET_VOLUME_HOVERING:
      return { ...state, volumeHovering: action.isHovering };
    case SET_VOLUME_SLIDER_FOCUSED:
      return { ...state, volumeSliderFocused: action.shouldFocus };
    case TOGGLE_PLAY:
      return { ...state, shouldPlay: action.shouldPlay };
    case SET_SHOW_RATE_POPUP:
      return { ...state, showRatePopup: action.showPopup };
    case SET_SHOW_VIDEO_CONTROLS:
      return { ...state, showVideoControls: action.showControls };
    case SET_VOLUME_IS_MUTED:
      return { ...state, volumeIsMuted: action.volumeIsMuted };
    case SET_MEDIA_ELEMENT_LOADED:
      return { ...state, mediaElementLoaded: true };
    case SET_AUTOHIDE_TIMEOUT:
      return {
        ...state,
        autohideTimeout: action.autohideTimeout,
      };
    default:
      return state;
  }
};

export const flushPlayer = () => ({ type: FLUSH_PLAYER_STATE });

export const getRecognitionAudio = (id) => (dispatch, getState) => {
  const { rmi, meeting: { shareKey } } = getState();
  const options = { id, shareKey };
  options.media = 'audio';

  const getAudioPromise = makeCancelable(rmi.recognitions.get(options));

  getAudioPromise.promise
    .then((resp) => {
      dispatch({ type: GET_AUDIO_REQUEST_SUCCESS, url: resp.audio_url });
    })
    .catch((error) => {
      if (!error.isCanceled) {
        dispatch({ type: GET_AUDIO_REQUEST_FAIL, error });
      }
    });

  dispatch({ type: GET_AUDIO_REQUEST_SENT, getAudioPromise });
};

export const cancelGetRecognitionAudio = () => ({
  type: CANCEL_GET_AUDIO,
});

export const getRecognitionMedia = (id) => (dispatch, getState) => {
  const { rmi } = getState();
  const options = { id };
  options.media = 'media';

  const getMediaPromise = makeCancelable(rmi.recognitions.get(options));

  getMediaPromise
    .then((resp) => {
      dispatch({ type: GET_MEDIA_REQUEST_SUCCESS, url: resp.media_url });
    })
    .catch((error) => {
      if (!error.isCanceled) {
        dispatch({ type: GET_MEDIA_REQUEST_FAIL, error });
      }
    });

  dispatch({ type: GET_MEDIA_REQUEST_SENT, getMediaPromise });
};

export const cancelGetRecognitionMedia = () => ({
  type: CANCEL_GET_MEDIA,
});

export const getRecognitionVideo = (id) => (dispatch, getState) => {
  const { rmi, meeting: { shareKey } } = getState();
  const options = { id, shareKey };
  options.media = 'video';

  const getVideoPromise = makeCancelable(rmi.recognitions.get(options));

  getVideoPromise.promise
    .then((resp) => {
      dispatch({ type: GET_VIDEO_REQUEST_SUCCESS, url: resp.video_url });
    })
    .catch((error) => {
      if (!error.isCanceled) {
        dispatch({ type: GET_VIDEO_REQUEST_FAIL, error });
      }
    });

  dispatch({ type: GET_VIDEO_REQUEST_SENT, getVideoPromise });
};

export const cancelGetRecognitionVideo = () => ({
  type: CANCEL_GET_VIDEO,
});

/**
 * setProgressBarHovering() updates Redux state of whether or not the user is currently hovering
 * hover the progress bar.
 */
export const setProgressBarHovering = (isHovering) => ({
  type: SET_PROGRESS_BAR_HOVERING,
  isHovering,
});

/**
 * setVolume() updates Redux state of the volume.
 */
export const setVolume = (volume) => ({
  type: SET_VOLUME,
  volume,
});

/**
 * setVolumeFocused updates Redux state of whether or not the user is focused on the volume button.
 * This can happen either through clicking the volume button or keyboard tabbing onto it.
 */
export const setVolumeFocused = (shouldFocus) => ({
  type: SET_VOLUME_FOCUSED,
  shouldFocus,
});

/**
 * setVolumeHovering updates Redux state of whether or not the user is currently hovering over the
 * volume and volume slider.
 */
export const setVolumeHovering = (isHovering) => ({
  type: SET_VOLUME_HOVERING,
  isHovering,
});

/**
 * setVolumeSliderFocused updates Redux state of whether or not the user is focused on the volume
 * slider. This can happen either through clicking the volume slider or keyboard tabbing onto it.
 */
export const setVolumeSliderFocused = (shouldFocus) => ({
  type: SET_VOLUME_SLIDER_FOCUSED,
  shouldFocus,
});

/**
 * togglePlay() allows outside components toggle play/pause on the media player.
 *
 * This action creator changes the shouldPlay variable in Redux.
 * If shouldPlay is not specified, togglePlay() will simply send an action specifying
 * shouldPlay as the opposite of what shouldPlay currently is.
 *
 * @param {boolean} userShouldPlay
 */
export const togglePlay = (userShouldPlay = -1) => (dispatch, getState) => {
  const { shouldPlay } = getState().player;
  if (userShouldPlay !== -1) {
    dispatch({ type: TOGGLE_PLAY, shouldPlay: userShouldPlay });
  } else {
    dispatch({
      type: TOGGLE_PLAY,
      shouldPlay: !shouldPlay,
    });
  }
};

export const setShowRatePopup = (showPopup) => ({
  type: SET_SHOW_RATE_POPUP,
  showPopup,
});

export const setShowVideoControls = (showControls) => ({
  type: SET_SHOW_VIDEO_CONTROLS,
  showControls,
});

export const setVolumeIsMuted = (volumeIsMuted) => ({
  type: SET_VOLUME_IS_MUTED,
  volumeIsMuted,
});

export const toggleControls = (shouldShow) => (dispatch, getState) => {
  const { autohideTimeout } = getState().player;
  clearTimeout(autohideTimeout);
  dispatch({
    type: SET_AUTOHIDE_TIMEOUT,
    autohideTimeout: null,
  });

  if (shouldShow) {
    dispatch(setShowVideoControls(true));
  } else {
    dispatch(setShowVideoControls(false));
  }
};

export const toggleRatePopup = (shouldShow) => (dispatch) => {
  if (shouldShow) {
    dispatch(setShowRatePopup(true));
  } else {
    dispatch(setShowRatePopup(false));
  }
};

export const setAutohideTimeout = () => (dispatch) => {
  dispatch({
    type: SET_AUTOHIDE_TIMEOUT,
    autohideTimeout: setTimeout(() => {
      dispatch(toggleControls(false));
    }, 3000),
  });
};

export const setMediaElementLoaded = () => ({
  type: SET_MEDIA_ELEMENT_LOADED,
});

/**
 * setPlaybackRate() updates Redux state of the playback rate of the media elements.
 *
 * To be called by external components that need to change the playback rate of the media.
 */
export const setPlaybackRate = (rate) => ({
  type: SET_PLAYBACK_RATE,
  rate,
});
