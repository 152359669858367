import React from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const ShortcutModal = (props) => {
  const {
    open,
    onClose,
  } = props;

  return (
    <Modal open={open} onClose={onClose} style={{ width: 650 }} closeIcon>
      <Modal.Header>
        Keyboard Shortcuts
      </Modal.Header>
      <Modal.Content>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              ?
            </p>
          </Grid>
          <Grid item xs={4}>
            Open/close this guide
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              F
            </p>
          </Grid>
          <Grid item xs={4}>
            Toggle full-screen video
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              Ctrl + F
            </p>
          </Grid>
          <Grid item xs={4}>
            Find within transcript
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              &#9166;
            </p>
          </Grid>
          <Grid item xs={4}>
            Next find result
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              &#8679; + &#9166;
            </p>
          </Grid>
          <Grid item xs={4}>
            Prev. find result
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              SPACE
            </p>
          </Grid>
          <Grid item xs={4}>
            Start/stop media playback
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              C
            </p>
          </Grid>
          <Grid item xs={4}>
            Toggle video captions
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              B
            </p>
          </Grid>
          <Grid item xs={4}>
            Show transcript at seek time
          </Grid>

          <Grid item xs={1}>
            <p className="shortcut-modal-key">
              &#8592;
            </p>
          </Grid>
          <Grid item xs={1}>
            <p className="shortcut-modal-key">
              &#8594;
            </p>
          </Grid>
          <Grid item xs={4}>
            Seek +/- 10 seconds
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              M
            </p>
          </Grid>
          <Grid item xs={4}>
            Mute/unmute video
          </Grid>

          <Grid item xs={2}>
            <p className="shortcut-modal-key">
              R
            </p>
          </Grid>
          <Grid item xs={4}>
            Toggle transcript formatting
          </Grid>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

ShortcutModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ShortcutModal.defaultProps = {
  open: false,
};

export default ShortcutModal;
