const request = require('request-promise-native');

/**
 * call
 * makes a network request using the request library
 * @params options Obj - options for the network request
 * @params resolve, reject Func
 * @params instance Obj - instance with extend method present
 * @returns native Promise
 */
function call(options, resolve, reject, instance) {
  const rq = request(options);

  if (resolve) {
    rq.then((data) => {
      if (instance) {
        resolve(instance.extend(data));
      } else {
        resolve(data);
      }
    });
  }

  if (reject) {
    rq.catch((data) => {
      reject(data);
    });
  }
  return rq;
}

module.exports = call;
