import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import { Spinner } from 'reactstrap';
import { Checkbox, Popup } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import { historyType } from '../types';

import { toggleSearchBarFocus } from '../../modules/search';
import { buildURLQueryString, keyCodes } from '../../modules/utils';

/**
 * ChipInput custom style overriding code.
 * See https://v1.material-ui.com/customization/overrides/#overriding-with-classes
 */
const chipInputStyles = {
  chip: {
    fontFamily: "'PT Sans','Segoe UI',Arial,Helvetica,sans-serif",
  },
  helperText: {
    fontFamily: "'PT Sans','Segoe UI',Arial,Helvetica,sans-serif",
  },
  inputRoot: {
    fontFamily: "'PT Sans','Segoe UI',Arial,Helvetica,sans-serif",
  },
  root: {
    marginBottom: '20px',
  },
};

const chipRenderer = ({
  text,
  isFocused,
  isDisabled,
  isReadOnly,
  handleClick,
  handleDelete,
  className,
}, key) => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
      backgroundColor: isFocused ? '#e8e9ea' : undefined,
      boxShadow: isFocused ? 'inset 0px 0px 0px 2px darkgray' : undefined,
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
  />
);

function StyledChipInput(props) {
  const {
    classes,
    // Mapped to a new name to abide by the no-shadow ESLint rule
    chipRenderer: inputChipRenderer,
    fullWidth,
    helperText,
    onAdd,
    onDelete,
    newChipKeys,
    value,
  } = props;

  return (
    <ChipInput
      classes={{
        chip: classes.chip,
        helperText: classes.helperText,
        inputRoot: classes.inputRoot,
        root: classes.root,
      }}
      chipRenderer={inputChipRenderer}
      fullWidth={fullWidth}
      helperText={helperText}
      onAdd={onAdd}
      onDelete={onDelete}
      newChipKeys={newChipKeys}
      value={value}
    />
  );
}

StyledChipInput.propTypes = {
  classes: PropTypes.shape({
    chip: PropTypes.string,
    helperText: PropTypes.string,
    inputRoot: PropTypes.string,
    root: PropTypes.string,
  }).isRequired,
  chipRenderer: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  newChipKeys: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

const WrappedChipInput = withStyles(chipInputStyles)(StyledChipInput);

/**
 * Convert the fields state object into a comma-separated list of
 * fields to search. If all fields are checked, we don't need to pass a
 * fields query arg and so return an empty string.
 *
 * @param {Object} fields - A flat object of field names and their boolean values.
 * @param {boolean} fields.ocr
 * @param {boolean} fields.title
 * @param {boolean} fields.transcript
 * @returns {string} A comma-separated string of toggled fields. If all fields were true, return ''.
 */
export const stringifyFieldsObject = (fields) => {
  let fieldsString = '';
  const numUncheckedFields = Object.entries(fields).reduce((acc, [, val]) => {
    if (!val) {
      return acc + 1;
    }
    return acc;
  }, 0);
  if (numUncheckedFields) {
    fieldsString = `${Object.keys(fields).filter((key) => fields[key]).join(',')}`;
  }
  return fieldsString;
};

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: props.initialQuery,
    };

    this.searchBarRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false);
  }

  componentDidUpdate(prevProps) {
    const { initialQuery } = this.props;
    if (prevProps.initialQuery !== initialQuery) {
      this.setState({ query: initialQuery }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false);
  }

  enterSearch = () => {
    if (this.searchBarRef.current) {
      this.searchBarRef.current.focus();
    }
  }

  exitSearch = () => {
    this.searchBarRef.current.blur();
  }

  clearSearch = () => {
    this.setState({ query: '' });
    this.searchBarRef.current.focus();
  }

  onKeyPress = (e) => {
    const { cardActionOpen } = this.props;
    if (!cardActionOpen) {
      if (e.which === keyCodes.F && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        this.enterSearch();
      } else if (e.which === keyCodes.ESC) {
        e.preventDefault();
        this.exitSearch();
      }
    }
  }

  onSearchBarKeyPress = (e) => {
    if (e.which === keyCodes.ENTER) {
      this.onSearchClick();
    }
  }

  onSearchClick = () => {
    const { fields, history, speakers } = this.props;
    const { query } = this.state;
    const trimQuery = query.trim();
    const qsConfig = { q: trimQuery };
    const fieldsString = stringifyFieldsObject(fields);
    const speakersString = speakers.join(',');
    if (fieldsString) qsConfig.fields = fieldsString;
    if (speakersString) qsConfig.speakers = speakersString;
    const queryParams = buildURLQueryString(qsConfig);
    this.exitSearch();
    history.push(`/search?${queryParams}`);
  }

  render() {
    const {
      connectedToggleSearchBarFocus,
      fields,
      requestOut,
      settingsUpdateHandler,
      speakers,
    } = this.props;
    const { query } = this.state;
    const numFieldsToggled = Object.entries(fields).reduce((acc, [, val]) => {
      if (val) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return (
      <div id="xms-bar-group">
        <button
          id="xms-bar-search-btn"
          className="xms-bar-button"
          onClick={this.onSearchClick}
          title="Search"
          type="button"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
        <input
          autoComplete="off"
          type="text"
          id="xms-bar"
          className={query ? 'focused' : ''}
          ref={this.searchBarRef}
          placeholder="Search..."
          value={query}
          onChange={(e) => {
            this.setState({
              query: e.target.value,
            });
          }}
          onFocus={() => connectedToggleSearchBarFocus(true)}
          onBlur={() => connectedToggleSearchBarFocus(false)}
          onKeyPress={this.onSearchBarKeyPress}
        />
        {
          (requestOut) && (
            <div id="xms-bar-spinner">
              <Spinner size="sm" />
            </div>
          )
        }
        {
          query && (
            <button
              id="xms-bar-clear"
              className="xms-bar-button"
              onClick={this.clearSearch}
              title="Clear search"
              type="button"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )
        }
        <Popup
          basic
          id="search-settings-popup"
          on="click"
          pinned
          position="bottom right"
          trigger={(
            <button
              id="xms-settings-button"
              className="xms-bar-button"
              type="button"
              title="Show search settings"
            >
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
          )}
        >
          <Popup.Content>
            <div className="search-settings-subheader">Search fields</div>
            <div className="re is-flex">
              <div className="search-settings-checkbox-container">
                <Checkbox
                  checked={fields.transcript}
                  disabled={numFieldsToggled === 1 && fields.transcript}
                  label="Audio"
                  onChange={(_, d) => {
                    settingsUpdateHandler({ fields: { transcript: d.checked } });
                  }}
                />
              </div>
              <div className="search-settings-checkbox-container">
                <Checkbox
                  checked={fields.title}
                  disabled={numFieldsToggled === 1 && fields.title}
                  label="Title"
                  onChange={(_, d) => {
                    settingsUpdateHandler({ fields: { title: d.checked } });
                  }}
                />
              </div>
              <div className="search-settings-checkbox-container">
                <Checkbox
                  checked={fields.ocr}
                  disabled={numFieldsToggled === 1 && fields.ocr}
                  label="Video"
                  onChange={(_, d) => {
                    settingsUpdateHandler({ fields: { ocr: d.checked } });
                  }}
                />
              </div>
            </div>
            <div className="xms-setting-item">
              <div className="search-settings-subheader">Participants</div>
              <WrappedChipInput
                chipRenderer={chipRenderer}
                fullWidth
                helperText="Enter a comma after each participant"
                onAdd={(chip) => settingsUpdateHandler({ speakers: [...speakers, chip] })}
                onDelete={
                  (chip) => settingsUpdateHandler({ speakers: speakers.filter((c) => c !== chip) })
                }
                newChipKeys={[',', 'ENTER']}
                value={speakers}
              />
            </div>
          </Popup.Content>
        </Popup>
      </div>
    );
  }
}

SearchBar.defaultProps = {
  fields: {
    ocr: true,
    title: true,
    transcript: true,
  },
  initialQuery: '',
  speakers: [],
};

SearchBar.propTypes = {
  connectedToggleSearchBarFocus: PropTypes.func.isRequired,

  /* fields: An object passed from parent representing the current
     state of the search fields setting. */
  fields: PropTypes.shape({
    ocr: PropTypes.bool.isRequired,
    title: PropTypes.bool.isRequired,
    transcript: PropTypes.bool.isRequired,
  }),

  /* settingsUpdateHandler: A callback function passed from parent for
     when you change the search settings. */
  settingsUpdateHandler: PropTypes.func.isRequired,

  /* history: React Router history object that should be passed from a
     parent function. */
  history: historyType.isRequired,

  /* initialQuery: A string passed from parent representing what the
     search bar should be initialized with (this is not the
     placeholder). */

  initialQuery: PropTypes.string,
  requestOut: PropTypes.bool.isRequired,

  /* speakers: An array of speaker names representing the current
     state of speakers being filtered on. */
  speakers: PropTypes.arrayOf(PropTypes.string),

  cardActionOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  cardActionOpen: state.recognitions.cardActionOpen,
  requestOut: state.search.requestOut,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedToggleSearchBarFocus: toggleSearchBarFocus,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBar);
