import { TOGGLE_PLAY } from './player';

const SEEK_TIME = 'PLAYER::SEEK_TIME';
const SET_CURRENT_TIME = 'PLAYER::SET_CURRENT_TIME';
const SET_PLAYING = 'PLAYER::SET_PLAYING';
const SET_BUFFERED = 'PLAYER::SET_BUFFERED';
const SET_DURATION = 'PLAYER::SET_DURATION';

const SET_VIDEO_DIMS = 'PLAYER::SET_VIDEO_DIMS';
const SET_IS_BUFFERING = 'PLAYER::SET_IS_BUFFERING';
const FLUSH_MEDIA_ELEMENT = 'PLAYER::FLUSH_MEDIA_ELEMENT';

const initialState = {
  isBuffering: false,
  isPlaying: false,
  currentTime: 0,
  buffered: null,
  duration: 0.1, // Set to 0.1 so time display shows 0:00/0:00 before video loads
  seekRequest: {},
  videoWidth: 0,
  videoHeight: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLUSH_MEDIA_ELEMENT:
      return { ...initialState };
    case SET_PLAYING:
      return { ...state, isPlaying: action.playing };
    case SET_BUFFERED:
      return { ...state, buffered: action.buffered };
    case SET_DURATION:
      return { ...state, duration: action.duration };
    case SEEK_TIME:
      return { ...state, seekRequest: action.seekRequest };
    case SET_CURRENT_TIME:
      return { ...state, currentTime: action.currentTime };
    case SET_VIDEO_DIMS:
      return { ...state, videoWidth: action.width, videoHeight: action.height };
    case SET_IS_BUFFERING:
      return { ...state, isBuffering: action.buffering };
    default:
      return state;
  }
};

/**
 * Flushes the state in Redux.
 */
export const flushMediaElementState = () => ({
  type: FLUSH_MEDIA_ELEMENT,
});

/**
 * setPlaying() updates Redux state of whether or not the media player is playing
 * called isPlaying
 *
 * Only the audio/video element in the MediaPlayer component should call setPlaying.
 * This function in effect allows the player's internal state to be exposed
 * in Redux.
 *
 * Any other component or user-facing interface that wants to play/pause media
 * should use the togglePlay action creator below.
 *
 * NOTE: The isPlaying attribute in Redux specifies when playback has actually started, not when
 * the play button has been toggled, for example. For instance, if the video player is buffering
 * after a play request, isPlaying would still be false.
 */
export const setPlaying = (playing) => ({
  type: SET_PLAYING,
  playing,
});

/**
 * setBuffered() updates Redux state of buffered information.
 *
 * Only the audio/video element in the MediaPlayer component should call setBuffered.
 */
export const setBuffered = (buffered) => ({
  type: SET_BUFFERED,
  buffered,
});

/**
 * setDuration() updates Redux state of the duration of the media file.
 *
 * Only the audio/video element in the MediaPlayer component should call setDuration.
 */
export const setDuration = (duration) => ({
  type: SET_DURATION,
  duration,
});

/**
 * setCurrentTime() updates Redux state of what the currentTime of the media is.
 *
 * Only the audio/video element in the MediaPlayer component should call setCurrentTimer.
 * This function in effect allows the player's internal state to be exposed
 * in Redux.
 *
 * Any other component or user-facing interface that wants to set currentTime should instead
 * use the seekToTimestamp action creator.
 */
export const setCurrentTime = (currentTime) => ({
  type: SET_CURRENT_TIME,
  currentTime,
});

/**
 * seekToTimestamp() exposes seeking to outside components.
 *
 * It will send a seekRequest, which is an object that has 2 fields: time
 * and shouldPlay. time specifies where, in seconds, to seek to, and shouldPlay
 * specifies whether or not to play after the seek.
 *
 * @param {number} time
 * @param {boolean} shouldPlay
 */
export const seekToTimestamp = (time, shouldPlay) => (dispatch) => {
  dispatch(setCurrentTime(time));
  dispatch({
    type: SEEK_TIME,
    seekRequest: {
      time,
      shouldPlay,
    },
  });
  dispatch({
    type: TOGGLE_PLAY,
    shouldPlay,
  });
};

/**
 * setVideoDimensions() exposes the video resizing capabilities to React components.
 *
 * It takes in the width and height parameters and sets videoWidth and videoHeight to the desired
 * lengths.
 *
 * @param {number} width
 * @param {number} height
 */
export const setVideoDimensions = (width, height) => ({
  type: SET_VIDEO_DIMS,
  width,
  height,
});

/**
 * setIsBuffering() updates Redux state of whether or not buffering was detected during playback.
 */
export const setIsBuffering = (buffering) => ({
  type: SET_IS_BUFFERING,
  buffering,
});
