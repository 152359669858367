import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { seekToTimestamp } from '../../modules/mediaElement';

class RichSpan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  handleClick = () => {
    const {
      connectedSeekToTimestamp,
      formattedWord,
    } = this.props;

    const [startTime] = formattedWord.interval;
    connectedSeekToTimestamp(startTime, true);
  }

  mouseOverHandler = () => {
    this.setState({ isHovering: true });
  }

  mouseLeaveHandler = () => {
    this.setState({ isHovering: false });
  }

  renderItalicWording = () => {
    const { italics, formattedWord } = this.props;
    const words = formattedWord.word.split(/\s+/);
    const resultWording = [];
    for (let i = 0; i < words.length; i += 1) {
      if (italics.has(i)) {
        resultWording.push(
          <span
            className="richified-italicized"
            key={`richified-italicize-${i}`}
          >
            {words[i]}
          </span>,
        );
      } else {
        resultWording.push(words[i]);
      }
      resultWording.push(' ');
    }
    resultWording.pop();
    return resultWording;
  }

  render() {
    const { highlight, identifier } = this.props;
    const { isHovering } = this.state;

    const className = isHovering ? `${highlight} rich-span-selected` : highlight;

    return (
      <span
        id={`richified-transcript-${identifier}`}
        key={`rich-span-${identifier}`}
        onClick={this.handleClick}
        onKeyUp={(event) => event.code === 'Enter' && this.handleClick(event)}
        onMouseOver={this.mouseOverHandler}
        onFocus={this.mouseOverHandler}
        onMouseLeave={this.mouseLeaveHandler}
        onBlur={this.mouseLeaveHandler}
        className={className}
        role="button"
        tabIndex={0}
      >
        { this.renderItalicWording() }
      </span>
    );
  }
}

RichSpan.propTypes = {
  connectedSeekToTimestamp: PropTypes.func.isRequired,
  formattedWord: PropTypes.shape({
    interval: PropTypes.arrayOf(PropTypes.number),
    word: PropTypes.string,
  }).isRequired,
  highlight: PropTypes.string,
  identifier: PropTypes.string.isRequired,
  italics: PropTypes.objectOf(PropTypes.number).isRequired,
};

RichSpan.defaultProps = {
  highlight: undefined,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSeekToTimestamp: seekToTimestamp,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RichSpan);
