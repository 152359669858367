const call = require('../utils/call');
const messages = require('../utils/messages');

const BASE_OPTIONS = Symbol();
class G2P {
  constructor(graphemes, baseOptions, config) {
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(G2P);
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}g2p`;
    this.graphemes = graphemes;
    this.extend(config);
  }

  extend(obj) {
    return Object.assign(this, obj);
  }

  get(config) {
    const g2p = this;
    config = config || {};
    g2p.extend(config);

    return new Promise((resolve, reject) => {
      if (!g2p.graphemes) {
        reject({ message: messages.graphemesRequired });
        return;
      }
      const options = g2p[BASE_OPTIONS].getOptions({ id: g2p.graphemes.replace(' ', '_') });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.body = { ...g2p };

      const rq = call(options, undefined, reject);
      rq.then((data) => {
        g2p.extend({ items: data });
        resolve(g2p);
      });
    });
  }
}

module.exports = G2P;
