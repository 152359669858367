const call = require('../utils/call');
const messages = require('../utils/messages');

const BASE_OPTIONS = Symbol();
class Lexentry {
  /*
   * Create a new Lexentry. You can set lexicon_uuid here to avoid
   * having to set it when calling future operations (e.g. get,
   * put). You can also set other defaults, such as lexentry_uuid,
   * name, silprobs, etc., but this is not recommended.
   */

  constructor(config, baseOptions) {
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Lexentry);
    // setting the resource url is weird here because base options
    // doesn't have a concept of a nested reource, this is a TODO item
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}lexicon`;
    this.extend(config);
  }

  extend(obj) {
    return Object.assign(this, obj);
  }

  /*
   * Returns a promise that will resolve a lexical entry.
   * The lexicon_uuid property of config is required.
   * The lexentry_uuid property of config is required.
   * Example:
   *
   * get({lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
          lexentry_uuid: '670047a6-b75f-4bcd-aff7-e6061f62acdf' }) ->
   *   Lexentry {
   *     graphemes: 'arpa',
   *     lexentry_uuid: '670047a6-b75f-4bcd-aff7-e6061f62acdf',
   *     lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
   *     phonemes: 'AA R P AH',
   *     prob: null,
   *     silprobs: null,
   *     soundslike: null,
   *     unigram: 0.8
   *   }
   */

  get(config) {
    const lexentry = this;
    config = config || {};
    lexentry.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexentry.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }
      if (!lexentry.lexentry_uuid) {
        reject({ message: messages.lexentry_uuidRequired });
        return;
      }

      const options = lexentry[BASE_OPTIONS].getOptions({ id: lexentry.lexicon_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.url += `/lexentry/${lexentry.lexentry_uuid}`;

      const rq = call(options, undefined, reject, lexentry);
      rq.then((data) => {
        resolve(lexentry.extend(data[0]));
      });
    });
  }

  /*
   * Create a new lexical entry. The lexicon_uuid property is
   * required. All other fields are optional, and include graphemes,
   * phonemes, soundslike, unigram, prob, and silprobs.  The
   * lexentry_uuid will be generated by the API. Returns a promise
   * that will receive the newly created lexical entry.
   *
   * Example:
   *
   * post({lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa'}) ->
   *   Lexentry {
   *     lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
   *     graphemes: null,
   *     lexentry_uuid: 'cd6aef4e-024b-41e3-b93c-26ebc1b1671b',
   *     phonemes: null,
   *     prob: null,
   *     silprobs: null,
   *     soundslike: null,
   *     unigram: null }
   */

  post(config) {
    const lexentry = this;
    config = config || {};
    lexentry.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexentry.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      if (lexentry.lexentry_uuid) {
        reject({ message: messages.lexentry_uuidSetInvalid });
        return;
      }
      const options = lexentry[BASE_OPTIONS].getOptions({ method: 'POST', id: lexentry.lexicon_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.url += '/lexentry';
      options.body = {};

      // NOTE: this forEach loop is somewhat stylistically contentious:
      // https://github.com/airbnb/javascript#iterators--nope
      // https://stackoverflow.com/questions/500504/why-is-using-for-in-for-array-iteration-a-bad-idea/
      ['graphemes', 'phonemes', 'soundslike', 'unigram', 'prob', 'silprobs'].forEach((field) => {
        if (lexentry[field]) {
          options.body[field] = lexentry[field];
        }
      });

      call(options, resolve, reject, lexentry);
    });
  }

  /*
   * Updates an existing lexical entry. The lexicon_uuid and
   * lexentry_uuid properties are required. All other fields are
   * optional, and include graphemes, phonemes, soundslike, unigram,
   * prob, and silprobs. Returns a promise that will receive the
   * updated lexical entry.
   *
   * Example:
   *
   * put({lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
          lexentry_uuid: 'cd6aef4e-024b-41e3-b93c-26ebc1b1671b', graphemes: 'remeeting'}) ->
   *   Lexentry {
   *     lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
   *     graphemes: 'remeeting',
   *     lexentry_uuid: 'cd6aef4e-024b-41e3-b93c-26ebc1b1671b'
   *   }
   */

  /* Lots of code duplication with post. TODO: refactor */
  put(config) {
    const lexentry = this;
    config = config || {};
    lexentry.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexentry.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      if (!lexentry.lexentry_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      const options = lexentry[BASE_OPTIONS].getOptions({ method: 'PUT', id: lexentry.lexicon_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.url += `/lexentry/${lexentry.lexentry_uuid}`;
      options.body = { ...lexentry };

      call(options, resolve, reject, lexentry);
    });
  }

  /*
   * Delete a lexical entry. The lexicon_uuid and lexentry_uuid
   * properties are required. This operation cannot be undone! Returns
   * a promise that resolve nothing if successful.
   *
   * Example:
   *
   * delete({lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
             lexentry_uuid: 'cd6aef4e-024b-41e3-b93c-26ebc1b1671b'}) ->
   *   undefined
   */

  delete(config) {
    const lexentry = this;
    config = config || {};
    lexentry.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexentry.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      if (!lexentry.lexentry_uuid) {
        reject({ message: messages.lexentry_uuidRequired });
        return;
      }

      const options = lexentry[BASE_OPTIONS].getOptions({ method: 'DELETE', id: lexentry.lexicon_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.url += `/lexentry/${lexentry.lexentry_uuid}`;

      call(options, resolve, reject);
    });
  }
}

/*
 * Returns a promise that will receive a list of lexical entries.
 * The lexicon_uuid property of config is required. You may provide
 * graphemes property to restrict the returnedlist to matching lexical
 * entries.
 *
 * Example:
 *
 * list({lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa', graphemes: 'arpa'}) ->
 *   [ Lexentry {
 *       graphemes: 'arpa',
 *       lexentry_uuid: '670047a6-b75f-4bcd-aff7-e6061f62acdf',
 *       lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
 *       phonemes: 'AA R P AH',
 *       prob: null,
 *       silprobs: null,
 *       soundslike: null,
 *       unigram: 0.8
 *     },
 *     Lexentry {
 *       graphemes: 'arpa',
 *       lexentry_uuid: '31176ca6-8d43-49e3-9cc2-5d9f6ffff776',
 *       lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
 *       phonemes: 'EY AA R P IY EY',
 *       prob: null,
 *       silprobs: null,
 *       soundslike: null,
 *       unigram: 0.2
 *     } ]
 */

Lexentry.list = (config, baseOptions) => {
  config = config || {};

  baseOptions.setResource = Lexentry;
  baseOptions.resourceUrl = `${baseOptions.apiUrl}lexicon`;

  return new Promise((resolve, reject) => {
    if (!config.lexicon_uuid) {
      reject({ message: messages.lexicon_uuidRequired });
      return;
    }

    const options = baseOptions.getOptions({ id: config.lexicon_uuid });
    if (!options) {
      reject({ message: messages.missingAuth });
      return;
    }

    options.url += '/lexentry';

    if (config.graphemes) {
      options.url += `?graphemes=${lexentry.graphemes}`;
    }

    const rq = call(options, undefined, reject);
    rq.then((data) => {
      resolve(data.map((entry) => new Lexentry(entry, baseOptions.clone())));
    });
  });
};

module.exports = Lexentry;
