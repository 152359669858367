import Cats from 'remeeting/src/recognition/cats';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import IntervalTree from 'node-interval-tree';

import {
  colorFromString,
  sortSegmentsByFirstSpan,
  getAlignmentBlocks,
  makeCancelable,
} from './utils';

import { END_SESSION } from './session';
import {
  getLexiconInfo,
} from './customize';

export const FLUSH_STATE = 'MEETING::FLUSH_STATE';
const CLEAR_ERROR = 'MEETING::CLEAR_ERROR';
const CLEAR_MEETING = 'MEETING::CLEAR_MEETING';
const DELETE_MEETING_REQUEST_SENT = 'MEETING::DELETE_MEETING_REQUEST_SENT';
export const DELETE_MEETING_REQUEST_SUCCESS = 'MEETING::DELETE_MEETING_REQUEST_SUCCESS';
const DELETE_MEETING_REQUEST_FAIL = 'MEETING::DELETE_MEETING_REQUEST_FAIL';
const GET_MEETING_REQUEST_SENT = 'MEETING::GET_MEETING_REQUEST_SENT';
export const GET_MEETING_REQUEST_SUCCESS = 'MEETING::GET_MEETING_REQUEST_SUCCESS';
const GET_MEETING_REQUEST_FAIL = 'MEETING::GET_MEETING_REQUEST_FAIL';
const DEFAULT_HAS_BEEN_VISITED = 'MEETING::DEFAULT_HAS_BEEN_VISITED';
const CANCEL_GET_MEETING = 'MEETING::CANCEL_GET_MEETING_REQUEST';
const POST_FEEDBACK_REQUEST_SENT = 'MEETING::POST_FEEDBACK_REQUEST_SENT';
const POST_FEEDBACK_REQUEST_SUCCESS = 'MEETING::POST_FEEDBACK_REQUEST_SUCCESS';
const POST_FEEDBACK_REQUEST_FAIL = 'MEETING::POST_FEEDBACK_REQUEST_FAIL';
const TOGGLE_FEEDBACK_MODAL = 'MEETING::TOGGLE_FEEDBACK_MODAL';
const TOGGLE_GOOGLE_STT_MODAL = 'MEETING::TOGGLE_GOOGLE_STT_MODAL';
const TOGGLE_SETTINGS_MODAL = 'MEETING::TOGGLE_SETTINGS_MODAL';
const TOGGLE_HELP_MODAL = 'MEETING::TOGGLE_HELP_MODAL';
const SET_CURRENT_RECOGNITION = 'MEETING::SET_CURRENT_RECOGNITION';
const SET_CATS_STATE = 'MEETING::SET_CATS_STATE';
const SET_FULLSCREEN_STATE = 'MEETING::SET_FULLSCREEN_STATE';
const SET_PLAY_BUTTON_FOCUSED = 'MEETING::SET_PLAY_BUTTON_FOCUSED';
const SET_SEGMENT_INTERVAL_TREE = 'MEETING::SET_SEGMENT_INTERVAL_TREE';
const SET_SHOWING_CAPTIONS = 'MEETING::SET_SHOWING_CAPTIONS';
const SET_SCROLL_TARGET = 'MEETING::SET_SCROLL_TARGET';
const GET_DATE_REQUEST_FAIL = 'MEETING::GET_DATE_REQUEST_FAIL';
const GET_DATE_REQUEST_SUCCESS = 'MEETING::GET_DATE_REQUEST_SUCCESS';
const GET_DATE_REQUEST_SENT = 'MEETING::GET_DATE_REQUEST_SENT';
const SET_SPEAKER_METADATA = 'MEETING::SET_SPEAKER_METADATA';
const GET_CATS_REQUEST_SENT = 'MEETING::GET_CATS_REQUEST_SENT';
const GET_CATS_REQUEST_SUCCESS = 'MEETING::GET_CATS_REQUEST_SUCCESS';
const GET_CATS_REQUEST_FAIL = 'MEETING::GET_CATS_REQUEST_FAIL';
const CANCEL_GET_CATS = 'MEETING::CANCEL_GET_CATS_REQUEST';
const SET_PHRASE_ALTS_POPOVER = 'MEETING::SET_PHRASE_ALTS_POPOVER';
const SET_SHOW_SPAN_POPOVER = 'MEETING::SET_SHOW_SPAN_POPOVER';
const SET_AM_LM_BALANCE_REQUEST_SENT = 'MEETING::SET_AM_LM_BALANCE_REQUEST_SENT';
const SET_AM_LM_BALANCE_REQUEST_FAIL = 'MEETING::SET_AM_LM_BALANCE_REQUEST_FAIL';
const SET_TRANSCRIPT_AM_LM_BALANCE = 'MEETING::SET_TRANSCRIPT_AM_LM_BALANCE';
const SET_ALTS_AM_LM_BALANCE = 'MEETING::SET_ALTS_AM_LM_BALANCE';
const RICHIFY_REQUEST_SENT = 'MEETING::RICHIFY_REQUEST_SENT';
const RICHIFY_REQUEST_FAIL = 'MEETING::RICHIFY_REQUEST_FAIL';
const RICHIFY_REQUEST_SUCCESS = 'MEETING::RICHIFY_REQUEST_SUCCESS';
const SET_TITLE_REQUEST_SENT = 'MEETING::SET_TITLE_REQUEST_OUT';
const SET_TITLE_REQUEST_SUCCESS = 'MEETING::SET_TITLE_REQUEST_ERROR';
const SET_TITLE_REQUEST_FAIL = 'MEETING::SET_TITLE_REQUEST_FAIL';
const SET_SPEAKER_LABEL_REQUEST_SENT = 'MEETING::SET_SPEAKER_LABEL_REQUEST_SENT';
const SET_SPEAKER_LABEL_REQUEST_SUCCESS = 'MEETING::SET_SPEAKER_LABEL_REQUEST_SUCCESS';
const SET_SPEAKER_LABEL_REQUEST_FAIL = 'MEETING::SET_SPEAKER_LABEL_REQUEST_FAIL';
const SET_RICHIFICATION_ENABLED = 'MEETING::SET_RICHIFICATION_ENABLED';
const SET_DYNAMIC_RICHIFICATION_ENABLED = 'MEETING::SET_DYNAMIC_RICHIFICATION_ENABLED';
const SET_SHARE_KEY = 'MEETING::SET_SHARE_KEY';
const GET_SHARE_LINKS_REQUEST_SENT = 'MEETING::GET_SHARE_LINKS_REQUEST_SENT';
const GET_SHARE_LINKS_REQUEST_SUCCESS = 'MEETING::GET_SHARE_LINKS_REQUEST_SUCCESS';
const GET_SHARE_LINKS_REQUEST_FAIL = 'MEETING::GET_SHARE_LINKS_REQUEST_FAIL';
const CREATE_SHARE_LINK_REQUEST_SENT = 'MEETING::CREATE_SHARE_LINK_REQUEST_SENT';
const CREATE_SHARE_LINK_REQUEST_SUCCESS = 'MEETING::CREATE_SHARE_LINK_REQUEST_SUCCESS';
const CREATE_SHARE_LINK_REQUEST_FAIL = 'MEETING::CREATE_SHARE_LINK_REQUEST_FAIL';
const DELETE_SHARE_LINK_REQUEST_SENT = 'MEETING::DELETE_SHARE_LINK_REQUEST_SENT';
const DELETE_SHARE_LINK_REQUEST_SUCCESS = 'MEETING::DELETE_SHARE_LINK_REQUEST_SUCCESS';
const DELETE_SHARE_LINK_REQUEST_FAIL = 'MEETING::DELETE_SHARE_LINK_REQUEST_FAIL';
const GET_OCR_REQUEST_SENT = 'MEETING::GET_OCR_REQUEST_SENT';
const GET_OCR_REQUEST_SUCCESS = 'MEETING::GET_OCR_REQUEST_SUCCESS';
const GET_OCR_REQUEST_FAIL = 'MEETING::GET_OCR_REQUEST_FAIL';
const CANCEL_GET_OCR = 'MEETING::CANCEL_GET_OCR_REQUEST';
const SET_VENDOR = 'MEETING::SET_VENDOR';
const SET_TIME_QUERY = 'MEETING::SET_TIME_QUERY';
const LEXICAL_LOOKUP_REQUEST_SENT = 'MEETING::LEXICAL_LOOKUP_REQUEST_SENT';
const LEXICAL_LOOKUP_REQUEST_SUCCESS = 'MEETING::LEXICAL_LOOKUP_REQUEST_SUCCESS';
const LEXICAL_LOOKUP_REQUEST_FAIL = 'MEETING::LEXICAL_LOOKUP_REQUEST_FAIL';
const CLEAR_LEXICAL_LOOKUP = 'MEETING::CLEAR_LEXICAL_LOOKUP';
const SET_SETTINGS_PANE = 'MEETING::SET_SETTINGS_PANE';
const REPROCESS_REQUEST_SENT = 'MEETING::REPROCESS_REQUEST_SENT';
const REPROCESS_REQUEST_SUCCESS = 'MEETING::REPROCESS_REQUEST_SUCCESS';
const REPROCESS_REQUEST_FAIL = 'MEETING::REPROCESS_REQUEST_FAIL';
const CREATE_SNAPSHOT_REQUEST_SENT = 'MEETING::CREATE_SNAPSHOT_REQUEST_SENT';
const CREATE_SNAPSHOT_REQUEST_SUCCESS = 'MEETING::CREATE_SNAPSHOT_REQUEST_SUCCESS';
const CREATE_SNAPSHOT_REQUEST_FAIL = 'MEETING::CREATE_SNAPSHOT_REQUEST_FAIL';
const SHARE_MODAL_OPEN = 'MEETING::SHARE_MODAL_OPEN';
const SHARE_MODAL_CLOSE = 'MEETING::SHARE_MODAL_CLOSE';

/*
 * Utility functions:
 * Retrieve data from the meeting, taking fallback behavior into account, as well
 * as updating metadata through conditional PUT requests
 */

export const getMetadata = (meeting, key) => {
  if (meeting === undefined || meeting.metadata === undefined) {
    return undefined;
  }
  return meeting.metadata[key];
};

export const getMetadataHasTrackException = (meeting) => {
  const zoomTrackException = 'ZoomTrackLonger';
  const fallbackToDownmixed = 'Falling back to downmixed.';
  const moozifyErrors = getMetadata(meeting, 'moozify_error');
  if (moozifyErrors) {
    /* Standard way to type objects in JS, e.g. see https://stackoverflow.com/a/32297474 */
    const moozifyErrorsType = Object.prototype.toString.call(moozifyErrors);
    if (moozifyErrorsType === '[object Array]') {
      /* As of https://bitbucket.org/remeeting/mrp-asr/pull-requests/247
       * the `moozify_error` metadata field, if it exists, is an array
       * of strings including critical-level logs returned by Moozify to exec.
       * In previous PRs we handle the possibility of the Zoom bug in most
       * cases, so we should not search for the exceptions, but instead for
       * the string "Falling back to downmixed." to determine when to
       * render warnings.
       */
      return Array.from(moozifyErrors).reduce(
        (result, moozifyError) => result || moozifyError.includes(fallbackToDownmixed),
        false,
      );
    }
    if (moozifyErrorsType === '[object String]') {
      /* Before https://bitbucket.org/remeeting/mrp-asr/pull-requests/247
       * the `moozify_error` metadata field, if it exists, is a string: an exception returned
       * by Moozify to exec see definitions in `mrp-asr/docker/moozify/exceptions.py`.
       */
      return moozifyErrors.includes(zoomTrackException);
    }
  }
  return false;
};

export const getSpeakerNameAtIndex = (meeting, speakerMetadata, index) => {
  const labels = (
    getMetadata(meeting, 'speaker_names')
    /* some old meetings have it as speaker_labels */
    || getMetadata(meeting, 'speaker_labels')
  );

  /* If nothing else works, default to Speaker_00X */
  let result = `Speaker_${`${index}`.padStart(3, '0')}`;

  /* If no speaker_names metadata exists, but there is speaker label info in Redux */
  if (
    isEmpty(labels)
    && !isEmpty(speakerMetadata)
    && !isEmpty(speakerMetadata[index])
  ) {
    /* in this case we default to the labels in Redux */
    result = speakerMetadata[index].label;
  } else if (!isEmpty(labels)) {
    /* If speaker_names metadata exists, that takes precedence */
    result = labels[index] || result;
  } else if (meeting !== undefined && !isEmpty(meeting.speakers)) {
    /* There is a small chance that neither speakerMetadata (Redux) or speaker_names (API)
       exists. This will happen when both cats + results.json have loaded, but we haven't
       dispatched SET_SPEAKER_METADATA yet. We should only be in this state for a split
       second, but it's good to have a solid fallback in this situation. */
    result = meeting.speakers[index] || result;
  }

  return result;
};

export const getMeetingStartTime = (meeting) => {
  /* If we got an actual start time from exec, we use that */
  if (meeting.metadata && meeting.metadata.recording_started) {
    return meeting.metadata.recording_started;
  }

  if (meeting.metadata && meeting.metadata.meeting_started) {
    return meeting.metadata.meeting_started;
  }

  /* If we don't have an actual start time but we have a "job created" time
     and "duration" metadata, we can make a guess */
  if (meeting.metadata && meeting.metadata.recording_duration) {
    const date = moment(meeting.created);
    const duration = parseFloat(meeting.metadata.recording_duration);
    return moment(date - duration).format();
  }

  /* Otherwise we just show the created date */
  return meeting.created;
};

export const getMeetingTitle = (meeting) => {
  /* meeting_title, which the user can set, takes precedence. */
  if (!meeting) return '';

  let result = getMetadata(meeting, 'meeting_title');
  if (result && result.length) {
    return result;
  }

  result = getMetadata(meeting, 'meeting_name');
  return result || '';
};

export const getMeetingSource = (meeting) => {
  const workerVersion = getMetadata(meeting, 'worker_version');
  const execVersion = getMetadata(meeting, 'exec_version');

  if (execVersion) {
    return {
      source: 'exec',
      version: execVersion || 'unknown',
    };
  }

  return {
    source: 'worker',
    version: workerVersion || 'unknown',
  };
};

export const getMeetingParticipants = (recognition) => {
  // TODO: add a surname initial to disambiguate speakers with same first name?
  // TODO: order speakers by talking time?
  let numSpeakers;

  if (getMetadata(recognition, 'speaker_names')) {
    numSpeakers = getMetadata(recognition, 'speaker_names').length;
  }

  function convertName(name) {
    const splitName = name.split(' ');
    if (splitName.length === 0) {
      return '';
    }
    if (splitName.length === 1) {
      return splitName[0];
    }
    return `${splitName[0]}`;
  }

  if (numSpeakers) {
    let i;
    const rawSpeakers = [];
    let speaker;
    for (i = 0; i < numSpeakers; i += 1) {
      speaker = getSpeakerNameAtIndex(recognition, undefined, i);
      if (!speaker.includes('(Shared Audio)')
        && !speaker.includes('Unknown Speaker(s)')
        && !rawSpeakers.includes(speaker)) {
        rawSpeakers.push(speaker);
      }
    }

    const speakers = [];
    for (i = 0; i < rawSpeakers.length; i += 1) {
      speakers.push(convertName(rawSpeakers[i]));
    }

    if (speakers.length === 3) {
      return (
        `${speakers[0]},
          ${speakers[1]},
          and\u00A0${speakers[2]}`
      );
    }
    if (speakers.length > 2) {
      return (
        `${speakers[0]},
          ${speakers[1]},
          +${speakers.length - 2}\u00A0others`
      );
    }
    if (speakers.length === 2) {
      return (
        `${speakers[0]} and ${speakers[1]}`
      );
    }
    if (speakers.length === 1) {
      return (
        speakers[0]
      );
    }
  }
  return '';
};

/* These are the actions and reducer that handle the recognition slice of the global state */
const initialState = {
  deleteMeetingRequestError: undefined,
  deleteMeetingRequestOut: false,
  getMeetingRequestError: undefined,
  getMeetingRequestOut: false,
  googleSttModalOpen: false,
  feedbackModalOpen: false,
  settingsModalOpen: false,
  hasDefaultVisited: false,
  helpModalOpen: false,
  postFeedbackError: undefined,
  postFeedbackRequestOut: false,
  currentRecognition: undefined,
  isFullScreen: false,
  playButtonFocused: false,
  segmentIntervalTree: undefined,
  showingCaptions: true,
  scrollTarget: 0,
  getDateRequestError: undefined,
  getDateRequestOut: false,
  date: undefined,
  speakerMetadata: [],
  cats: undefined,
  getCatsPromise: undefined,
  getCatsRequestOut: false,
  getCatsRequestError: undefined,
  meeting: {},
  phraseAltsPopover: undefined,
  showSpanPopover: false,
  setAmLmBalanceRequestOut: false,
  setAmLmBalanceRequestError: undefined,
  transcriptAmLmBalance: 0.5,
  altsAmLmBalance: 0.25,
  richificationRequestsOut: {},
  richificationRequestsError: {},
  richifiedSegments: {},
  setTitleRequestOut: false,
  setTitleRequestError: undefined,
  setSpeakerLabelRequestOut: false,
  setSpeakerLabelRequestError: undefined,
  richificationEnabled: true,
  dynamicRichificationEnabled: true,
  shareKey: undefined,
  getShareLinksRequestError: undefined,
  getShareLinksRequestOut: false,
  shareLinks: [],
  createShareLinkRequestOut: false,
  createShareLinkRequestError: undefined,
  deleteShareLinkRequestOut: false,
  deleteShareLinkRequestError: undefined,
  ocr: undefined,
  getOCRPromise: undefined,
  getOCRRequestOut: false,
  getOCRRequestError: undefined,
  vendor: undefined,
  timeQuery: undefined,
  lexicalLookupResults: {},
  lexicalLookupRequestsOut: {},
  lexicalLookupRequestsError: {},
  settingsPane: 0,
  getRecognitionDataPromise: undefined,
  getRecognitionPromise: undefined,
  getMetadataPromise: undefined,
  getSnapshotsPromise: undefined,
  reprocessRequestOut: false,
  reprocessRequestError: undefined,
  createSnapshotRequestOut: false,
  createSnapshotRequestError: undefined,
  shareLinkModalOpen: false,
  shareModalRecordingId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHARE_MODAL_OPEN:
      return {
        ...state,
        shareLinkModalOpen: true,
        shareModalRecordingId: action.shareRecordingId,
      };
    case SHARE_MODAL_CLOSE:
      return {
        ...state,
        shareLinkModalOpen: false,
        shareModalRecordingId: '',
      };
    case FLUSH_STATE:
      return { ...initialState };
    case CLEAR_ERROR:
      return {
        ...state,
        deleteMeetingRequestError: undefined,
        getMeetingRequestError: undefined,
        postFeedbackError: undefined,
      };
    case CLEAR_MEETING:
      return {
        ...state,
        meeting: {},
      };
    case DELETE_MEETING_REQUEST_SENT:
      return {
        ...state,
        deleteMeetingRequestError: undefined,
        deleteMeetingRequestOut: true,
      };
    case DELETE_MEETING_REQUEST_SUCCESS:
      return {
        ...state,
        deleteMeetingRequestOut: false,
        meeting: {},
      };
    case DELETE_MEETING_REQUEST_FAIL:
      return {
        ...state,
        deleteMeetingRequestError: action.error,
        deleteMeetingRequestOut: false,
      };
    case END_SESSION:
      return { ...initialState };
    case GET_MEETING_REQUEST_SENT: {
      const cachedMtg = state.meeting;
      return {
        ...state,
        getMeetingRequestOut: true,
        getMeetingRequestError: undefined,
        meeting: cachedMtg.id === action.id ? cachedMtg : {},
        getRecognitionDataPromise: action.getRecognitionDataPromise,
        getRecognitionPromise: action.getRecognitionPromise, // Legacy
        getMetadataPromise: action.getMetadataPromise,
        getSnapshotsPromise: action.getSnapshotsPromise,
      };
    }
    case GET_MEETING_REQUEST_SUCCESS: {
      return {
        ...state,
        meeting: action.recognition,
        getMeetingRequestOut: false,
      };
    }
    case GET_MEETING_REQUEST_FAIL:
      return {
        ...state,
        getMeetingRequestError: action.error,
        getMeetingRequestOut: false,
      };
    case DEFAULT_HAS_BEEN_VISITED:
      return {
        ...state,
        hasDefaultVisited: true,
      };
    case CANCEL_GET_MEETING: {
      const {
        getRecognitionDataPromise,
        getRecognitionPromise, // Legacy
        getMetadataPromise,
        getSnapshotsPromise,
      } = state;
      if (getRecognitionDataPromise) getRecognitionDataPromise.cancel();
      if (getRecognitionPromise) getRecognitionPromise.cancel(); // Legacy
      if (getMetadataPromise) getMetadataPromise.cancel();
      if (getSnapshotsPromise) getSnapshotsPromise.cancel();
      return state;
    }
    case GET_DATE_REQUEST_SENT:
      return {
        ...state,
        getDateRequestOut: true,
        getDateRequestError: undefined,
      };
    case GET_DATE_REQUEST_SUCCESS:
      return {
        ...state,
        getDateRequestError: undefined,
        getDateRequestOut: false,
        date: action.date,
      };
    case GET_DATE_REQUEST_FAIL:
      return {
        ...state,
        getDateRequestOut: false,
        getDateRequestError: action.error,
      };
    case POST_FEEDBACK_REQUEST_SENT:
      return { ...state, postFeedbackRequestOut: true, postFeedbackError: undefined };
    case POST_FEEDBACK_REQUEST_SUCCESS:
      return { ...state, postFeedbackRequestOut: false };
    case POST_FEEDBACK_REQUEST_FAIL:
      return { ...state, postFeedbackRequestOut: false, postFeedbackError: action.error };
    case TOGGLE_FEEDBACK_MODAL:
      return { ...state, feedbackModalOpen: action.shouldOpen };
    case TOGGLE_GOOGLE_STT_MODAL:
      return { ...state, googleSttModalOpen: action.shouldOpen };
    case TOGGLE_SETTINGS_MODAL:
      return { ...state, settingsModalOpen: action.shouldOpen };
    case TOGGLE_HELP_MODAL:
      return { ...state, helpModalOpen: action.shouldOpen };
    case SET_FULLSCREEN_STATE:
      return { ...state, isFullScreen: action.isFullScreen };
    case SET_PLAY_BUTTON_FOCUSED:
      return { ...state, playButtonFocused: action.playButtonFocused };
    case SET_SEGMENT_INTERVAL_TREE:
      return { ...state, segmentIntervalTree: action.intervalTree };
    case SET_SHOWING_CAPTIONS:
      return { ...state, showingCaptions: action.showingCaptions };
    case SET_SCROLL_TARGET:
      return { ...state, scrollTarget: action.scrollTarget };
    case SET_SPEAKER_METADATA:
      return { ...state, speakerMetadata: action.speakerMetadata };
    case SET_CATS_STATE:
      return { ...state, cats: action.cats };
    case GET_CATS_REQUEST_SENT:
      return {
        ...state,
        getCatsRequestOut: true,
        getCatsRequestError: undefined,
        getCatsPromise: action.getCatsPromise,
      };
    case GET_CATS_REQUEST_SUCCESS:
      // Legacy
      return {
        ...state,
        getCatsRequestOut: false,
        getCatsRequestError: undefined,
        cats: action.cats,
      };
    case GET_CATS_REQUEST_FAIL:
      // Legacy
      return {
        ...state,
        getCatsRequestError: action.error,
        getCatsRequestOut: false,
      };
    case CANCEL_GET_CATS: {
      const { getCatsPromise } = state;
      if (getCatsPromise) getCatsPromise.cancel();
      return state;
    }
    case SET_PHRASE_ALTS_POPOVER:
      return {
        ...state,
        phraseAltsPopover: action.phraseAltsPopover,
      };
    case SET_SHOW_SPAN_POPOVER:
      return {
        ...state,
        showSpanPopover: action.showSpanPopover,
      };
    case SET_TRANSCRIPT_AM_LM_BALANCE:
      return { ...state, transcriptAmLmBalance: action.balance };
    case SET_ALTS_AM_LM_BALANCE:
      return { ...state, altsAmLmBalance: action.balance };
    case RICHIFY_REQUEST_SENT:
      return {
        ...state,
        richificationRequestsOut: {
          ...state.richificationRequestsOut,
          [action.utterance]: true,
        },
      };
    case RICHIFY_REQUEST_FAIL:
      return {
        ...state,
        richificationRequestsOut: {
          ...state.richificationRequestsOut,
          [action.utterance]: false,
        },
        richificationRequestsError: {
          ...state.richificationRequestsError,
          [action.utterance]: action.error,
        },
        richifiedSegments: {
          ...state.richifiedSegments,
          [action.utterance]: undefined,
        },
      };
    case RICHIFY_REQUEST_SUCCESS:
      return {
        ...state,
        richificationRequestsOut: {
          ...state.richificationRequestsOut,
          [action.utterance]: false,
        },
        richificationRequestsError: {
          ...state.richificationRequestsError,
          [action.utterance]: undefined,
        },
        richifiedSegments: {
          ...state.richifiedSegments,
          [action.utterance]: {
            transcript: action.transcript,
            original: action.original,
            alignment: action.alignment,
            wordsFormatted: action.wordsFormatted,
          },
        },
      };
    case SET_TITLE_REQUEST_SENT:
      return { ...state, setTitleRequestOut: true };
    case SET_TITLE_REQUEST_SUCCESS:
      return {
        ...state,
        setTitleRequestOut: false,
        setTitleRequestError: undefined,
        meeting: { ...state.meeting, ...action.data },
      };
    case SET_TITLE_REQUEST_FAIL:
      return { ...state, setTitleRequestOut: false, setTitleRequestError: action.error };
    case SET_SPEAKER_LABEL_REQUEST_SENT:
      return {
        ...state,
        setSpeakerLabelRequestOut: true,
        setSpeakerLabelRequestError: undefined,
      };
    case SET_SPEAKER_LABEL_REQUEST_SUCCESS:
      return {
        ...state,
        setSpeakerLabelRequestOut: false,
        setSpeakerLabelRequestError: undefined,
        meeting: { ...state.meeting, ...action.data },
      };
    case SET_SPEAKER_LABEL_REQUEST_FAIL:
      return {
        ...state,
        setSpeakerLabelRequestError: action.error,
        setSpeakerLabelRequestOut: false,
      };
    case SET_RICHIFICATION_ENABLED:
      return {
        ...state,
        richificationEnabled: action.enabled,
      };
    case SET_AM_LM_BALANCE_REQUEST_SENT:
      return {
        ...state,
        setAmLmBalanceRequestOut: true,
      };
    case SET_AM_LM_BALANCE_REQUEST_FAIL:
      return {
        ...state,
        setAmLmBalanceRequestError: action.error,
        setAmLmBalanceRequestOut: false,
      };
    case SET_DYNAMIC_RICHIFICATION_ENABLED:
      return {
        ...state,
        dynamicRichificationEnabled: action.enabled,
      };
    case SET_SHARE_KEY:
      return {
        ...state,
        shareKey: action.shareKey,
      };
    case GET_SHARE_LINKS_REQUEST_SENT:
      return {
        ...state,
        getShareLinksRequestOut: true,
        getShareLinksRequestError: undefined,
      };
    case GET_SHARE_LINKS_REQUEST_FAIL: {
      return {
        ...state,
        getShareLinksRequestError: action.error,
        getShareLinksRequestOut: false,
      };
    }
    case GET_SHARE_LINKS_REQUEST_SUCCESS: {
      return {
        ...state,
        getShareLinksRequestError: undefined,
        getShareLinksRequestOut: false,
        shareLinks: action.links,
      };
    }
    case CREATE_SHARE_LINK_REQUEST_SENT: {
      return {
        ...state,
        createShareLinkRequestOut: true,
        createShareLinkRequestError: undefined,
      };
    }
    case CREATE_SHARE_LINK_REQUEST_FAIL: {
      return {
        ...state,
        createShareLinkRequestOut: false,
        createShareLinkRequestError: action.error,
      };
    }
    case CREATE_SHARE_LINK_REQUEST_SUCCESS: {
      return {
        ...state,
        createShareLinkRequestOut: false,
        createShareLinkRequestError: undefined,
      };
    }
    case DELETE_SHARE_LINK_REQUEST_SENT: {
      return {
        ...state,
        deleteShareLinkRequestOut: true,
        deleteShareLinkRequestError: undefined,
      };
    }
    case DELETE_SHARE_LINK_REQUEST_FAIL: {
      return {
        ...state,
        deleteShareLinkRequestOut: false,
        deleteShareLinkRequestError: action.error,
      };
    }
    case DELETE_SHARE_LINK_REQUEST_SUCCESS: {
      return {
        ...state,
        deleteShareLinkRequestOut: false,
        deleteShareLinkRequestError: undefined,
      };
    }
    case GET_OCR_REQUEST_SENT: {
      return {
        ...state,
        getOCRRequestOut: true,
        getOCRRequestError: undefined,
        getOCRPromise: action.getOCRPromise,
      };
    }
    case GET_OCR_REQUEST_SUCCESS: {
      return {
        ...state,
        getOCRRequestOut: false,
        getOCRRequestError: undefined,
        ocr: action.ocr,
      };
    }
    case GET_OCR_REQUEST_FAIL: {
      return {
        ...state,
        getOCRRequestOut: false,
        getOCRRequestError: action.error,
      };
    }
    case CANCEL_GET_OCR: {
      const { getOCRPromise } = state;
      if (getOCRPromise) getOCRPromise.cancel();
      return state;
    }
    case SET_VENDOR: {
      return {
        ...state,
        vendor: action.vendor,
      };
    }
    case SET_TIME_QUERY: {
      return {
        ...state,
        timeQuery: action.timeQuery,
      };
    }
    case LEXICAL_LOOKUP_REQUEST_SENT: {
      return {
        ...state,
        lexicalLookupRequestsOut: {
          ...state.lexicalLookupRequestsOut,
          [action.word]: true,
        },
        lexicalLookupRequestsError: {
          ...state.lexicalLookupRequestsError,
          [action.word]: undefined,
        },
      };
    }
    case LEXICAL_LOOKUP_REQUEST_FAIL: {
      return {
        ...state,
        lexicalLookupRequestsOut: {
          ...state.lexicalLookupRequestsOut,
          [action.word]: false,
        },
        lexicalLookupRequestsError: {
          ...state.lexicalLookupRequestsError,
          [action.word]: action.error,
        },
      };
    }
    case LEXICAL_LOOKUP_REQUEST_SUCCESS: {
      return {
        ...state,
        lexicalLookupRequestsOut: {
          ...state.lexicalLookupRequestsOut,
          [action.word]: false,
        },
        lexicalLookupResults: {
          ...state.lexicalLookupResults,
          [action.word]: action.info,
        },
      };
    }
    case CLEAR_LEXICAL_LOOKUP: {
      const newLookupResults = { ...state.lexicalLookupResults };
      delete newLookupResults[action.word];
      return {
        ...state,
        lexicalLookupResults: newLookupResults,
      };
    }
    case SET_SETTINGS_PANE:
      return {
        ...state,
        settingsPane: action.pane,
      };
    case REPROCESS_REQUEST_SENT:
      return {
        ...state,
        reprocessRequestOut: true,
        reprocessRequestError: undefined,
      };
    case REPROCESS_REQUEST_FAIL:
      return {
        ...state,
        reprocessRequestOut: false,
        reprocessRequestError: action.error,
      };
    case REPROCESS_REQUEST_SUCCESS:
      return {
        ...state,
        reprocessRequestOut: false,
        reprocessRequestError: undefined,
      };
    case CREATE_SNAPSHOT_REQUEST_SENT:
      return {
        ...state,
        createSnapshotRequestOut: true,
        createSnapshotRequestError: undefined,
      };
    case CREATE_SNAPSHOT_REQUEST_FAIL:
      return {
        ...state,
        createSnapshotRequestOut: false,
        createSnapshotRequestError: action.error,
      };
    case CREATE_SNAPSHOT_REQUEST_SUCCESS:
      return {
        ...state,
        createSnapshotRequestOut: false,
        createSnapshotRequestError: undefined,
      };
    default:
      return state;
  }
};

export const setTranscriptAmLmBalance = (balance) => ({
  type: SET_TRANSCRIPT_AM_LM_BALANCE,
  balance,
});

export const setAltsAmLmBalance = (balance) => ({
  type: SET_ALTS_AM_LM_BALANCE,
  balance,
});

export const clearMeeting = () => ({
  type: CLEAR_MEETING,
});

export const flushState = () => ({
  type: FLUSH_STATE,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const deleteMeeting = (id) => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: DELETE_MEETING_REQUEST_SENT });
  return rmi.recognitions.delete({ id })
    .then(() => {
      dispatch({ type: DELETE_MEETING_REQUEST_SUCCESS, id });
    })
    .catch((error) => {
      dispatch({ type: DELETE_MEETING_REQUEST_FAIL, error });
    });
};

export const checkIfUserOwnsMeeting = (id, shareKey) => async (dispatch, getState) => {
  /* There is a special situation where a user is authenticated, but are still
     viewing someone else's meeting. This method checks whether this is the
     case. First we check if the user is signed in in the first place. */
  const { session: { signedIn }, rmi } = getState();
  if (!signedIn) {
    return false;
  }

  /* We do a trick where we get the user's share keys for meeting id ID. If the user
     owns a share key that is identical to the one in the URL, we know that we own this
     meeting because the userid in the share key belongs to the user. */
  try {
    const { share_keys: userShareKeys } = await rmi.recognitions.get({
      id, format: 'sharing',
    });

    return userShareKeys.some((key) => key.share_key === shareKey);
  } catch (_) {
    return false;
  }
};

export const getCats = (id, snapshot) => (dispatch, getState) => {
  const {
    rmi,
    meeting: { shareKey, vendor },
    find: { multiFind },
  } = getState();

  const getCatsPromise = makeCancelable(
    rmi.recognitions.getCats({
      id,
      snapshot,
      shareKey,
      vendor,
    }),
  );

  getCatsPromise.promise
    .then((cats) => {
      multiFind.setCatsData(cats);
      const { meeting } = getState().meeting;
      if (meeting && meeting.segments) {
        /* Meeting has been loaded, so sort meeting segments */
        sortSegmentsByFirstSpan(
          meeting.segments,
          cats,
          (
            meeting.metadata
            && meeting.metadata.worker_version === undefined
          ),
        );
      }
      dispatch({ type: GET_CATS_REQUEST_SUCCESS, cats });
    }, (error) => {
      if (!error.isCanceled) {
        dispatch({ type: GET_CATS_REQUEST_FAIL, error });
      }
    });

  dispatch({ type: GET_CATS_REQUEST_SENT, getCatsPromise });
};

export const cancelGetCats = () => ({
  type: CANCEL_GET_CATS,
});

export const setVendor = (vendor) => ({
  type: SET_VENDOR,
  vendor,
});

export const getMeeting = (id, snapshot) => (dispatch, getState) => {
  const {
    rmi,
    meeting: { hasDefaultVisited, shareKey },
    find: { multiFind },
  } = getState();

  const getMetadataPromise = makeCancelable(rmi.recognitions.get({
    id, format: 'metadata', snapshot, shareKey,
  }));

  const getSnapshotsPromise = makeCancelable(rmi.recognitions.getSnapshots({ id, shareKey }));

  return new Promise((resolve, reject) => {
    dispatch({
      type: GET_MEETING_REQUEST_SENT,
      id,
      getMetadataPromise,
      getSnapshotsPromise,
    });
    Promise.all([
      getMetadataPromise.promise,
      getSnapshotsPromise.promise,
    ])
      .then(([metadata, snapshots]) => {
        /* Once the meeting finsihed processing, fetch the results. */
        if (metadata.status === 'completed') {
          // Determine if we should default to Google Results
          if (metadata.metadata.has_google_stt && !hasDefaultVisited) {
            dispatch(setVendor('google-stt'));
            dispatch({ type: DEFAULT_HAS_BEEN_VISITED });
          }
          const { meeting: { vendor } } = getState();
          const getRecognitionDataPromise = makeCancelable(rmi.recognitions.getTracks({
            id, shareKey, snapshot, vendor,
          })
            .then((results) => {
              let dataPromise;
              if (results.tracks.length !== 0) {
                const { tracks } = results;
                dataPromise = Promise.all(
                  tracks.map((trackId) => rmi.recognitions.getTracks({
                    id, shareKey, snapshot, trackId, vendor,
                  })),
                );
              } else {
                /* Handles the request for legacy meetings. */
                dataPromise = rmi.recognitions.get({
                  id, format: 'remeeting', snapshot, vendor,
                });
              }
              return dataPromise;
            }));

          dispatch({
            type: GET_MEETING_REQUEST_SENT,
            id,
            getRecognitionDataPromise,
          });

          getRecognitionDataPromise.promise
            .then((recognitionData) => {
              const segmentMap = {};
              const newSegments = [];
              const catsData = [];
              if (!recognitionData.segments) {
                /* Handling the new Engine format */
                for (let trackIndex = 0; trackIndex < recognitionData.length; trackIndex += 1) {
                  const currentTrack = recognitionData[trackIndex];
                  for (let uIndex = 0; uIndex < currentTrack.length; uIndex += 1) {
                    const utterance = `${trackIndex}_${uIndex}`;
                    currentTrack[uIndex].speaker = trackIndex;
                    currentTrack[uIndex].utterance = utterance;

                    segmentMap[utterance] = currentTrack[uIndex];

                    catsData.push({
                      cats: currentTrack[uIndex].phrases,
                      utterance,
                    });
                  }
                  newSegments.push(...currentTrack);
                }
                const cats = new Cats(catsData);
                multiFind.setCatsData(cats);
                /*
                  Note: Dispatching an action will cause the components that are connected to this
                  state to call their render function. In this instance, the cats and segmentMap
                  may be states that are out of sync.
                */
                dispatch({ type: SET_CATS_STATE, cats });
              } else {
                /* Supporting Legacy meetings without the new engine format. */
                /* free up memory used by word alternatives; we are using cats
                  (phrase alternatives) instead */
                const { segments } = recognitionData;
                if (segments) {
                  for (let i = 0; i < segments.length; i += 1) {
                    segments[i].word_alternatives = undefined;
                    segmentMap[segments[i].utterance] = segments[i];
                  }
                }
              }

              const newJobData = {
                ...metadata,
                ...snapshots,
                segmentMap,
                segments: !recognitionData.segments ? newSegments : recognitionData.segments,
              };

              dispatch({
                type: GET_MEETING_REQUEST_SUCCESS,
                recognition: newJobData,
              });

              /* MEETING POST-PROCESSING */

              /* once we have the meeting, we need to compile the segments into
                an interval tree so they can be easily queried by time */
              const iTree = new IntervalTree();
              const { meeting } = getState().meeting;

              if (meeting.segments) {
                multiFind.setSegmentData(meeting.segments);
                for (let i = 0; i < meeting.segments.length; i += 1) {
                  const { interval } = meeting.segments[i];
                  iTree.insert(interval[0], interval[1], { ...meeting.segments[i], index: i });
                }
                dispatch({ type: SET_SEGMENT_INTERVAL_TREE, intervalTree: iTree });

                if (getState().meeting.cats) {
                  sortSegmentsByFirstSpan(
                    meeting.segments,
                    getState().meeting.cats,
                    metadata !== undefined && metadata.metadata.worker_version === undefined,
                  );
                } else {
                  /* Support legacy meetings. Meetings that have cats in a seperate file. */
                  dispatch(getCats(id, snapshot));
                }
              }

              /* get the date from the meeting */
              dispatch({
                type: GET_DATE_REQUEST_SUCCESS,
                date: getMeetingStartTime(meeting),
              });

              /* we also need to generate colors for each speaker. we pick N colors
                around the color wheel and store them in redux for other components
                to use. */
              const { speaker_names: speakers } = metadata.metadata;
              const speakerMetadata = [];

              if (speakers) {
                for (let i = 0; i < speakers.length; i += 1) {
                  let speakerName = speakers[i];
                  if (meeting.metadata
                      && meeting.metadata.speaker_names
                      && meeting.metadata.speaker_names[i]) {
                    speakerName = meeting.metadata.speaker_names[i];
                  }
                  speakerMetadata.push({
                    label: speakerName,
                    hue: colorFromString(speakerName.trim().toLowerCase()),
                  });
                }
                dispatch({ type: SET_SPEAKER_METADATA, speakerMetadata });
              }

              /* If there is some stuff stored in metadata that should be populated
                into the root Redux state, do that here */
              if (meeting.metadata) {
                if (meeting.metadata.transcript_am_lm_balance) {
                  dispatch(setTranscriptAmLmBalance(meeting.metadata.transcript_am_lm_balance));
                }
                if (meeting.metadata.alternatives_am_lm_balance) {
                  dispatch(setAltsAmLmBalance(meeting.metadata.alternatives_am_lm_balance));
                }
              }

              if (meeting.metadata && meeting.metadata.asr) {
                dispatch(
                  getLexiconInfo(
                    (meeting.metadata.asr.asr_model || meeting.metadata.asr.model).md5.lexicon,
                  ),
                );
              }
              resolve(meeting);
            })
            .catch((error) => {
              if (!error.isCanceled) {
                dispatch({ type: GET_MEETING_REQUEST_FAIL, error });
              }
              reject(error);
            });
        } else {
          dispatch({
            type: GET_MEETING_REQUEST_SUCCESS,
            recognition: { ...metadata, ...snapshots },
          });
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          dispatch({ type: GET_MEETING_REQUEST_FAIL, error });
        }
        reject(error);
      });
  });
};

export const cancelGetMeeting = () => ({
  type: CANCEL_GET_MEETING,
});

export const updateMetadata = (
  updateRule, // (oldMetadata) => { newMetadata }
  config = { updated: '', retries: 5 },
  givenMeeting = {},
) => async (
  dispatch,
  getState,
) => {
  const { retries } = config;
  const { rmi } = getState();
  let { meeting: { meeting } } = getState();

  if (!isEmpty(givenMeeting)) {
    meeting = givenMeeting;
  }

  for (let i = 0; i < retries; i += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await rmi.recognitions.updateMetadata({
        id: meeting.id,
        updateKeys: updateRule(meeting.metadata),
        ...config,
      });
      // eslint-disable-next-line no-await-in-loop
      await dispatch(getMeeting(meeting.id));
      return result;
    } catch (error) {
      if (error.statusCode === 409) {
        /* This is probably a synchronization error - conditional PUT was rejected.
           We need to re-get the job's "updated" field and try again. */
        // eslint-disable-next-line no-await-in-loop
        await dispatch(getMeeting(meeting.id));
        const { meeting: { meeting: mtg } } = getState();
        // Disable ESLint because this function has intentional side effects.
        // eslint-disable-next-line no-param-reassign
        config.updated = mtg.updated;
      } else {
        throw error;
      }
    }
  }

  throw Promise.reject(new Error('Conditional PUT to /metadata failed.'));
};

export const getOCR = (id, snapshot) => (dispatch, getState) => {
  const {
    rmi,
    meeting: { shareKey },
    find: { multiFind },
  } = getState();

  const getOCRPromise = makeCancelable(rmi.recognitions.get({
    id,
    snapshot,
    shareKey,
    format: 'ocr',
  }));

  getOCRPromise.promise
    .then((ocr) => {
      dispatch({ type: GET_OCR_REQUEST_SUCCESS, ocr });
      multiFind.setOCRData(ocr);
    })
    .catch((error) => {
      if (!error.isCanceled) {
        dispatch({ type: GET_SHARE_LINKS_REQUEST_FAIL, error });
      }
    });

  dispatch({ type: GET_OCR_REQUEST_SENT, getOCRPromise });
};

export const cancelGetOCR = () => ({
  type: CANCEL_GET_OCR,
});

export const getDate = (meetingId) => (dispatch, getState) => {
  const { rmi, meeting: { shareKey } } = getState();
  const options = { id: meetingId, shareKey };

  dispatch({ type: GET_DATE_REQUEST_SENT });
  return rmi.recognitions.get(options)
    .then((recognition) => {
      dispatch({ type: GET_DATE_REQUEST_SUCCESS, date: recognition.created });
    })
    .catch((error) => {
      dispatch({ type: GET_DATE_REQUEST_FAIL, error });
    });
};

export const sendFeedback = (config) => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: POST_FEEDBACK_REQUEST_SENT });
  return rmi.sendFeedback(config)
    .then(() => {
      dispatch({ type: POST_FEEDBACK_REQUEST_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: POST_FEEDBACK_REQUEST_FAIL, error });
    });
};

export const createSnapshot = (config) => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: CREATE_SNAPSHOT_REQUEST_SENT });
  return new Promise((resolve, reject) => {
    rmi.recognitions.createSnapshot(config)
      .then(() => {
        dispatch({ type: CREATE_SNAPSHOT_REQUEST_SUCCESS });
        resolve();
      })
      .catch((error) => {
        dispatch({ type: CREATE_SNAPSHOT_REQUEST_FAIL, error });
        reject(error);
      });
  });
};

export const reprocessMeeting = (config, snapshot = true) => async (dispatch, getState) => {
  const { rmi } = getState();

  if (snapshot) {
    if (!config || !config.id) {
      throw new Error('Must supply id to config.');
    }
    await createSnapshot({ id: config.id })(dispatch, getState);
  }

  dispatch({ type: REPROCESS_REQUEST_SENT });
  try {
    await rmi.recognitions.reprocess(config);
    dispatch({ type: REPROCESS_REQUEST_SUCCESS });
    return null;
  } catch (error) {
    dispatch({ type: REPROCESS_REQUEST_FAIL, error });
    throw error;
  }
};

export const toggleFeedbackModal = (shouldOpen) => ({
  type: TOGGLE_FEEDBACK_MODAL,
  shouldOpen,
});

export const toggleGoogleSttModal = (shouldOpen) => ({
  type: TOGGLE_GOOGLE_STT_MODAL,
  shouldOpen,
});

export const toggleSettingsModal = (shouldOpen) => ({
  type: TOGGLE_SETTINGS_MODAL,
  shouldOpen,
});

export const toggleHelpModal = (shouldOpen) => ({
  type: TOGGLE_HELP_MODAL,
  shouldOpen,
});

export const setCurrentRecognition = (recognition) => ({
  type: SET_CURRENT_RECOGNITION,
  recognition,
});

export const setFullScreen = (isFullScreen) => ({
  type: SET_FULLSCREEN_STATE,
  isFullScreen,
});

export const setPlayButtonFocused = (playButtonFocused) => ({
  type: SET_PLAY_BUTTON_FOCUSED,
  playButtonFocused,
});

export const setShowingCaptions = (showingCaptions) => ({
  type: SET_SHOWING_CAPTIONS,
  showingCaptions,
});

export const setScrollTarget = (scrollTarget) => ({
  type: SET_SCROLL_TARGET,
  scrollTarget,
});

export const setPhraseAltsPopover = (utteranceId, catsIndex, showAllAlternatives = false) => ({
  type: SET_PHRASE_ALTS_POPOVER,
  phraseAltsPopover: {
    utterance: utteranceId,
    catsIndex,
    showAllAlternatives,
  },
});

export const setShowSpanPopover = (show) => ({
  type: SET_SHOW_SPAN_POPOVER,
  showSpanPopover: show,
});

export const updateRecognition = (id, snapshot) => (dispatch) => {
  dispatch(getMeeting(id, snapshot));
  // This is purely legacy now. The cats are embedded in the new engine format.
  // dispatch(getCats(id, snapshot));
  dispatch(getOCR(id, snapshot));
};

export const richify = (utterance, query, wordsJson, fallback = false) => (dispatch, getState) => {
  const { rmi } = getState();

  return new Promise((resolve, reject) => {
    const currentRichifiedUtterance = getState().meeting.richifiedSegments[utterance];
    const { meeting } = getState().meeting;

    if (meeting.segmentMap && meeting.segmentMap[utterance] && fallback) {
      dispatch({
        type: RICHIFY_REQUEST_SUCCESS,
        utterance,
        transcript: meeting.segmentMap[utterance].transcript_formatted,
        original: query,
        alignment: getAlignmentBlocks(
          query,
          meeting.segmentMap[utterance].transcript_formatted,
        ),
      });
      resolve(meeting.segmentMap[utterance].transcript_formatted);
      return;
    }

    if (currentRichifiedUtterance) {
      const { original, transcript } = currentRichifiedUtterance;

      /* If the query is already cached, or we pre-populated richification results with
         transcript_formatted */
      if (
        original === query
        || (original === undefined && transcript !== undefined)
      ) {
        dispatch({
          type: RICHIFY_REQUEST_SUCCESS,
          utterance,
          transcript,
          original: query,
          alignment: getAlignmentBlocks(query, transcript),
        });
        resolve(transcript);
        return;
      }
    }

    if (query.trim().length === 0) {
      dispatch({
        type: RICHIFY_REQUEST_SUCCESS,
        utterance,
        transcript: '',
        original: query,
        alignment: getAlignmentBlocks('', ''),
      });
      resolve('');
      return;
    }

    dispatch({ type: RICHIFY_REQUEST_SENT, utterance });
    rmi
      .richify(wordsJson ? { wordsJson } : { query })
      .then((result) => {
        dispatch({
          type: RICHIFY_REQUEST_SUCCESS,
          utterance,
          transcript: result.text_formatted,
          original: query,
          alignment: getAlignmentBlocks(query, result.text_formatted),
          wordsFormatted: result.words_formatted,
        });
        resolve(result.text_formatted);
      })
      .catch((error) => {
        dispatch({ type: RICHIFY_REQUEST_FAIL, utterance, error });
        reject(error);
      });
  });
};

export const setRichificationEnabled = (enabled) => ({
  type: SET_RICHIFICATION_ENABLED,
  enabled,
});

export const setDynamicRichificationEnabled = (enabled) => ({
  type: SET_DYNAMIC_RICHIFICATION_ENABLED,
  enabled,
});

export const setShareKey = (shareKey) => ({
  type: SET_SHARE_KEY,
  shareKey,
});

export const getShareLinks = (id) => async (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({
    type: GET_SHARE_LINKS_REQUEST_SENT,
  });

  try {
    const { share_keys: links } = await rmi.recognitions.get({ id, format: 'sharing' });
    dispatch({
      type: GET_SHARE_LINKS_REQUEST_SUCCESS,
      links,
    });
    return links;
  } catch (error) {
    dispatch({
      type: GET_SHARE_LINKS_REQUEST_FAIL,
      error,
    });
    return null;
  }
};

export const createShareLinkWithName = (id, name) => async (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({
    type: CREATE_SHARE_LINK_REQUEST_SENT,
  });

  try {
    await rmi.recognitions.createShareLink({
      id,
      name,
    });
    const result = await getShareLinks(id)(dispatch, getState);
    dispatch({
      type: CREATE_SHARE_LINK_REQUEST_SUCCESS,
    });
    return result;
  } catch (error) {
    dispatch({
      type: CREATE_SHARE_LINK_REQUEST_FAIL,
      error,
    });
    throw error;
  }
};

export const deleteShareLinkWithKey = (id, shareKey) => async (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({
    type: DELETE_SHARE_LINK_REQUEST_SENT,
  });

  try {
    await rmi.recognitions.deleteShareLink({
      id, shareKey,
    });
    const result = await getShareLinks(id)(dispatch, getState);
    dispatch({
      type: DELETE_SHARE_LINK_REQUEST_SUCCESS,
    });
    return result;
  } catch (error) {
    dispatch({
      type: DELETE_SHARE_LINK_REQUEST_FAIL,
      error,
    });
    throw error;
  }
};

export const setTimeQuery = (timeQuery) => ({
  type: SET_TIME_QUERY,
  timeQuery,
});

export const lookupWordInLexicon = (word) => async (dispatch, getState) => {
  const { rmi, meeting: { meeting } } = getState();

  dispatch({
    type: LEXICAL_LOOKUP_REQUEST_SENT,
    word,
  });

  const hash = (meeting.metadata.asr.asr_model || meeting.metadata.asr.model).md5.lexicon;
  try {
    const info = await rmi.lookup.lexicon({
      hash,
      word,
    });
    dispatch({
      type: LEXICAL_LOOKUP_REQUEST_SUCCESS,
      word,
      info,
    });
    return info;
  } catch (error) {
    dispatch({
      type: LEXICAL_LOOKUP_REQUEST_FAIL,
      word,
    });
    throw error;
  }
};

export const clearLexicalLookup = (word) => ({
  type: CLEAR_LEXICAL_LOOKUP,
  word,
});

export const setSettingsPane = (pane) => ({
  type: SET_SETTINGS_PANE,
  pane,
});

export const openShareLinkModal = (recordingId) => ({
  type: SHARE_MODAL_OPEN,
  shareRecordingId: recordingId,
});

export const closeShareLinkModal = () => ({ type: SHARE_MODAL_CLOSE });
