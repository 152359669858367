/**
 * ##################################################################
 * # Account Action Types
 * ##################################################################
*/
import { END_SESSION } from './session';

export const CLEAR_ERROR = 'ACCOUNT::CLEAR_ERROR';
export const CREATE_ACCOUNT_REQUEST_SENT = 'ACCOUNT::CREATE_ACCOUNT_REQUEST_SENT';
export const CREATE_ACCOUNT_REQUEST_SUCCESS = 'ACCOUNT::CREATE_ACCOUNT_REQUEST_SUCCESS';
export const CREATE_ACCOUNT_REQUEST_FAIL = 'ACCOUNT::CREATE_ACCOUNT_REQUEST_FAIL';
export const DAEMON_INFORMATION_REQUEST_SENT = 'ACCOUNT::DAEMON_INFORMATION_REQUEST_SENT';
export const DAEMON_INFORMATION_REQUEST_SUCCESS = 'ACCOUNT::DAEMON_INFORMATION_REQUEST_SUCCESS';
export const DAEMON_INFORMATION_REQUEST_FAIL = 'ACCOUNT::DAEMON_INFORMATION_REQUEST_FAIL';
export const FORGOT_PASSWORD_REQUEST_SENT = 'ACCOUNT::FORGOT_PASSWORD_REQUEST_SENT';
export const FORGOT_PASSWORD_REQUEST_SUCCESS = 'ACCOUNT::FORGOT_PASSWORD_REQUEST_SUCCESS';
export const FORGOT_PASSWORD_REQUEST_FAIL = 'ACCOUNT::FORGOT_PASSWORD_REQUEST_FAIL';
export const GET_ACCOUNT_REQUEST_SENT = 'ACCOUNT::GET_ACCOUNT_REQUEST_SENT';
export const GET_ACCOUNT_REQUEST_SUCCESS = 'ACCOUNT::GET_ACCOUNT_REQUEST_SUCCESS';
export const GET_ACCOUNT_REQUEST_FAIL = 'ACCOUNT::GET_ACCOUNT_REQUEST_FAIL';
export const GET_ACCOUNT_SETTINGS_REQUEST_SENT = 'ACCOUNT::GET_ACCOUNT_SETTINGS_REQUEST_SENT';
export const GET_ACCOUNT_SETTINGS_REQUEST_SUCCESS = 'ACCOUNT::GET_ACCOUNT_SETTINGS_REQUEST_SUCCESS';
export const GET_ACCOUNT_SETTINGS_REQUEST_FAIL = 'ACCOUNT::GET_ACCOUNT_SETTINGS_REQUEST_FAIL';
export const GET_CARD_REQUEST_SENT = 'ACCOUNT::GET_CARD_REQUEST_SENT';
export const GET_CARD_REQUEST_SUCCESS = 'ACCOUNT::GET_CARD_REQUEST_SUCCESS';
export const GET_CARD_REQUEST_FAIL = 'ACCOUNT::GET_CARD_REQUEST_FAIL';
export const GET_PLAN_REQUEST_SENT = 'ACCOUNT::GET_PLAN_REQUEST_SENT';
export const GET_PLAN_REQUEST_SUCCESS = 'ACCOUNT::GET_PLAN_REQUEST_SUCCESS';
export const GET_PLAN_REQUEST_FAIL = 'ACCOUNT::GET_PLAN_REQUEST_FAIL';
export const RESET_PASSWORD_REQUEST_SENT = 'ACCOUNT::RESET_PASSWORD_REQUEST_SENT';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'ACCOUNT::RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAIL = 'ACCOUNT::RESET_PASSWORD_REQUEST_FAIL';
export const CHANGE_PASSWORD_REQUEST_SENT = 'ACCOUNT::CHANGE_PASSWORD_REQUEST_SENT';
export const CHANGE_PASSWORD_REQUEST_SUCCESS = 'ACCOUNT::CHANGE_PASSWORD_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_REQUEST_ERROR = 'ACCOUNT::CHANGE_PASSWORD_REQUEST_ERROR';
export const SAVE_ACCOUNT_REQUEST_SENT = 'ACCOUNT::SAVE_ACCOUNT_REQUEST_SENT';
export const SAVE_ACCOUNT_REQUEST_SUCCESS = 'ACCOUNT::SAVE_ACCOUNT_REQUEST_SUCCESS';
export const SAVE_ACCOUNT_REQUEST_FAIL = 'ACCOUNT::SAVE_ACCOUNT_REQUEST_FAIL';
export const SET_ACCOUNT_DATA = 'ACCOUNT::SET_ACCOUNT_DATA';
export const SET_ACCOUNT_SETTING_REQUEST_SENT = 'ACCOUNT::SET_ACCOUNT_SETTING_REQUEST_SENT';
export const SET_ACCOUNT_SETTING_REQUEST_SUCCESS = 'ACCOUNT::SET_ACCOUNT_SETTING_REQUEST_SUCCESS';
export const SET_ACCOUNT_SETTING_REQUEST_FAIL = 'ACCOUNT::SET_ACCOUNT_SETTING_REQUEST_FAIL';
export const SET_PASSWORD_REQUEST_SENT = 'ACCOUNT::SET_PASSWORD_REQUEST_SENT';
export const SET_PASSWORD_REQUEST_SUCCESS = 'ACCOUNT::SET_PASSWORD_REQUEST_SUCCESS';
export const SET_PASSWORD_REQUEST_FAIL = 'ACCOUNT::SET_PASSWORD_REQUEST_FAIL';
export const VERIFY_TOKEN_REQUEST_SENT = 'SESSION::VERIFY_TOKEN_REQUEST_SENT';
export const VERIFY_TOKEN_REQUEST_SUCCESS = 'SESSION::VERIFY_TOKEN_REQUEST_SUCCESS';
export const VERIFY_TOKEN_REQUEST_FAIL = 'SESSION::VERIFY_TOKEN_REQUEST_FAIL';

const initialState = {
  createAccountRequestOut: false,
  daemonInfo: {},
  daemonInfoRequestOut: false,
  error: undefined,
  getAccountRequestOut: false,
  getAccountSettingsRequestOut: false,
  accountSettings: {},
  getCardRequestOut: false,
  getPlanRequestOut: false,
  saveAccountRequestOut: false,
  resetPasswordRequestOut: false,
  changePasswordRequestOut: false,
  setPasswordRequestOut: false,
  setAccountSettingRequestOut: false,
  verifyTokenFail: false,
  verifyTokenRequestOut: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERROR:
      return { ...state, error: undefined, verificationFail: false };
    case CREATE_ACCOUNT_REQUEST_SENT:
      return { ...state, createAccountRequestOut: true, error: undefined };
    case CREATE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        createAccountRequestOut: false,
        info: action.info,
        error: undefined,
      };
    case CREATE_ACCOUNT_REQUEST_FAIL:
      return { ...state, createAccountRequestOut: false, error: action.error };
    case DAEMON_INFORMATION_REQUEST_SENT:
      return { ...state, daemonInfoRequestOut: true };
    case DAEMON_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        daemonInfoRequestOut: false,
        daemonInfo: action.info,
      };
    case DAEMON_INFORMATION_REQUEST_FAIL:
      return { ...state, daemonInfoRequestOut: false, error: action.error };
    case END_SESSION:
      return { ...initialState };
    case FORGOT_PASSWORD_REQUEST_SENT:
      return { ...state, forgotPasswordRequestOut: true, error: undefined };
    case FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        forgotPasswordRequestOut: false,
        info: action.info,
        error: undefined,
      };
    case FORGOT_PASSWORD_REQUEST_FAIL:
      return { ...state, forgotPasswordRequestOut: false, error: action.error };
    case GET_ACCOUNT_REQUEST_SENT:
      return { ...state, getAccountRequestOut: true, error: undefined };
    case GET_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        getAccountRequestOut: false,
        info: action.info,
        error: undefined,
      };
    case GET_ACCOUNT_REQUEST_FAIL:
      return { ...state, getAccountRequestOut: false, error: action.error };
    case GET_ACCOUNT_SETTINGS_REQUEST_SENT:
      return { ...state, getAccountSettingsRequestOut: true };
    case GET_ACCOUNT_SETTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        getAccountSettingsRequestOut: false,
        accountSettings: action.accountSettings,
      };
    case GET_ACCOUNT_SETTINGS_REQUEST_FAIL:
      return { ...state, getAccountSettingsRequestOut: false, error: action.error };
    case SET_ACCOUNT_SETTING_REQUEST_SENT:
      return { ...state, setAccountSettingRequestOut: true };
    case SET_ACCOUNT_SETTING_REQUEST_SUCCESS:
      return {
        ...state,
        setAccountSettingRequestOut: false,
        accountSettings: action.accountSettings,
      };
    case SET_ACCOUNT_SETTING_REQUEST_FAIL:
      return { ...state, setAccountSettingRequestOut: false, error: action.error };
    case GET_CARD_REQUEST_SENT:
      return { ...state, getCardRequestOut: true, error: undefined };
    case GET_CARD_REQUEST_SUCCESS:
      return {
        ...state,
        getCardRequestOut: false,
        card: action.info,
        error: undefined,
      };
    case GET_CARD_REQUEST_FAIL:
      return { ...state, getCardRequestOut: false, error: action.error };
    case GET_PLAN_REQUEST_SENT:
      return { ...state, getPlanRequestOut: true, error: undefined };
    case GET_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        getPlanRequestOut: false,
        plan: action.info,
        error: undefined,
      };
    case GET_PLAN_REQUEST_FAIL:
      return { ...state, getPlanRequestOut: false, error: action.error };
    case RESET_PASSWORD_REQUEST_SENT:
      return { ...state, resetPasswordRequestOut: true, error: undefined };
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return { ...state, resetPasswordRequestOut: false, error: undefined };
    case RESET_PASSWORD_REQUEST_FAIL:
      return { ...state, resetPasswordRequestOut: false, error: action.error };
    case CHANGE_PASSWORD_REQUEST_SENT:
      return { ...state, changePasswordRequestOut: true, error: undefined };
    case CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        changePasswordRequestOut: false,
        info: action.info,
        error: undefined,
      };
    case CHANGE_PASSWORD_REQUEST_ERROR:
      return { ...state, changePasswordRequestOut: false, error: action.error };
    case SAVE_ACCOUNT_REQUEST_SENT:
      return { ...state, saveAccountRequestOut: true, error: undefined };
    case SAVE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        saveAccountRequestOut: false,
        info: action.info,
        error: undefined,
      };
    case SAVE_ACCOUNT_REQUEST_FAIL:
      return { ...state, saveAccountRequestOut: false, error: action.error };
    case SET_ACCOUNT_DATA: {
      const newInfo = { ...state.info };
      newInfo[action.prop] = action.value;
      return { ...state, info: newInfo };
    }
    case SET_PASSWORD_REQUEST_SENT:
      return { ...state, setPasswordRequestOut: true, error: undefined };
    case SET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        setPasswordRequestOut: false,
        info: action.info,
        error: undefined,
      };
    case SET_PASSWORD_REQUEST_FAIL:
      return { ...state, setPasswordRequestOut: false, error: action.error };
    case VERIFY_TOKEN_REQUEST_SENT:
      return { ...state, verifyTokenRequestOut: true, verifyTokenFail: false };
    case VERIFY_TOKEN_REQUEST_SUCCESS:
      return {
        ...state,
        verifyTokenFail: false,
        verifyTokenRequestOut: false,
        password: action.password,
      };
    case VERIFY_TOKEN_REQUEST_FAIL:
      return { ...state, verifyTokenFail: true, verifyTokenRequestOut: false };
    default:
      return state;
  }
};

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const getAccount = () => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: GET_ACCOUNT_REQUEST_SENT });
  return rmi.accounts.get()
    .then((info) => {
      dispatch({ type: GET_ACCOUNT_REQUEST_SUCCESS, info });
    })
    .catch((error) => {
      dispatch({ type: GET_ACCOUNT_REQUEST_FAIL, error });
    });
};

export const getDaemonInformation = () => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: DAEMON_INFORMATION_REQUEST_SENT });
  return rmi.accounts.getDaemonInfo()
    .then((info) => {
      dispatch({
        type: DAEMON_INFORMATION_REQUEST_SUCCESS,
        info,
      });
    })
    .catch((error) => dispatch({ type: DAEMON_INFORMATION_REQUEST_FAIL, error }));
};

export const setAccountData = (prop, value) => ({
  type: SET_ACCOUNT_DATA,
  prop,
  value,
});

export const getAccountSettings = () => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: GET_ACCOUNT_SETTINGS_REQUEST_SENT });
  return rmi.accounts.getAccountSettings()
    .then((accountSettings) => {
      dispatch({
        type: GET_ACCOUNT_SETTINGS_REQUEST_SUCCESS,
        accountSettings,
      });
    })
    .catch((error) => dispatch({ type: GET_ACCOUNT_SETTINGS_REQUEST_FAIL, error }));
};

export const setAccountSetting = (setting, value) => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: SET_ACCOUNT_SETTING_REQUEST_SENT });
  return rmi.accounts.setAccountSetting({ setting, value })
    .then((accountSettings) => {
      dispatch({
        type: SET_ACCOUNT_SETTING_REQUEST_SUCCESS,
        accountSettings,
      });
    })
    .catch((error) => dispatch({ type: SET_ACCOUNT_SETTING_REQUEST_FAIL, error }));
};

export const sendCreateAccountRequest = () => (dispatch, getState) => {
  const { rmi } = getState();
  const { email } = getState().session;

  dispatch({ type: CREATE_ACCOUNT_REQUEST_SENT });
  return rmi.accounts.post({ email })
    .then((info) => {
      dispatch({ type: CREATE_ACCOUNT_REQUEST_SUCCESS, info });
    })
    .catch((error) => {
      dispatch({ type: CREATE_ACCOUNT_REQUEST_FAIL, error });
    });
};

export const sendForgotPasswordRequest = () => (dispatch, getState) => {
  const { rmi } = getState();
  const { email } = getState().session;

  dispatch({ type: FORGOT_PASSWORD_REQUEST_SENT });
  return rmi.accounts.sendVerificationToken({ email, forgot: true })
    .then((info) => {
      dispatch({ type: FORGOT_PASSWORD_REQUEST_SUCCESS, info });
    })
    .catch((error) => {
      dispatch({ type: FORGOT_PASSWORD_REQUEST_FAIL, error });
    });
};

export const sendResetPasswordRequest = () => (dispatch, getState) => {
  const { rmi } = getState();
  const { email } = getState().session;

  dispatch({ type: RESET_PASSWORD_REQUEST_SENT });
  return rmi.accounts.sendVerificationToken({ email, forgot: false })
    .then((info) => {
      dispatch({ type: RESET_PASSWORD_REQUEST_SUCCESS, info });
    })
    .catch((error) => {
      dispatch({ type: RESET_PASSWORD_REQUEST_FAIL, error });
    });
};

export const sendSaveAccount = () => (dispatch, getState) => {
  const { rmi } = getState();
  const { info } = getState().account;

  dispatch({ type: SAVE_ACCOUNT_REQUEST_SENT });
  return rmi.accounts.put(info)
    .then((newInfo) => {
      dispatch({ type: SAVE_ACCOUNT_REQUEST_SUCCESS, info: newInfo });
    })
    .catch((error) => {
      dispatch({ type: SAVE_ACCOUNT_REQUEST_FAIL, error });
    });
};

export const sendSetPassword = (verificationToken) => (dispatch, getState) => {
  const { rmi } = getState();
  // What is going on here? Old code?
  const { email, password } = getState().session;

  dispatch({ type: SET_PASSWORD_REQUEST_SENT });
  return rmi.accounts.setPassword({ email, verificationToken, newPassword: password })
    .then((info) => {
      dispatch({ type: SET_PASSWORD_REQUEST_SUCCESS, info });
    })
    .catch((error) => {
      dispatch({ type: SET_PASSWORD_REQUEST_FAIL, error });
    });
};

export const sendGetCard = () => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: GET_CARD_REQUEST_SENT });
  return rmi.accounts.getCard()
    .then((info) => {
      const card = info.number ? info : undefined;
      dispatch({ type: GET_CARD_REQUEST_SUCCESS, info: card });
    })
    .catch((error) => {
      dispatch({ type: GET_CARD_REQUEST_FAIL, error });
    });
};

export const sendGetPlan = () => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: GET_PLAN_REQUEST_SENT });
  return rmi.accounts.getPlan()
    .then((info) => {
      dispatch({ type: GET_PLAN_REQUEST_SUCCESS, info });
    })
    .catch((error) => {
      dispatch({ type: GET_PLAN_REQUEST_FAIL, error });
    });
};

export const verifyVerificationToken = (verificationToken) => (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({ type: VERIFY_TOKEN_REQUEST_SENT });

  const rq = rmi.accounts.verifyVerificationToken({ verificationToken });

  rq.then(() => {
    dispatch({ type: VERIFY_TOKEN_REQUEST_SUCCESS });
  });

  rq.catch((error) => {
    dispatch({ type: VERIFY_TOKEN_REQUEST_FAIL, error });
  });

  return rq;
};

export const sendChangePassword = (password, newPassword) => (dispatch, getState) => {
  const { rmi } = getState();
  const { email } = getState().session;

  dispatch({ type: CHANGE_PASSWORD_REQUEST_SENT });
  return rmi.accounts.getVerificationToken({ email, password })
    .then((info) => {
      rmi.accounts.setPassword(({
        newPassword,
        verificationToken: info.verification_token,
        email,
      }))
        .then((infoSetPassword) => {
          dispatch({ type: CHANGE_PASSWORD_REQUEST_SUCCESS, info: infoSetPassword });
        })
        .catch((errorSetPassword) => {
          dispatch({ type: CHANGE_PASSWORD_REQUEST_ERROR, error: errorSetPassword });
        });
    })
    .catch((error) => {
      dispatch({ type: CHANGE_PASSWORD_REQUEST_ERROR, error });
    });
};
