import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
import PropTypes from 'prop-types';
import { Button, Header, Modal } from 'semantic-ui-react';

import '../../stylesheets/account.css';

import { sendGetCard } from '../../modules/account';
import { cardType } from '../types';

function chargifyUrl(origin) {
  const base = origin.match('localhost')
    ? 'https://www-dev.remeeting.com'
    : origin;

  return `${base}/chargify/index.html`;
}

class CCInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creditCardUrl: '',
    };
  }

  componentDidMount() {
    const { origin } = this.props;
    this.setState({
      creditCardUrl: chargifyUrl(origin),
    });
  }

  handleCloseModal = () => {
    const { connectedSendGetCard } = this.props;
    connectedSendGetCard();
  }

  render() {
    const { card } = this.props;
    const { creditCardUrl } = this.state;
    return (
      <Modal
        style={{ minWidth: '430px' }}
        closeIcon
        size="tiny"
        trigger={(
          <Button
            content={card ? 'Update card' : 'Add card'}
            primary
            size="small"
          />
        )}
        onClose={this.handleCloseModal}
      >
        <Modal.Content>
          <Header as="h2">
            Payment method
          </Header>
          <Iframe
            className="cc-iframe edit-form"
            position="relative"
            url={creditCardUrl}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

CCInfo.propTypes = {
  card: cardType,
  origin: PropTypes.string.isRequired,
  connectedSendGetCard: PropTypes.func.isRequired,
};

CCInfo.defaultProps = {
  card: null,
};

const mapStateToProps = (state) => ({
  origin: state.rmi.origin,
  card: state.account.card,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSendGetCard: sendGetCard,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CCInfo);
