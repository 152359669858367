import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import round from 'lodash/round';
import {
  Button, Message, Modal, Table,
} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import { sendGetCard } from '../../modules/account';
import {
  getMeetingParticipants,
  getMeetingTitle,
  getMetadata,
  reprocessMeeting,
  toggleGoogleSttModal,
  updateRecognition,
} from '../../modules/meeting';

import { errorType, meetingType, cardType } from '../types';
import { formatHoursMinutesSeconds, smartDate } from '../../modules/utils';

class GoogleSttModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  componentDidMount = () => {
    const { card, connectedSendGetCard } = this.props;
    if (card === undefined) {
      connectedSendGetCard();
    }
  }

  componentDidUpdate(prevProps) {
    const { connectedSendGetCard, open: currentOpen } = this.props;
    if (prevProps.open !== currentOpen && currentOpen) {
      connectedSendGetCard();
    }
  }

  handleCloseModal = () => {
    const { connectedToggleGoogleSttModal, connectedUpdateRecognition, meeting } = this.props;
    this.setState({ submitted: false });
    connectedToggleGoogleSttModal(false);
    connectedUpdateRecognition(meeting.id, undefined);
  }

  handleProcessMeeting = () => {
    const { connectedReprocessMeeting, meeting } = this.props;
    connectedReprocessMeeting({
      id: meeting.id,
      body: { google_stt: true },
    })
      .then(() => this.setState({ submitted: true }));
  }

  renderActionButtons = () => {
    const {
      meeting,
      reprocessRequestOut,
      createSnapshotRequestOut,
      hasPaymentMethod,
    } = this.props;
    const { submitted } = this.state;
    const requestOut = reprocessRequestOut || createSnapshotRequestOut;

    return (
      <>
        { (submitted || !hasPaymentMethod) ? (
          <Button
            onClick={this.handleCloseModal}
          >
            Close
          </Button>
        ) : (
          <>
            <Button
              disabled={requestOut}
              onClick={this.handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              loading={requestOut}
              onClick={() => this.handleProcessMeeting()}
              disabled={meeting.id === ''}
              primary
            >
              Send to Google
            </Button>
          </>
        )}
      </>
    );
  };

  renderContent = () => {
    const { hasPaymentMethod, meeting } = this.props;
    const { submitted } = this.state;
    let contents;
    if (!hasPaymentMethod) {
      contents = (
        <>
          <p>
            Please add a payment method to your account
            in order to enable transcripts by Google.
          </p>
          <Link to="/account" target="_blank" rel="noopener noreferrer">Account Page</Link>
        </>
      );
    } else if (!submitted) {
      contents = (
        <div>
          <div className="re is-textAlign-center">
            Would you like to send this
            {' '}
            {formatHoursMinutesSeconds(getMetadata(meeting, 'recording_duration'), false, true)}
            {' '}
            recording to Google for an alternative transcript?
            <br />
            <div className="re is-marginTop-1em">
              <div className="modal-meeting-title">
                { getMeetingTitle(meeting) }
              </div>
              <div>
                { smartDate(meeting.indexed, true, true) }
              </div>
              <span>{getMeetingParticipants(meeting)}</span>
            </div>
            <br />
            <hr />
          </div>
          <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <Table id="gstt-price-table" basic="very" compact>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <a href="https://cloud.google.com/speech-to-text/pricing" target="_blank" rel="noopener noreferrer">
                      Google STT pricing:
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    $0.009 per 15-second request increment, rounded up.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    Quote detail:
                  </Table.Cell>
                  <Table.Cell>
                    This transcript requires
                    {' '}
                    {meeting.segments.length}
                    {' '}
                    requests, averaging
                    {' '}
                    {
                      round(
                        meeting.segments.map((segment) => {
                          const [startTime, endTime] = segment.interval;
                          return endTime - startTime;
                        }).reduce((totalTime, currentTime) => totalTime + currentTime)
                          / meeting.segments.length,
                        1,
                      )
                    }
                    s each.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    Handling fee:
                  </Table.Cell>
                  <Table.Cell>
                    $0.00 (Google STT is charged at-cost to your Remeeting account.)
                  </Table.Cell>
                </Table.Row>
                <Table.Row id="gstt-estimate-total">
                  <Table.Cell>
                    <b>Estimated total:</b>
                  </Table.Cell>
                  <Table.Cell>
                    <b>
                      $
                      { round((getMetadata(meeting, 'google_stt_usage_seconds') / 15) * 0.009, 2).toFixed(2) }
                    </b>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      );
    } else if (submitted) {
      contents = this.renderRequestMessage();
    }

    return (<>{contents}</>);
  }

  renderRequestMessage = () => {
    const { reprocessError, snapshotError } = this.props;
    const isError = (reprocessError !== undefined) && (snapshotError !== undefined);
    return (
      <Message positive={!isError} negative={isError}>
        <Message.Header>
          {isError ? (<p>Something went wrong.</p>)
            : ''}
        </Message.Header>
        {isError ? (<p>Sorry, please try again later.</p>)
          : (
            <p>
              This recording was sent to Google,
              which will provide an alternative transcript.
            </p>
          )}
      </Message>
    );
  }

  render() {
    const { open } = this.props;
    return (
      <Modal
        open={open}
        size="small"
      >
        <Modal.Header>
          Transcribe with Google Speech-to-Text
        </Modal.Header>
        <Modal.Content>
          { this.renderContent() }
        </Modal.Content>
        <Modal.Actions>
          { this.renderActionButtons() }
        </Modal.Actions>
      </Modal>
    );
  }
}

GoogleSttModal.propTypes = {
  connectedReprocessMeeting: PropTypes.func.isRequired,
  connectedSendGetCard: PropTypes.func.isRequired,
  connectedToggleGoogleSttModal: PropTypes.func.isRequired,
  connectedUpdateRecognition: PropTypes.func.isRequired,
  meeting: meetingType.isRequired,
  open: PropTypes.bool.isRequired,
  card: cardType,
  hasPaymentMethod: PropTypes.bool,
  reprocessError: errorType,
  reprocessRequestOut: PropTypes.bool.isRequired,
  snapshotError: errorType,
  createSnapshotRequestOut: PropTypes.bool.isRequired,
};

GoogleSttModal.defaultProps = {
  card: undefined,
  hasPaymentMethod: false,
  reprocessError: undefined,
  snapshotError: undefined,
};

const mapStateToProps = (state) => ({
  hasPaymentMethod: !isEmpty(state.account.card || {}),
  card: state.account.card,
  open: state.meeting.googleSttModalOpen,
  reprocessRequestOut: state.meeting.reprocessRequestOut,
  reprocessError: state.meeting.reprocessRequestError,
  createSnapshotRequestOut: state.meeting.createSnapshotRequestOut,
  snapshotError: state.meeting.createSnapshotRequestError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedReprocessMeeting: reprocessMeeting,
  connectedSendGetCard: sendGetCard,
  connectedToggleGoogleSttModal: toggleGoogleSttModal,
  connectedUpdateRecognition: updateRecognition,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoogleSttModal);
