/**
 * Remeeting Heart
 *
 * This module is a heartbeat that reads the Remeeting session key from localStorage and extends
 * it periodically. If it doesn't find a session key, then it will do nothing. If localStorage.auth
 * is somehow corrupted, then this will probably error out.
 *
 * This assumes localStorage.auth will have at least these properties
 * {
 *   session: {
 *     signedIn,
 *     temporaryKey
 *   }
 * }
 *
 * This code intentionally produces the side-effect in that it will modify localStorage.auth by
 * modifying session.temporaryKey to update the `exipres` and `updated` properties. Under normal
 * circumstances, only the auth app should write to localStorage.auth, but in the interest of
 * having auto-extending session tokens we allow this as well.
 *
 * If the request returns a 401 or 403 because the key is invalid, the Heart stops itself.
 * Otherwise if the request itself fails, the Heart will retry the request every 3 * 1.5^(n-1)
 * seconds, where n represents the nth retry, capped at the period of the Heart..
 *
 * NOTE: This file uses ES5 syntax so we don't have to worry about browser compatibility
 *
 * @param {Number} period - The period (in seconds) of the heartbeats
 * @param {String} apiBaseUrl - Base URL for the Remeeting API
 * @param {Function} callback - Callback function on success or errors, which will be called with
 *                              a parameter that looks like this: { type, payload }
 */
function Heart(period, apiBaseUrl, callback) {
  const instance = this;

  instance.apiBaseUrl = apiBaseUrl;
  instance.period = period;
  instance.callback = callback;

  /**
   * The error object returned for any 401 or 403 errors will look like a StatusCodeError, as
   * defined by request/promise-core here:
   *
   * https://github.com/request/promise-core/blob/7c4f30776a9b732db68afc30ceef87e59fa5f9a0/lib/errors.js#L22
   *
   * Even though this isn't strictly correct from the point of view of what the API might return
   * it suffices for our apps because if we can't extend the session token, we are effectively
   * unauthenticated.
   *
   * Errors with no status codes are assumed to be the result of no internet connection. This is
   * because XMLHttpRequest.status property is 0 before the request completes, and if the request
   * fails status is unchanged. We assume failed requests are the result of no internet connection.
   */
  instance.responses = {
    expired: {
      name: 'StatusCodeError',
      statusCode: 401,
      error: {
        message: 'This session has expired due to inactivity. Please sign in again.',
      },
    },
    unauthorized: {
      name: 'StatusCodeError',
      statusCode: 401,
      error: {
        // This is probably the correct explanation, though it could be due to some other reason.
        message: 'This session has ended because you signed out in another window. Please sign in again.',
      },
    },
    disconnected: {
      message: 'This session appears to be offline. Attempting to reconnect...',
    },
    success: {
      /* This is not a user-facing message. */
      message: 'Successfully extended session token.',
    },
    unexpected: {
      message: 'Something went wrong while updating this session. Attempting to refresh...',
    },
  };

  function parseAuth(authString) {
    const stub = {
      session: {
        signedIn: false,
        temporaryKey: null,
      },
    };
    const ret = JSON.parse(authString) || stub;
    return (ret.session && ret.session.temporaryKey) ? ret : stub;
  }

  function readSessionKey(auth) {
    const authObject = parseAuth(auth);
    return authObject.session.temporaryKey;
  }

  function updateSessionKey(resp) {
    const authObject = parseAuth(window.localStorage.getItem('auth'));

    authObject.session.temporaryKey.expires = resp.expires;
    authObject.session.temporaryKey.updated = resp.updated;
    window.localStorage.setItem('auth', JSON.stringify(authObject));
  }

  function beat() {
    instance.sessionKey = readSessionKey(window.localStorage.getItem('auth'));
    if (!instance.sessionKey) return;

    const rq = new XMLHttpRequest();
    rq.open('POST', `${instance.apiBaseUrl}/asr/v1/account/key/${instance.sessionKey.id}`);
    rq.setRequestHeader('Authorization', `Bearer ${instance.sessionKey.secret}`);
    rq.setRequestHeader('Content-Type', 'application/json');
    rq.send(JSON.stringify({ ttl: instance.sessionKey.ttl }));

    rq.onreadystatechange = function xhrStateChange() {
      if (rq.readyState === 4) {
        /* Non-200 status codes are assumed to be errors */
        if (rq.status === 200) {
          updateSessionKey(JSON.parse(rq.responseText));
          instance.callback({ type: 'success', payload: instance.responses.success });
        } else if (rq.status === 401 || rq.status === 403) {
          if (instance.sessionKey.expires < new Date().toISOString()) {
            instance.callback({ type: 'error', payload: instance.responses.expired });
          } else {
            instance.callback({ type: 'error', payload: instance.responses.unauthorized });
          }
          instance.stop();
        } else {
          if (rq.status === 0) {
            instance.callback({ type: 'error', payload: instance.responses.disconnected });
          } else {
            instance.callback({ type: 'error', payload: instance.responses.unexpected });
          }
          // TODO: Message user on how long retry delays are w/ option to force retry.
          setTimeout(beat, instance.retryDelay * 1000);

          // TODO: use exponential backoff?
          // instance.retryDelay = Math.min(instance.retryDelay * 1.5, instance.period);
        }
      }
    };
  }

  instance.interval = null;
  instance.retryDelay = 3; // Init retry delay to 3 seconds
  instance.sessionKey = null;

  instance.start = function start() {
    beat();
    instance.interval = setInterval(beat, instance.period * 1000);
  };

  instance.stop = function stop() {
    clearInterval(instance.interval);
  };
}

module.exports = Heart;
