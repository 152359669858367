const call = require('./utils/call');
const Extendable = require('./utils/extendable');

const BASE_OPTIONS = Symbol();

/** Status
 * The status is the primary resource of the
 * Remeeting ASR api. This wrapper exposes the basic
 * get/post network requests to create or update the status instance
 */
class Status extends Extendable {
  constructor(config, baseOptions) {
    super(config);
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Status);
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}stats`;
  }

  /**
   * get
   * make a get request to the api for this statuss id
   * @params config Obj - optional, properties to be copied before network request
   * @returns native Promise - resolve self, reject error obj
   */
  get(config) {
    const status = this;
    if (config) {
      status.extend(config);
    }

    return new Promise((resolve, reject) => {
      const options = status[BASE_OPTIONS].getOptions({ authRequired: false });
      call(options, resolve, reject, status);
    });
  }
}

module.exports = Status;
