const sha = require('sha.js');

const call = require('./utils/call');
const Extendable = require('./utils/extendable');
const messages = require('./utils/messages');

const BASE_OPTIONS = Symbol();
/** Key
 * The Key is a secret/key pair used for authentication on the
 * Remeeting ASR api. This wrapper exposes the basic
 * CRUD network requests to create or update the Key instance
 */
class Key extends Extendable {
  constructor(config, baseOptions) {
    super(config);
    this.expires = config.expires || '';
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Key);
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}account/keys`;
  }

  /**
   * get
   * make a get request to the api for this id
   * @params config Obj - optional, properties to be copied before network request
   * @returns native Promise - resolve self, reject error obj
   * @notes: the Key must have an id set, an id must be provided in config, or
   * the Key must have a secret from which the id can be calculated.
   */
  get(config = {}) {
    const key = this;
    key.extend(config);

    return new Promise((resolve, reject) => {
      if (!key.id) {
        if (!key.secret) {
          reject(new Error({ message: messages.idOrSecretRequired }));
          return;
        }
        key.id = sha('sha256').update(key.secret).digest('hex');
      }

      const options = key[BASE_OPTIONS].getOptions({ id: key.id });
      if (!options) {
        reject(new Error({ message: messages.authRequired }));
        return;
      }

      call(options, resolve, reject, key);
    });
  }

  /**
   * post
   * create a new key on the api
   * @params config Obj - optional, properties to be copied before network request,
   *   email and password must be included in config
   * @returns native Promise - resolves key Instance, rejects error obj
   * Notes: This is the only way to get the secret, as the api doesn't store it and
   *   won't return it on subsequent gets.
   */
  post(config) {
    const key = this;
    if (config) {
      key.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (key.id) {
        reject({ message: messages.idSetInvalid });
        return;
      }

      const options = key[BASE_OPTIONS].getOptions({ basicRequired: true, method: 'POST' });
      if (!options) {
        reject({ message: messages.basicRequired });
        return;
      }

      options.body = {
        name: key.name || '',
      };

      if (key.ttl !== undefined) {
        options.body.ttl = key.ttl;
        if (key.ttl < 0 || key.ttl > 2592000) {
          reject({ message: messages.ttlRange });
          return;
        }
      }

      const rq = call(options, undefined, reject);
      rq.then((data) => {
        if (data.api_url) {
          data.secret = data.api_url;
          delete data.secret;
        }
        key.extend(data);
        resolve(key);
      });
    });
  }

  /**
   * put
   * modify a key on the api
   * @params config Obj - optional, properties to be copied before network request
   * @returns native Promise - resolves key Instance, rejects error obj
   * Notes: A key can only be modified by itself or email and password
   */
  put(config) {
    const key = this;
    if (config) {
      key.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!key.id) {
        reject({ message: messages.idRequired });
        return;
      }

      const options = key[BASE_OPTIONS].getOptions({ key: true, id: key.id, method: 'POST' });
      if (!options) {
        reject({ message: messages.authRequired });
        return;
      }

      options.body = {
        name: key.name || '',
      };

      if (key.ttl !== undefined) {
        options.body.ttl = key.ttl;
        if (key.ttl < 0 || key.ttl > 2592000) {
          reject({ message: messages.ttlRange });
          return;
        }
      }

      call(options, resolve, reject, key);
    });
  }

  /**
   * delete
   * make a delete request to the api for this Keys id
   * @params config Obj - optional, properties to be copied before network request
   * @returns native Promise - resolve nothing, reject error obj
   * @notes: the Key must have an id set, or an id must be provided in config.
   */
  delete(config) {
    const key = this;
    if (config) {
      key.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!key.id) {
        reject({ message: messages.idRequired });
        return;
      }

      const options = key[BASE_OPTIONS].getOptions({ key: true, id: key.id, method: 'DELETE' });
      if (!options) {
        reject({ message: messages.authRequired });
        return;
      }

      call(options, resolve, reject);
    });
  }
}

// TODO: Add add support for perminant and temporary keys and adjust return value
Key.list = function (config, baseOptions) {
  config = config || {};

  return new Promise((resolve, reject) => {
    baseOptions.setResource(Key);
    baseOptions.resourceUrl = `${baseOptions.apiUrl}/account/keys`;

    const options = baseOptions.getOptions({});
    if (!options) {
      reject({ message: messages.authRequired });
      return;
    }

    const rq = call(options, undefined, reject);
    rq.then((data) => {
      resolve(data.keys.map((info) => new Key(info, baseOptions)));
    });
  });
};

module.exports = Key;
