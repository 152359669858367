import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  // Button,
  Header,
  Menu,
  Table,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { historyType, hitType } from '../types';

import '../../stylesheets/search.css';
import '../../stylesheets/utilities.css';

import Hit from './hit';
import SearchBar, { stringifyFieldsObject } from './searchBar';

import { clearSearch, sendSearch, SEARCH_PAGE_SIZE } from '../../modules/search';
import { buildURLQueryString } from '../../modules/utils';

import PaginationNav from './paginationNav';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFilterBefore: '', // TODO: Implement
      loadedQuery: '', // Query for the hits rendered (ie request returned)
    };
  }

  componentDidMount() {
    const { history, query } = this.props;
    if (query) {
      this.executeSearch(query);
    } else {
      history.push('/');
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fields,
      history,
      page,
      query,
      requestOut,
      speakers,
    } = this.props;
    if (prevProps.query !== query
        || !isEqual(prevProps.fields, fields)
        || !isEqual(prevProps.speakers, speakers)
        || prevProps.page !== page) {
      if (query) {
        this.executeSearch(query);
      } else {
        history.push('/');
      }
    }
    if (prevProps.requestOut !== requestOut) {
      if (!requestOut) {
        this.setState({ loadedQuery: query }); // eslint-disable-line react/no-did-update-set-state
      }
    }
  }

  componentWillUnmount() {
    const { connectedClearSearch } = this.props;
    connectedClearSearch();
  }

  onPageChange = (activePage) => {
    const { fields, history, query } = this.props;
    const qsConfig = { q: query };
    const fieldsString = stringifyFieldsObject(fields);
    if (fieldsString) qsConfig.fields = fieldsString;
    if (activePage > 1) {
      qsConfig.p = activePage;
    }
    const queryParams = buildURLQueryString(qsConfig);
    history.push(`/search?${queryParams}`);
  }

  executeSearch = (query, newSearch = true) => {
    const {
      connectedClearSearch,
      connectedSearch,
      fields,
      page,
      speakers,
    } = this.props;
    const trimQuery = query.trim();

    if (trimQuery) {
      const { dateFilterBefore } = this.state;

      const searchConfig = {};

      if (dateFilterBefore) {
        searchConfig.recording_date_time = `(,${dateFilterBefore})`;
      }

      const searchFields = [];
      if (fields.transcript) searchFields.push('cats.concats');
      if (fields.ocr) searchFields.push('ocr');
      if (fields.title) searchFields.push('title');

      searchConfig.search_fields = searchFields.join(',');

      if (!isEmpty(speakers)) searchConfig.speaker_names = speakers.join(',');

      searchConfig.terms = trimQuery;

      searchConfig.from = (page - 1) * SEARCH_PAGE_SIZE;
      searchConfig.limit = SEARCH_PAGE_SIZE;

      if (newSearch) searchConfig.newSearch = true;

      connectedSearch(searchConfig)
        .then(() => {
          if (newSearch) window.scrollTo(0, 0);
        });
    } else {
      connectedClearSearch();
    }
  }

  settingsUpdateHandler = (payload) => {
    const {
      fields,
      history,
      query,
      speakers,
    } = this.props;
    const updatedFields = { ...fields, ...payload.fields };
    const fieldsString = stringifyFieldsObject(updatedFields);
    const speakersString = payload.speakers ? payload.speakers.join(',') : speakers.join(',');
    const queryConfig = { q: query };
    if (fieldsString) queryConfig.fields = fieldsString;
    if (speakersString) queryConfig.speakers = speakersString;
    const queryParams = buildURLQueryString(queryConfig);
    history.push(`/search?${queryParams}`);
  }

  renderSearchBar = () => {
    const {
      fields,
      history,
      query,
      speakers,
    } = this.props;
    // Remove hits and page from destructuring assignment
    const { hits, page } = this.props;
    const numHits = hits.total ? hits.total.value : 0;
    return (
      <div>
        <Menu secondary borderless className="re is-header-row">
          <SearchBar
            fields={fields}
            history={history}
            initialQuery={query}
            settingsUpdateHandler={this.settingsUpdateHandler}
            speakers={speakers}
          />
          { numHits > 0 && (
            <PaginationNav
              page={parseInt(page, 10)}
              changePage={this.onPageChange}
              hits={hits}
            />
          )}
        </Menu>
      </div>
    );
  }

  renderNoHits = () => (
    <div className="re is-flexGrow-1 is-flex is-justifyContent-center is-alignItems-center">
      <div className="re is-textAlign-center is-padding-bottom-16px is-padding-top-16px">
        <Header as="h2">
          No recordings found
        </Header>
        <p>
          Looks like no recordings fit your criteria.
        </p>
      </div>
    </div>
  );

  renderHits = () => {
    const {
      hits,
      requestOut,
    } = this.props;
    const { loadedQuery } = this.state;
    const numHits = hits.total ? hits.total.value : 0;
    if (numHits && hits.hits.length > 0) {
      return (
        <>
          <Table basic="very" className="re is-table">
            <Table.Body>
              { !!numHits && hits.hits
                .map((hit) => (
                  <Hit
                    key={hit.id}
                    id={hit.id}
                    highlightCats={hit.highlight_concats}
                    ocrWordCounts={hit.ocr_word_counts}
                    query={loadedQuery}
                    recordingDuration={hit.recording_duration}
                    speakers={hit.speakers}
                    startTime={hit.recording_date_time}
                    title={hit.title}
                    utterances={hit.utterances}
                  />
                ))}
            </Table.Body>
          </Table>
        </>
      );
    }
    return !requestOut && this.renderNoHits();
  }

  render() {
    const { query } = this.props;
    return (
      <div className="re is-main is-full-height is-flex is-flexDirection-column">
        <Helmet>
          <title>
            { query }
            { ' ' }
            &mdash;
            { ' ' }
            Remeeting
          </title>
        </Helmet>
        <div style={{ margin: '5em 1em 0 1.5em' }}>
          <div className="sticky-header">
            { this.renderSearchBar() }
          </div>
          { this.renderHits() }
        </div>
      </div>
    );
  }
}

Search.defaultProps = {
  fields: {
    transcript: true,
    ocr: true,
    title: true,
  },
  page: 1,
  query: '',
  speakers: [],
};

Search.propTypes = {
  connectedClearSearch: PropTypes.func.isRequired,
  connectedSearch: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    ocr: PropTypes.bool.isRequired,
    title: PropTypes.bool.isRequired,
    transcript: PropTypes.bool.isRequired,
  }),
  history: historyType.isRequired,
  hits: PropTypes.shape({
    hits: PropTypes.arrayOf(hitType),
    total: PropTypes.shape({
      relation: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  }).isRequired,
  page: PropTypes.number,
  query: PropTypes.string,
  requestOut: PropTypes.bool.isRequired,
  speakers: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const {
    p, q, fields, speakers,
  } = queryString.parse(location.search);
  const fieldsObj = {
    ocr: false,
    title: false,
    transcript: false,
  };
  if (fields) {
    fields.split(',').forEach((field) => {
      fieldsObj[field] = true;
    });
  } else {
    /* If no fields specified, search all fields. */
    Object.keys(fieldsObj).forEach((field) => {
      fieldsObj[field] = true;
    });
  }
  return {
    page: p || 1,
    fields: fieldsObj,
    hits: state.search.hits,
    query: q,
    requestOut: state.search.requestOut,
    speakers: speakers ? speakers.split(',').map((s) => s.trim()) : [],
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearSearch: clearSearch,
  connectedSearch: sendSearch,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
