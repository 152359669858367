const call = require('../utils/call');
const Extendable = require('../utils/extendable');

const BASE_OPTIONS = Symbol();

class Lookup extends Extendable {
  constructor(config, baseOptions) {
    super(config);
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource({ name: 'lookup' }); // hack to get the right v1 URL
  }

  lexicon(config) {
    const lookup = this;
    if (config) {
      lookup.extend(this);
    }

    return new Promise((resolve, reject) => {
      if (!lookup.hash) {
        reject({ message: 'Must provide a lookup hash for the lexicon.' });
        return;
      }

      const options = lookup[BASE_OPTIONS].getOptions(config);
      options.method = 'GET';
      options.url += `/lexicon/${lookup.hash}`;

      if (lookup.word) {
        options.url += `/${lookup.word}`;
      }

      /* Call works normally, except in the case when the API returns a 404 for OOV words. */
      call(
        options,
        resolve,
        (error) => {
          if (error.statusCode === 404) {
            if (lookup.word && error.error.error.includes('vocab')) {
              /* "Out-of-vocabulary word" error */
              resolve({});
            } else {
              reject(error);
            }
          }
        },
      );
    });
  }

  lm(config) {
    const lookup = this;
    if (config) {
      lookup.extend(this);
    }

    return new Promise((resolve, reject) => {
      if (!lookup.hash) {
        reject({ message: 'Must provide a lookup hash for the LM.' });
        return;
      }

      const options = lookup[BASE_OPTIONS].getOptions(config);
      options.method = 'GET';
      options.url += `/lm/${lookup.hash}`;

      if (lookup.phrase) {
        options.url += `/${lookup.phrase}`;
      }

      call(
        options,
        resolve,
        (error) => {
          if (error.statusCode === 404) {
            if (lookup.phrase && error.error.error.includes('vocab')) {
              /* "Out-of-vocabulary word in phrase" error */
              resolve({});
            } else {
              reject(error);
            }
          }
        },
      );
    });
  }

  pronunciation(config) {
    const lookup = this;
    if (config) {
      lookup.extend(this);
    }

    return new Promise((resolve, reject) => {
      if (!lookup.phrase) {
        reject({ message: 'Must provide a word or phrase to run G2P.' });
        return;
      }

      const options = lookup[BASE_OPTIONS].getOptions(config);
      options.method = 'GET';
      options.url += `/pronunciation/${encodeURI(lookup.phrase)}`;

      call(options, resolve, reject);
    });
  }
}

module.exports = Lookup;
