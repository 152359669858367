import { spliceItemsIntoCollectionById } from './utils';

import { END_SESSION } from './session';
import { GET_MEETING_REQUEST_SUCCESS, DELETE_MEETING_REQUEST_SUCCESS } from './meeting';

export const RECOGNITIONS_PAGE_LIMIT = 25;

const CLEAR_ERROR = 'RECOGNITIONS::CLEAR_ERROR';
const GET_THUMBNAIL_REQUEST_SENT = 'RECOGNITIONS::GET_THUMBNAIL_REQUEST_SENT';
const GET_THUMBNAIL_REQUEST_SUCCESS = 'RECOGNITIONS::GET_THUMBNAIL_REQUEST_SUCCESS';
const GET_THUMBNAIL_REQUEST_FAIL = 'RECOGNITIONS::GET_THUMBNAIL_REQUEST_FAIL';
const LIST_RECOGNITIONS_REQUEST_SENT = 'RECOGNITIONS::LIST_RECOGNITIONS_REQUEST_SENT';
const LIST_RECOGNITIONS_REQUEST_SUCCESS = 'RECOGNITIONS::LIST_RECOGNITIONS_REQUEST_SUCCESS';
const LIST_RECOGNITIONS_REQUEST_FAIL = 'RECOGNITIONS::LIST_RECOGNITIONS_REQUEST_FAIL';
const PAGE_RECOGNITIONS_REQUEST_SENT = 'RECOGNITIONS::PAGE_RECOGNITIONS_REQUEST_SENT';
const PAGE_RECOGNITIONS_REQUEST_SUCCESS = 'RECOGNITIONS::PAGE_RECOGNITIONS_REQUEST_SUCCESS';
const PAGE_RECOGNITIONS_REQUEST_FAIL = 'RECOGNITIONS::PAGE_RECOGNITIONS_REQUEST_FAIL';
const UPDATE_RECOGNITIONS_REQUEST_SENT = 'RECOGNITIONS::UPDATE_RECOGNITIONS_REQUEST_SENT';
const UPDATE_RECOGNITIONS_REQUEST_SUCCESS = 'RECOGNITIONS::UPDATE_RECOGNITIONS_REQUEST_SUCCESS';
const UPDATE_RECOGNITIONS_REQUEST_FAIL = 'RECOGNITIONS::UPDATE_RECOGNITIONS_REQUEST_FAIL';
const CHANGE_DISPLAY_FILTER_STATUS = 'RECOGNITIONS::CHANGE_DISPLAY_FILTER_STATUS';

const CARD_ACTION_SET = 'RECOGNITIONS::CARD_ACTION_SET';
const CARD_ACTION_OPEN = 'RECOGNITIONS::CARD_ACTION_OPEN';
const CARD_ACTION_CLOSE = 'RECOGNITIONS::CARD_ACTION_CLOSE';

/* These are the actions and reducer that handle the dashboard slice of the global state */
const initialState = {
  cardAction: '',
  cardActionMeeting: {},
  cardActionOpen: false,
  error: undefined,
  listRecognitionsRequestOut: false,
  pageRecognitionsRequestOut: false,
  recognitions: [],
  recognitionsLoaded: false,
  updateRecognitionsRequestOut: false,
  getThumbnailRequestOut: false,
  meetingThumbnails: {},
  displayStatusFilter: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CARD_ACTION_CLOSE:
      return {
        ...state, cardAction: '', cardActionMeeting: {}, cardActionOpen: false,
      };
    case CARD_ACTION_OPEN:
      return { ...state, cardActionOpen: true };
    case CARD_ACTION_SET:
      return { ...state, cardAction: action.action, cardActionMeeting: action.meeting };
    case CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case GET_MEETING_REQUEST_SUCCESS: {
      const meeting = action.recognition;
      const rId = state.recognitions.findIndex((elem) => elem.id === meeting.id);
      if (rId > -1) {
        // Only copy over the needed fields for the dashboard recognition element.
        const update = {};
        update.created = meeting.created;
        update.id = meeting.id;
        update.indexed = meeting.indexed;
        update.metadata = meeting.metadata;
        update.status = meeting.status;
        update.updated = meeting.updated;

        const recognitions = spliceItemsIntoCollectionById(
          state.recognitions,
          [update],
          false,
          false,
        );
        return { ...state, recognitions };
      }
      return state;
    }
    case GET_THUMBNAIL_REQUEST_SENT:
      return { ...state, getThumbnailRequestOut: true };
    case GET_THUMBNAIL_REQUEST_SUCCESS: {
      return {
        ...state,
        error: undefined,
        getThumbnailRequestOut: false,
        meetingThumbnails: {
          ...state.meetingThumbnails,
          [action.meetingId]: action.meetingThumbnail,
        },
      };
    }
    case GET_THUMBNAIL_REQUEST_FAIL: {
      /* TODO: Track thumbnail request errors separately from dashboard page errors in general.
         In fact we probably should track errors in a more fine-grained manner. */
      return {
        ...state,
        error: undefined,
        getThumbnailRequestOut: false,
        meetingThumbnails: {
          ...state.meetingThumbnails,
          [action.meetingId]: action.meetingThumbnail,
        },
      };
    }
    case DELETE_MEETING_REQUEST_SUCCESS:
      return {
        ...state,
        recognitions: [...state.recognitions.filter((recog) => recog.id !== action.id)],
      };
    case END_SESSION:
      return { ...initialState };
    case LIST_RECOGNITIONS_REQUEST_SENT:
      return {
        ...state,
        listRecognitionsRequestOut: true,
      };
    case LIST_RECOGNITIONS_REQUEST_SUCCESS:
      return {
        ...state,
        recognitions: action.recognitions,
        error: undefined,
        listRecognitionsRequestOut: false,
        recognitionsLoaded: action.recognitionsLoaded,
      };
    case LIST_RECOGNITIONS_REQUEST_FAIL:
      return { ...state, error: action.error, listRecognitionsRequestOut: false };
    case PAGE_RECOGNITIONS_REQUEST_SENT:
      return { ...state, pageRecognitionsRequestOut: true };
    case PAGE_RECOGNITIONS_REQUEST_SUCCESS:
      return {
        ...state,
        recognitions: action.recognitions.concat(state.recognitions),
        recognitionsLoaded: action.recognitionsLoaded,
        error: undefined,
        pageRecognitionsRequestOut: false,
      };
    case PAGE_RECOGNITIONS_REQUEST_FAIL:
      return { ...state, error: action.error, pageRecognitionsRequestOut: false };
    case UPDATE_RECOGNITIONS_REQUEST_SENT:
      return { ...state, updateRecognitionsRequestOut: true };
    case UPDATE_RECOGNITIONS_REQUEST_SUCCESS: {
      let recognitions = spliceItemsIntoCollectionById(
        state.recognitions,
        action.recognitions,
        action.stripOlder,
        action.stripNewer,
      );

      if (action.filterOlder) {
        recognitions = recognitions.filter((item) => action.filterOlder <= item.indexed);
      }

      if (action.clearCollection) {
        recognitions = [];
      }

      return {
        ...state,
        recognitions,
        error: undefined,
        updateRecognitionsRequestOut: false,
      };
    }
    case UPDATE_RECOGNITIONS_REQUEST_FAIL:
      return { ...state, error: action.error, updateRecognitionsRequestOut: false };
    case CHANGE_DISPLAY_FILTER_STATUS:
      return { ...state, displayStatusFilter: action.displayStatusFilter };
    default:
      return state;
  }
};

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const getThumbnail = (id) => (dispatch, getState) => {
  const {
    meeting: { shareKey },
    rmi,
  } = getState();

  dispatch({ type: GET_THUMBNAIL_REQUEST_SENT });
  return (
    rmi.recognitions.getThumbnail({ id, proxy: true, shareKey })
      .then((resp) => {
        const imageBlob = new Blob([resp], { type: 'image/jpeg' });
        const URL = window.URL || window.webkitURL;
        dispatch({
          type: GET_THUMBNAIL_REQUEST_SUCCESS,
          meetingThumbnail: URL.createObjectURL(imageBlob),
          meetingId: id,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_THUMBNAIL_REQUEST_FAIL,
          error,
          meetingThumbnail: null,
          meetingId: id,
        });
      })
  );
};

export const listRecognitions = (config) => (dispatch, getState) => {
  const { rmi } = getState();
  dispatch({ type: LIST_RECOGNITIONS_REQUEST_SENT });

  return new Promise(
    (resolve, reject) => rmi.recognitions.list({
      limit: RECOGNITIONS_PAGE_LIMIT,
      indexed: true,
      ...config,
    })
      .then((list) => {
        dispatch({
          type: LIST_RECOGNITIONS_REQUEST_SUCCESS,
          recognitions: list.items,
          recognitionsLoaded: list.items.length !== RECOGNITIONS_PAGE_LIMIT,
        });
        resolve();
      })
      .catch((error) => {
        dispatch({ type: LIST_RECOGNITIONS_REQUEST_FAIL, error });
        reject(error);
      }),
  );
};

export const pageRecognitions = (config) => (dispatch, getState) => {
  const { rmi } = getState();
  const { recognitions } = getState().recognitions;
  const oldestRecognition = recognitions[0];

  dispatch({ type: PAGE_RECOGNITIONS_REQUEST_SENT });
  return rmi.recognitions.list({
    before: encodeURIComponent(oldestRecognition.indexed),
    indexed: true,
    limit: RECOGNITIONS_PAGE_LIMIT,
    ...config,
  })
    .then((list) => {
      dispatch({
        type: PAGE_RECOGNITIONS_REQUEST_SUCCESS,
        recognitions: list.items,
        recognitionsLoaded: list.items.length !== RECOGNITIONS_PAGE_LIMIT,
      });
    })
    .catch((error) => {
      dispatch({ type: PAGE_RECOGNITIONS_REQUEST_FAIL, error });
    });
};

export const updateRecognitions = (config) => (dispatch, getState) => {
  const { rmi } = getState();
  const { recognitions } = getState().recognitions;
  /* If there are no meetings, create a dummy oldest meeting with a
  "created" date set to January 1, 1970. We think that's old enough. */
  const oldestRecognition = recognitions[0] || { indexed: new Date(0).toISOString() };
  let requestCount = 0;

  function sendListRequest(listConfig) {
    return (
      rmi.recognitions.list(listConfig)
        .then((list) => {
          let clearCollection = false;
          let filterOlder = false;

          const items = list.items.filter((item) => oldestRecognition.indexed <= item.indexed);
          requestCount += 1;
          if (list.items.length === 0) {
            if (requestCount === 1) {
              clearCollection = true;
            } else {
              filterOlder = decodeURIComponent(listConfig.before);
            }
          }

          // if this is the first request, we know that we have the newest recognitions
          // on the database, and can throw away any newer recognitions in memory
          const stripNewer = requestCount === 1;

          // if the list has been filtered, then there were older items, and any
          // unmatched old collection should be discarded
          const stripOlder = list.items.length !== items.length;

          dispatch({
            type: UPDATE_RECOGNITIONS_REQUEST_SUCCESS,
            recognitions: items,
            stripNewer,
            stripOlder,
            clearCollection,
            filterOlder,
          });
          return items;
        })
        .then((items) => {
          const oldestItem = items[0];
          if (oldestRecognition && oldestItem && oldestRecognition.indexed < oldestItem.indexed) {
            return sendListRequest({
              ...listConfig,
              before: encodeURIComponent(oldestItem.indexed),
            });
          }
          return items;
        })
    );
  }

  dispatch({ type: UPDATE_RECOGNITIONS_REQUEST_SENT });
  return sendListRequest({ limit: RECOGNITIONS_PAGE_LIMIT, indexed: true, ...config })
    .catch((error) => {
      dispatch({ type: UPDATE_RECOGNITIONS_REQUEST_FAIL, error });
    });
};

export const changeDisplayStatusFilter = () => (dispatch, getState) => {
  const { displayStatusFilter } = getState().recognitions;
  dispatch({ type: CHANGE_DISPLAY_FILTER_STATUS, displayStatusFilter: !displayStatusFilter });
};

/* Card action (edit/delete) modal */
export const setCardAction = (meeting, action) => ({
  type: CARD_ACTION_SET,
  action,
  meeting,
});

export const openCardAction = () => ({ type: CARD_ACTION_OPEN });

export const closeCardAction = () => ({ type: CARD_ACTION_CLOSE });
