import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import account from './account';
import customize from './customize';
import find from './find';
import mediaElement from './mediaElement';
import meeting from './meeting';
import player from './player';
import recognitions from './recognitions';
import rmi from './rmi';
import search from './search';
import session from './session';

/* This is the place where the global state gets compiled
from all the individual slices of state. New reducers must
 be registered here to be accessible in the global state */

export default (history) => combineReducers({
  router: connectRouter(history),
  account,
  customize,
  find,
  mediaElement,
  meeting,
  player,
  recognitions,
  rmi,
  search,
  session,
});
