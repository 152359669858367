import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { locationType } from './types';

import '../stylesheets/redirect.css';

export class Redirect extends Component {
  componentDidMount() {
    this.signinRedirect(1000);
  }

  signinRedirect = (waitMs = 500) => {
    const { location, signedIn } = this.props;
    const { from = {} } = location.state || {};
    const { pathname = '', search } = from;
    const { e } = queryString.parse(search);
    const target = `/app${pathname}`;
    const params = queryString.stringify({ e, from: target });
    setTimeout(() => {
      window.location.href = signedIn ? target : `/auth/signin?${params}`;
    }, waitMs);
  }

  render() {
    return (
      <div className="re is-main is-full-height">
        <h1 className="re is-textAlign-center">Redirecting</h1>
        <h3 className="re is-textAlign-center">Please stand by...</h3>
      </div>
    );
  }
}

Redirect.propTypes = {
  location: locationType.isRequired,
  signedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({ signedIn: state.session.signedIn });

export default connect(mapStateToProps)(Redirect);
