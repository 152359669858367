import React, { Component } from 'react';
import {
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import 'remeeting-css/semantic/dist/semantic.min.css';
import Heart from 'remeeting-heartbeat';

import Account from './account';
import Recognitions from './recognitions';
import Meeting from './meeting';
import Search from './search';
import NavContainer from './nav';
import NoMatch from './nomatch';
import RedirectPage from './redirect';

import ProtectedRoute from './protected-route';
import {
  clearError,
  refreshTokenFail,
  setRmiAuth,
  setUserAgent,
} from '../modules/session';

import { determineRemeetingApiBaseUrl } from '../modules/rmi';

/* This is the root component that gets appended to the dom.
   This is important because it is where route urls get defined
   and the components they are meant to render are connected.
   Thus, new components that are meant to be accessed directly
   by a url must be registered here with the url and the component. */

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heart: null,
    };
    const {
      connectedSetRmiAuth,
      connectedSetUserAgent,
    } = props;
    connectedSetUserAgent();
    connectedSetRmiAuth();
  }

  componentDidMount() {
    const { connectedClearError, connectedRefreshTokenFail } = this.props;
    const heart = new Heart(60 * 10, // Extend token every 10 minutes
      determineRemeetingApiBaseUrl(window.location.hostname),
      ({ type, payload }) => {
        if (type === 'success') {
          connectedClearError();
        }
        if (type === 'error') {
          connectedRefreshTokenFail(payload);
        }
      });
    heart.start();
    this.setState({ heart });
  }

  componentWillUnmount() {
    const { heart } = this.state;
    heart.stop();
  }

  render() {
    return (
      <div className="re is-full-height">
        <NavContainer />
        <Helmet>
          <title>Remeeting</title>
        </Helmet>
        <main className="re is-full-height">
          <Switch>
            {/* Put these special routes at the top */}
            <ProtectedRoute exact path="/account" component={Account} />
            <Route exact path="/signin" component={RedirectPage} />

            {/* Recognitions list page */}
            <ProtectedRoute exact path="/" component={Recognitions} />

            <ProtectedRoute path="/search" component={Search} />

            { /* Catch some legacy routes and redirect them */ }
            <Route exact path="/meetings" render={() => <Redirect to="/" />} />

            <Route
              exact
              path="/meetings/:meetingId"
              render={(props) => (
                <Redirect
                  to={{
                    pathname: `/r/${props.match.params.meetingId}`,
                    search: props.location.search,
                  }}
                />
              )}
            />

            <Route
              exact
              path="/meetings/:meetingId/:shareKey"
              render={(props) => (
                <Redirect
                  to={{
                    pathname: `/r/${props.match.params.meetingId}/${props.match.params.shareKey}`,
                    search: props.location.search,
                  }}
                />
              )}
            />

            {/* Route to a regular, non-shared meeting. */}
            <ProtectedRoute
              exact
              path="/r/:meetingId"
              render={(props) => {
                const {
                  location,
                  history,
                  match,
                } = props;
                const key = `${location.pathname}${location.search}`;
                return (
                  <Meeting key={key} location={location} history={history} match={match} />
                );
              }}
            />

            {/* Route to a shared meeting. */}
            <Route
              exact
              path="/r/:meetingId/:shareKey"
              render={(props) => {
                const {
                  location,
                  history,
                  match,
                } = props;
                const key = `${location.pathname}${location.search}`;
                return (
                  <Meeting key={key} location={location} history={history} match={match} />
                );
              }}
            />

            { /* As a default, if it doesn't match a specific route, redirect to recognitions */ }
            <Route component={NoMatch} />
          </Switch>
        </main>
      </div>
    );
  }
}

App.propTypes = {
  connectedClearError: PropTypes.func.isRequired,
  connectedRefreshTokenFail: PropTypes.func.isRequired,
  connectedSetRmiAuth: PropTypes.func.isRequired,
  connectedSetUserAgent: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedRefreshTokenFail: refreshTokenFail,
  connectedSetRmiAuth: setRmiAuth,
  connectedSetUserAgent: setUserAgent,
}, dispatch);

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(App));
