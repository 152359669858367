import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Menu,
  Modal,
  Table,
  List,
} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';

import { Spinner } from 'reactstrap';

import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { errorType, historyType, meetingType } from '../types';

import CardActionModal from './cardActionModal';

import RemeetingUserFriendlyDate from '../utils/remeeting-user-friendly-date';
import ConsoleErrorMessage from '../utils/console-error-message';
import RefreshButton from '../utils/refresh-button';
import ShareLinkModal from '../utils/shareLinkModal';

import {
  deleteMeeting,
} from '../../modules/meeting';

import {
  clearError,
  listRecognitions,
  pageRecognitions,
  updateRecognitions,
  changeDisplayStatusFilter,
  RECOGNITIONS_PAGE_LIMIT,
} from '../../modules/recognitions';

import RecognitionTableItem, { Processing } from './recognitionsTableItem';
import SignupProgress from './signupProgress';
import SearchBar from '../search/searchBar';
import { buildURLQueryString, keyCodes } from '../../modules/utils';

class RecognitionTable extends Component {
  constructor() {
    super();

    let meetingsView;
    if (localStorage.getItem('meetingsView')) {
      meetingsView = localStorage.getItem('meetingsView');
    } else {
      meetingsView = 'grid';
    }

    this.state = {
      xmsFields: {
        ocr: false,
        transcript: true,
        title: true,
      },
      xmsSpeakers: [],
      statusFilter: undefined,
      dateFilterOpen: false,
      dateFilterBefore: undefined,
      firstLoad: true,
      meetingsView,
    };
  }

  componentDidMount() {
    const {
      connectedClearError,
      connectedListRecognitions,
      filters,
    } = this.props;
    connectedClearError();
    if (!isEmpty(filters)) {
      this.setState({
        statusFilter: filters.status || undefined,
        dateFilterBefore: filters.before ? new Date(filters.before) : undefined,
      });
    }
    this.setupHotkeyListener();
    connectedListRecognitions({ ...filters })
      .then(() => this.setState({ firstLoad: false }));
  }

  componentDidUpdate(prevProps) {
    const { connectedListRecognitions, filters } = this.props;
    const { filters: prevFilters } = prevProps;

    if (!isEqual(filters, prevFilters)) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        dateFilterBefore: filters.before ? new Date(filters.before) : undefined,
        statusFilter: filters.status || undefined,
      });
      /* eslint-enable react/no-did-update-set-state */
      connectedListRecognitions({ ...filters });
    }
  }

  componentWillUnmount() {
    this.teardownHotkeyListener();
  }

  setupHotkeyListener = () => {
    document.addEventListener('keydown', this.onKeyPress, false);
  }

  teardownHotkeyListener = () => {
    document.removeEventListener('keydown', this.onKeyPress, false);
  }

  onKeyPress = (e) => {
    const { connectedChangeDisplayStatusFilter, searchBarFocused } = this.props;

    const keyNum = e.keyCode || e.which;
    if (e.shiftKey && keyNum === keyCodes.FWD_SLASH
        && !searchBarFocused) {
      connectedChangeDisplayStatusFilter();
    }
  }

  settingsUpdateHandler = (payload) => {
    const { xmsFields, xmsSpeakers } = this.state;
    this.setState({
      xmsFields: { ...xmsFields, ...payload.fields },
      xmsSpeakers: payload.speakers ? payload.speakers : xmsSpeakers,
    });
  }

  deleteRecognition = (id) => {
    const {
      connectedDeleteMeeting,
      connectedListRecognitions,
      filters,
      recognitions,
      recognitionsLoaded,
    } = this.props;
    return connectedDeleteMeeting(id)
      .then(() => {
        /* Update the recognitions list to fill at least one page if possible. */
        if (!recognitionsLoaded && recognitions.length <= RECOGNITIONS_PAGE_LIMIT) {
          connectedListRecognitions({ ...filters });
        }
      });
  }

  loadMoreRecognitions = () => {
    const { connectedPageRecognitions } = this.props;
    const { statusFilter } = this.state;
    connectedPageRecognitions({ status: statusFilter });
  }

  handleViewChange = (e, { value }) => {
    localStorage.setItem('meetingsView', value);
    this.setState({ meetingsView: value });
  }

  renderActiveFilterTags = () => {
    const {
      displayStatusFilter,
      filters: { before },
    } = this.props;
    const {
      statusFilter,
    } = this.state;
    return (
      <div>
        {(statusFilter && displayStatusFilter)
          && (
            <Button
              className="ui filter tag button"
              circular
              onClick={(e) => {
                this.setState({ statusFilter: undefined });
                this.handleStatusFilterChange(e, { value: 'all' });
              }}
            >
              <Icon name="remove" />
              {statusFilter}
            </Button>
          )}
        {before
          && (
            <Button
              className="ui filter tag button"
              circular
              onClick={() => {
                this.setState({
                  dateFilterBefore: undefined,
                }, this.applyDateFilter);
              }}
            >
              <Icon name="remove" />
              {'On or before '}
              <RemeetingUserFriendlyDate
                date={new Date(before)}
                includeTime={false}
                capitalizeBeginning={false}
              />
            </Button>
          )}
      </div>
    );
  }

  /* First row of the page header
   *
   * Creates the title and upload button
   * */
  renderPageTitleAndPrimaryAction = () => {
    const {
      connectedUpdateRecognitions,
      displayStatusFilter,
      history,
      recognitions,
      filters,
      updateRecognitionsRequestOut,
      sessionError,
      listRecognitionsRequestOut,
    } = this.props;
    const {
      statusFilter,
      dateFilterBefore,
      xmsFields,
      xmsSpeakers,
      dateFilterOpen,
      meetingsView,
    } = this.state;
    return (
      <div>
        <Menu secondary borderless className="re is-header-row">

          <SearchBar
            fields={xmsFields}
            history={history}
            settingsUpdateHandler={this.settingsUpdateHandler}
            speakers={xmsSpeakers}
          />

          {displayStatusFilter
            && (
              <div
                className="ui filters dropdown"
              >
                <Dropdown
                  text="Status Filter"
                >
                  <Dropdown.Menu>
                    <Menu.Item
                      value={undefined}
                      onClick={this.handleStatusFilterChange}
                      active={statusFilter === undefined}
                    >
                      all
                    </Menu.Item>
                    <Menu.Item
                      value="waiting"
                      onClick={this.handleStatusFilterChange}
                      active={statusFilter === 'waiting'}
                    >
                      waiting
                    </Menu.Item>
                    <Menu.Item
                      value="processing"
                      onClick={this.handleStatusFilterChange}
                      active={statusFilter === 'processing'}
                    >
                      processing
                    </Menu.Item>
                    <Menu.Item
                      value="completed"
                      onClick={this.handleStatusFilterChange}
                      active={statusFilter === 'completed'}
                    >
                      completed
                    </Menu.Item>
                    <Menu.Item
                      value="failed"
                      onClick={this.handleStatusFilterChange}
                      active={statusFilter === 'failed'}
                    >
                      failed
                    </Menu.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          <div
            className="ui filters dropdown"
          >
            <Dropdown
              text="Date Filter"
              onClick={() => { this.setState({ dateFilterOpen: true }); }}
              open={dateFilterOpen}
              onBlur={() => { this.setState({ dateFilterOpen: false }); }}
            >
              <Dropdown.Menu
                className="ui date secondary menu"
              >
                Show recordings on or before
                <div
                  style={{ padding: '1em 0 1em 0' }}
                >
                  <SemanticDatepicker
                    keepOpenOnClear
                    value={dateFilterBefore}
                    onChange={(e, { value }) => { this.setState({ dateFilterBefore: value }); }}
                  />
                </div>
                <Button
                  color="blue"
                  onClick={(e) => { e.stopPropagation(); this.applyDateFilter(); }}
                >
                  Apply
                </Button>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {this.renderActiveFilterTags()}

          <Menu.Menu position="right">
            {(meetingsView !== 'grid')
              ? (
                <Menu.Item
                  value="grid"
                  onClick={this.handleViewChange}
                >
                  <Icon name="th" />
                </Menu.Item>
              )
              : (
                <Menu.Item
                  value="table"
                  onClick={this.handleViewChange}
                >
                  <Icon name="align justify" />
                </Menu.Item>
              )}
            <RefreshButton
              collection={recognitions}
              callback={() => connectedUpdateRecognitions({
                status: (filters ? filters.status : undefined),
                before: (filters ? filters.before : undefined),
              })}
              delay={10000}
              filter={Processing}
              loading={updateRecognitionsRequestOut}
              halt={!!sessionError}
              disabled={updateRecognitionsRequestOut || listRecognitionsRequestOut}
            />
          </Menu.Menu>
        </Menu>
      </div>
    );
  }

  /**
   * Navigates to the meeting we want, and sets some meeting-relevant state
   * for the meeting's detail page to use.
   */
  navigateToMeeting = (history, recognition) => {
    history.push(`/${recognition.id}`);
  }

  handleStatusFilterChange = (e, { value }) => {
    const { filters, history } = this.props;
    const newStatus = value === 'all' ? undefined : value;
    const qsConfig = { ...filters };
    if (newStatus) {
      qsConfig.status = newStatus;
    } else {
      delete qsConfig.status;
    }
    let qs = buildURLQueryString(qsConfig);
    if (qs) qs = `?${qs}`;
    history.push(`/${qs}`);
  }

  applyDateFilter = () => {
    const { filters, history } = this.props;
    const { dateFilterBefore } = this.state;
    const qsConfig = { ...filters };
    let newDfb;
    if (dateFilterBefore) {
      /* Add 1 to the day to get the next day's
         midnight and then subtract 1 ms for "inclusive"
         date filtering. */
      const tmp = new Date(dateFilterBefore);
      newDfb = new Date(tmp.setDate(tmp.getDate() + 1) - 1).toISOString();
    }
    if (newDfb) {
      qsConfig.before = newDfb;
    } else {
      delete qsConfig.before;
    }
    let qs = buildURLQueryString(qsConfig);
    if (qs) qs = `?${qs}`;
    history.push(`/${qs}`);
    this.setState({ dateFilterOpen: false });
  }

  /* Table Rows
   *
   * Iterates through a list of recognitions and formats
   * table rows with the necessary information
   *
   * */
  renderTableRows = (history) => {
    const { recognitions } = this.props;
    const { statusFilter, meetingsView } = this.state;
    return recognitions.map((recognition) => (
      <RecognitionTableItem
        key={recognition.id}
        recognition={recognition}
        meetingsView={meetingsView}
        history={history}
        statusFilter={statusFilter}
      />
    )).reverse();
  }

  /* Table
   *
   * Formats the table along with the column headers
   * */
  renderTable = () => {
    const {
      listRecognitionsRequestOut,
      pageRecognitionsRequestOut,
      recognitionsLoaded,
    } = this.props;

    const { meetingsView } = this.state;

    if (meetingsView === 'grid') {
      return (
        <Route render={({ history }) => (
          <div>
            { this.renderTableRows(history) }
            {
              !listRecognitionsRequestOut && !recognitionsLoaded && (
                <List horizontal>
                  <div className="recognitions-item">
                    <div
                      id="load-more-recognitions-card"
                      className="ui meetings center aligned compact segment"
                      onClick={() => { this.loadMoreRecognitions(); }}
                      onKeyDown={(event) => event.code === 'Enter' && this.loadMoreRecognitions()}
                      role="button"
                      tabIndex={0}
                    >
                      <div id="load-more-recognitions-card-content">
                        <div id="load-more-text">
                          {
                            pageRecognitionsRequestOut
                              ? <Spinner color="#3e98c7" />
                              : (
                                <>
                                  <div id="load-more-recordings-icon">
                                    <FontAwesomeIcon icon={faEllipsisH} color="white" />
                                  </div>
                                  Load more recordings
                                </>
                              )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </List>
              )
            }
          </div>
        )}
        />
      );
    }

    return (
      <Route render={({ history }) => (
        <Table
          basic="very"
          selectable
          className="re is-table recognitions-table"
          style={{ tableLayout: 'fixed' }}
        >
          <Table.Header>
            <Table.Row verticalAlign="middle">
              <Table.HeaderCell style={{ width: '192px' }}>
                Recording
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: '25%' }} />
              <Table.HeaderCell>
                Date
              </Table.HeaderCell>
              <Table.HeaderCell>
                Participants
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: '25%' }}>
                Topics
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { this.renderTableRows(history) }
          </Table.Body>
        </Table>
      )}
      />
    );
  }

  renderEmptyStateFromFiltering = () => (
    <div className="re is-flexGrow-1 is-flex is-justifyContent-center is-alignItems-center">
      <div className="re is-textAlign-center is-padding-bottom-16px is-padding-top-16px">
        <Header as="h2">
          No recordings found
        </Header>
        <p>
          Looks like no recordings fit your criteria.
        </p>
      </div>
    </div>
  )

  renderErrorModal = () => {
    const { connectedClearError, error } = this.props;
    return (
      <Modal
        onClose={connectedClearError}
        open={!!error}
      >
        <Modal.Header>Oops!</Modal.Header>
        <Modal.Content>
          <ConsoleErrorMessage error={error} />
        </Modal.Content>
      </Modal>
    );
  }

  /* Primary render function */
  render() {
    const {
      listRecognitionsRequestOut,
      pageRecognitionsRequestOut,
      recognitions,
      recognitionsLoaded,
    } = this.props;

    const {
      firstLoad,
      statusFilter,
      dateFilterBefore,
      meetingsView,
    } = this.state;

    /* Check to see there are truly no recognitions */
    const dashboardIsEmpty = recognitions.length === 0 && !statusFilter && !dateFilterBefore;
    const isOverFiltered = (
      !firstLoad
      && recognitions.length === 0
      && (
        statusFilter
        || dateFilterBefore
      )
    );
    return (
      <div className="re is-main is-full-height is-flex is-flexDirection-column">
        <div className="recognitions" style={{ margin: '5em 1em 0 1.5em' }}>
          <div className="sticky-header">
            { !dashboardIsEmpty && this.renderPageTitleAndPrimaryAction() }
          </div>
          { this.renderErrorModal() }
          {
            dashboardIsEmpty && !listRecognitionsRequestOut
              ? <SignupProgress />
              : this.renderTable()
          }
          { isOverFiltered && this.renderEmptyStateFromFiltering() }
          { !dashboardIsEmpty && !recognitionsLoaded && (meetingsView !== 'grid') && (
          <Button
            fluid
            loading={pageRecognitionsRequestOut}
            onClick={() => { this.loadMoreRecognitions(); }}
          >
            Load more recordings
          </Button>
          ) }
          <CardActionModal deleteRecognition={this.deleteRecognition} />
          <ShareLinkModal dropdownMode />
        </div>
      </div>
    );
  }
}

RecognitionTable.propTypes = {
  connectedClearError: PropTypes.func.isRequired,
  displayStatusFilter: PropTypes.bool.isRequired,
  error: errorType,
  filters: PropTypes.shape({
    before: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  searchBarFocused: PropTypes.bool.isRequired,
  connectedDeleteMeeting: PropTypes.func.isRequired,
  connectedListRecognitions: PropTypes.func.isRequired,
  connectedPageRecognitions: PropTypes.func.isRequired,
  listRecognitionsRequestOut: PropTypes.bool.isRequired,
  pageRecognitionsRequestOut: PropTypes.bool.isRequired,
  updateRecognitionsRequestOut: PropTypes.bool.isRequired,
  recognitions: PropTypes.arrayOf(meetingType).isRequired,
  recognitionsLoaded: PropTypes.bool.isRequired,
  connectedUpdateRecognitions: PropTypes.func.isRequired,
  connectedChangeDisplayStatusFilter: PropTypes.func.isRequired,
  history: historyType.isRequired,
  sessionError: errorType,
};

RecognitionTable.defaultProps = {
  error: undefined,
  sessionError: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const { before, status } = queryString.parse(location.search);
  const filters = {};
  if (before) filters.before = before;
  if (status) filters.status = status;
  return {
    error: state.recognitions.error,
    listRecognitionsRequestOut: state.recognitions.listRecognitionsRequestOut,
    pageRecognitionsRequestOut: state.recognitions.pageRecognitionsRequestOut,
    updateRecognitionsRequestOut: state.recognitions.updateRecognitionsRequestOut,
    recognitions: state.recognitions.recognitions,
    recognitionsLoaded: state.recognitions.recognitionsLoaded,
    searchBarFocused: state.search.searchBarFocused,
    filters,
    displayStatusFilter: state.recognitions.displayStatusFilter,
    sessionError: state.session.error,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedDeleteMeeting: deleteMeeting,
  connectedListRecognitions: listRecognitions,
  connectedPageRecognitions: pageRecognitions,
  connectedUpdateRecognitions: updateRecognitions,
  connectedChangeDisplayStatusFilter: changeDisplayStatusFilter,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecognitionTable);
