import {
  func,
  node,
  shape,
  number,
  string,
  arrayOf,
  instanceOf,
  oneOfType,
  objectOf,
  bool,
} from 'prop-types';

export const accountInfoType = shape({
  address_line1: string,
  address_line2: string,
  city: string,
  country: string,
  created: string,
  email: string,
  memo: string,
  name: string,
  organization: string,
  phone_number: string,
  postal_code: string,
  state_province: string,
  updated: string,
});

export const accountSettingsType = shape({
  exec_google_stt: bool,
});

export const cardType = shape({
  number: string.isRequired,
  type: string.isRequired,
  expiration_month: number.isRequired,
  expiration_year: number.isRequired,
  first_name: string.isRequired,
  last_name: string.isRequired,
  address: string,
  address_2: string,
  city: string,
  state: string,
  postal_code: string,
  country: string,
});

export const daemonInfoType = shape({
  hostname: string,
  state: string,
  updated: string,
  version: string,
  watched_folder: string,
});

export const errorType = shape({
  name: string,
  statusCode: number,
  message: string,
  error: shape({
    code: number,
    code_description: string,
    error: string,
  }),
  options: shape({}),
  response: shape({}),
});

export const hitType = shape({
  _score: number,
  highlight_transcript: arrayOf(string),
  highlight_concats: arrayOf(string),
  id: string.isRequired,
  ocr_word_counts: shape({}),
  recording_date_time: string.isRequired,
  speakers: arrayOf(string),
  title: string.isRequired,
});

export const infoType = shape({
  created: string.isRequired,
  email: string.isRequired,
  updated: string,
  name: string,
  organization: string,
  address_line1: string,
  address_line2: string,
  city: string,
  state_province: string,
  postal_code: string,
  country: string,
  phone_number: string,
  memo: string,
});

export const keyType = shape({
  created: string.isRequired,
  id: string.isRequired,
  updated: string.isRequired,
  name: string,
  expires: string,
  ttl: number,
  secret: string,
});

/*
 * React router location type.
 * See https://reactrouter.com/web/api/location
 */
export const locationType = shape({
  hash: string.isRequired,
  key: string,
  pathname: string.isRequired,
  search: string,
  state: shape({}),
});

/*
 * React router history object.
 * See https://reactrouter.com/web/api/history
 */
export const historyType = shape({
  length: number,
  action: string,
  location: locationType,
  push: func,
  replace: func,
  go: func,
  goBack: func,
  goForward: func,
  block: func,
});

export const planType = shape({
  id: string.isRequired,
  name: string.isRequired,
  period: string.isRequired,
  price: number.isRequired,
  usage: arrayOf(shape({
    id: string,
    name: string,
    pricing: shape({
      price: number,
      type: string,
    }),
    type: string,
    unit: string,
    value: number,
  })).isRequired,
  balance: number.isRequired,
  due: string.isRequired,
  period_from: string,
  period_to: string,
  state: string.isRequired,
});

export const meetingType = shape({
  created: string,
  id: string,
  media_url: string,
  status: string,
  updated: string,
});

export const intervalType = arrayOf(number);

export const catsAltType = shape({
  bias: shape({
    am: number,
    lm: number,
  }),
  phrase: string,
});

export const altsArrayType = arrayOf(catsAltType);

export const catsItemType = shape({
  alternatives: altsArrayType,
  interval: intervalType,
});

export const spanIdentifierType = shape({
  utterance: string,
  catsIndex: number,
});

export const utteranceFlatCatsType = shape({
  utterance: string,
  cats: string,
});

export const matchPositionType = shape({
  word: number,
  alt: number,
  span: number,
});

export const matchType = shape({
  cost: number,
  positions: arrayOf(matchPositionType),
});

export const segmentType = shape({
  confidence: number,
  interval: intervalType,
  speaker: number,
  transcript: string,
  transcript_formatted: string,
  transcript_formatted_timestamps: arrayOf(number),
  utterance: string,
});

export const speakerInfoType = shape({
  label: string,
  hue: arrayOf(node),
});

export const currentMatchType = shape({
  match: matchType,
  utterance: string,
  segmentIndex: number,
  matchIndex: number,
});

export const refType = oneOfType([
  func,
  shape({
    current: oneOfType([
      node,
      instanceOf(Element),
    ]),
  }),
]);

export const richifiedTextType = shape({
  original: string,
  transcript: string,
});

export const richifiedSegmentsType = objectOf(richifiedTextType);

export const customizationWordType = shape({
  frequency: number,
  pronunciations: arrayOf(string),
});

export const lexiconInfoType = shape({
  phones: arrayOf(string),
  pronunciations: number,
  pronunciations_unique: number,
  size_mib: number,
  words: number,
});
