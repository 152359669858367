import React from 'react';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

class FakeProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.fireTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  fireTimer() {
    const { speed } = this.props;
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      const { percentage } = this.state;
      this.setState({
        percentage: Math.min(100, percentage + (Math.random() * 0.4 + 0.8) * speed),
      });
      this.fireTimer();
    }, Math.random() * 600 + 700);
  }

  render() {
    const { width, isFull } = this.props;
    const { percentage } = this.state;
    return (
      <LinearProgress
        variant="determinate"
        value={isFull ? 100 : percentage}
        style={{ width, height: 8, borderRadius: 4 }}
      />
    );
  }
}

FakeProgress.propTypes = {
  width: PropTypes.number,
  speed: PropTypes.number,
  isFull: PropTypes.bool,
};

FakeProgress.defaultProps = {
  width: 500,
  speed: 20,
  isFull: false,
};

export default FakeProgress;
