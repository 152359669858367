export const FLUSH_CUSTOMIZATION_STATE = 'CUSTOMIZE::FLUSH_CUSTOMIZATION_STATE';
export const LEXICAL_LOOKUP_REQUEST_SENT = 'CUSTOMIZE::LEXICAL_LOOKUP_REQUEST_SENT';
export const LEXICAL_LOOKUP_REQUEST_SUCCESS = 'CUSTOMIZE::LEXICAL_LOOKUP_REQUEST_SUCCESS';
export const LEXICAL_LOOKUP_REQUEST_FAIL = 'CUSTOMIZE::LEXICAL_LOOKUP_REQUEST_FAIL';
export const ADD_WORD = 'CUSTOMIZE::ADD_WORD';
export const REMOVE_WORD = 'CUSTOMIZE::REMOVE_WORD';
export const SET_WORD_FREQUENCY = 'CUSTOMIZE::SET_WORD_FREQUENCY';
export const PRON_LOOKUP_REQUEST_SENT = 'CUSTOMIZE::PRON_LOOKUP_REQUEST_SENT';
export const PRON_LOOKUP_REQUEST_SUCCESS = 'CUSTOMIZE::PRON_LOOKUP_REQUEST_SUCCESS';
export const PRON_LOOKUP_REQUEST_FAIL = 'CUSTOMIZE::PRON_LOOKUP_REQUEST_FAIL';
export const ADD_PRONS_TO_WORD = 'CUSTOMIZE::ADD_PRONS_TO_WORD';
export const REMOVE_PRON_FROM_WORD = 'CUSTOMIZE::REMOVE_PRON_FROM_WORD';
export const SET_WORD_CHANGED = 'CUSTOMIZE::SET_WORD_CHANGED';
export const PRON_AUDIO_REQUEST_SENT = 'CUSTOMIZE::PRON_AUDIO_REQUEST_SENT';
export const PRON_AUDIO_REQUEST_SUCCESS = 'CUSTOMIZE::PRON_AUDIO_REQUEST_SUCCESS';
export const PRON_AUDIO_REQUEST_FAIL = 'CUSTOMIZE::PRON_AUDIO_REQUEST_FAIL';
export const LM_LOOKUP_REQUEST_SENT = 'CUSTOMIZE::UNIGRAM_LM_LOOKUP_REQUEST_SENT';
export const LM_LOOKUP_REQUEST_SUCCESS = 'CUSTOMIZE::UNIGRAM_LM_LOOKUP_REQUEST_SUCCESS';
export const LM_LOOKUP_REQUEST_FAIL = 'CUSTOMIZE::UNIGRAM_LM_LOOKUP_REQUEST_FAIL';
export const GET_LEXICON_INFO_REQUEST_SENT = 'CUSTOMIZE::GET_LEXICON_INFO_REQUEST_SENT';
export const GET_LEXICON_INFO_REQUEST_SUCCESS = 'CUSTOMIZE::GET_LEXICON_INFO_REQUEST_SUCCESS';
export const GET_LEXICON_INFO_REQUEST_FAIL = 'CUSTOMIZE::GET_LEXICON_INFO_REQUEST_FAIL';
export const SET_ORIGINAL_WORD_INFO = 'CUSTOMIZE::SET_ORIGINAL_WORD_INFO';
export const SET_WORD_TO_DELETE = 'CUSTOMIZE::SET_WORD_TO_DELETE';

/* NOTE: In the future, these will come from job or account metadata. */
export const LEX_LANGUAGE = 'en-US';

const initialState = {
  words: {},
  originalWords: {},
  lexicalLookupRequestsOut: {},
  lexicalLookupRequestsError: {},
  lexicalLookupResults: {},
  pronLookupRequestsOut: {},
  pronLookupRequestsError: {},
  pronLookupResults: {},
  pronAudioRequestsOut: {},
  pronAudioRequestsError: {},
  pronAudioResults: {},
  lmLookupRequestsOut: {},
  lmLookupRequestsError: {},
  lmLookupResults: {},
  lexiconInfoRequestOut: false,
  lexiconInfoRequestError: undefined,
  lexiconInfo: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLUSH_CUSTOMIZATION_STATE:
      return initialState;
    case LEXICAL_LOOKUP_REQUEST_SENT:
      return {
        ...state,
        lexicalLookupRequestsOut: {
          ...state.lexicalLookupRequestsOut,
          [action.word]: true,
        },
        lexicalLookupRequestsError: {
          ...state.lexicalLookupRequestsError,
          [action.word]: undefined,
        },
      };
    case LEXICAL_LOOKUP_REQUEST_SUCCESS:
      return {
        ...state,
        lexicalLookupRequestsOut: {
          ...state.lexicalLookupRequestsOut,
          [action.word]: false,
        },
        lexicalLookupRequestsError: {
          ...state.lexicalLookupRequestsError,
          [action.word]: undefined,
        },
        lexicalLookupResults: {
          ...state.lexicalLookupResults,
          [action.word]: action.results,
        },
      };
    case LEXICAL_LOOKUP_REQUEST_FAIL:
      return {
        ...state,
        lexicalLookupRequestsOut: {
          ...state.lexicalLookupRequestsOut,
          [action.word]: false,
        },
        lexicalLookupRequestsError: {
          ...state.lexicalLookupRequestsError,
          [action.word]: action.error,
        },
      };
    case REMOVE_WORD: {
      // eslint-disable-next-line no-case-declarations
      const words = { ...state.words };
      delete words[action.word];
      return {
        ...state,
        words,
      };
    }
    case ADD_WORD:
      return {
        ...state,
        words: {
          ...state.words,
          [action.word]: {},
        },
      };
    case SET_WORD_FREQUENCY:
      return {
        ...state,
        words: {
          ...state.words,
          [action.word]: {
            ...state.words[action.word],
            frequency: action.frequency,
          },
        },
      };
    case SET_WORD_CHANGED:
      return {
        ...state,
        words: {
          ...state.words,
          [action.word]: {
            ...state.words[action.word],
            changed: action.changed,
          },
        },
      };
    case PRON_LOOKUP_REQUEST_SENT:
      return {
        ...state,
        pronLookupRequestsOut: {
          ...state.pronLookupRequestsOut,
          [action.phrase]: true,
        },
        pronLookupRequestsError: {
          ...state.pronLookupRequestsError,
          [action.phrase]: undefined,
        },
      };
    case PRON_LOOKUP_REQUEST_FAIL: {
      return {
        ...state,
        pronLookupRequestsError: {
          ...state.pronLookupRequestsError,
          [action.phrase]: action.error,
        },
        pronLookupRequestsOut: {
          ...state.pronLookupRequestsOut,
          [action.phrase]: false,
        },
      };
    }
    case PRON_LOOKUP_REQUEST_SUCCESS: {
      return {
        ...state,
        pronLookupRequestsOut: {
          ...state.pronLookupRequestsOut,
          [action.phrase]: false,
        },
        pronLookupResults: {
          ...state.pronLookupResults,
          [action.phrase]: action.pronunciations,
        },
        pronLookupRequestsError: {
          ...state.pronLookupRequestsError,
          [action.phrase]: undefined,
        },
      };
    }
    case ADD_PRONS_TO_WORD: {
      let newProns;
      if (!state.words[action.word].pronunciations) {
        newProns = [];
      } else {
        newProns = [...state.words[action.word].pronunciations];
      }
      for (let i = 0; i < action.prons.length; i += 1) {
        if (!newProns.includes(action.prons[i])) {
          newProns.push(action.prons[i]);
        }
      }
      return {
        ...state,
        words: {
          ...state.words,
          [action.word]: {
            ...state.words[action.word],
            pronunciations: newProns,
          },
        },
      };
    }
    case REMOVE_PRON_FROM_WORD: {
      const newProns = [...state.words[action.word].pronunciations];
      const pronIndex = newProns.indexOf(action.pron);
      if (pronIndex >= 0) {
        newProns.splice(pronIndex, 1);
      }
      return {
        ...state,
        words: {
          ...state.words,
          [action.word]: {
            ...state.words[action.word],
            pronunciations: newProns,
          },
        },
      };
    }
    case PRON_AUDIO_REQUEST_SENT: {
      return {
        ...state,
        pronAudioRequestsOut: {
          ...state.pronAudioRequestsOut,
          [action.pron]: true,
        },
        pronAudioRequestError: {
          ...state.pronAudioRequestsError,
          [action.pron]: undefined,
        },
      };
    }
    case PRON_AUDIO_REQUEST_SUCCESS: {
      return {
        ...state,
        pronAudioRequestError: {
          ...state.pronAudioRequestsError,
          [action.pron]: undefined,
        },
        pronAudioRequestsOut: {
          ...state.pronAudioRequestsOut,
          [action.pron]: false,
        },
        pronAudioResults: {
          ...state.pronAudioResults,
          [action.pron]: action.audio,
        },
      };
    }
    case PRON_AUDIO_REQUEST_FAIL: {
      return {
        ...state,
        pronAudioRequestsOut: {
          ...state.pronAudioRequestsOut,
          [action.pron]: false,
        },
        pronAudioRequestsError: {
          ...state.pronAudioRequestsError,
          [action.pron]: action.error,
        },
      };
    }
    case LM_LOOKUP_REQUEST_SENT: {
      return {
        ...state,
        lmLookupRequestsError: {
          ...state.lmLookupRequestsError,
          [action.phrase]: undefined,
        },
        lmLookupRequestsOut: {
          ...state.lmLookupRequestsOut,
          [action.phrase]: true,
        },
      };
    }
    case LM_LOOKUP_REQUEST_SUCCESS: {
      return {
        ...state,
        lmLookupRequestsError: {
          ...state.lmLookupRequestsError,
          [action.phrase]: undefined,
        },
        lmLookupRequestsOut: {
          ...state.lmLookupRequestsOut,
          [action.phrase]: false,
        },
        lmLookupResults: {
          ...state.lmLookupResults,
          [action.phrase]: action.result,
        },
      };
    }
    case LM_LOOKUP_REQUEST_FAIL: {
      return {
        ...state,
        lmLookupRequestsError: {
          ...state.lmLookupRequestsError,
          [action.phrase]: action.error,
        },
        lmLookupRequestsOut: {
          ...state.lmLookupRequestsOut,
          [action.phrase]: false,
        },
      };
    }
    case GET_LEXICON_INFO_REQUEST_SENT: {
      return {
        ...state,
        lexiconInfoRequestError: undefined,
        lexiconInfoRequestOut: true,
      };
    }
    case GET_LEXICON_INFO_REQUEST_FAIL: {
      return {
        ...state,
        lexiconInfoRequestError: action.error,
        lexiconInfoRequestOut: false,
      };
    }
    case GET_LEXICON_INFO_REQUEST_SUCCESS: {
      return {
        ...state,
        lexiconInfoRequestOut: false,
        lexiconInfoRequestError: undefined,
        lexiconInfo: action.result,
      };
    }
    case SET_ORIGINAL_WORD_INFO: {
      return {
        ...state,
        originalWords: {
          ...state.originalWords,
          [action.word]: action.info,
        },
      };
    }
    case SET_WORD_TO_DELETE:
      return {
        ...state,
        words: {
          ...state.words,
          [action.word]: {
            ...state.words[action.word],
            toDelete: action.toDelete,
          },
        },
      };
    default:
      return { ...state };
  }
};

export const flushCustomizationState = () => ({
  type: FLUSH_CUSTOMIZATION_STATE,
});

export const addWordToCustomize = (word) => ({
  type: ADD_WORD,
  word,
});

export const removeWordFromCustomize = (word) => ({
  type: REMOVE_WORD,
  word,
});

export const setWordFrequency = (word, frequency) => ({
  type: SET_WORD_FREQUENCY,
  word,
  frequency,
});

export const lookupWordInLexicon = (word) => async (dispatch, getState) => {
  const { rmi, meeting: { meeting } } = getState();

  const lexHash = (meeting.metadata.asr.asr_model || meeting.metadata.asr.model).md5.lexicon;

  dispatch({
    type: LEXICAL_LOOKUP_REQUEST_SENT,
    word,
  });

  try {
    const lookupResult = await rmi.lookup.lexicon({
      hash: lexHash,
      word,
    });
    dispatch({
      type: LEXICAL_LOOKUP_REQUEST_SUCCESS,
      word,
      result: lookupResult,
    });
    return lookupResult;
  } catch (error) {
    /* This error must've been caused by an invalid hash */
    dispatch({
      type: LEXICAL_LOOKUP_REQUEST_FAIL,
      word,
      error,
    });
    throw error;
  }
};

export const lookupPronunciation = (phrase) => async (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({
    type: PRON_LOOKUP_REQUEST_SENT,
    phrase,
  });

  try {
    const { pronunciations } = await rmi.lookup.pronunciation({ phrase });
    dispatch({
      type: PRON_LOOKUP_REQUEST_SUCCESS,
      phrase,
      pronunciations,
    });
    return pronunciations;
  } catch (error) {
    dispatch({
      type: PRON_LOOKUP_REQUEST_FAIL,
      phrase,
      error,
    });
    throw error;
  }
};

export const addPronunciationsToWord = (word, prons) => ({
  type: ADD_PRONS_TO_WORD,
  word,
  prons,
});

export const removePronunciationFromWord = (word, pron) => ({
  type: REMOVE_PRON_FROM_WORD,
  word,
  pron,
});

export const setWordChanged = (word, changed = true) => ({
  type: SET_WORD_CHANGED,
  word,
  changed,
});

export const getPronunciationAudio = (pron) => async (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({
    type: PRON_AUDIO_REQUEST_SENT,
    pron,
  });

  try {
    const audio = await rmi.lexicons.pronunciationAudio(pron, LEX_LANGUAGE);
    dispatch({
      type: PRON_AUDIO_REQUEST_SUCCESS,
      pron,
      /* Create the Audio player itself, so we don't have to make multiple requests to Polly */
      audio: new Audio(audio),
    });
    return new Audio(audio);
  } catch (error) {
    dispatch({
      type: PRON_AUDIO_REQUEST_FAIL,
      pron,
      error,
    });
    throw error;
  }
};

/* For one word, this represents the unigram probability of that word.
   For a phrase, this represents the joint probability of all words. */
export const getLMProbability = (phrase) => async (dispatch, getState) => {
  const { rmi, meeting: { meeting } } = getState();

  const lmHash = (meeting.metadata.asr.asr_model || meeting.metadata.asr.model).md5.lm;

  dispatch({
    type: LM_LOOKUP_REQUEST_SENT,
    phrase,
  });

  try {
    const result = await rmi.lookup.lm({
      hash: lmHash,
      phrase,
    });
    dispatch({
      type: LM_LOOKUP_REQUEST_SUCCESS,
      phrase,
      result,
    });
    return result;
  } catch (error) {
    dispatch({
      type: LM_LOOKUP_REQUEST_FAIL,
      phrase,
      error,
    });
    throw error;
  }
};

/* Retrieve the full info of the lexicon. */
export const getLexiconInfo = (hash) => async (dispatch, getState) => {
  const { rmi } = getState();

  dispatch({
    type: GET_LEXICON_INFO_REQUEST_SENT,
  });

  try {
    const result = await rmi.lookup.lexicon({ hash });
    dispatch({
      type: GET_LEXICON_INFO_REQUEST_SUCCESS,
      result,
    });
    return result;
  } catch (error) {
    dispatch({
      type: GET_LEXICON_INFO_REQUEST_FAIL,
      error,
    });
    throw error;
  }
};

export const setOriginalWordInfo = (word, info) => ({
  type: SET_ORIGINAL_WORD_INFO,
  word,
  info,
});

export const setWordToBeDeleted = (word, toDelete = true) => ({
  type: SET_WORD_TO_DELETE,
  word,
  toDelete,
});
