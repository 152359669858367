import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { Header, Message, Segment } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { getAccountSettings, setAccountSetting } from '../../modules/account';

class VendorSettings extends Component {
  componentDidMount() {
    const { connectedGetAccountSettings } = this.props;
    connectedGetAccountSettings();
  }

  isLoading = () => {
    const { getAccountSettingsRequestOut, getCardRequestOut } = this.props;
    return getAccountSettingsRequestOut || getCardRequestOut;
  }

  toggleChecked = () => {
    const { connectedsetAccountSetting, checked, hasPaymentMethod } = this.props;
    if (hasPaymentMethod || checked) {
      connectedsetAccountSetting('exec_google_stt', !checked);
    }
  }

  renderMessage = () => (
    <Message>
      <Message.Header content="No payment method found" />
      <Message.Content>
        <br />
        Please add a payment method to use Google Speech-to-Text.
      </Message.Content>
    </Message>
  );

  renderToggleSetting = () => {
    const { checked, hasPaymentMethod } = this.props;
    return (
      <div
        className="re is-padding-top-16px"
        style={{ opacity: hasPaymentMethod || checked ? 'unset' : 0.3 }}
      >
        <p>
          All recordings will be transcribed with the
          {' '}
          {' '}
          <a href="https://mod9.io" target="_blank" rel="noopener noreferrer">
            Mod9 ASR Engine
          </a>
          , enabling advanced functionality that is deployed on-premise
          for cost-effectiveness and data privacy.
        </p>
        <p>
          Recordings can also be sent to Google Speech-to-Text for an alternative transcript.
          This will be charged at-cost to your Remeeting account, per
          {' '}
          {' '}
          <a href="https://cloud.google.com/speech-to-text/pricing" target="_blank" rel="noopener noreferrer">
            Google STT pricing
          </a>
          .
        </p>
        <br />
        <div
          className={
            `re is-flex is-flex-align-center is-flex-justify-space-between
            ${hasPaymentMethod || checked ? 'vendor-toggle-container' : ''}`
          }
          role="button"
          tabIndex="0"
          onKeyPress={this.toggleChecked}
          onClick={this.toggleChecked}
        >
          <div
            style={hasPaymentMethod || checked ? {
              color: '#586069',
              paddingLeft: '4px',
            }
              : {}}
          >
            <span>Send all new recordings to Google for alternative transcripts</span>
          </div>
          <div
            style={{
              paddingLeft: '16px',
            }}
          >
            <Switch
              color="primary"
              disabled={!hasPaymentMethod && !checked}
              onChange={this.toggleChecked}
              checked={checked}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { checked, hasPaymentMethod } = this.props;
    return (
      <Segment loading={this.isLoading()}>
        <div className="re is-border-bottom is-padding-bottom-16px is-flex-aling-center">
          <Header as="h2" className="re is-header-item">
            Transcription
          </Header>
        </div>
        {
          !hasPaymentMethod && !checked
            && this.renderMessage()
        }
        { this.renderToggleSetting() }
      </Segment>
    );
  }
}

VendorSettings.propTypes = {
  checked: PropTypes.bool,
  hasPaymentMethod: PropTypes.bool,
  connectedGetAccountSettings: PropTypes.func.isRequired,
  connectedsetAccountSetting: PropTypes.func.isRequired,
  getAccountSettingsRequestOut: PropTypes.bool.isRequired,
  getCardRequestOut: PropTypes.bool.isRequired,
};

VendorSettings.defaultProps = {
  checked: false,
  hasPaymentMethod: false,
};

const mapStateToProps = (state) => ({
  checked: state.account.accountSettings.exec_google_stt || false,
  hasPaymentMethod: !isEmpty(state.account.card || {}),
  getAccountSettingsRequestOut: state.account.getAccountSettingsRequestOut,
  getCardRequestOut: state.account.getCardRequestOut,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedGetAccountSettings: getAccountSettings,
  connectedsetAccountSetting: setAccountSetting,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorSettings);
