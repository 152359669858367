import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { refType } from '../types';

class FindInput extends Component {
  componentDidUpdate(prevProps) {
    const { mode: newMode, value: newValue, findBarRef } = this.props;
    if (newMode !== prevProps.mode) {
      this.onChange();
    }
    if (this.getValue() !== newValue) {
      findBarRef.current.innerHTML = newValue;
      this.onChange();
    }
  }

  /* Shout out to https://codepen.io/brianmearns/pen/YVjZWw

     The purpose of this function is to take the DOM structure
     of the #find-bar element, and translate that into an array of objects
     which we call "words". A word can either be an actual word typed into
     the find bar, or the vertical separators between the words that we add
     in. We want DOM text nodes to alternate with vertical bar nodes.

     If we are not in "words" mode, this code is still valid... we just won't
     see any vertical bar nodes */
  getWords = (ref) => {
    if (!ref) return [];

    const words = [];
    Array.from(ref.childNodes).forEach((node) => {
      const val = node.nodeValue;

      switch (node.nodeType) {
        case Node.TEXT_NODE:
          words.push({
            word: val.replace(/\n+/g, '').replace(/\s+/g, ' '),
            node,
          });
          break;
        case Node.ELEMENT_NODE:
          if (node.className === 'find-bar-placeholder') {
            words.push({ word: '', node });
          } else if (node.className === 'find-word-separator') {
            words.push({ word: ' ', node });
            if (node.childNodes.length && node.childNodes[0].nodeValue.trim()) {
              words.push({ word: node.childNodes[0].nodeValue.trim() });
            }
          } else {
            words.splice(
              words.length, 0,
              ...this.getWords(node),
            );
          }
          break;
        default:
          throw new Error('Unexpected node type');
      }
    });

    /* If there are multiple text node words in a row, we can stick them together
       into one word */
    for (let i = 0; i < words.length; i += 1) {
      if (!words[i].node) {
        let j = i;
        while (j < words.length && !words[j].node) j += 1;
        if (j < words.length) {
          /* every previous node is a word node that points to this node */
          let fullText = '';
          for (let k = i; k < j; k += 1) {
            fullText += words[k].word;
          }

          words.splice(i, j - i, { word: fullText, node: words[j].node });
        }
      }
    }

    return words;
  }

  getValue = () => {
    const { findBarRef } = this.props;
    if (findBarRef.current) return this.getWords(findBarRef.current).map((obj) => obj.word).join('');
    return '';
  }

  isValidRegex = (str) => {
    try {
      ''.match(new RegExp(str));
      return true;
    } catch (err) {
      return false;
    }
  }

  /* Reset the cursor to the correct point when we type in new characters. */
  restoreSelection = (absoluteAnchorIndex, absoluteFocusIndex) => {
    const { findBarRef } = this.props;

    const sel = window.getSelection();
    const words = this.getWords(findBarRef.current);

    let anchorNode = findBarRef.current;
    let anchorIndex = 0;

    let focusNode = findBarRef.current;
    let focusIndex = 0;
    let currentIndex = 0;

    words.forEach(({ word, node }) => {
      const startIndexOfNode = currentIndex;
      const endIndexOfNode = startIndexOfNode + word.length;
      if (
        ![
          'find-bar-placeholder',
          'find-word-separator',
        ].includes(node.className)
      ) {
        if (startIndexOfNode <= absoluteAnchorIndex && absoluteAnchorIndex <= endIndexOfNode) {
          anchorNode = node;
          anchorIndex = absoluteAnchorIndex - startIndexOfNode;
        }
        if (startIndexOfNode <= absoluteFocusIndex && absoluteFocusIndex <= endIndexOfNode) {
          focusNode = node;
          focusIndex = absoluteFocusIndex - startIndexOfNode;
        }
      }
      currentIndex += word.length;
    });

    /* If focusNode or anchorNode are null, we should move cursor to the end. Otherwise the
       cursor will move to the beginning */
    if (anchorNode === findBarRef.current || focusNode === findBarRef.current) {
      const lastWord = words[words.length - 1];
      if (lastWord) {
        anchorNode = lastWord.node;
        focusNode = lastWord.node;
        anchorIndex = lastWord.node.nodeValue.length;
        focusIndex = lastWord.node.nodeValue.length;
      }
    }

    sel.setBaseAndExtent(anchorNode, anchorIndex, focusNode, focusIndex);
  }

  /* Given a string, like the one we've typed into the find bar, convert that into HTML
     that represents what the contents of the find bar should actually be.

     If we are in "words" mode, we separate the string into words and separate those words
     with a vertical bar; we also make the word red if the regex is invalid. Otherwise
     we just keep the text as it is. */
  transformText = (text) => {
    const { mode } = this.props;

    if (mode === 'words') {
      const endsWithSpace = text.endsWith(' ');
      const items = text.match(/\S+/g);
      if (!items) {
        return '';
      }

      let result = '';

      for (let i = 0; i < items.length; i += 1) {
        let part = items[i];
        if (!this.isValidRegex(items[i])) {
          part = `<span class="find-regex-error">${part}</span>`;
        }
        result += part;
        if (i < items.length - 1) {
          result += '<div class="find-word-separator"></div>';
        }
      }

      if (endsWithSpace) {
        result += ' ';
      }

      return result;
    }

    return text;
  }

  /* When a user changes the input to the find bar, we have to:
     1. Given where the cursor is before we apply transformText(), we need to
        calculate where it needs to be placed once transformText() is called
     2. Transform the text (i.e. recalculate where the vertical bars will be)
     3. Restore the selection (i.e. place cursor at desired new spot)
     4. Run the changeHandler, so that a parent component can receive the find
        bar text */
  onChange = () => {
    const { onChange: changeHandler, findBarRef } = this.props;

    const sel = window.getSelection();
    const words = this.getWords(findBarRef.current);
    const text = words.map((obj) => obj.word).join('');

    let anchorIndex = null;
    let focusIndex = null;
    let currentIndex = 0;

    words.forEach(({ word, node }) => {
      if (node.className === 'find-word-separator') {
        anchorIndex = currentIndex + sel.anchorOffset + 1;
        focusIndex = currentIndex + sel.focusOffset + 1;
      }
      if (node === sel.anchorNode) {
        anchorIndex = currentIndex + sel.anchorOffset;
      }
      if (node === sel.focusNode) {
        focusIndex = currentIndex + sel.focusOffset;
      }
      currentIndex += word.length;
    });

    findBarRef.current.innerHTML = this.transformText(text);
    this.restoreSelection(anchorIndex, focusIndex);
    changeHandler(text);
  }

  render = () => {
    const {
      findBarRef,
      className,
      mode,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      value,
    } = this.props;

    return (
      <div
        id="find-bar"
        type="text"
        ref={findBarRef}
        contentEditable
        spellCheck={false}
        onInput={this.onChange}
        suppressContentEditableWarning
        className={className}
        mode={mode}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={value}
        role="searchbox"
        tabIndex={0}
        aria-label={placeholder}
      />
    );
  }
}

FindInput.propTypes = {
  findBarRef: refType.isRequired,
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

FindInput.defaultProps = {
  onKeyDown: undefined,
};

export default FindInput;
