const AUTH = Symbol();

class BaseOptions {
  constructor(auth, apiUrl) {
    this.apiUrl = apiUrl;
    this[AUTH] = auth;
  }

  clone() {
    return new BaseOptions(this[AUTH], this.apiUrl);
  }

  setResource(resource) {
    this.resource = resource;
    if (this.resource && this.resource.name) {
      this.resourceUrl = this.apiUrl + resource.name.toLowerCase();
    }
    if (this.resource && this.resource.resourceName) {
      this.resourceUrl = this.apiUrl + resource.resourceName.toLowerCase();
    }
  }

  getOptions(config) {
    config = config || {};

    if (!this.resourceUrl) { return; }

    // If auth is required (true by default),
    // check to see if basic is required (false by default),
    // check to see if either bearer or basic is available
    if (config.authRequired !== false) {
      if (config.basicRequired && !this[AUTH].basic) { return; }
      if (!this[AUTH].bearer && !this[AUTH].basic) { return; }
    }

    let auth;
    if (config.key) {
      auth = this[AUTH].basic || this[AUTH].bearer;
      if (this[AUTH].keys && this[AUTH].keys[config.id]) {
        auth = `Bearer ${this[AUTH].keys[config.id].secret}`;
      }
    } else if (config.preferBasic || config.basicRequired) {
      auth = this[AUTH].basic || this[AUTH].bearer;
    } else {
      auth = this[AUTH].bearer || this[AUTH].basic;
    }

    const headers = config.headers || {};
    if (!config.excludeAuth) {
      headers.Authorization = auth;
    }

    return {
      url: this.resourceUrl + (config.id ? `/${config.id}` : ''),
      method: config.method || 'GET',
      json: config.json || true,
      headers,
    };
  }

  getAuthenticatedUrl(config = {}) {
    if (!this.resourceUrl) { return; }
    if (!this[AUTH].bearer && !this[AUTH].basic) { return; }

    let url = this.resourceUrl;
    if (config.id) {
      url += `/${config.id}`;
    }

    if (this[AUTH].bearer) {
      return `${url}?api_key=${this[AUTH].bearer.slice(7)}`;
    }
    if (this[AUTH].basic) {
      return `${this[AUTH].basic.splice(6)}@${url}`;
    }
    return url;
  }
}

module.exports = BaseOptions;
