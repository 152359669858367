const reduce = require('lodash/reduce');
const call = require('../utils/call');

/**
 * Recognition.prototype.post
 * This function defines the replacement for recognition.post
 * when the build target is the browser.
 * Because of differences in how browsers post data-binary,
 * a XMLHttpRequest is manually created when posting a media file.
 * @params config Obj - optional, properties to be copied before network request
 * @returns native Promise - resolves Recognition Instance, rejects error obj
 */
module.exports = function (baseOptions) {
  return function (config) {
    // important so that mutations of the baseOptions don't bleed into this instance
    baseOptions = baseOptions.clone();
    baseOptions.resourceUrl = `${baseOptions.apiUrl}recognitions`;

    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (recognition.id) {
        reject({ message: 'Cannot post already created recognitions, or recognitions with an id set' });
        return;
      }

      const options = baseOptions.getOptions({ method: 'POST' });
      if (!options) {
        reject({ message: "Remeeting Authorization not provided - You can set defaults by calling remeeting.auth({apiKey: #key}) or remeeting.auth({userName: 'username', plainTextPassword: 'password'}) or passing authentication information to Recognition.post" });
        return;
      }

      options.url += generateUrlParams(recognition);

      if (recognition.file) {
        options.encoding = null;
        options.headers = options.headers || {};
        options.body = recognition.file;
        xhrCall(options, resolve, reject, recognition);
      } else if (recognition.mediaUrl) {
        options.body = {
          media_url: recognition.mediaUrl,
        };
        call(options, resolve, reject, recognition);
      } else {
        reject({ message: 'Media not set - expected config.file or config.mediaUrl' });
      }
    });

    function generateUrlParams(recognition) {
      let params = '?';

      Object.keys(recognition).forEach((key) => {
        if (key !== 'file') {
          if (key === 'events') {
            params += `${key}=`;
            const events = reduce(recognition[key], (result, value, key) => {
              if (value) {
                result += `${key},`;
              }
              return result;
            }, '');
            params += `${events.slice(0, -1)}&`;
          } else {
            params += (`${key}=${recognition[key]}&`);
          }
        }
      });

      params = params.slice(0, -1);
      return params;
    }

    function xhrCall(options, resolve, reject, instance) {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', options.url);
      xhr.setRequestHeader('Authorization', options.headers.Authorization);
      xhr.send(options.body);

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 201 || xhr.status == 200) {
            resolve(instance.extend(JSON.parse(xhr.responseText)));
          } else {
            reject(JSON.parse(xhr.responseText));
          }
        }
      };
    }
  };
};
