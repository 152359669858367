const call = require('./utils/call');

const sendFeedback = function (config, baseOptions) {
  config = config || {};

  // This is a hack for getting the proper URL
  baseOptions.setResource({ name: 'Feedback' });

  return new Promise((resolve, reject) => {
    const options = baseOptions.getOptions({ authRequired: true, method: 'POST' });
    options.body = {
      job_id: config.jobId,
      message: config.message,
      recipient: config.recipient, // NOTE: This is an internal feature for testing
    };
    call(options, resolve, reject);
  });
};

module.exports = sendFeedback;
