// TODO: refactor this utils.js file into a utils/*.js folder.
/* eslint-disable max-classes-per-file */

import difflib from 'difflib';
import moment from 'moment';
import trim from 'lodash/trim';
import crypto from 'crypto';

/*
 * Utilities
 *
 * Basically, these are functions you might want to use in other modules
 * or containers. They should be 'pure' or functional only and should not ever
 * effect application state.
 */

export function copyStringToClipboard(str) {
  // Create new element
  const el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}

export const copyUnassignedProperites = (previous, next) => {
  Object.assign(previous, next);
  Object.assign(next, previous);
  return next;
};

export const mergePropertiesOfArrayOfObjectsWithIdKeys = (previous, next) => next.map((obj2) => {
  const obj1 = previous.find((obj) => (obj.id === obj2.id));
  if (obj1) {
    copyUnassignedProperites(obj1, obj2);
  }
  return obj2;
});

export const immediateInterval = (func, delay) => {
  func();
  return setInterval(func, delay);
};

export const findIndexById = (objects, id) => objects.findIndex((obj) => (obj.id === id));

/**
 * sliceItemsIntoCollectionById
 *
 * This function is useful for updating a subsection of a collection
 * where the items are stable sorted, and have a id property. This assumes
 * that if there is no overlap, the items are sorted after (index larger)
 * than the collection.
 *
 * @param collection Array of objects with id property
 * @param items Array of objects with id property
 * @param stripStart Bool - remove objects that have a smaller index than the first match
 * @param stripEnd Bool - remove objects that have a larger index than the last match
 *
 * @returns copy of altered collection
 */
export const spliceItemsIntoCollectionById = (
  collection = [],
  items = [],
  stripStart,
  stripEnd,
) => {
  let newCollection = [];
  if (!items.length) {
    newCollection = [...collection];
  } else if (!collection.length) {
    newCollection = [...items];
  } else {
    const start = findIndexById(collection, items[0].id);
    const end = findIndexById(collection, items[items.length - 1].id);

    // if position is -1, it means the item was not in collection
    if (start === -1 && end === -1) {
      // case naturally handles strip end as there is no end
      newCollection = [...collection].concat(items);
    } else if (end === -1) {
      // case naturally handles strip end, as there is no end
      newCollection = stripStart ? [...items] : collection.slice(0, start).concat(items);
    } else if (start === -1) {
      // if there is no start, then all of the remaining collection is the 'end' to be stripped
      newCollection = stripEnd ? [...items] : [...items].concat(collection.slice(end + 1));
    } else {
      if (!stripStart) {
        newCollection = collection.slice(0, start);
      }

      newCollection = newCollection.concat(items);

      // case handles strip end by optionally adding the end collection items
      if (!stripEnd) {
        newCollection = newCollection.concat(collection.slice(end + 1));
      }
    }
  }
  return newCollection;
};

export const findObjById = (objects, id) => {
  try {
    return objects[findIndexById(objects, id)] || {};
  } catch (error) {
    return {};
  }
};

export const replaceObjByObjId = (objects = [], obj) => {
  const replacedObjects = [...objects];
  const objIndex = findIndexById(replacedObjects, obj.id);
  if (objIndex > -1) {
    replacedObjects[findIndexById(replacedObjects, obj.id)] = obj;
  } else {
    replacedObjects.push(obj);
  }
  return replacedObjects;
};

export const zeroPad = (num) => (
  `0${num}`.slice(-2)
);

export const formatHoursMinutesSeconds = (durationFloat, playback = false, hm = false) => {
  const secs = Math.round(durationFloat);
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) - (hours * 60);
  const seconds = secs % 60;
  if (hm) {
    // This format is used to display "duration", e.g. on the meetings list page.
    if (hours) {
      if (minutes) {
        return `${hours}h${minutes}m`;
      }
      return `${hours}h`;
    }
    if (minutes) {
      return `${minutes}m`;
    }
    return '1m';
  }
  if (playback) {
    // This format is used to display "timestamps", e.g. on the meeting review page.
    if (hours) {
      return `${hours}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
    }
    return `${minutes}:${zeroPad(seconds)}`;
  }
  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
};

export const durationTimeString = (obj) => {
  if (!obj || !obj.duration) return '';

  return formatHoursMinutesSeconds(obj.duration);
};

/* https://dev.to/maurobringolf/a-neat-trick-to-compute-modulo-of-negative-numbers-111e */
export const mod = (x, n) => ((x % n) + n) % n;

/**
 * Based on discussion at https://bitbucket.org/remeeting/mrp-www/pull-requests/1154
 *
 * We are using the color scheme from
 * https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/.
 * We include every color in the list except for grey, black, and white. I have also
 * marked the colors below that we might want to get rid of, to be inclusive to colorblind
 * users.
 *
 * In linguistics the "color hierarchy" is the idea that languages will have seperate words for
 * colors that are highly differentiated, and use qualified color terms (i.e. "light blue",
 * "dark brown") to identify less differentiated colors.
 *
 * Languages tend to evolve to identify basic color terms in the order:
 * - black/white
 * - red
 * - green/yellow
 * - blue
 * - brown/orange/purple/gray
 * - cyan/pink/maroon
 *
 * The colors below do not exactly follow this order, as they are instead based on
 * these colors' frequencies on subway maps. However, the first six colors do match
 * the linguistic approach exactly.
 *
 * @param {*} ix
 * @returns a triple [r, g, b]
 */
export const getColorFromIndex = (ix) => {
  const red = [230, 25, 75];
  const green = [60, 180, 75];
  const yellow = [255, 225, 25];
  const blue = [0, 130, 200];
  const orange = [245, 130, 48];
  const purple = [145, 30, 180];
  const cyan = [70, 240, 240];
  const magenta = [240, 50, 230];
  const lime = [210, 245, 60];
  const pink = [250, 190, 190];
  const teal = [0, 128, 128];
  const lavender = [230, 190, 255];
  const brown = [170, 110, 40];
  const beige = [255, 250, 200];
  const maroon = [128, 0, 0];
  const mint = [170, 255, 195];
  const olive = [128, 128, 0];
  const apricot = [255, 215, 180];
  const navy = [0, 0, 128];

  const colors = [
    red,
    green,
    yellow,
    blue,
    orange,
    purple, // can conflict with blue
    cyan,
    magenta,
    lime, // can conflict with green/yellow
    pink,
    teal,
    lavender,
    brown,
    beige,
    maroon,
    mint,
    olive, // can conflict with brown
    apricot, // can conflict with pink
    navy,
  ];

  return colors[mod(ix, colors.length)];
};

/* Relies on the fact that truncated MD5 is uniformly distributed. */
export const hashStringToFraction = (s) => {
  const hash = crypto.createHash('md5').update(s).digest('hex').substring(0, 8);
  return 1 - parseInt(`0x${hash}`, 16) / 0xffffffff;
};

export const colorFromString = (speakerName) => (
  speakerName.includes('(shared audio)')
    ? [0, '0%', '49%']
    : [hashStringToFraction(speakerName) * 360, '60%', '45%']
);

export const colorWithAlpha = (color, alpha) => `hsla(${color.join(', ')}, ${alpha})`;

/* W3 uses this formula to get the perceived brightness of a color. */
export const w3ContrastTextColor = (color, alpha = 1) => {
  const rgb = color.map((col) => (255 - col) * (1 - alpha) + col);
  const brightness = 0.299 * rgb[0] + 0.587 * rgb[1] + 0.144 * rgb[2];
  return brightness > 165 ? 'black' : 'white';
};

export const keyCodes = {
  SPACEBAR: 32,
  LEFT: 37,
  RIGHT: 39,
  ENTER: 13,
  ESC: 27,
  TAB: 9,
  FWD_SLASH: 191,

  B: 66,
  C: 67,
  F: 70,
  M: 77,
  R: 82,
  D: 68,
};

export const colorFromModelCost = (cost) => {
  const lightness = Math.min(75, 7 * cost);
  return `hsl(210, 0%, ${lightness}%)`;
};

export const numMatchesAcrossUtterances = (matches) => {
  if (matches === undefined) {
    return 0;
  }

  let total = 0;
  const keys = Object.keys(matches);
  for (let i = 0; i < keys.length; i += 1) {
    total += matches[keys[i]].length;
  }
  return total;
};

/**
 * Given a JSON object mapping utterance ID -> list of catsSearch Match
 * objects, and an "index" - i.e. match number 12 - find the 12th match
 * out of all utterances.
 *
 * @param {*} segments
 * @param {*} matches
 * @param {*} index
 */
export const enumerateFindMatches = (segments, matches, index) => {
  if (index === -1) {
    return undefined;
  }

  let counter = 0;

  for (let i = 0; i < segments.length; i += 1) {
    const { utterance } = segments[i];
    const matchList = matches[utterance];

    if (matchList) {
      for (let j = 0; j < matchList.length; j += 1) {
        if (index === counter) {
          return {
            match: matchList[j],
            utterance,
            segmentIndex: i,
            matchIndex: j,
          };
        }

        counter += 1;
      }
    }
  }

  return undefined;
};

export const speakerLabelToAbbrev = (label) => {
  if (!label || label.length === 0) {
    return '';
  }

  const l = label.trim();
  let result = l;

  if (/^Speaker_\d{3}$/.test(l)) {
    /* Check if in the Speaker_XXX format */
    result = l.slice(9);
  } else if (/\s|_|-/.test(l)) {
    /* Check if label has multiple words */
    const words = l.split(/\s|_|-/);
    result = words[0][0] + words[words.length - 1][0];
  } else {
    /* For 1-word names we just use 1 letter */
    [result] = result;
  }

  return result.slice(0, 2).toUpperCase();
};

export const sortAlternativesByAmLmBalance = (alts, balance) => {
  const amWeight = 1;
  const lmWeight = balance * 2;

  const sorted = alts.slice().sort((a, b) => {
    const result = (
      a.bias.am * amWeight + a.bias.lm * lmWeight
      - b.bias.am * amWeight - b.bias.lm * lmWeight
    );
    return result;
  });
  return sorted;
};

export const smartDate = (d, includeTime, capitalizeBeginning) => {
  const date = moment(d);
  const nextMidnight = moment().add(1, 'days').hours(0).minutes(0)
    .seconds(0);
  const midnight = moment().hours(0).minutes(0).seconds(0);
  const yesterdayMidnight = moment().subtract(1, 'days').hours(0).minutes(0)
    .seconds(0);
  const startOfYear = moment().startOf('year');
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayOfWeek = daysOfWeek[date.format('e')];
  let formatted = '';

  if (!includeTime) {
    if (date.isAfter(nextMidnight)) {
      formatted = `${dayOfWeek}, ${date.format('MMM D, YYYY')}`;
    } else if (date.isAfter(midnight)) {
      formatted = capitalizeBeginning ? 'Today' : 'today';
    } else if (date.isAfter(yesterdayMidnight)) {
      formatted = capitalizeBeginning ? 'Yesterday' : 'yesterday';
    } else if (date.isAfter(startOfYear)) {
      formatted = `${dayOfWeek}, ${date.format('MMM D')}`;
    } else {
      formatted = `${date.format('MMM D, YYYY')}`;
    }
    return formatted;
  }

  if (date.isAfter(nextMidnight)) {
    formatted = `${dayOfWeek}, ${date.format('MMM D, YYYY @ h:mma')}`;
  } else if (date.isAfter(midnight)) {
    formatted = `${capitalizeBeginning ? 'T' : 't'}oday @ ${date.format('h:mma')}`;
  } else if (date.isAfter(yesterdayMidnight)) {
    formatted = `${capitalizeBeginning ? 'Y' : 'y'}esterday @ ${date.format('h:mma')}`;
  } else if (date.isAfter(startOfYear)) {
    formatted = `${dayOfWeek}, ${date.format('MMM D @ h:mma')}`;
  } else {
    formatted = ` ${date.format('MMM D, YYYY @ h:mma')}`;
  }
  return formatted;
};

export const areEqualShallow = (a, b, excludeKeys = []) => {
  const aKeys = Object.keys(a);
  const excludeSet = new Set(excludeKeys);

  for (let i = 0; i < aKeys.length; i += 1) {
    const key = aKeys[i];
    if ((!(key in b) || a[key] !== b[key]) && !excludeSet.has(key)) {
      return false;
    }
  }
  const bKeys = Object.keys(b);
  for (let i = 0; i < bKeys.length; i += 1) {
    const key = bKeys[i];
    if ((!(key in a) || a[key] !== b[key]) && !excludeSet.has(key)) {
      return false;
    }
  }
  return true;
};

/* HTML attributes (id=, key=) can only contain certain characters, and in many
   cases errors will be thrown when invalid characters show up. This function removes
   all characters in a string that are not alphanumeric or -, _, :. */
export const sanitizeHtmlAttr = (text) => {
  try {
    return [...text.matchAll(/[a-zA-Z0-9\-_:]+/g)].join('');
  } catch (error) {
    return '';
  }
};

/* Utilities for richification: Sometimes we need to compare a raw transcript with
   a richified one, and insert highlights. */

export const splitWords = (s) => s.trim().match(/\S+/g) || [];

/* Get sequence of words from string, removing punctuation and lowercasing. */
const getWordSeq = (s) => splitWords(s).map((w) => trim(w, '.,:;!?').toLowerCase());

export const getAlignmentBlocks = (base, richified) => {
  const differ = new difflib.SequenceMatcher(null, getWordSeq(base), getWordSeq(richified));
  return [[0, 0, 0]].concat(differ.getMatchingBlocks());
};

const mappedRangeDiffHelper = (from, to, blocks) => {
  let bFrom;
  let bTo;

  if (!blocks) {
    return [0, 0];
  }

  for (let i = 0; i < blocks.length - 1; i += 1) {
    const [aStart, bStart, len] = blocks[i];
    const [aStartNext, bStartNext] = blocks[i + 1];

    if (from >= to) {
      return null;
    }

    if (aStart <= from && aStart + len > from) {
      /* FROM is in the middle of a block */
      bFrom = bStart + (from - aStart);
    } else if (aStart + len <= from && from < aStartNext) {
      /* FROM is in the middle of a substitution or deletion. the rule is as follows:
         - if the length of the match is at least the length of the substitution, we
           will include the entire target of the substitution in the highlight.
         - otherwise, we return null */
      const subTargetLength = bStartNext - bStart - len;
      if (subTargetLength <= to - from) {
        bFrom = bStart + len;
      }
    }

    if (aStart < to && aStart + len >= to) {
      /* TO is in the middle of a block */
      bTo = bStart + (to - aStart);
    } else if (aStart + len < to && to <= aStartNext) {
      /* TO is in the middle of a substitution, same rules apply. */
      const subTargetLength = bStartNext - bStart - len;
      if (subTargetLength <= to - from) {
        bTo = bStartNext;
      }
    }
  }

  if (bFrom === undefined || bTo === undefined) {
    return null;
  }

  return [bFrom, bTo];
};

export const intervalsOverlap = ([x1, x2], [y1, y2]) => x1 < y2 && y1 < x2;

/* Given the base text (possibly with highlighting) and the richified output,
   find the indices of the words in richified that should be highlighted.

   BASE: the original string before richification
   BASERANGE: an array [from, to] with indices highlighted in BASE
   RICHIFIED: the richified text

   Returns: the range of indices of words in RICHIFIED that are to be highlighted */
export const getMappedRange = (base, baseRange, richified) => {
  const [from, to] = baseRange;

  const differ = new difflib.SequenceMatcher(null, getWordSeq(base), getWordSeq(richified));
  const blocks = [[0, 0, 0]].concat(differ.getMatchingBlocks());

  return mappedRangeDiffHelper(from, to, blocks);
};

export const getMappedRanges = (blocks, selectedBaseRange, baseRanges) => {
  let selectedMappedRange;
  if (selectedBaseRange) {
    selectedMappedRange = mappedRangeDiffHelper(...selectedBaseRange, blocks);
  }
  const mappedRanges = baseRanges.map((range) => mappedRangeDiffHelper(...range, blocks));

  /* Sort mapped ranges and remove overlaps */
  const sortedMappedRanges = mappedRanges.concat().filter((x) => x).sort((a, b) => a[0] - b[0]);
  let minStart = 0;
  const resultRanges = [];

  let insertedSelectedRange = false;
  if (selectedMappedRange === null) {
    insertedSelectedRange = true;
  }

  let selectedIndex;
  for (let i = 0; i < sortedMappedRanges.length; i += 1) {
    if (
      selectedMappedRange
      && !insertedSelectedRange
      && sortedMappedRanges[i][0] >= minStart
      && selectedMappedRange[0] <= sortedMappedRanges[i][0]
    ) {
      selectedIndex = resultRanges.length;
      resultRanges.push(selectedMappedRange);
      insertedSelectedRange = true;
      [, minStart] = selectedMappedRange;
    }

    if (
      sortedMappedRanges[i][0] >= minStart
      && (
        selectedMappedRange === undefined
        || selectedMappedRange === null
        || !intervalsOverlap(sortedMappedRanges[i], selectedMappedRange)
      )
    ) {
      resultRanges.push(sortedMappedRanges[i]);
      [, minStart] = sortedMappedRanges[i];
    }
  }

  return {
    selectedIndex,
    resultRanges,
  };
};

/* https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f */
export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const makeCancelable = (promise) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(
        (val) => (hasCanceled
          // The custom value of isCanceled is used, and the refactor
          // doesn't seem worth the effort to edit the places that
          // are using `makeCancelable`. It appears that `makeCancelable`
          // is only really utilized by `/modules/search.js`, and changing
          // this functionality can be revisited if it seems pressing
          // to do so or if a different refactor requires editing the
          // code that utilizes `makeCancelable`.
          // eslint-disable-next-line prefer-promise-reject-errors
          ? reject({ isCanceled: true })
          : resolve(val)),
      )
      .catch(
        (error) => (hasCanceled
          // eslint-disable-next-line prefer-promise-reject-errors
          ? reject({ isCanceled: true })
          : reject(error)),
      );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

/* In-place sort for "almost-sorted" arrays. */
/* eslint-disable no-param-reassign */
export const insertionSort = (array, fn) => {
  for (let i = 0; i < array.length; i += 1) {
    const key = array[i];
    let j = i - 1;
    while ((j >= 0) && (fn(array[j]) > fn(key))) {
      array[j + 1] = array[j];
      j -= 1;
    }
    array[j + 1] = key;
  }

  return array;
};
/* eslint-enable no-param-reassign */

export const sortSegmentsByFirstSpan = (segments, cats, absoluteTimestamps = true) => {
  const catsData = cats.getData();

  const sortByFirstSpan = (segment) => {
    const firstSpan = catsData[segment.utterance].cats[0];

    if (!firstSpan) {
      return segment.interval[0];
    }

    if (!absoluteTimestamps) {
      /* Use relative timestamps for cats */
      return segment.interval[0] + firstSpan.interval[0];
    }

    return firstSpan.interval[0];
  };

  insertionSort(segments, sortByFirstSpan);
};

/**
 * splitExceptInQuotes
 *
 * Copied from API code.
 * The purpose of this function is to split according to a delimiter, except if that delimiter
 * happens to fall in between double quotes.
 */
export const splitExceptInQuotes = (terms, delim = 'OR') => {
  const pattern = new RegExp(`"[^"]*"|(${delim})`, 'g');
  const splitTerms = [];
  let startPos = 0;
  /* eslint-disable no-restricted-syntax */
  for (const match of terms.matchAll(pattern)) {
    if (match[1]) {
      /* Matched delim, so push the terms from startPos to match.index, which is the index of delim.
         Advance startPos to end of delim. */
      const endPos = match.index;
      splitTerms.push(terms.slice(startPos, endPos));
      startPos = endPos + delim.length;
    }
    /* Else we matched a quoted phrase, so don't advance startPos. */
  }
  /* eslint-enable no-restricted-syntax */
  splitTerms.push(terms.slice(startPos));
  return splitTerms;
};

/* buildURLQueryString
 * Given a flat object, return a &-separated string of key=value pairs.
 * NOTE: Replace '%20' with '+' to adhere to application/x-www-form-urlencoded formatting, which is
 * what search engines like Google use.
 */
export const buildURLQueryString = (config) => (
  Object.entries(config)
    .map(([key, val]) => `${key}=${encodeURIComponent(val).replaceAll('%20', '+')}`)
    .join('&')
);

/* MultiFind is a class that represents our ability to perform "find" queries on any
   downstream products of video/audio processing - that includes ASR, OCR, and richification,
   and anything else we might add in the future. */

export class MultiFindMatch {
  constructor(type, data) {
    this.type = type;
    this.data = data;
  }

  getType() {
    return this.type;
  }

  getData() {
    return this.data;
  }

  /* For a given MultiFind match, we need a notion of its position within the document.
     For now, we will order a particular match based on where it occurs in the audio (as
     opposed to where it occurs in the transcript). */
  getPosition() {
    if (this.type === 'cats') {
      /* The start of the interval of the first DocPos in the match. */
      const { match: { positions }, cats } = this.data;
      return cats[positions[0].span].interval[0];
    }

    if (this.type === 'ocr') {
      /* The start of the interval of the OCR frame. */
      return this.data.frames[0].interval[0];
    }

    return null;
  }

  setIndex(index) {
    this.index = index;
  }

  getIndex() {
    return this.index;
  }
}

export class MultiFindResult {
  constructor(results) {
    this.results = results.sort((a, b) => a.getPosition() - b.getPosition());
    this.results.forEach((match, index) => {
      match.setIndex(index);
    });
  }

  getMatchAtIndex(index) {
    return this.results[index];
  }

  size() {
    return this.results.length;
  }

  getMatchesByType(type) {
    return this.results.filter((match) => match.getType() === type);
  }

  getMatches() {
    return this.results;
  }
}

export class MultiFind {
  /* Is a Cats() object */
  cats = undefined;

  /* Is an array of JSON dicts */
  segmentData = undefined;

  /* Is an array of JSON dicts */
  ocrData = undefined;

  setCatsData(cats) {
    this.cats = cats;
  }

  setSegmentData(segments) {
    this.segmentData = segments;
  }

  setOCRData(data) {
    this.ocrData = data;
  }

  hasCatsData() {
    return this.cats !== undefined;
  }

  hasSegmentData() {
    return this.segmentData !== undefined;
  }

  hasOCRData() {
    return this.ocrData !== undefined;
  }

  find(query, options) {
    const {
      catsOptions,
      ocrOptions,
    } = options;

    const queryWords = query.toLowerCase().match(/\S+/g);

    /* In OCR find, we return all intervals where the query is matched. */
    const ocrMatches = [];

    if (ocrOptions.enabled && this.hasOCRData()) {
      for (let i = 0; i < this.ocrData.length; i += 1) {
        const { frames } = this.ocrData[i];
        const ocrMatch = [];

        /* If the word directly hits one of the words in the word list, we can call it
           a match at this point. */
        if (frames) {
          for (let j = 0; j < frames.length; j += 1) {
            const frameResult = [];
            /* For this frame, check if the words appear inside. If they do, we say that
               a match exists, and this frame will be part of the match. */
            let frameContainsAllWords = true;
            for (let k = 0; k < queryWords.length; k += 1) {
              let frameContainsWord = false;
              for (let l = 0; l < frames[j].words.length; l += 1) {
                if (frames[j].words[l].word.toLowerCase().includes(queryWords[k])) {
                  /* This frame contains this word. */
                  frameContainsWord = true;
                  frameResult.push(frames[j].words[l]);
                }
              }
              if (!frameContainsWord) {
                /* This frame does not contain all words. */
                frameContainsAllWords = false;
                break;
              }
            }

            if (frameContainsAllWords) {
              /* If this frame is really a match, we retrieve the bounding boxes for
                 the words we've found. */
              ocrMatch.push({
                frameWords: frameResult,
                interval: frames[j].interval,
              });
            }
          }
        }

        if (ocrMatch.length) {
          const fullInterval = [
            ocrMatch[0].interval[0],
            ocrMatch[ocrMatch.length - 1].interval[1],
          ];

          ocrMatches.push(
            new MultiFindMatch(
              'ocr',
              {
                frames: ocrMatch,
                interval: fullInterval,
              },
            ),
          );
        }
      }
    }

    /* Cats find code is already implemented, retrieve results */
    const catsMatches = [];

    if (this.hasCatsData() && this.hasSegmentData()) {
      const catsData = this.cats.getData();
      const catsResult = this.cats.find(query, catsOptions);

      for (let i = 0; i < this.segmentData.length; i += 1) {
        const segment = this.segmentData[i];
        if (catsResult[segment.utterance] !== undefined) {
          for (let j = 0; j < catsResult[segment.utterance].length; j += 1) {
            catsMatches.push(
              new MultiFindMatch(
                'cats',
                {
                  segmentIndex: i,
                  utterance: segment.utterance,
                  cats: catsData[segment.utterance].cats,
                  match: catsResult[segment.utterance][j],
                },
              ),
            );
          }
        }
      }
    }

    return new MultiFindResult([...catsMatches, ...ocrMatches]);
  }
}
