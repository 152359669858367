import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import RemeetingUserFriendlyDate from '../utils/remeeting-user-friendly-date';

import successfulMeetingImage from '../../images/successful-meeting-756x424.png';

import {
  clearError,
  getThumbnail,
} from '../../modules/recognitions';
import { formatHoursMinutesSeconds } from '../../modules/utils';

class Hit extends Component {
  componentDidMount() {
    const { connectedGetThumbnail, id, meetingThumbnails } = this.props;
    if (!(id in meetingThumbnails)) {
      connectedGetThumbnail(id);
    }
  }

  renderCatsHighlights = () => {
    const { highlightCats, id } = this.props;
    if (!highlightCats.length) return '';
    const concatHighlights = highlightCats.join(' ... ');
    /* Separate the highlighted text from the rest of the text.
       Elasticsearch returns highlights wrapped in <em> tags. */
    const chunkedText = concatHighlights.split(/<em>(.*?)<\/em>/g);
    const textElems = [];
    /* Build an array of <span> elements. Every other chunk should be a highlight. */
    for (let i = 0; i < chunkedText.length; i += 1) {
      if (i % 2 === 0) {
        textElems.push(
          <span
            className="xms-highlight-context"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: chunkedText[i] }}
          />,
        );
      } else {
        /**
         * If we're at chunk i, and chunk i+1 is " ", that suggests chunk i is a multi-word
         * highlight with chunk i+2 at least.
         *
         * NOTE (Elliot): You don't generally see the last word as a highlight because of
         * highlighting context, so I don't believe we need to worry about chunkedText[i + 2] being
         * out of bounds.
         */
        let highlightText = chunkedText[i];
        while (chunkedText[i + 1] === ' ' && chunkedText[i + 2] !== undefined) {
          highlightText += chunkedText[i + 1];
          highlightText += chunkedText[i + 2];
          i += 2;
        }
        const openItalics = /<i>/g;
        const closeItalics = /<\/i>/g;
        textElems.push(
          <a
            className="xms-highlighted-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: highlightText }}
            aria-label={`Find ${highlightText} in the meeting page`}
            href={`/app/r/${id}?q=${highlightText.split(' ').join('+').replace(openItalics, '').replace(closeItalics, '')}`}
          />,
        );
      }
    }
    return (
      <p>
        { textElems }
      </p>
    );
  }

  renderMeetingParticipants = (participants) => {
    // TODO: add a surname initial to disambiguate speakers with same first name?
    // TODO: order speakers by talking time?
    const numSpeakers = participants.length;

    function convertName(name) {
      const splitName = name.split(' ');
      if (splitName.length === 0) {
        return '';
      }
      if (splitName.length === 1) {
        return splitName[0];
      }
      return `${splitName[0]}`;
    }

    if (numSpeakers) {
      let i;
      const rawSpeakers = [];
      participants.forEach((speaker) => {
        if (!speaker.includes('(Shared Audio)') && !rawSpeakers.includes(speaker)) {
          rawSpeakers.push(speaker);
        }
      });

      const speakers = [];
      for (i = 0; i < rawSpeakers.length; i += 1) {
        speakers.push(convertName(rawSpeakers[i]));
      }

      if (speakers.length === 3) {
        return (
          <span>
            {`${speakers[0]},
            ${speakers[1]},
            and\u00A0${speakers[2]}`}
          </span>
        );
      }
      if (speakers.length > 2) {
        return (
          <span>
            {`${speakers[0]},
            ${speakers[1]},
            +${speakers.length - 2}\u00A0others`}
          </span>
        );
      }
      if (speakers.length === 2) {
        return (
          <span>
            {`${speakers[0]} and ${speakers[1]}`}
          </span>
        );
      }
      if (speakers.length === 1) {
        return (
          <span>
            {speakers[0]}
          </span>
        );
      }
    }
    return '';
  }

  renderOcrStats = () => {
    const { id, ocrWordCounts } = this.props;
    if (isEmpty(ocrWordCounts)) return '';
    // TODO: Should really do the following in the API.
    const ocrWordMatches = new Set(Object.keys(ocrWordCounts).map((elem) => elem.toLowerCase()));
    const renderTextArray = [...ocrWordMatches].map((word) => (
      [<a className="xms-ocr-hit" href={`/app/r/${id}?q=${word}`} key={`ocr-${word}`}>{word}</a>]
    ));
    const renderText = [];
    if (renderTextArray.length === 1) {
      renderText.push(renderTextArray[0]);
    } else {
      let i = 0;
      for (i; i < renderTextArray.length - 1; i += 1) {
        renderText.push(renderTextArray[i]);
        renderText.push(', ');
      }
      renderText.push(' and ');
      renderText.push(renderTextArray[i]);
    }
    return (
      <div className="xms-ocr-stat">
        {'Found '}
        { renderText }
        {' in video.'}
      </div>
    );
  }

  renderThumbnailImage = (id) => {
    const { meetingThumbnails } = this.props;
    if (!meetingThumbnails[id]) {
      return successfulMeetingImage;
    }
    return meetingThumbnails[id];
  }

  renderThumbnail = (id) => (
    <img
      alt={`${id.slice(0, 6)}-thumbnail`}
      className="xms-hit-image"
      src={this.renderThumbnailImage(id)}
      size="large"
    />
  );

  render() {
    const {
      id,
      ocrWordCounts,
      recordingDuration,
      speakers,
      startTime,
      title,
    } = this.props;

    return (
      <Table.Row>
        <Table.Cell className="xms-hit-image-container">
          <Link
            to={`/r/${id}`}
          >
            <div className="ui duration segment">
              {
                recordingDuration
                  && (
                    <div className="ui bottom left attached duration label">
                      {formatHoursMinutesSeconds(recordingDuration, false, true)}
                    </div>
                  )
              }
              {this.renderThumbnail(id)}
            </div>
          </Link>
        </Table.Cell>
        <Table.Cell textAlign="left" verticalAlign="top">
          <Link
            className="xms-hit"
            to={`/r/${id}`}
          >
            <div className="xms-hit-title">
              {title}
            </div>
          </Link>
          <div className="xms-hit-metadata">
            <RemeetingUserFriendlyDate
              date={startTime}
            />
            <p>
              {this.renderMeetingParticipants(speakers)}
            </p>
          </div>
          { this.renderCatsHighlights() }
          { !isEmpty(ocrWordCounts) && this.renderOcrStats() }
        </Table.Cell>
      </Table.Row>
    );
  }
}

Hit.propTypes = {
  connectedGetThumbnail: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  highlightCats: PropTypes.arrayOf(PropTypes.string),
  meetingThumbnails: PropTypes.shape({}).isRequired,
  ocrWordCounts: PropTypes.shape({}),
  recordingDuration: PropTypes.number,
  speakers: PropTypes.arrayOf(PropTypes.string),
  startTime: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Hit.defaultProps = {
  highlightCats: [],
  ocrWordCounts: {},
  recordingDuration: undefined,
  speakers: [],
};

const mapStateToProps = (state) => ({
  error: state.recognitions.error,
  meetingThumbnails: state.recognitions.meetingThumbnails,
  recognitionsLoaded: state.recognitions.recognitionsLoaded,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedGetThumbnail: getThumbnail,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Hit);
