import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const isDirty = (collection, member, filter) => (
  collection.filter(filter).length || filter(member)
);

class RefreshButton extends Component {
  constructor() {
    super();
    this.state = {};
    this.countdownInterval = null;
  }

  componentDidMount() {
    const {
      delay,
      filter,
      halt,
      collection,
      member,
    } = this.props;

    if (halt) return;

    if (!delay) return;

    if (!filter || isDirty(collection, member, filter)) {
      this.setCallbackTimeout();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      collection,
      delay,
      filter,
      halt,
      member,
    } = this.props;

    if (!delay || !filter) return;

    const dirty = isDirty(collection, member, filter);

    if (halt) {
      this.cancelCountdownInterval();
      return;
    }

    if (collection.length !== prevProps.collection.length) {
      // member was added or removed
      this.cancelCountdownInterval();
    }

    if (dirty) {
      this.setCallbackTimeout();
    } else {
      this.cancelCountdownInterval();
    }
  }

  componentWillUnmount() {
    this.cancelCountdownInterval();
  }

  cancelCountdownInterval = () => {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
      this.setState({
        timeRemaining: undefined,
      });
    }
  }

  setCallbackTimeout = () => {
    const { delay } = this.props;

    if (!this.countdownInterval) {
      this.countdownInterval = setInterval(this.tick, 10);
      this.setState({
        timeRemaining: delay,
      });
    }
  }

  refresh = () => {
    const { callback, delay } = this.props;
    if (this.countdownInterval) {
      this.setState({
        timeRemaining: delay,
      });
    }
    callback();
  }

  tick = () => {
    let { timeRemaining } = this.state;
    timeRemaining -= 10;
    if (!timeRemaining) {
      this.refresh();
    } else {
      this.setState({
        timeRemaining,
      });
    }
  }

  render() {
    const {
      className,
      disabled,
      delay,
      noSemantic,
      loading,
    } = this.props;
    const { timeRemaining } = this.state;

    if (noSemantic) {
      return (
        <div className={className}>
          {
            !timeRemaining
            && <FontAwesomeIcon icon={faSyncAlt} size="sm" />
          }
          { timeRemaining
            && (
            <div className="re is-flex is-flex-align-center">
              <CircularProgressbar
                percentage={Math.floor((timeRemaining / delay) * 100)}
                strokeWidth={15}
                textForPercentage={null}
                styles={{
                  root: { width: '24px', margin: '6px' },
                  path: {
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.0s ease 0s',
                  },
                  text: { fill: '#000' },
                }}
              />
            </div>
            )}
        </div>
      );
    }

    return (
      <div className={className}>
        <Button
          onClick={(event) => this.refresh(event)}
          loading={loading}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      </div>
    );
  }
}

/* TODO: Still not sure how to resolve this disable :-( */
RefreshButton.propTypes = {
  callback: PropTypes.func.isRequired,
  className: PropTypes.string,
  collection: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  member: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  delay: PropTypes.number,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  /* halt allows parent components to manually cancel the auto-refresh. */
  halt: PropTypes.bool,
  noSemantic: PropTypes.bool,
  loading: PropTypes.bool,
};

RefreshButton.defaultProps = {
  noSemantic: undefined,
  className: undefined,
  collection: [],
  member: {},
  loading: false,
  disabled: false,
  filter: undefined,
  halt: false,
  delay: 0,
};

export default RefreshButton;
