import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import {
  seekToTimestamp,
} from '../../modules/mediaElement';

import {
  setPhraseAltsPopover, setShowSpanPopover,
} from '../../modules/meeting';

import {
  catsItemType,
  spanIdentifierType,
  intervalType,
  matchType,
} from '../types';

import {
  sortAlternativesByAmLmBalance,
} from '../../modules/utils';

// We use ... to mark silences in the 1best transcript so that users can hover over them to see
// the phrase alternatives for the silence.
//
// Richification will also use ... when a segment ends in a way that's clearly not a sentence,
// e.g. "and...", "or...". It's probably okay to use ... to mark silences too, since the
// "trailing off" is always attached to a word and "silence" is separate. So hovering
// over "and..." would show phrase alternatives to "and".
//
// If that does prove to be confusing, we can switch to using - here instead.
const SILENCE_MARKER = '. . .';

/**
 * Separate the words of the span into the parts before, during and after a search phrase match,
 * together with appropriate whitespacing.
 *
 * @param alternatives: ranked list of phrase alternatives (1-best, 2nd best, etc.) for this span
 * @param currentMatch: the MultiFindMatch object (multi-find.js) for the find match the user is on
 * @param currentSpanIndex: the index of this span
 */
export const separateMatchTextInSpan = (alternatives, currentMatch, currentSpanIndex) => {
  const { positions } = currentMatch;

  const positionsInSpan = positions.filter((pos) => pos.span === currentSpanIndex);
  if (positionsInSpan.length === 0) {
    /* this should never happen */
    return {};
  }

  const currentAltWords = alternatives[positionsInSpan[0].alt].phrase.match(/\S+/g) || [];
  const firstWord = positionsInSpan[0].word;
  const lastWord = positionsInSpan[positionsInSpan.length - 1].word;

  const match = [];
  const beforeMatch = [];
  const afterMatch = [];

  for (let i = 0; i < currentAltWords.length; i += 1) {
    if (i < firstWord) {
      if (beforeMatch.length) {
        beforeMatch.push(' ');
      }
      beforeMatch.push(currentAltWords[i]);
      if (i === firstWord - 1 && lastWord - firstWord >= 0) {
        beforeMatch.push(' ');
      }
    } else if (i >= firstWord && i <= lastWord) {
      if (match.length) {
        match.push(' ');
      }
      match.push(currentAltWords[i].length > 0 ? currentAltWords[i] : SILENCE_MARKER);
    } else {
      afterMatch.push(' ');
      afterMatch.push(currentAltWords[i]);
    }
  }

  return {
    beforeMatch,
    match,
    afterMatch,
  };
};

class CatsSpan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupShowTimeout: undefined,
      spanSelected: false,
    };
  }

  clickHandler = () => {
    const {
      connectedSeekToTimestamp,
      catsItem,
      segmentInterval,
      absoluteTimestamps,
    } = this.props;

    /* Worker jobs output cats with timestamps relative to the start of the segment.
       Exec jobs output them relative to the start of the meeting (absolute times).
       Check if this meeting came from worker or exec, and start playback from the
       appropriate time. */
    let seekLocation = catsItem.interval[0];
    if (!absoluteTimestamps) {
      seekLocation += segmentInterval[0];
    }
    connectedSeekToTimestamp(seekLocation, true);
  }

  mouseOverHandler = () => {
    const {
      connectedSetPhraseAltsPopover,
      connectedSetShowSpanPopover,
      identifier,
      showSpanPopover,
    } = this.props;

    const { utterance, catsIndex } = identifier;
    connectedSetPhraseAltsPopover(utterance, catsIndex, false);

    if (showSpanPopover) {
      this.setState({
        popupShowTimeout: setTimeout(() => {
          connectedSetShowSpanPopover(true);
        }, 100),
        spanSelected: true,
      });
    } else {
      this.setState({
        popupShowTimeout: setTimeout(() => {
          connectedSetShowSpanPopover(true);
        }, 700),
        spanSelected: true,
      });
    }
  }

  mouseLeaveHandler = () => {
    const { connectedSetShowSpanPopover } = this.props;
    const { popupShowTimeout } = this.state;

    connectedSetShowSpanPopover(false);

    this.setState({
      spanSelected: false,
    });

    clearInterval(popupShowTimeout);
  }

  getCurrentMatch = () => {
    const { currentMatch, spanMatches } = this.props;
    if (spanMatches && spanMatches.length === 0) {
      return undefined;
    }
    if (currentMatch) {
      return currentMatch;
    }
    return spanMatches[0];
  }

  getDisplayText = () => {
    const {
      catsItem,
      spanMatches,
      spanIndex,
      currentMatch,
      transcriptAmLmBalance,
    } = this.props;

    /* Normally the span displays the 1best phrase alt.
       This can change during finds.
       E.g., if we have a span with two alts, like:
          alt0: "bike awareness"
          alt1: "bike oh where are you so"
       if a user tries to find "where", we are matching the second
       alternative (alt1). We will therefore display alt1 in its entirety,
       and highlight only the word "where" (this information is available
       in spanMatches). */

    const sortedAlts = sortAlternativesByAmLmBalance(
      catsItem.alternatives,
      transcriptAmLmBalance,
    );

    /* If there are no find matches to show */
    if (spanMatches.length === 0) {
      const { phrase } = sortedAlts[0];
      if (phrase.length === 0) {
        return SILENCE_MARKER;
      }
      return phrase;
    }

    const currentSpanMatch = this.getCurrentMatch();

    const {
      beforeMatch,
      match,
      afterMatch,
    } = separateMatchTextInSpan(catsItem.alternatives, currentSpanMatch.data.match, spanIndex);

    let matchClassName = 'cats-match';
    if (currentMatch !== undefined) {
      matchClassName += ' current-match';
    }

    let textClassName = '';
    if (currentSpanMatch) {
      for (let i = 0; i < currentSpanMatch.data.match.positions.length; i += 1) {
        if (currentSpanMatch.data.match.positions[i].span === spanIndex) {
          const altIndex = currentSpanMatch.data.match.positions[i].alt;
          if (catsItem.alternatives[altIndex] !== sortedAlts[0]) {
            textClassName = 'span-new-alt';
          }
        }
      }
    }

    return (
      <span className={textClassName}>
        { beforeMatch }
        <span className={matchClassName}>
          { match.length > 0 ? match : [SILENCE_MARKER] }
        </span>
        { afterMatch }
      </span>
    );
  }

  render() {
    const {
      identifier,
      selected,
      idPrefix,
      isLoading,
    } = this.props;

    const {
      spanSelected,
    } = this.state;

    const { utterance, catsIndex } = identifier;
    const idKey = `${idPrefix}${utterance}-${catsIndex}`;

    let className = 'cats-span';
    if (selected || spanSelected) {
      className += ' selected';
    }
    if (isLoading) {
      className += ' grey-loading';
    }

    return (
      <span
        id={idKey}
        key={`span-${utterance}-${catsIndex}`}
        onClick={this.clickHandler}
        onKeyUp={(event) => event.code === 'Enter' && this.clickHandler(event)}
        onMouseOver={this.mouseOverHandler}
        onFocus={this.mouseOverHandler}
        onMouseLeave={this.mouseLeaveHandler}
        onBlur={this.mouseLeaveHandler}
        className={className}
        role="button"
        tabIndex={0}
      >
        { this.getDisplayText() }
      </span>
    );
  }
}

CatsSpan.propTypes = {
  catsItem: catsItemType.isRequired,
  identifier: spanIdentifierType.isRequired,
  selected: PropTypes.bool.isRequired,
  spanIndex: PropTypes.number.isRequired,
  spanMatches: PropTypes.arrayOf(matchType).isRequired,
  currentMatch: matchType,
  idPrefix: PropTypes.string,
  showSpanPopover: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  transcriptAmLmBalance: PropTypes.number.isRequired,
  segmentInterval: intervalType.isRequired,
  absoluteTimestamps: PropTypes.bool.isRequired,

  connectedSeekToTimestamp: PropTypes.func.isRequired,
  connectedSetPhraseAltsPopover: PropTypes.func.isRequired,
  connectedSetShowSpanPopover: PropTypes.func.isRequired,
};

CatsSpan.defaultProps = {
  currentMatch: undefined,
  idPrefix: '',
  isLoading: false,
};

const mapStateToProps = (state) => ({
  transcriptAmLmBalance: state.meeting.transcriptAmLmBalance,
  showSpanPopover: state.meeting.showSpanPopover,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSeekToTimestamp: seekToTimestamp,
  connectedSetPhraseAltsPopover: setPhraseAltsPopover,
  connectedSetShowSpanPopover: setShowSpanPopover,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatsSpan);
