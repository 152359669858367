import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import { endSession } from '../../modules/session';
import { errorType } from '../types';

/**
 * This function takes an error object and returns an error message.
 * If the type of error is RequestError, we return error.message.
 * If the type of error is StatusCodeError, we know that it came from
 * our API and pull from the error.error.error property.
 * Else just return a generic error message.
 *
 * @param {object} error - The error object
 * @param {bool} showSignIn - Show sign out button if 401 error
 * @return {string} The error message
 */
const ConsoleErrorMessage = ({ connectedEndSession, error, showSignIn }) => {
  if (error) {
    if (error.name === 'RequestError') {
      return <p>{error.message}</p>;
    }
    if (error.name === 'StatusCodeError') {
      const message = [];
      message.push(<p>{error.error.message || error.error.error}</p>);
      if (error.statusCode === 401 && showSignIn) {
        message.push(<Button onClick={connectedEndSession} primary>Sign in</Button>);
      }
      return message;
    }
    if (error.message) {
      return error.message;
    }
  }
  return (
    <p>
      Something went wrong. Please try again or contact
      <a href="mailto:support@remeeting.com"> support</a>
    </p>
  );
};

ConsoleErrorMessage.propTypes = {
  connectedEndSession: PropTypes.func.isRequired,
  error: errorType,
  showSignIn: PropTypes.bool,
};

ConsoleErrorMessage.defaultProps = {
  error: undefined,
  showSignIn: true,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedEndSession: endSession,
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ConsoleErrorMessage);
