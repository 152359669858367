import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import {
  Header,
  Icon,
  Message,
  Popup,
  Segment,
} from 'semantic-ui-react';

import { cardType, planType } from '../types';

import CCInfo from './cc';

import '../../stylesheets/account.css';

import { sendGetCard, sendGetPlan } from '../../modules/account';
import chargifyCc from './chargifyCc';

class Usage extends Component {
  componentDidMount() {
    const { connectedSendGetCard, connectedSendGetPlan } = this.props;
    connectedSendGetCard();
    connectedSendGetPlan();
  }

  isLoading = () => {
    const { getCardRequestOut, getPlanRequestOut } = this.props;
    return getCardRequestOut || getPlanRequestOut;
  }

  renderAccountDisabled = () => (
    <Message warning>
      <Message.Header content="Account Disabled" />
      <Message.Content>
        This account is disabled and should not be functional.
        <br />
        Please contact
        <a href="mailto:support@remeeting.com"> support@remeeting.com </a>
        for assistance.
      </Message.Content>
    </Message>
  )

  renderBalance = () => {
    const { plan } = this.props;
    return (
      <div className="re is-padding-bottom-16px is-flex is-flex-align-baseline">
        <div className="usage-title">
          Balance
        </div>
        <div className="re is-flex-auto">
          { `$${plan.balance.toFixed(2)}  will be charged on ` }
          <Moment date={plan.due} format="MMM D, YYYY" />
        </div>
      </div>
    );
  }

  renderCreditCard = () => {
    const { card, plan } = this.props;
    return (
      <div className="re is-padding-bottom-16px is-flex is-flex-align-baseline">
        <div className="usage-title">
          Payment method
        </div>
        <Icon name="credit card" />
        <div className="re is-flex-auto">
          { card
            ? `${chargifyCc[card.type]} ${card.number.replace(/X/g, '*').replace(/-/g, ' ')}`
            : 'No card on file'}
        </div>
        { plan && <CCInfo /> }
      </div>
    );
  }

  renderNoPlan = () => (
    <Message warning>
      <Message.Header content="No Plan Found" />
      <Message.Content>
        This account is not set up to automatically bill.
        <br />
        Please contact
        <a href="mailto:support@remeeting.com"> support </a>
        if you wish to set this up.
      </Message.Content>
    </Message>
  )

  renderPlan = () => {
    const { plan } = this.props;
    return (
      <>
        <div className="re is-padding-top-16px is-flex">
          <div className="usage-title">
            Plan
          </div>
          <div className="re is-flex-auto">
            { plan.name }
          </div>
        </div>
        <div className="re is-padding-bottom-16px">
          <div className="re is-display-inline-block usage-title" />
          <div className="re is-display-inline-block">
            $10/month (100GB storage)
          </div>
        </div>
      </>
    );
  }

  renderStatus = () => {
    const { plan } = this.props;
    let prep;
    let statusDate;
    /* TODO: Add logic for other Chargify states. */
    switch (plan.state) {
      case 'active':
        prep = 'Since ';
        statusDate = plan.activated;
        break;
      case 'canceled':
        prep = 'On ';
        statusDate = plan.canceled;
        break;
      case 'trialing':
        prep = 'Ends ';
        statusDate = plan.trial_to;
        break;
      case 'trial_ended':
        prep = 'On ';
        statusDate = plan.trial_to;
        break;
      default:
        prep = 'Since ';
        statusDate = plan.updated;
    }
    return (
      <>
        <div className="re is-flex">
          <div className="usage-title">
            Status
          </div>
          <div className="re is-flex-auto">
            { startCase(plan.state) }
          </div>
        </div>
        <div className="re is-padding-bottom-16px">
          <div className="re is-display-inline-block usage-title" />
          <div className="re is-display-inline-block">
            { prep }
            <Moment date={statusDate} format="MMM D, YYYY" />
          </div>
        </div>
      </>
    );
  }

  /* NOTE: Unused but will implement later. */
  renderUsage = () => {
    const { plan } = this.props;
    return (
      <div className="re is-flex">
        <div className="usage-title">
          Transcription
        </div>
        <div className="re is-flex-auto">
          { `${plan.usage[0].value.toFixed(2)} minutes at $0.01/min` }
        </div>
      </div>
    );
  }

  renderBillingLink = () => (
    <Popup
      key="billing-popup"
      trigger={
        <a href="mailto: billing@remeeting.com">Need a statement?</a>
      }
      hoverable
      on="hover"
      position="left center"
    >
      Contact&nbsp;
      <a href="mailto:billing@remeeting.com">billing@remeeting.com</a>
      &nbsp;to request billing statements.
    </Popup>
  );

  render() {
    const { plan } = this.props;
    return (
      <Segment loading={this.isLoading()}>
        <div className="re is-border-bottom is-padding-bottom-16px is-flex is-flex-row is-flex-justify-space-between is-flex-align-center">
          <Header as="h2" className="re is-header-item">
            Billing
          </Header>
        </div>
        { plan && plan.state === 'canceled' && this.renderAccountDisabled() }
        { plan ? this.renderPlan() : this.renderNoPlan() }
        { plan && this.renderStatus() }
        { this.renderCreditCard() }
        {/* We run a no-obligation trial, so it's misleading to show users their balance when
            trialing.
            See https://help.chargify.com/subscriptions/subscription-states.html */}
        {/* TODO: We should ensure trialing users can't rack up usage charges, e.g. for Google. */}
        { plan && (plan.state !== 'trialing' && plan.state !== 'trial_ended') && this.renderBalance() }
        { this.renderBillingLink() }
      </Segment>
    );
  }
}

Usage.propTypes = {
  card: cardType,
  plan: planType,
  getCardRequestOut: PropTypes.bool.isRequired,
  getPlanRequestOut: PropTypes.bool.isRequired,
  connectedSendGetCard: PropTypes.func.isRequired,
  connectedSendGetPlan: PropTypes.func.isRequired,
};

Usage.defaultProps = {
  card: null,
  plan: null,
};

const mapStateToProps = (state) => ({
  card: state.account.card,
  getCardRequestOut: state.account.getCardRequestOut,
  getPlanRequestOut: state.account.getPlanRequestOut,
  plan: state.account.plan,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedSendGetCard: sendGetCard,
  connectedSendGetPlan: sendGetPlan,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Usage);
