import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import unauthMiddleware from './middlewares/unauth-middleware';

import history from './history';
import { loadState } from './localstorage';
import createRootReducer from './modules';

// Load the persistedState from localStorage or sessionStorage
// and uses it as initialState if available
const initialState = loadState() || {};
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
  unauthMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers,
);

export default store;
