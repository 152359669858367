import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Modal,
  Message,
  Input,
  Label,
} from 'semantic-ui-react';
import { debounce } from 'lodash';

import { clearError, sendChangePassword } from '../../modules/account';
import { errorType, infoType } from '../types';

const PASSWORD_MIN_LENGTH = 8;
const UNAUTHORIZED = 401;

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentPassword: '',
      newPassword: '',
      isReadyToChange: false,
      lengthError: false,
      changeComplete: false,
      showPassword: false,
    };
    this.debounceCheckLengthError = debounce(this.checkLengthError.bind(this), 300);
  }

  checkChangeComplete = () => {
    const { error } = this.props;
    this.setState({ changeComplete: error === undefined });
  }

  handleClick = (event) => {
    const { connectedSendChangePassword } = this.props;
    const { currentPassword, newPassword } = this.state;
    event.preventDefault();
    connectedSendChangePassword(currentPassword, newPassword)
      .finally(this.checkChangeComplete);
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  }

  handleModalClose = () => {
    const { connectedClearError } = this.props;
    connectedClearError();
    this.setState({
      modalOpen: false,
      currentPassword: '',
      newPassword: '',
      isReadyToChange: false,
      lengthError: false,
      changeComplete: false,
      showPassword: false,
    });
  }

  handleOnChange = (e) => {
    const { connectedClearError } = this.props;
    const { changeComplete } = this.state;
    if (changeComplete) {
      this.setState({ changeComplete: false });
    }
    if (e.target.id === 'currentPassword') {
      connectedClearError();
    }
    this.setState({ [e.target.id]: e.target.value }, () => {
      this.checkReadyToChange();
      this.debounceCheckLengthError();
    });
  }

  checkReadyToChange = () => {
    const { newPassword } = this.state;
    this.setState({
      isReadyToChange: newPassword.length >= PASSWORD_MIN_LENGTH,
    });
  }

  checkLengthError = () => {
    const { newPassword, lengthError } = this.state;
    const showErrorLength = newPassword.length > 0 && newPassword.length < PASSWORD_MIN_LENGTH;
    if (showErrorLength !== lengthError) {
      this.setState({ lengthError: showErrorLength });
    }
  }

  triggerError = () => {
    const { newPassword, currentPassword } = this.state;
    if (newPassword !== '' || currentPassword !== '') {
      this.setState({
        currentPassword: '',
        newPassword: '',
      });
    }
    return (
      <Message
        error
        className="password-message"
        content="Something went wrong. Please try again later."
      />
    );
  }

  successfulChange = () => {
    const { newPassword, currentPassword } = this.state;
    if (newPassword !== '' || currentPassword !== '') {
      this.setState({
        currentPassword: '',
        newPassword: '',
      });
    }
    return (
      <Message
        success
        className="password-message"
        content="Password was succesfully changed!"
      />
    );
  }

  handleEyeClick = () => {
    const { showPassword: currentShowPassword } = this.state;
    this.setState({ showPassword: !currentShowPassword },
      () => document.getElementById('newPassword').focus());
  }

  renderGridInputs = () => {
    const { info, error } = this.props;
    const {
      currentPassword,
      newPassword,
      lengthError,
      showPassword,
    } = this.state;
    const currentPasswordError = error !== undefined && error.error.code_description === 'Unauthorized';
    return (
      <Grid>
        <Grid.Row className="content-row">
          <Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ padding: 0 }}>
            Signed in as
          </Grid.Column>
          <Grid.Column width={12}>
            { info && info.email }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="content-row">
          <Grid.Column width={4} textAlign="right" verticalAlign="middle" style={{ paddingRight: 0 }}>
            <label
              htmlFor="currentPassword"
              style={{
                color: currentPasswordError && '#9f3a38',
              }}
            >
              Current password
            </label>
          </Grid.Column>
          <Grid.Column width={6}>
            <Input
              id="currentPassword"
              type="password"
              fluid
              value={currentPassword}
              onChange={this.handleOnChange}
              error={currentPasswordError}
            >
              <input />
            </Input>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign="middle" className="error-label-container">
            { currentPasswordError
            && (
              <Label basic className="error-label" pointing="left">
                Your password was incorrect.
              </Label>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="content-row">
          <Grid.Column
            width={4}
            textAlign="right"
            verticalAlign="middle"
            style={{ paddingRight: 0 }}
          >
            <label
              htmlFor="newPassword"
              style={{ color: lengthError && '#9f3a38' }}
            >
              New password
            </label>
          </Grid.Column>
          <Grid.Column width={6} className="pwd-input-container">
            <Input
              id="newPassword"
              type={showPassword ? 'text' : 'password'}
              fluid
              spellCheck={false}
              value={newPassword}
              onChange={this.handleOnChange}
              error={lengthError}
            >
              <input className="pwd-input" />
              <Button
                floated="left"
                icon={showPassword ? 'eye' : 'eye slash'}
                className="pwd-show-btn"
                onClick={this.handleEyeClick}
              />
            </Input>
          </Grid.Column>
          <Grid.Column width={6} verticalAlign="middle" className="error-label-container">
            { lengthError
              && (
                <Label basic className="error-label" pointing="left">
                  Your password must be at least&nbsp;
                  {PASSWORD_MIN_LENGTH}
                  &nbsp;characters long.
                </Label>
              )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    const {
      isReadyToChange,
      modalOpen,
      changeComplete,
    } = this.state;
    const { requestOut, error } = this.props;
    const disableChange = error !== undefined ? error.statusCode === UNAUTHORIZED : false;
    const isError = error && error.statusCode !== UNAUTHORIZED;
    const isSuccessful = changeComplete && error === undefined;
    return (
      <Modal
        closeOnDimmerClick={false}
        open={modalOpen}
        size="small"
        trigger={(
          <Button
            content="Change password"
            primary
            size="small"
            onClick={this.handleModalOpen}
          />
        )}
      >
        <Modal.Header>Change password</Modal.Header>
        <Modal.Content id="modal-content">
          { !changeComplete && (!isError || isSuccessful) && this.renderGridInputs() }
          {isError && this.triggerError()}
          {isSuccessful && this.successfulChange()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={changeComplete || isError ? 'Close' : 'Cancel'}
            size="small"
            onClick={this.handleModalClose}
            type="button"
            disabled={requestOut}
          />
          {
            !changeComplete && !isError
            && (
              <Button
                content="Change password"
                primary
                size="small"
                onClick={this.handleClick}
                loading={requestOut}
                disabled={!isReadyToChange || requestOut || disableChange}
              />
            )
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

ChangePassword.propTypes = {
  error: errorType,
  info: infoType,
  connectedClearError: PropTypes.func.isRequired,
  connectedSendChangePassword: PropTypes.func.isRequired,
  requestOut: PropTypes.bool.isRequired,
};

ChangePassword.defaultProps = {
  error: undefined,
  info: null,
};

const mapStateToProps = (state) => ({
  info: state.account.info,
  error: state.account.error,
  requestOut: state.account.changePasswordRequestOut,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedSendChangePassword: sendChangePassword,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePassword);
