const call = require('./utils/call');
const Extendable = require('./utils/extendable');
const messages = require('./utils/messages');

const profileKeys = ['name', 'organization', 'address_line1', 'address_line2', 'city', 'state_province', 'postal_code', 'country', 'phone_number', 'memo'];

const BASE_OPTIONS = Symbol();
/**
 * Account is for managing CRUD for accounts
 */
class Account extends Extendable {
  constructor(config, baseOptions) {
    super(config);
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Account);
  }

  /**
   * account
   * @params config Obj - optional, properties to be used to define list constraints
   * @returns native Promise - resolves Account with items updated, rejects error obj
   */
  get(config) {
    const account = this;

    config = config || {};

    account.extend(config);

    return new Promise((resolve, reject) => {
      const options = account[BASE_OPTIONS].getOptions();
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      const rq = call(options, undefined, reject);
      rq.then((data) => {
        account.extend(data);
        account.extend(data.profile);
        delete account.profile;
        delete account.settings;
        resolve(account);
      });
    });
  }

  /**
   * account
   * @params config Obj - optional, properties to be used to define list constraints
   * @returns native Promise - resolves Account with items updated, rejects error obj
   */
  post(config) {
    const account = this;

    config = config || {};
    account.extend(config);

    return new Promise((resolve, reject) => {
      if (!account.email) {
        reject({ message: messages.emailRequired });
        return;
      }

      const options = account[BASE_OPTIONS].getOptions({ method: 'POST', authRequired: false });
      options.auth = {
        user: account.email,
        pass: '',
      };

      if (account.plan) {
        options.body = {
          plan: account.plan,
        };
      }

      call(options, resolve, reject, account);
    });
  }

  /**
   * put
   * @params config Obj - optional, properties to be copied before being saved
   * @returns native Promise - resolves Account with items updated, rejects error obj
   */
  put(config) {
    const account = this;

    config = config || {};
    account.extend(config);

    function buildProfile(account) {
      const profile = {};
      profileKeys.forEach((key) => profile[key] = account[key]);
      return profile;
    }

    return new Promise((resolve, reject) => {
      const options = account[BASE_OPTIONS].getOptions({ method: 'POST' });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.url += '/profile';
      options.body = buildProfile(account);

      call(options, resolve, reject, account);
    });
  }

  setPassword(config) {
    const account = this;
    Account.setPassword(config, account[BASE_OPTIONS]);
  }

  sendVerificationToken(config) {
    const account = this;
    Account.sendVerificationToken(config, account[BASE_OPTIONS]);
  }

  getVerificationToken(config) {
    const account = this;
    Account.getVerificationToken(config, account[BASE_OPTIONS]);
  }
}

/**
 * account
 * @params config Obj - optional, properties to be used to define list constraints
 * @returns native Promise - resolves Account with items updated, rejects error obj
 */
Account.setPassword = function (config, baseOptions) {
  config = config || {};

  function validateInputs(config) {
    return config.newPassword && config.verificationToken && config.email;
  }

  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    if (!validateInputs(config)) {
      reject({ message: messages.setPasswordInputs });
      return;
    }

    const auth = `Basic ${new Buffer(`${config.email}:${config.newPassword}`).toString('base64')}`;

    const options = baseOptions.getOptions({ authRequired: false, method: 'POST' });
    options.url += '/password';
    options.headers = { Authorization: auth };
    options.body = { verification_token: config.verificationToken };

    call(options, resolve, reject);
  });
};

Account.getVerificationToken = function (config, baseOptions) {
  config = config || {};

  function validateInputs(config) {
    return (config.email && config.password);
  }
  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    if (!validateInputs(config)) {
      reject({ message: messages.basicRequired });
    }

    const auth = `Basic ${new Buffer(`${config.email}:${config.password}`).toString('base64')}`;

    const options = baseOptions.getOptions({ authRequired: true, method: 'POST' });
    options.url += '/verify';
    options.headers = { Authorization: auth };
    options.body = {};

    call(options, resolve, reject);
  });
};

Account.sendVerificationToken = function (config, baseOptions) {
  config = config || {};

  function validateInputs(config) {
    return config.email;
  }

  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    if (!validateInputs(config)) {
      reject({ message: messages.emailRequired });
      return;
    }

    const auth = `Basic ${new Buffer(`${config.email}:`).toString('base64')}`;

    const options = baseOptions.getOptions({ authRequired: false, method: 'POST' });
    options.url += '/verify';
    options.headers = { Authorization: auth };
    options.body = {};

    call(options, resolve, reject);
  });
};

Account.verifyVerificationToken = function (config, baseOptions) {
  config = config || {};

  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    if (!config.verificationToken) {
      reject({ message: messages.verificationTokenRequired });
      return;
    }

    const options = baseOptions.getOptions({ authRequired: false, excludeAuth: true, method: 'GET' });
    options.url += `/verify?verification_token=${config.verificationToken}`;
    call(options, resolve, reject);
  });
};

Account.getCard = function (config, baseOptions) {
  config = config || {};

  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    const options = baseOptions.getOptions({ authRequired: true });
    options.url += '/card';

    call(options, resolve, reject);
  });
};

Account.getDaemonInfo = function (config, baseOptions) {
  config = config || {};
  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    const options = baseOptions.getOptions({ authRequired: true });
    options.url += '/daemon';

    call(options, resolve, reject);
  });
};

Account.getPlan = function (config, baseOptions) {
  config = config || {};

  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    const options = baseOptions.getOptions({ authRequired: true });
    options.url += '/plan';

    call(options, resolve, reject);
  });
};

Account.getAccountSettings = (config, baseOptions) => {
  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    const options = baseOptions.getOptions({ authRequired: true });
    options.url += '/settings';

    call(options, resolve, reject);
  });
};

Account.setAccountSetting = (config, baseOptions) => {
  const setting = config || {};

  baseOptions.setResource(Account);

  return new Promise((resolve, reject) => {
    if (!setting.setting || !(setting.value !== undefined)) {
      reject({ message: 'Provide the setting name and its value.' });
    }

    const options = baseOptions.getOptions({
      authRequired: true,
      method: 'POST',
    });
    options.url += '/settings';
    options.body = { [setting.setting]: setting.value };

    call(options, resolve, reject);
  });
};

Account.resourceName = 'account';

module.exports = Account;
