/** setAuth
 * sets the Remeeting instance authentication informtion
 * it is intended to synchonous in most cases, and chainable
 * `rmi.setAuth({secret: 'yourApiSecret'}).keys.post({})`
 * should work. This is accomplished by returning the instance even if
 * setting the auth fails to actually set valid authentication.
 *
 * This means that to clear an instances authentication, you can call
 * setAuth with no arguements, or an empty config object.
 *
 * If multiple properties are given, the order of precidence is:
 * 1. 'email' and 'password' or the token created from 'createTemporaryToken'
 * 2. The 'key' property
 * 3. The 'secret' arument
 * 4. The first valid Key in the 'keys' arguement
 *
 * @params instance - Remeeting instance to set auth on
 * @params AUTH - the symbol to hide the auth under
 * @params config - Obj. - { keys: Array or Obj,
 *                           secret: String,
 *                           key: Key instance or Obj,
 *                           email: String,
 *                           password: String,
 *                           createTemporaryKey: Bool
 *                           clearAuth: Bool
 *                          }
 *
 * @returns Remeeting Instance (self)
 */
function setAuth(instance, AUTH, config) {
  if (!instance) { return; }
  if (!AUTH || !(typeof AUTH === 'symbol')) { return; }

  config = config || {};

  const auth = instance[AUTH] || { keys: {} };

  if (config.clearAuth) {
    // NOTE: this forEach loop is somewhat stylistically contentious:
    // https://github.com/airbnb/javascript#iterators--nope
    // https://stackoverflow.com/questions/500504/why-is-using-for-in-for-array-iteration-a-bad-idea/
    Object.keys(auth).forEach((prop) => {
      delete auth[prop];
    });
    auth.keys = {};
  }

  if (config.keys) {
    auth.keys = config.keys;
    auth.bearer = `Bearer ${config.keys[Object.keys(config.keys).sort()[0]].secret}`;
  }

  if (config.secret) {
    auth.bearer = `Bearer ${config.secret}`;
  }

  if (config.key) {
    auth.keys[config.key.id] = config.key;
    auth.bearer = `Bearer ${config.key.secret}`;
  }

  if (config.email && config.password) {
    auth.basic = `Basic ${new Buffer(`${config.email}:${config.password}`).toString('base64')}`;
    if (config.createTemporaryKey) {
      instance.keys.post({ email: config.email, password: config.password, ttl: 2592000 })
        .then((key) => {
          auth.bearer = `Bearer ${key.secret}`;
          auth.keys[key.id] = key;
        })
        .catch((error) => {
          // Note: setAuth is synchronous so there isn't a promise to reject
          // this may be a bad decision, but this part of the control flow isn't
          // expected to be used as the primary mode
          console.log('Failed to create a temporary key for ', config.email);
          console.log('error', error);
        });
    }
  }

  instance[AUTH] = auth;
  return instance;
}

module.exports = setAuth;
