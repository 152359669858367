// Disable ESLint rule because this is intended to be a simple single-file module.
/* eslint-disable max-classes-per-file */

/* Cats search library routines.
 *
 * There is also a Python version inside the mrp-dev project which is intended for future use
 * with ElasticSearch.
 *
 * Cats represent a document of recognition output as a sequence of time spans with an n-best list
 * of phrase alternatives ("alts") for each span.
 *
 * Each hit for the search phrase in the document is represented as a "match". A match is a
 * list the same length as the search phrase, containing the positions (DocPos objects; the
 * DocPos class is below) in the document where the words in the search phrase where found.
 *
 * The term "document" in this file is a carry-over from the Python version. We prefer to keep
 * the two versions as close as possible to make it easier to merge bug fixes & other improvements
 * back and forth. In this front end code, the term "document" in this file refers to a single
 * utterance.
 *
 * All the complexity in this file (especially, in searchInAnAlt) is because search phrase matches
 * can start in one times span's list of phrase alternatives and finish in another span's (or any
 * number of spans later). Otherwise, this fine could be replaced with just a simple JavaScript
 * string search.
 *
 * In future, if we want to increase search speed for long meetings one option is building
 * an inverted index like ElasticSearch does, e.g. with https://lunrjs.com/
 *
 * https://remeeting.atlassian.net/wiki/spaces/EN/pages/4227091/Cats
 * https://remeeting.atlassian.net/wiki/spaces/EN/pages/178094095/Code+for+finding+search+phrase+matches+in+cats+JSON
 */

const cloneDeep = require('lodash/cloneDeep');
const isEqual = require('lodash/isEqual');

const stemmer = require('./porter-stemmer');

/* Instead of using .split(" "), which will give us extra elements
   where there are multiple spaces in a row, the regex /\S+/g will just give
   us the list of words, split by all whitespace. This is to make the code
   equivalent to Python's .split() function. */
const splitOnWhitespace = (string) => string.match(/\S+/g) || [];

/**
 * Basic set operations
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#implementing_basic_set_operations
 */
const isSuperset = (set, subset) => {
  // https://github.com/airbnb/javascript#iterators--nope
  // AirBnb is a bit heavy-handed in its guidance to avoid for-of loops.
  // However, this is one of the exceptional cases in which it is used appropriately!
  // eslint-disable-next-line no-restricted-syntax
  for (const elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
};

const MatchCriteriaEnum = {
  // For a search phrase match, each search phrase word must match a complete word in the data.
  WHOLE_WORD: 'whole_word',
  // For a search phrase match, each search phrase word must be a substring of a word in the data.
  PARTIAL_WORD: 'partial_word',
  // For a search phrase match, each search phrase word must regex match a word in the data.
  // Each word in the query string is a separate regex and each regex can only span one word.
  // So query string "b.* y.*" can match "but yeah", but query string "b.*h" cannot.
  REGEX: 'regex',
  // Combination of WHOLE_WORD and REGEX.
  REGEX_WHOLE_WORD: 'regex_whole_word',
  // For a search phrase match, each stemmed search phrase word must match a stemmed word in the
  // data.
  STEMMED_WORD: 'stemmed_word',
};

/**
 * A DocPos is a word position in the document.
 */
class DocPos {
  /**
   * @param span integer - this span's position among spans in the document, counting from 0
   * @param alt integer - this alt's position among alts in the span, counting from 0
   * @param word integer - this word's position among words in the alt, counting from 0
   * @param silence Boolean - is this a silence that's being skipped during matching?
   */
  constructor(span, alt, word, silence = false) {
    this.span = span;
    this.alt = alt;
    this.word = word;
    this.silence = silence;
  }

  getWord(cats) {
    const words = splitOnWhitespace(cats[this.span].alternatives[this.alt].phrase);
    return words[this.word];
  }
}

DocPos.prototype.toString = function () {
  return `DocPos: ${this.span} ${this.alt} ${this.word}`;
};

/**
 * A PossiblePosition is used to track progress looking for the search phrase.
 *
 * The PP class is necessary because the search phrase can be more than one word long.
 * Each PP contains a position in the search phrase that we are now looking for a match at
 * (because we have matches for the words in the previous positions), and an array representing
 * the positions in the document that the previous word matches occurred at.
 */
class PossiblePosition {
  /**
   * @param searchPhrasePos integer - next word position in the search phrase, counting from 0
   * @param history array - the DocPos objects which are the matches for the previous words
   *  in the search phrase
   */
  constructor(searchPhrasePos, history) {
    this.searchPhrasePos = searchPhrasePos;
    this.history = history;
  }
}

/**
 * A match represents a search phrase match in the document.
 */
class Match {
  /**
   * @param positions array - the DocPos objects that make up the match. The array is the same
   *   length as the number of words in the search phrase
   * @param cost float - the combined acoustic and graph costs over the positions
   *
   * Note that costs are not strictly comparable between different matches which are happening over
   * different time spans. Costs are only strictly comparable between alts in the same time span.
   */
  constructor(positions, cost) {
    this.positions = positions;
    this.cost = cost;
  }
}

/**
 * A MatchText contains the document text version corresponding to a search phrase match in
 * the document. That means that the text for the span(s) of the match are taken from the match
 * alt(s), while elsewhere the text is taken from the 1best version of the document.
 */
class MatchText {
  /**
   * @param text string
   * @param cost float - the combined acoustic and graph costs. While the text is for the whole
   *   document, the cost is just based on the costs of the alts containing the search phrase match,
   *   because outside of those the text comes from the 1best and the cost of the 1best is
   *   normalized to 0 during cats generation.
   * @param startTime float - in seconds relative to the start of the utterance
   */
  constructor(text, cost, startTime) {
    this.text = text;
    this.cost = cost;
    this.startTime = startTime;
  }
}

/** Case insensitive string compare
 *
 * We use toUpperCase instead of toLowercase since that way we treat German
 * ß and ss as equivalent: 'ß'.toUpperCase() is 'SS'
 *
 * In future it would be good to also treat accented and accentless characters as equivalent.
 * (e.g., 'a' and 'á'). We might even want to move from spelling based matching to phonetic
 * matching.
 *
 * See:
 *  https://bitbucket.org/remeeting/mrp-www/issues/196/cats-find-should-be-smarter-about-european
 */
const ciEquals = (a, b) => (a.toUpperCase() === b.toUpperCase());
const ciContains = (a, b) => (b.toUpperCase().includes(a.toUpperCase()));

/**
 * Compare a string to a string or a RegExp to a string.
 */
const compare = (needle, haystack, matchCriteria) => {
  if (matchCriteria === MatchCriteriaEnum.REGEX
    || matchCriteria === MatchCriteriaEnum.REGEX_WHOLE_WORD) {
    return needle.test(haystack);
  }

  if (matchCriteria === MatchCriteriaEnum.PARTIAL_WORD) {
    return ciContains(needle, haystack);
  }

  if (matchCriteria === MatchCriteriaEnum.STEMMED_WORD) {
    return ciEquals(needle, stemmer(haystack));
  }

  return ciEquals(needle, haystack);
};

const searchWordToRegex = (searchWord) => new RegExp(searchWord, 'i');

const searchWordToWholeWordRegex = (searchWord) => new RegExp(`^${searchWord}$`, 'i');

/**
 * Total the costs across the passed-in array of DocPos objects
 *
 * 1-best alts have cost normalized to 0 during cats generation, so to find the cost of the
 * path through the document associated with a search phrase match, it's enough to look at just the
 * the positions matching the search phrase words and ignore the rest of the document.
 */
const costOfPositions = (positions, cats, acousticCostWeight, graphCostWeight) => {
  let cost = 0;
  const spansAlreadySeen = [];
  positions.forEach((docPos) => {
    if (!spansAlreadySeen.includes(docPos.span)) {
      // Since we don't have word-level cost information, only alt-level, we include the cost
      // of the entire alt even if the search phrase match does not include every word of the alt.
      const acousticCost = acousticCostWeight * cats[docPos.span].alternatives[docPos.alt].bias.am;
      const graphCost = graphCostWeight * cats[docPos.span].alternatives[docPos.alt].bias.lm;
      cost += acousticCost + graphCost;
      spansAlreadySeen.push(docPos.span);
    }
  });
  return cost;
};

/**
 * Function to search for a search phrase inside ONE alt in ONE span
 *
 * @param searchPhrase array - the phrase we are searching for, as an array of words.
 *
 * @param cats object - cats JSON data
 *
 * @param spanPosInDoc integer - the position in the doc of the span we are searching in.
 *
 * @param altPosInSpan integer - the position of the alt we are searching in, in its span
 *
 * @param possiblePositions array - A (possibly empty) array of PossiblePositions. These are
 * possible positions in the search phrase we might be at, based on words matched in previous
 * span(s) in the document, together with the paths through the spans in the document that got
 * us to those positions in the search phrase.
 *
 * @param matchCriteria - see catsSearch()
 * @param maxSilencesToSkip - see catsSearch()
 * @param maxSilenceSecondsToSkip - see catsSearch()
 * @param maxRankOfSilenceToSkip - see catsSearch()
 *
 * The possiblePositions array is inspired by positions[] in the Lucene's TermAutomatonScorer code.
 * There are Lucene blog posts about TermAutomatonQuery which explain TAQ and TAS. Because TAS is
 * more general (supporting a TAQ query graph instead of just a search phrase) it tracks states in
 * a query state machine, not possible positions (possiblePositions) in a search phrase. Another
 * analogy we could make is to something like section 2.2 (Finite State Automata) in Jurafsky and
 * Martin's Speech and Language Processing book. In that analogy, possiblePositions is keeping track
 * of the different states which an FSA for the search phrase might be on. For example if the search
 * phrase is 'the end of the day' and we've already seen 'the end of' and now we see a 'the', that
 * 'the' could be the 4th word of a search phrase match but could also be the 1st word of a search
 * phrase match, so the FSA could be in one of two different states. This analogy to state machines
 * could be helpful for seeing how searchInAnAlt() could be expanded in future to add support for
 * (e.g.) search phrase slop.
 *
 * searchInAnAlt() will return a result object with two fields: possiblePositions and matches:
 *
 * "possiblePositions" is the (possibly empty) updated array of PossiblePosition objects to be
 * passed back to searchInAnAlt() when it's called for the next span.
 *
 * "matchHistories" is the (possibly empty) array of of the matches for the search phrase that
 * were found in this alt, each represented as the array of positions (DocPos) objects that makes
 * up the match. They will be combined with costs later on to make Match objects.
 */
const searchInAnAlt = (searchPhrase, cats, spanPosInDoc, altPosInSpan, possiblePositions,
  matchCriteria, maxSilencesToSkip, maxSilenceSecondsToSkip, maxRankOfSilenceToSkip) => {
  // Positions we found the search phrase at
  const matchHistories = [];

  let possiblePositionsOut = possiblePositions;

  const alt = splitOnWhitespace(cats[spanPosInDoc].alternatives[altPosInSpan].phrase);

  // If the phrase alternative is empty (silence) it gets special treatment because we want a search
  // for, e.g., "hello world" to also match "hello [silence] world".
  if (alt.length === 0) {
    const possiblePositionsIn = possiblePositionsOut;
    possiblePositionsOut = [];

    if (altPosInSpan < maxRankOfSilenceToSkip) {
      possiblePositionsIn.forEach((pp) => {
        const history = pp.history.slice(); // Make a deep copy

        let silencesSkippedSoFar = 0;
        let silenceStartsAt = -1; // Use < 0 to mean no value.
        let currentlyInSilence = false;

        // Go through the history to check our budgets regarding silence.
        pp.history.forEach((docPos) => {
          if (docPos.silence) {
            silencesSkippedSoFar += 1;

            if (!currentlyInSilence) {
              [silenceStartsAt] = cats[docPos.span].interval;
              currentlyInSilence = true;
            }
          } else {
            // Reset, since we only count consecutive silences.
            currentlyInSilence = false;
            silencesSkippedSoFar = 0;
            silenceStartsAt = -1;
          }
        });

        if (silencesSkippedSoFar < maxSilencesToSkip) {
          if (silenceStartsAt < 0) {
            [silenceStartsAt] = cats[spanPosInDoc].interval;
          }
          const [, silenceEndsAt] = cats[spanPosInDoc].interval;
          if ((silenceEndsAt - silenceStartsAt) < maxSilenceSecondsToSkip) {
            const silenceDocPos = new DocPos(spanPosInDoc, altPosInSpan, 0, true);
            history.push(silenceDocPos);

            // We do not increase searchPhrasePos because we are just moving along past the silence
            // here; we haven't found a match yet for the current search phrase word.
            possiblePositionsOut.push(new PossiblePosition(pp.searchPhrasePos, history));
          }
        }
      });
    }
  } else {
    let wordPosInAlt = 0;
    alt.forEach((wordFromAlt) => {
      const possiblePositionsIn = possiblePositionsOut;
      possiblePositionsOut = [];

      const docPos = new DocPos(spanPosInDoc, altPosInSpan, wordPosInAlt);

      possiblePositionsIn.forEach((pp) => {
        const { searchPhrasePos } = pp;
        const history = pp.history.slice(); // Make a deep copy

        // If current word matches this position in the search phrase
        if (compare(searchPhrase[searchPhrasePos], wordFromAlt, matchCriteria)) {
          history.push(docPos);

          // If it's the last word of the search phrase
          if (searchPhrasePos + 1 === searchPhrase.length) {
            matchHistories.push(history);
          } else {
            // Now we have to see if the next word in the search phrase
            // matches the next word in the doc
            const nextPosInSearchPhrase = searchPhrasePos + 1;
            possiblePositionsOut.push(new PossiblePosition(nextPosInSearchPhrase, history));
          }
        }
      });

      // If the first word of the search phrase matches the current word in the doc
      if (compare(searchPhrase[0], wordFromAlt, matchCriteria)) {
        if (searchPhrase.length === 1) {
          // Found it
          matchHistories.push([docPos]);
        } else {
          // The next word in the doc should be checked against the second word in the
          // search phrase.
          const nextPosInSearchPhrase = 1; // We are now looking for the second word in searchPhrase
          possiblePositionsOut.push(new PossiblePosition(nextPosInSearchPhrase, [docPos]));
        }
      }

      wordPosInAlt += 1;
    });
  }

  return { possiblePositions: possiblePositionsOut, matchHistories };
};

/**
 * findMatches returns an array of Match objects representing all the hits found for searchPhrase
 * (an array of words) in the cats.
 */
const findMatches = (searchPhrase, cats, acousticCostWeight = 1.0, graphCostWeight = 1.0,
  matchCriteria = MatchCriteriaEnum.WHOLE_WORD, maxSilencesToSkip = 3,
  maxSilenceSecondsToSkip = 1.0, maxRankOfSilenceToSkip = 3) => {
  const matches = [];
  let possiblePositions = [];

  let preppedSearchPhrase = searchPhrase;
  if (matchCriteria === MatchCriteriaEnum.REGEX) {
    preppedSearchPhrase = preppedSearchPhrase.map(searchWordToRegex);
  } else if (matchCriteria === MatchCriteriaEnum.REGEX_WHOLE_WORD) {
    preppedSearchPhrase = preppedSearchPhrase.map(searchWordToWholeWordRegex);
  } else if (matchCriteria === MatchCriteriaEnum.STEMMED_WORD) {
    preppedSearchPhrase = preppedSearchPhrase.map(stemmer);
  }

  // Find all hits for the search phrase and put them in matches[]
  let spanIndex = 0;

  cats.forEach((span) => {
    const possiblePositionsForNextSpan = [];

    for (let altIndex = 0; altIndex < span.alternatives.length; altIndex += 1) {
      const altResults = searchInAnAlt(preppedSearchPhrase, cats, spanIndex, altIndex,
        possiblePositions, matchCriteria, maxSilencesToSkip, maxSilenceSecondsToSkip,
        maxRankOfSilenceToSkip);
      const possiblePositionsAfterThisAlt = altResults.possiblePositions;
      const matchHistoriesInThisAlt = altResults.matchHistories;

      possiblePositionsForNextSpan.push(...possiblePositionsAfterThisAlt);

      matchHistoriesInThisAlt.forEach((mh) => {
        const cost = costOfPositions(mh, cats, acousticCostWeight, graphCostWeight);
        matches.push(new Match(mh, cost));
      });
    }

    possiblePositions = possiblePositionsForNextSpan;

    spanIndex += 1;
  });

  return matches;
};

/* Given a match, returns the span indices in the match. */
const spansInMatch = (match) => (
  match.positions.filter((docPos) => !docPos.silence).map((docPos) => docPos.span)
);

/* Given a match, returns the alt indices in the match. */
const altsInMatch = (match) => (
  match.positions.filter((docPos) => !docPos.silence).map((docPos) => docPos.alt)
);

/**
 * Given an array of Match objects, returns the array with fully overlapping matches removed.
 *
 * In other words, if Match a and Match b are over the exact same time spans, only a or b will be
 * kept, whichever has the lower cost.
 */
const removeFullyOverlappingMatches = (matchesIn) => {
  // Make sure the lowest-cost matches are first.
  matchesIn.sort((a, b) => a.cost - b.cost);

  const matchesOut = [];
  matchesIn.forEach((match) => {
    const spans = spansInMatch(match);
    const alts = altsInMatch(match);

    const overlapping = matchesOut.some((otherMatch) => {
      const otherSpans = spansInMatch(otherMatch);
      const otherAlts = altsInMatch(otherMatch);

      /* A special case: if the search phrase occurs at more than one word position in the very
       * same alt, we don't consider the matches overlapping because we know for sure they don't
       * overlap in time. E.g., an alt contains "apple apple" and the search phrase is "apple",
       * so there are actually two non-overlapping hits for "apple".
       */
      if (isEqual(spans, otherSpans) && isEqual(alts, otherAlts)) {
        // Check the word positions. The word positions must be different if you're not performing
        // an OR boolean search, otherwise our algorithm wouldn't generate two separate matches with
        // the same spans and same alts.
        // A case where you might see exactly overlapping matches is in the search query
        // 'anything OR anything'.
        const wordPositions = match.positions.map((pos) => pos.word);
        const otherWordPositions = otherMatch.positions.map((pos) => pos.word);
        return isEqual(wordPositions, otherWordPositions);
      }
      return isEqual(new Set(spans), new Set(otherSpans));
    });

    if (!overlapping) {
      matchesOut.push(match);
    }
  });

  return matchesOut;
};

/**
 * Given an array of Match objects, returns an array with overlapping matches removed.
 *
 * More specifically, if Match b is a subset of Match a, Match b will be dropped. So partially
 * overlapping matches should be unaffected.
 *
 * NOTE: not to be confused with the other function `removeFullyOverlappingMatches`, where the other
 * function defines "fully overlapping" as covering the exact same time spans.
 *
 * NOTE: Weird behavior: if 2 matches are in the same span but different alts, the lower cost match
 * is returned. Hoewver if 2 one of the matches also includes another span, that match will be
 * returned, even if it has a higher cost. This is due to how removeFullyOverlappingMatches filters
 * the matches.
 * Search on elliot@rmtg.co prod account: `"the other mom was" "yellow one"` for a demonstration.
 */
const removeOverlappingMatches = (matchesIn) => {
  // Filter out low-interest matches
  const matches = removeFullyOverlappingMatches(matchesIn);
  // Sort matches by how many spans they are. We drop the shorter matches.
  matches.sort((a, b) => b.positions.length - a.positions.length);

  const matchesOut = [];
  matches.forEach((match) => {
    const spans = spansInMatch(match);
    const span2alt = match.positions
      .filter((p) => !p.silence)
      .map((p) => `${p.span}-${p.alt}`);
    const overlapping = matchesOut.some((otherMatch) => {
      const otherSpans = spansInMatch(otherMatch);
      if (isSuperset(new Set(otherSpans), new Set(spans))) {
        // Additionally check if alts match in overlapping spans.
        const otherSpan2alt = otherMatch.positions
          .filter((p) => !p.silence)
          .map((p) => `${p.span}-${p.alt}`);
        if (isSuperset(new Set(otherSpan2alt), new Set(span2alt))) {
          // If submatch contains same alts as longer match, check the positions.
          const positions = new Set(match.positions.map((p) => p.toString()));
          const otherPositions = new Set(otherMatch.positions.map((p) => p.toString()));
          return isSuperset(otherPositions, positions);
        }
        // If there are overlapping spans but different alts in the submatch, consider it an
        // overlap.
        return true;
      }
      return false;
    });

    if (!overlapping) {
      matchesOut.push(match);
    }
  });

  return matchesOut;
};

/**
 * This function returns the document text with the search phrase matches surfaced.
 *
 * In other words, for each match it returns a MatchText. The search phrase is in the text where
 * the match occurred, and before and after that, the text is always taken from the 1-best.
 *
 * @param cats object
 * @param matches array - the Match objects
 * @param startTag string - start of emphasis for a match, e.g. <em>
 * @param endTag string - end of emphasis for a match, e.g. </em>
 */
const findMatchTexts = (cats, matches, startTag, endTag) => {
  const matchTexts = [];

  // For each match we'll now find the full returned text that we would return to the user for
  // that match, along with the score for it, so that we can find and return the best-scoring one.
  matches.forEach((match) => {
    let text = '';

    // From beginning of doc up to the span before the match
    for (let spanIndex = 0; spanIndex < match.positions[0].span; spanIndex += 1) {
      const best = cats[spanIndex].alternatives[0].phrase; // Use the 1best alt for this span
      if (text) {
        text += ' ';
      }
      text += best;
    }

    /*
     * For the alts containing the match, find the highlighted text
     */

    // If the first word of the match is not the first word of its alt, fill in the non-match
    // words from that alt
    const docPos = match.positions[0];
    if (docPos.word > 0) {
      for (let wordIndex = 0; wordIndex < docPos.word; wordIndex += 1) {
        if (text) {
          text += ' ';
        }
        text += new DocPos(docPos.span, docPos.alt, wordIndex).getWord(cats);
      }
    }

    // The matching words, highlighted
    match.positions.forEach((docPos2) => {
      if (text) {
        text += ' ';
      }
      text += startTag + docPos2.getWord(cats) + endTag;
    });

    // If the last word of the match is not the last word of its alt, fill in the non-match
    // words from that alt
    const matchEnd = match.positions[match.positions.length - 1];
    const altLength = splitOnWhitespace(
      cats[matchEnd.span].alternatives[matchEnd.alt].phrase,
    ).length;
    if (matchEnd.word < altLength - 1) {
      for (let wordIndex = matchEnd.word + 1; wordIndex < altLength; wordIndex += 1) {
        if (text) {
          text += ' ';
        }
        text += new DocPos(matchEnd.span, matchEnd.alt, wordIndex).getWord(cats);
      }
    }

    // From the span after the match to the end of the doc
    for (let spanIndex = matchEnd.span + 1; spanIndex < cats.length; spanIndex += 1) {
      const best = cats[spanIndex].alternatives[0].phrase; // use the 1best alt for this span
      if (text) {
        text += ' ';
      }
      text += best;
    }

    const startTime = cats[match.positions[0].span].interval[0];
    const matchText = new MatchText(text, match.cost, startTime);
    matchTexts.push(matchText);
  });

  return matchTexts;
};

/**
 * Sorts the matches by the position in the utterance (DocPos) in which they appear.
 * This makes sure matches are in order of time again, following the sort by cost in
 * removeFullyOverlappingMatches.
 *
 * @param matchesIn - array of Matches objects to be sorted
 */
const sortMatchesByDocPos = (matchesIn) => matchesIn.sort((match1, match2) => {
  /* The "position" of the match is determined by the position of the beginning of the match */
  const pos1 = match1.positions[0];
  const pos2 = match2.positions[0];

  if (pos1.span === pos2.span) {
    return pos1.word - pos2.word;
  }

  return pos1.span - pos2.span;
});

/**
 * mergeOverlappingMatches
 *
 * This function returns a list of matches with overlapping matches merged. The overlapping matches
 * must be in the same alt, otherwise we take the match with the lower cost, tie-breaking based on
 * document position.
 *
 * This could happen with a boolean query where you end up with adjacent matches in the same span
 * but at different words.
 * We shouldn't have any fully overlapping matches because we remove them earlier.
 *
 * Ex. consider the following spans:
 *         A           B
 * | pigeons don't | exist |
 * and the query: `pigeons "don't exist"`
 * You'd get one match in span A and one match in both span A and B. The resulting highlights
 * should include all the words in both spans.
 *
 * Outstanding bugs/TODOs
 * - We should update the cost of the merged matches.
 * - Currently the condition for choosing matches for overlaps with mismatched alts would throw
 *   out a potentially large match chunk in favor of a later match if the latter has a lower cost.
 *   I (Elliot) am not sure if this is desirable.
 *
 * @param matches array - array of Match objects
 *
 * Returns the merged matches, sorted by DocPos
 */
const mergeOverlappingMatches = (matches) => {
  /* Remove overlapping matches. */
  const filteredMatches = removeOverlappingMatches(matches);
  /* Sort by position. */
  const sortedMatches = sortMatchesByDocPos(filteredMatches);
  /* Next check adjacent matches and merge if overlapping. */
  const mergedMatches = [];
  let prevMatch;
  sortedMatches.forEach((match) => {
    if (!prevMatch) {
      prevMatch = cloneDeep(match);
      mergedMatches.push(prevMatch);
    } else {
      let prevPosIdx = prevMatch.positions.length - 1;
      let posIdx = 0;
      if (prevMatch.positions[prevPosIdx].span < match.positions[posIdx].span) {
        /* Spans don't overlap, so iterate. */
        prevMatch = cloneDeep(match);
        mergedMatches.push(prevMatch);
      } else {
        /* Spans overlap. */
        /* First see if there are any conflicting alts in the overlapped spans. */
        while (prevMatch.positions[prevPosIdx]
            && prevMatch.positions[prevPosIdx].span >= match.positions[posIdx].span) {
          prevPosIdx -= 1;
        }
        prevPosIdx += 1;
        let hasMismatchedAlts = false;
        const prevSpanAlt = {}; // spanIdx -> altIdx
        for (prevPosIdx; prevPosIdx < prevMatch.positions.length; prevPosIdx += 1) {
          const prevPos = prevMatch.positions[prevPosIdx];
          prevSpanAlt[prevPos.span] = prevPos.alt;
        }
        prevPosIdx -= 1;
        /* Note the loop condition should be safe because there shouldn't be any fully overlapping
           matches at this point. And by "fully overlapping" we don't mean it as described in the
           docstring for removeFullyOverlappingMatches... */
        for (posIdx;
          match.positions[posIdx].span < prevMatch.positions[prevPosIdx].span;
          posIdx += 1) {
          const pos = match.positions[posIdx];
          if (pos.alt !== prevSpanAlt[pos.span]) {
            hasMismatchedAlts = true;
            break;
          }
        }
        /* Fence-posting: posIdx should now mark the first position in match in the same span as the
           the last position in prevMatch's span. */
        if (match.positions[posIdx].alt !== prevSpanAlt[match.positions[posIdx].span]) {
          /* If alts don't match, we mark it and move on. */
          hasMismatchedAlts = true;
        } else {
          /* Else we iterate posIdx until we reach the first word in match not in prevMatch. */
          while (match.positions[posIdx].span === prevMatch.positions[prevPosIdx].span
              && match.positions[posIdx].word <= prevMatch.positions[prevPosIdx].word) {
            posIdx += 1;
          }
        }
        if (hasMismatchedAlts) {
          /* If overlapping spans have mismatched alts, drop one of them (see docstring) */
          if (match.cost < prevMatch.cost) {
            mergedMatches.pop();
            prevMatch = cloneDeep(match);
            mergedMatches.push(prevMatch);
          }
        } else if (prevMatch.positions[prevPosIdx].word < (match.positions[posIdx].word - 1)) {
          /* If the words aren't adjacent, these are still separate matches. */
          prevMatch = cloneDeep(match);
          mergedMatches.push(prevMatch);
        } else {
          /* Add the rest of the positions from match to prevMatch and push. */
          for (posIdx; posIdx < match.positions.length; posIdx += 1) {
            prevMatch.positions.push(match.positions[posIdx]);
          }
        }
      }
    }
  });
  return mergedMatches;
};

/**
 * Search for a phrase in a document represented as cats.
 *
 * @param searchPhrase array - the phrase we are searching for, as a list of words.
 * @param cats object - cats JSON data
 * @param acousticCostWeight float
 * @param graphCostWeight float
 * @param startTag string - start of emphasis for a match, e.g. <em>
 * @param endTag string - end of emphasis for a match, e.g. </em>
 * @param sorted Boolean - ensure matches are sorted by time
 * @param matchCriteria MatchCriteriaEnum - how to perform matching
 * @param maxSilencesToSkip int - number of silences that can be skipped over in a single match
 * @param maxSilenceSecondsToSkip float - max total duration of silence that can be skipped over
 * @param maxRankOfSilenceToSkip int - set to 1 if only silences that are the 1best can be
 *                                     skipped, set to 2 to include 1-best or 2-best silences, etc.
 * @param matchPhrase Boolean - perform exact phrase matching
 *
 * Returns a list of Match objects with redundant (overlapping) matches stripped out. If startTag
 * and endTag are specified, returns MatchText objects instead of Match objects.
 */
const catsSearch = (searchPhrase, cats, acousticCostWeight = 1, graphCostWeight = 1,
  startTag = null, endTag = null, sorted = true, matchCriteria = MatchCriteriaEnum.WHOLE_WORD,
  maxSilencesToSkip = 3, maxSilenceSecondsToSkip = 1.0, maxRankOfSilenceToSkip = 3,
  matchPhrase = true) => {
  // Perform the search.
  let matches = findMatches(searchPhrase, cats, acousticCostWeight, graphCostWeight, matchCriteria,
    maxSilencesToSkip, maxSilenceSecondsToSkip, maxRankOfSilenceToSkip);

  // Early return if no matches, so we don't waste any more CPU time.
  if (matches.length === 0 && matchPhrase) {
    return [];
  }

  // Filter out low-interest matches.
  matches = removeFullyOverlappingMatches(matches);

  if (!matchPhrase && searchPhrase.length > 1) {
    // Additionally perform search on individual tokens in searchPhrase
    // TODO: Improve logic in findMatches to handle this to avoid repetitive searching?
    let tokenMatches = [];
    searchPhrase.forEach((word) => {
      tokenMatches.push(...findMatches([word], cats, acousticCostWeight, graphCostWeight,
        matchCriteria, maxSilencesToSkip, maxSilenceSecondsToSkip, maxRankOfSilenceToSkip));
    });
    tokenMatches = removeFullyOverlappingMatches(tokenMatches);
    // Remove token matches that overlap with phrase matches, ie prioritize phrase matches.
    tokenMatches = tokenMatches.filter((tokenMatch) => {
      // A match for a single token shouldn't have more than 1 DocPos.
      const tokenSpan = tokenMatch.positions[0].span;
      for (let i = 0; i < matches.length; i += 1) {
        const phraseSpans = spansInMatch(matches[i]);
        if (phraseSpans.includes(tokenSpan)) {
          return false;
        }
      }
      return true;
    });
    matches.push(...tokenMatches);
  }

  if (startTag !== null && endTag != null) {
    return findMatchTexts(cats, matches, startTag, endTag);
  }

  if (sorted) {
    return sortMatchesByDocPos(matches);
  }

  return matches;
};

module.exports = {
  catsSearch,
  costOfPositions,
  DocPos,
  findMatches,
  findMatchTexts,
  Match,
  MatchCriteriaEnum,
  MatchText,
  mergeOverlappingMatches,
  removeFullyOverlappingMatches,
  removeOverlappingMatches,
  searchInAnAlt,
  splitOnWhitespace,
};
