import { makeCancelable } from './utils';

const SEARCH_REQUEST_SENT = 'SEARCH::REQUEST_SENT';
const SEARCH_REQUEST_SUCCESS = 'SEARCH::REQUEST_SUCCESS';
const SEARCH_REQUEST_FAIL = 'SEARCH::REQUEST_FAIL';
const CLEAR_SEARCH = 'SEARCH::CLEAR_STATE';

const FOCUS_SEARCH_BAR = 'SEARCH::FOCUS_SEARCH_BAR';
const BLUR_SEARCH_BAR = 'SEARCH::BLUR_SEARCH_BAR';

export const SEARCH_PAGE_SIZE = 10;

const initialState = {
  error: undefined,
  hits: {},
  requestOut: false,
  requestPromise: undefined,
  searchBarFocused: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SEARCH:
      return {
        ...state,
        requestOut: false,
        requestPromise: undefined,
        hits: {},
        error: undefined,
      };
    case BLUR_SEARCH_BAR:
      return { ...state, searchBarFocused: false };
    case FOCUS_SEARCH_BAR:
      return { ...state, searchBarFocused: true };
    case SEARCH_REQUEST_SENT:
      return {
        ...state,
        requestOut: true,
        requestPromise: action.promise,
        error: undefined,
      };
    case SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        requestOut: false,
        requestPromise: undefined,
        hits: action.hits,
      };
    case SEARCH_REQUEST_FAIL:
      return {
        ...state,
        requestOut: false,
        requestPromise: undefined,
        hits: {},
        error: action.error,
      };
    default:
      return state;
  }
};

export const clearSearch = () => (dispatch, getState) => {
  const { requestPromise } = getState().search;
  if (requestPromise) requestPromise.cancel();
  dispatch({ type: CLEAR_SEARCH });
};

export const sendSearch = (config) => (dispatch, getState) => {
  const { rmi } = getState();

  const { requestPromise } = getState().search;
  if (requestPromise) requestPromise.cancel();
  const searchPromise = makeCancelable(rmi.search(config));
  dispatch({ type: SEARCH_REQUEST_SENT, promise: searchPromise });
  searchPromise.promise
    .then((resp) => {
      dispatch({ type: SEARCH_REQUEST_SUCCESS, hits: resp });
    })
    .catch((error) => {
      if (!error.isCanceled) {
        dispatch({ type: SEARCH_REQUEST_FAIL, error });
      }
    });
  return searchPromise.promise;
};

export const toggleSearchBarFocus = (shouldFocus) => (dispatch, getState) => {
  if (shouldFocus === undefined) {
    const { searchBarFocused } = getState().search;
    if (searchBarFocused) {
      dispatch({ type: BLUR_SEARCH_BAR });
    } else {
      dispatch({ type: FOCUS_SEARCH_BAR });
    }
  } else if (shouldFocus) {
    dispatch({ type: FOCUS_SEARCH_BAR });
  } else {
    dispatch({ type: BLUR_SEARCH_BAR });
  }
};
