import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import queryString from 'query-string';

import ConsoleErrorMessage from './utils/console-error-message';
import { errorType } from './types';
import { endSession } from '../modules/session';

/**
 * Private route component is based off of a Mcginnis post at
 * https://tylermcginnis.com/react-router-protected-routes-authentication/
 *
 * Basically, the goal is to centralize 'protecting' a route to this component
 * so that route components can assume the user is signed in successfully. The only
 * place that PrivateRoute should be used is the top level App component, as
 * sub routes won't be updated correctly.
 *
 * As per other notes, this only works in a maintainable fassion if by
 * convention the App component is the ONLY place where ProtectedRoutes are
 * defined, and this component is the ONLY place session level auth
 * is checked.
 *
 * You have been warned - Cooper, the vengeful.
 */
class ProtectedRoute extends Component {
  componentDidMount() {
  }

  /**
   * This error modal is for errors extending the session token
   * and thus will not be able to be closed. A user will be shown
   * a Sign In button to click to end the session and start a new
   * one.
   */
  renderErrorModal = () => {
    const { error } = this.props;
    return (
      <Modal
        open={error}
      >
        <Modal.Header>Oops!</Modal.Header>
        <Modal.Content>
          <ConsoleErrorMessage error={error} />
        </Modal.Content>
      </Modal>
    );
  }

  renderExpectedUserModal = () => {
    const { email, connectedEndSession } = this.props;
    return (
      <Modal
        open
      >
        <Modal.Header>Oops!</Modal.Header>
        <Modal.Content>
          <p>
            The page you are trying to view belongs to
            {' '}
            <b>{email}</b>
            . Either sign in with this email address, or request a share link from
            the owner of the account.
          </p>
          <Button onClick={connectedEndSession} primary>Sign out</Button>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const {
      component,
      render,
      error,
      email,
      path,
      exact,
      signedIn,
    } = this.props;

    const ProtectedComponent = component;
    const ProtectedRender = render;

    return (
      <Route
        path={path}
        exact={exact}
        render={(props) => {
          /* For old share links, redirect to new route format */
          const { s, ...other } = queryString.parse(props.location.search);
          if (s && path === '/r/:meetingId') {
            return (
              <Redirect
                to={{
                  pathname: `${props.location.pathname}/${s}`,
                  search: queryString.stringify(other),
                }}
              />
            );
          }

          if (!signedIn) {
            return (
              <Redirect
                to={{
                  pathname: '/signin',
                  state: { from: props.location },
                }}
              />
            );
          }

          const {
            history,
            location,
            match,
          } = props;
          const toRender = ProtectedComponent
            ? <ProtectedComponent history={history} location={location} match={match} />
            : ProtectedRender(props);

          const { e } = queryString.parse(props.location.search);
          if (e && decodeURIComponent(e) !== email) {
            /* Wrong expected email, show a modal that directs them to login */
            return (
              <>
                { this.renderExpectedUserModal() }
                { toRender }
              </>
            );
          }

          if (e && decodeURIComponent(e) === email) {
            const { e: ignore, ...newSearch } = queryString.parse(props.location.search || '');
            return (
              <Redirect
                to={{
                  pathname: props.location.pathname,
                  search: `?${queryString.stringify(newSearch)}`,
                }}
              />
            );
          }

          if (error) {
            return (
              <>
                { this.renderErrorModal() }
                { toRender }
              </>
            );
          }

          return toRender;
        }}
      />
    );
  }
}

ProtectedRoute.propTypes = {
  render: PropTypes.func,
  email: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType,
  signedIn: PropTypes.bool.isRequired,
  error: errorType,
  connectedEndSession: PropTypes.func.isRequired,
  exact: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  render: undefined,
  component: undefined,
  error: undefined,
  exact: false,
};

const mapStateToProps = (state) => ({
  signedIn: state.session.signedIn,
  email: state.session.email,
  error: state.session.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedEndSession: endSession,
}, dispatch);

// the withRouter here is necessary because the 'child routes' in app
// weren't reacting to location changes because they weren't recieving props
// from react router, see
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/redux.md
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtectedRoute));
