import React from 'react';
import { Grid, Input, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Keyboard input field with value tied to slider.
// Based on example code at https://material-ui.com/components/slider

const styles = () => ({
  root: {
    width: 350,
  },
  grid: {
    height: 28,
  },
  input: {
    width: 45,
    // Restate our app's font settings for modals, to cancel out material-ui's override of them.
    fontFamily: "'Source Sans Pro', 'Segoe UI', Arial, Helvetica, 'sans-serif'",
    fontSize: '16px',
  },
});

class InputSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setValue(value);
    }
  }

  setSliderValue = (newValue) => {
    this.setState({
      value: newValue,
    });
  }

  setValue = (newValue) => {
    const {
      onChange, marks, sliderMin, sliderMax,
    } = this.props;

    let setValue = newValue;

    if (marks) {
      for (let i = 0; i < marks.length; i += 1) {
        if (Math.abs(newValue - marks[i].value) < (sliderMax - sliderMin) / 30) {
          setValue = marks[i].value;
        }
      }
    }
    this.setSliderValue(setValue);
    onChange(setValue);
  };

  handleSliderChange = (event, newValue) => {
    this.setValue(newValue);
  };

  handleInputChange = (event) => {
    this.setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  handleBlur = () => {
    const { value } = this.state;
    const { sliderMin, sliderMax } = this.props;

    if (value < sliderMin) {
      this.setValue(sliderMin);
    } else if (value > sliderMax) {
      this.setValue(sliderMax);
    }
  };

  render() {
    const {
      classes,
      sliderStep,
      leftLabel,
      rightLabel,
      leftInputLabel,
      rightInputLabel,
      showInputField,
      disabled,
      sliderMax,
      sliderMin,
      marks,
    } = this.props;

    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={1} className={classes.grid}>
          <Grid item>
            {leftLabel}
          </Grid>
          <Grid item xs>
            <Slider
              value={typeof value === 'number' ? value : sliderMin}
              step={sliderStep}
              min={sliderMin}
              max={sliderMax}
              onChange={this.handleSliderChange}
              aria-labelledby="input-slider"
              disabled={disabled}
              marks={marks}
            />
          </Grid>
          <Grid item>
            {rightLabel}
          </Grid>
        </Grid>
        {
          showInputField && (
            <Grid container spacing={1} className={classes.grid}>
              <Grid item>
                <p style={{ lineHeight: '25px' }}>{leftInputLabel}</p>
              </Grid>
              <Grid item>
                {rightInputLabel}
                <Input
                  className={classes.input}
                  value={value}
                  margin="dense"
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    step: sliderStep,
                    min: sliderMin,
                    max: sliderMax,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          )
        }
      </div>
    );
  }
}

InputSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  sliderMin: PropTypes.number.isRequired,
  sliderMax: PropTypes.number.isRequired,
  sliderStep: PropTypes.number.isRequired,
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  leftInputLabel: PropTypes.node,
  rightInputLabel: PropTypes.node,
  showInputField: PropTypes.bool,
  disabled: PropTypes.bool,
  marks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })),
  classes: PropTypes.shape({
    root: PropTypes.string,
    grid: PropTypes.string,
    input: PropTypes.string,
  }).isRequired,
};

InputSlider.defaultProps = {
  marks: [],
  leftInputLabel: '',
  rightInputLabel: '',
  showInputField: true,
  disabled: false,
};

export default withStyles(styles)(InputSlider);
