export default {
  visa: 'Visa',
  master: 'Master',
  discover: 'Discover',
  american_express: 'Amex',
  diners_club: 'Diners Club',
  jcb: 'JCB',
  switch: 'Switch',
  solo: 'Solo',
  dankort: 'Dankort',
  maestro: 'Maestro',
  forbrugsforeningen: 'Forbrugsforeningen',
  laser: 'Laser',
};
