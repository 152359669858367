import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Popup } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import Usage from './usage';
import VendorSettings from './vendorSettings';
import Personal from './personal';

import { clearError } from '../../modules/account';

import '../../stylesheets/account.css';

class Account extends Component {
  componentDidMount() {
    const { connectedClearError } = this.props;
    connectedClearError();
  }

  renderAccountCancelLink = () => (
    <Popup
      key="billing-popup"
      trigger={
        <a href="mailto:support@remeeting.com">Cancel account?</a>
      }
      hoverable
      on="hover"
      position="left center"
    >
      Contact&nbsp;
      <a href="mailto:support@remeeting.com">support@remeeting.com</a>
      &nbsp;to request account cancellation.
    </Popup>

  );

  render() {
    return (
      <div className="re is-main">
        <Helmet>
          <title>Account &mdash; Remeeting</title>
        </Helmet>
        <div className="account-page">
          <div className="re is-header">
            <Header as="h1" className="re is-header-item">
              Account
            </Header>
          </div>
          <div className="account-page-content">
            <Usage />
            <VendorSettings />
            <Personal />
            { this.renderAccountCancelLink() }
          </div>
        </div>
      </div>
    );
  }
}

Account.propTypes = {
  connectedClearError: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
}, dispatch);

export default connect(
  undefined,
  mapDispatchToProps,
)(Account);
