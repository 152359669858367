import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight, faChevronDown, faTimes, faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { Spinner } from 'reactstrap';

import {
  lookupWordInLexicon,
  setWordFrequency,
  lookupPronunciation,
  addPronunciationsToWord,
  getLMProbability,
  removeWordFromCustomize,
  setWordChanged,
  setOriginalWordInfo,
  setWordToBeDeleted,
} from '../../../modules/customize';
import InputSlider from '../inputSlider';
import { keyCodes } from '../../../modules/utils';
import PronunciationItem from './pronunciationItem';
import { lexiconInfoType } from '../../types';

class CustomizeTableItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      addSoundsLikeText: '',
      waitingForProns: false,
      waitingForPrefill: false,
    };
  }

  componentDidMount() {
    const { word } = this.props;

    /* If the word is already in vocab, we fill the Redux item in with the given data. */
    this.prefillLexEntry(word);
  }

  componentDidUpdate(prevProps) {
    const { connectedSetWordChanged, wordInfo, word } = this.props;
    const { wordInfo: prevWordInfo } = prevProps;

    if (wordInfo && prevWordInfo) {
      if (
        prevWordInfo.frequency !== wordInfo.frequency
        || prevWordInfo.pronunciations !== wordInfo.pronunciations
      ) {
        connectedSetWordChanged(word, this.checkWordInfoChanged());
      }
    }
  }

  openItem = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  removeItem = () => {
    const { connectedRemoveWord, word } = this.props;
    connectedRemoveWord(word);
  }

  onWordFrequencySliderChanged = (word, newValue) => {
    const { connectedSetFrequency } = this.props;
    connectedSetFrequency(word, newValue);
  }

  checkWordInfoChanged = () => {
    const { wordInfo, originalWordInfo } = this.props;

    if (!wordInfo) return false;

    /* If no entry already exists, then there is a change by definition */
    if (wordInfo && !originalWordInfo) return true;

    /* Are frequencies different? */
    if (wordInfo.frequency !== originalWordInfo.frequency) {
      return true;
    }

    /* Are pronunciations different? */
    const originalPronsSet = new Set(originalWordInfo.pronunciations);
    if (
      !(
        wordInfo.pronunciations.every((pron) => originalPronsSet.has(pron))
        && wordInfo.pronunciations.length === originalWordInfo.pronunciations.length
      )
    ) {
      return true;
    }

    return false;
  }

  prefillLexEntry = async (word) => {
    const {
      wordInfo,
      originalWordInfo,
      connectedLexicalLookup,
      connectedAddPronunciationsToWord,
      connectedGetLMProbability,
      connectedSetFrequency,
      connectedSetOriginalWordInfo,
    } = this.props;

    try {
      this.setState({ waitingForPrefill: true });

      /* If we've already pre-loaded this, no need to make more requests */
      if (originalWordInfo && originalWordInfo.frequency && originalWordInfo.pronunciations) {
        if (!wordInfo.pronunciations || !wordInfo.frequency) {
          connectedSetFrequency(word, originalWordInfo.frequency);
          connectedAddPronunciationsToWord(word, originalWordInfo.pronunciations);
        }

        this.setState({ waitingForPrefill: false });
        return;
      }

      const lexicalLookup = await connectedLexicalLookup(word);
      if (lexicalLookup.word) {
        /* Since we know the word is in vocab, we locate it in the unigram LM. */
        try {
          const lmLookup = await connectedGetLMProbability(word);

          /* Save this downloaded entry as the "original", so we can check what's changed by the
             user */
          connectedSetOriginalWordInfo(word, {
            frequency: lmLookup.probability_log10,
            pronunciations: lexicalLookup.pronunciations.map((pron) => pron.pronunciation),
          });

          /* Check if this row has already been persisted in Redux. If not, we fill in the
             information we just got. */
          if (!Object.keys(wordInfo).length) {
            /* Already found this word in vocab. Instead of using G2P to guess pronunciation,
              retrieve the ones already supplied by the lexicon. */
            for (let i = 0; i < lexicalLookup.pronunciations.length; i += 1) {
              connectedAddPronunciationsToWord(
                word,
                [lexicalLookup.pronunciations[i].pronunciation],
              );
            }

            if (lmLookup.words) {
              /* We should always reach this point. If we don't, then there is a conflict between
                lexicon and LM. We use this to populate what we call "frequency". */
              connectedSetFrequency(word, lmLookup.probability_log10);
            }
          }
        } catch (error) {
          // Do nothing.
        }
      } else {
        /* If no lexicon entry is available, we guess the pronunciations for easier UX and let the
           user "create" a new lexicon entry. */
        await this.updateProns(word);

        /* We then set dummy values for the other properties. */
        connectedSetFrequency(word, -5);
      }
    } catch (error) {
      // do nothing.
    }

    this.setState({ waitingForPrefill: false });
  }

  updateProns = async (phrase) => {
    const {
      word,
      connectedLookupPronunciation,
      connectedAddPronunciationsToWord,
      lexiconInfo,
    } = this.props;

    try {
      this.setState({ waitingForProns: true });

      /* If the "phrase" is actually a list of phones, we simply pass it through instead
         of making the extra G2P request. */
      let isPhones = true;
      if (lexiconInfo) {
        /* In particular, we split the phrase into words and check if each word is a legal
           phoneme from the lexicon's phone set. */
        const phraseWords = phrase.match(/\S+/g);
        for (let i = 0; i < phraseWords.length; i += 1) {
          if (
            !lexiconInfo.phones.includes(phraseWords[i].toUpperCase())
          ) {
            isPhones = false;
            break;
          }
        }
      }

      let pronunciations;
      if (isPhones) {
        pronunciations = [phrase.trim().match(/\S+/g).join(' ').toUpperCase()];
      } else {
        pronunciations = await connectedLookupPronunciation(phrase);
      }

      connectedAddPronunciationsToWord(word, pronunciations);
      this.setState({ waitingForProns: false });
    } catch (e) {
      this.setState({ waitingForProns: false });
    }
  }

  onAddSoundsLikeClicked = async () => {
    const {
      addSoundsLikeText,
    } = this.state;

    this.updateProns(addSoundsLikeText);
    this.setState({ addSoundsLikeText: '' });
  }

  onWordDeleteChecked = (e) => {
    const { checked } = e.target;
    const { connectedSetWordToBeDeleted, word } = this.props;

    connectedSetWordToBeDeleted(word, checked);
  }

  validateSoundsLikeText = () => {
    const { addSoundsLikeText } = this.state;
    if (!addSoundsLikeText.length) return false;
    if (!/^[a-zA-Z ]+$/.test(addSoundsLikeText)) return false;

    return true;
  }

  validateWordInfo = () => {
    const { wordInfo } = this.props;
    if (!wordInfo) return true;
    if (wordInfo.toDelete) return true;
    if (wordInfo.pronunciations && wordInfo.pronunciations.length === 0) return false;
    return true;
  }

  render() {
    const {
      word,
      wordInfo,
      originalWordInfo,
    } = this.props;

    const {
      open,
      addSoundsLikeText,
      waitingForProns,
      waitingForPrefill,
      lexiconInfoRequestOut,
    } = this.state;

    let contextText;
    if (wordInfo) {
      if (originalWordInfo && !wordInfo.toDelete) {
        contextText = (
          <span style={{ color: 'grey' }}>
            Editing:
            {' '}
          </span>
        );
      } else if (originalWordInfo && wordInfo.toDelete) {
        contextText = (
          <span style={{ color: 'darkred' }}>
            Removing:
            {' '}
          </span>
        );
      } else {
        contextText = (
          <span style={{ color: 'grey' }}>
            Adding:
            {' '}
          </span>
        );
      }
    }

    if (wordInfo) {
      return (
        <div className={`customize-table-item ${open ? 'open' : ''}`}>
          <span
            className="customize-table-item-row"
            onClick={this.openItem}
            onKeyDown={(e) => { if (e.keyCode === 13) this.openItem(); }}
            role="button"
            tabIndex={0}
          >
            <div className="customize-table-item-left">
              <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} size="1x" />
            </div>
            <div className="customize-table-item-right">
              <div className="customize-word">
                {
                  waitingForPrefill
                    ? <CircularProgress size={20} />
                    : (
                      <>
                        <span style={{ color: 'grey' }}>
                          {
                            contextText
                          }
                        </span>
                        { word }
                        {
                          !this.validateWordInfo() && (
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              color="darkred"
                              style={{ marginLeft: 10 }}
                            />
                          )
                        }
                      </>
                    )
                }
              </div>
              <div
                className="remove-word"
                onClick={this.removeItem}
                onKeyDown={(e) => { if (e.keyCode === 13) this.removeItem(); }}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </div>
            </div>
          </span>
          <div className="customize-table-item-content">
            {
              waitingForPrefill
              || lexiconInfoRequestOut
              || !wordInfo.pronunciations
              || !wordInfo.frequency
                ? <Spinner size="lg" />
                : (
                  <>
                    {
                      /* TODO: Uncomment below when we enable removing words from vocab. */
                      /* originalWordInfo */ false && (
                        <div className="ui checkbox">
                          <input
                            type="checkbox"
                            name="example"
                            id={`delete-item-${word}`}
                            onChange={this.onWordDeleteChecked}
                          />
                          <label
                            htmlFor={`delete-item-${word}`}
                            className="delete-checkbox-text"
                          >
                            Remove
                            {' "'}
                            { word }
                            {'" '}
                            from vocabulary
                          </label>
                        </div>
                  )
                    }
                    <p />
                    <span className={`can-disable ${wordInfo.toDelete ? 'disabled' : ''}`}>
                      <h4>Word frequency</h4>
                      <InputSlider
                        onChange={(val) => { this.onWordFrequencySliderChanged(word, val); }}
                        value={wordInfo.frequency}
                        leftLabel="Rare"
                        rightLabel="Common"
                        showInputField={false}
                        sliderMax={-1}
                        sliderMin={-9} /* The least-probable word I found in the LM is -8.28 */
                        sliderStep={0.01}
                        marks={
                          originalWordInfo
                            ? [
                              { value: originalWordInfo.frequency, label: 'Current' },
                            ]
                            : undefined
                        }
                        disabled={wordInfo.toDelete || false}
                      />
                      <h4>Pronunciations</h4>
                      <Grid container spacing={2}>
                        <Grid item xs={1}>
                          <button
                            color="primary"
                            onClick={this.onAddSoundsLikeClicked}
                            disabled={!this.validateSoundsLikeText()}
                            className="add-share-link-button"
                            type="button"
                          >
                            {
                              waitingForProns
                                ? <Spinner size="sm" />
                                : '+'
                            }
                          </button>
                        </Grid>
                        <Grid item xs={6}>
                          <input
                            type="text"
                            placeholder="Add a pronunciation (sound it out)..."
                            onChange={
                              (e) => { this.setState({ addSoundsLikeText: e.target.value }); }
                            }
                            onKeyDown={
                              (e) => {
                                if (e.keyCode === keyCodes.ENTER && this.validateSoundsLikeText()) {
                                  this.onAddSoundsLikeClicked();
                                }
                              }
                            }
                            value={addSoundsLikeText}
                            disabled={wordInfo.toDelete}
                          />
                        </Grid>
                      </Grid>
                      {
                        wordInfo.pronunciations.length
                          ? wordInfo.pronunciations.map((pron) => (
                            <PronunciationItem
                              word={word}
                              phonemes={pron}
                              key={pron.replace(' ', '_')}
                              status={
                                (
                                  (
                                    originalWordInfo
                                    && originalWordInfo.pronunciations
                                    && !originalWordInfo.pronunciations.includes(pron)
                                  )
                                  || ''
                                )
                                && 'added'
                              }
                              disabled={wordInfo.toDelete || false}
                            />
                          ))
                          : (
                            <span style={{ color: 'darkred' }}>
                              <p style={{ marginTop: 10 }}>
                                You must specify at least one pronunciation for this word.
                              </p>
                            </span>
                          )
                      }
                    </span>
                  </>
                )
            }
          </div>
        </div>
      );
    }

    return '';
  }
}

CustomizeTableItem.propTypes = {
  lexiconInfo: lexiconInfoType,
  connectedLexicalLookup: PropTypes.func.isRequired,
  connectedSetFrequency: PropTypes.func.isRequired,
  connectedLookupPronunciation: PropTypes.func.isRequired,
  connectedAddPronunciationsToWord: PropTypes.func.isRequired,
  connectedSetWordToBeDeleted: PropTypes.func.isRequired,
  connectedGetLMProbability: PropTypes.func.isRequired,
  connectedRemoveWord: PropTypes.func.isRequired,
  connectedSetOriginalWordInfo: PropTypes.func.isRequired,
  word: PropTypes.string.isRequired,
  connectedSetWordChanged: PropTypes.func.isRequired,
  wordInfo: PropTypes.shape({
    frequency: PropTypes.number,
    pronunciations: PropTypes.arrayOf(
      PropTypes.string,
    ),
    toDelete: PropTypes.bool,
  }),
  originalWordInfo: PropTypes.shape({
    frequency: PropTypes.number,
    pronunciations: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }),
};

CustomizeTableItem.defaultProps = {
  lexiconInfo: undefined,
  wordInfo: undefined,
  originalWordInfo: undefined,
};

const mapStateToProps = (state) => ({
  lexiconInfoRequestOut: state.customize.lexiconInfoRequestOut,
  lexiconInfo: state.customize.lexiconInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedLexicalLookup: lookupWordInLexicon,
  connectedSetFrequency: setWordFrequency,
  connectedLookupPronunciation: lookupPronunciation,
  connectedAddPronunciationsToWord: addPronunciationsToWord,
  connectedGetLMProbability: getLMProbability,
  connectedRemoveWord: removeWordFromCustomize,
  connectedSetWordChanged: setWordChanged,
  connectedSetOriginalWordInfo: setOriginalWordInfo,
  connectedSetWordToBeDeleted: setWordToBeDeleted,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomizeTableItem);
