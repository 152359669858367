/**
 * ISO_8601
 * is a simple regex for testing the signature of a date string
 * This isn't as robust as true validity checking that moment offers,
 * but is sufficient for more date strings, and the documentation
 * should encourage clients to validate/format strings before they get
 * to the package if they are human created strings
 */
const ISO_8601 = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

module.exports = ISO_8601;
