module.exports = {
  missingAuth: 'Remeeting Authorization not provided - You can set defaults by calling remeeting.setAuth',
  emailRequired: 'An email is a required property',
  verificationTokenRequired: 'A verification token is a required property',
  setPasswordInputs: "You must have a 'newPassword' property, 'email', and 'verificationToken'",
  idRequired: 'An id is a required property',
  idOrSecretRequired: 'An id or secret is a required property',
  nameRequired: 'A name is a required property',
  graphemesRequired: 'An graphemes is a required property',
  lexicon_uuidRequired: 'lexicon_uuid is a required property',
  lexentry_uuidRequired: 'lexentry_uuid is a required property',
  lexicon_uuidOrNameRequired: 'lexicon_uuid or name is a required property',
  customization_uuidOrNameRequired: 'customization_uuid or name is a required property',
  customization_uuidRequired: 'customization_uuid is a required property',
  idSetInvalid: 'Cannot post already created keys, or keys with an id set',
  lexentry_uuidSetInvalid: 'Cannot post already created lexentries, or a lexentry with a lexentry_uuid set',
  basicRequired: 'Basic authentication is required: set email and password',
  ttlRange: 'key.ttl (time to live) must be between 0 - 2592000',
  invalidFilePath: 'The file path configured could not be read - please check the file exists at provided path',
  mediaNotSet: 'Media not set - expected config.filePath or config.mediaUrl',
  maxLimit: 'Api does not support list requests over 99 items. Use .page() to get more results',
  isoInvalid: 'The before: arguement could not be parsed because it is not in a recognized RFC2822 or ISO 8601 format.',
  invalidStatus: "The status: argument did was not recognized. Must be a string matching 'waiting', 'processing', 'completed' or 'failed'",
};
