const isEmpty = require('lodash/isEmpty');

const call = require('../utils/call');
const Extendable = require('../utils/extendable');
const messages = require('../utils/messages');
const Cats = require('./cats');
const JSONS = require('../utils/jsons');

const BASE_OPTIONS = Symbol();
/** Recognition
 * The recognition is the primary resource of the
 * Remeeting ASR api. This wrapper exposes the basic
 * get/post network requests to create or update the recognition instance
 */
class Recognition extends Extendable {
  constructor(config, baseOptions) {
    super(config);
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Recognition);
  }

  /**
   * get
   * make a get request to the api for this recognitions id
   * @params config Obj - optional, properties to be copied before network request
   * @returns native Promise - resolve self, reject error obj
   * @notes: the recognition must have an id set, or an id must be provided in config.
   */
  get(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
        return;
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        authRequired: recognition.shareKey === undefined,
      });
      if (!options) {
        reject({ message: messages.authRequired });
        return;
      }
      options.body = {
        progress: true,
      };

      let vendorPathComponent = '';
      if (recognition.vendor) {
        vendorPathComponent = `/vendor/${recognition.vendor}`;
      }

      if (recognition.media) {
        const { media } = recognition;
        switch (media) {
          case 'audio':
            options.url += '/audio_url';
            break;
          case 'media':
            options.url += '/media_url';
            break;
          case 'video':
            options.url += '/video_url';
            break;
          default:
            reject({ message: `Unrecognized option ${media}` });
            return;
        }
      }

      const callWithoutError = (options, resolve, reject, instance) => {
        call(
          options,
          resolve,
          (error) => {
            if (error.statusCode === 404) {
              /* If the API gives us 404, that means no such API resource
                 exists, so we send an empty object. */
              resolve({});
            } else {
              reject(error);
            }
          },
          instance,
        );
      };

      let shareQuery;
      if (recognition.shareKey) {
        shareQuery = `share_key=${recognition.shareKey}`;
      }

      if (recognition.snapshot) {
        options.url += `/snapshots/${recognition.snapshot}`;
      }

      if (recognition.format) {
        if (recognition.format === 'metadata') {
          options.url += '/metadata';
          if (shareQuery) {
            options.url += `?${shareQuery}`;
          }
          callWithoutError(options, resolve, reject, recognition);
          return;
        }
        if (recognition.format === 'sharing') {
          options.url += '/share_keys/';
          callWithoutError(options, resolve, reject, recognition);
          return;
        }
        options.url += `${vendorPathComponent}?format=${recognition.format}`;
        if (shareQuery) {
          options.url += `&${shareQuery}`;
        }
        if (recognition.format === 'cats') {
          // calling without the recognition instance so the raw response data is resolved
          call(options, resolve, reject);
          return;
        }
        if (recognition.format === 'ocr') {
          callWithoutError(options, resolve, reject);
          return;
        }
      } else {
        options.url += vendorPathComponent;
        if (shareQuery) {
          options.url += `?${shareQuery}`;
        }
      }

      call(options, resolve, reject, recognition);
    });
  }

  getTracks(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
      }

      let shareQuery;
      if (recognition.shareKey) {
        shareQuery = `share_key=${recognition.shareKey}`;
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        authRequired: recognition.shareKey === undefined,
      });

      if (recognition.snapshot) {
        options.url += `/snapshots/${recognition.snapshot}`;
      }
      if (recognition.vendor) {
        options.url += `/vendor/${recognition.vendor}`;
      }

      options.url += '/tracks/';

      if (recognition.trackId) {
        options.url += `${recognition.trackId}`;
        options.json = false;
        options.gzip = true;
        options.headers['Content-Type'] = 'application/x-jsonl';
      }

      if (shareQuery) {
        options.url += `?${shareQuery}`;
      }

      if (recognition.trackId) {
        const rq = call(options, undefined, reject);
        rq.then((result) => {
          resolve(JSONS.parse(result));
        });
      } else {
        call(options, resolve, reject);
      }
    });
  }

  createSnapshot(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        method: 'POST',
        authRequired: true,
      });

      options.url += '/snapshots/';

      call(options, resolve, reject);
    });
  }

  reprocess(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        method: 'PUT',
        authRequired: true,
        json: recognition.body,
      });

      call(options, resolve, reject);
    });
  }

  getThumbnail(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        method: 'GET',
        authRequired: recognition.shareKey === undefined,
      });
      options.url += '/thumbnail_url';

      const qargs = [];
      if (recognition.proxy) {
        qargs.push('proxy');
      }

      if (recognition.shareKey) {
        qargs.push(`share_key=${recognition.shareKey}`);
      }

      if (!isEmpty(qargs)) options.url += `?${qargs.join('&')}`;

      /*
       * Set encoding to null to return body as a Buffer. This is so
       * we can encode it as a base64 string to store.
       */
      options.encoding = null;

      call(options, resolve, reject);
    });
  }

  updateMetadata(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        method: 'PUT',
        json: recognition.updateKeys,
      });

      options.url += `/metadata?if_updated=${encodeURIComponent(recognition.updated)}`;

      call(options, resolve, reject);
    });
  }

  getSnapshots(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        authRequired: recognition.shareKey === undefined,
      });

      options.url += '/snapshots';

      if (recognition.shareKey) {
        options.url += `?share_key=${recognition.shareKey}`;
      }

      call(options, resolve, reject);
    });
  }

  getCats(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
        return;
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        shareKey: recognition.shareKey,
        authRequired: recognition.shareKey === undefined,
      });
      if (!options) {
        reject({ message: messages.authRequired });
        return;
      }

      if (recognition.snapshot) {
        options.url += `/snapshots/${recognition.snapshot}`;
      }

      if (recognition.vendor) {
        options.url += `/vendor/${recognition.vendor}`;
      }

      options.url += '?format=cats';
      if (recognition.shareKey) {
        options.url += `&share_key=${recognition.shareKey}`;
      }
      // cats are returned as a newline separated list of json objects,
      // so we do some processing here to make them into a valid json
      // array on the recognition
      options.gzip = true; // cats are returned as gzip and need to be parsed
      options.json = false; // cats aren't valid json
      options.headers['Content-Type'] = 'application/x.remeeting.jsons';
      delete options.body; // not valid unless json is true
      const rq = call(options, undefined, reject);
      rq.then((resp) => {
        let result;
        try {
          result = new Cats(resp, true);
        } catch (err) {
          reject(err);
        }
        resolve(result);
      });
    });
  }

  /**
   * delete
   * make a delete request to the api for this recognitions id
   * @params config Obj - optional, properties to be copied before network request
   * @returns native Promise - resolve nothing, reject error obj
   * @notes: the recognition must have an id set, or an id must be provided in config.
   */
  delete(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.id) {
        reject({ message: messages.idRequired });
        return;
      }

      const options = recognition[BASE_OPTIONS].getOptions({ method: 'DELETE', id: recognition.id });
      if (!options) {
        reject({ message: messages.authRequired });
        return;
      }

      call(options, resolve, reject);
    });
  }

  createShareLink(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        authRequired: true,
      });
      if (!options) {
        reject({ message: 'ID and name required.' });
      }

      options.url += '/share_keys/';
      options.method = 'POST';
      options.body = { name: recognition.name };

      call(options, resolve, reject);
    });
  }

  deleteShareLink(config) {
    const recognition = this;
    if (config) {
      recognition.extend(config);
    }

    return new Promise((resolve, reject) => {
      if (!recognition.shareKey) {
        reject({ message: 'Share key to delete required' });
      }

      const options = recognition[BASE_OPTIONS].getOptions({
        id: recognition.id,
        authRequired: true,
      });
      if (!options) {
        reject({ message: 'ID and share key required.' });
      }

      options.url += `/share_keys/${recognition.shareKey}`;
      options.method = 'DELETE';

      call(options, resolve, reject);
    });
  }
}

Recognition.resourceName = 'recognitions';

module.exports = Recognition;
