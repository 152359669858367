import React from 'react';

import { historyType, locationType } from '../types';
import RecognitionsTable from './recognitionsTable';

import '../../stylesheets/recognitions.css';
import '../../stylesheets/utilities.css';

/* This is the component that renders the user's dashboard. It essentially
builds the business logic for the dashboard and passes the functions
down to presentational components, which are UploadForms, Recognitiom,
and RecognitionTable. */

const Recognitions = (props) => {
  const {
    history,
    location,
  } = props;

  return (
    <div>
      <RecognitionsTable
        history={history}
        location={location}
      />
    </div>
  );
};

Recognitions.propTypes = {
  history: historyType.isRequired,
  location: locationType.isRequired,
};

export default Recognitions;
