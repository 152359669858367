import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Image,
  TextArea,
} from 'semantic-ui-react';
import cloneDeep from 'lodash/cloneDeep';

import ConsoleErrorMessage from '../utils/console-error-message';

import { errorType, meetingType } from '../types';

import {
  clearError,
  getMeetingParticipants,
  getMeetingTitle,
  sendFeedback,
  updateMetadata,
} from '../../modules/meeting';
import { closeCardAction } from '../../modules/recognitions';
import { smartDate } from '../../modules/utils';

import Settings from '../../images/configure_zoom_to_record.png';

/**
 * This timeout is to give the modal time to mount to the DOM.
 * Otherwise it will be called .focus() on an undefined object.
 */
const SET_REF_TIMEOUT_MS = 10;
export const WARNING_OPTIMIZE_ZOOM = 'recognitionWarningOptimizeZoom';
export const WARNING_UNEXPECTED_FAILURE = 'recognitionWarningUnexpectedFailure';
export const WARNING_CORRUPTED_ZOOM_RECORDING = 'recognitionWarningCorruptedZoomRecording';
export const RECOGNITION_DELETE = 'recognitionDelete';
export const RECOGNITION_UPDATE_METADATA = 'recognitionUpdateMetadata';

class CardActionModal extends Component {
  recognitionWarningTitle = {
    recognitionWarningOptimizeZoom: 'Configure Zoom to record separate audio files',
    recognitionWarningUnexpectedFailure: 'This recording failed to process',
    recognitionWarningCorruptedZoomRecording: 'This Zoom recording was corrupted',
  }

  constructor(props) {
    super(props);

    this.state = {
      cardActionError: undefined,
      title: '',
      message: '',
      updateMetadataRequestOut: false,
      submittedFeedback: false,
    };
    this.ref = null;
  }

  componentDidMount() {
    const { connectedClearError } = this.props;
    connectedClearError();
  }

  componentDidUpdate(prevProps) {
    const {
      cardActionMeeting, cardActionOpen, postFeedbackError, postFeedbackRequestOut,
    } = this.props;
    if (prevProps.cardActionOpen === false && cardActionOpen === true) {
      this.focusOnInput();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ title: getMeetingTitle(cardActionMeeting) });
    }
    if (prevProps.postFeedbackRequestOut === true && !postFeedbackRequestOut
      && postFeedbackError === undefined) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ submittedFeedback: true });
    }
  }

  componentWillUnmount() {
    this.handleModalClose();
  }

  setRef = (ref) => {
    this.ref = ref;
  }

  focusOnInput = () => {
    setTimeout(() => {
      if (this.ref) {
        this.ref.focus();
      }
    }, SET_REF_TIMEOUT_MS);
  }

  meetingTitleChanged = () => {
    const { cardActionMeeting } = this.props;
    const { title } = this.state;
    return getMeetingTitle(cardActionMeeting) !== title;
  }

  handleChange = (e) => {
    this.setState({ message: e.target.value });
  }

  handleKeyPress = (e) => {
    const { updateMetadataRequestOut } = this.state;
    if (!updateMetadataRequestOut && this.meetingTitleChanged() && e.key === 'Enter') {
      this.handleSubmit(e);
    }
  }

  handleSubmit = async (event) => {
    const { cardAction, cardActionMeeting, deleteRecognition } = this.props;
    const { message, title } = this.state;
    event.preventDefault();
    if (cardAction === RECOGNITION_DELETE) {
      deleteRecognition(cardActionMeeting.id)
        .then(() => {
          const { deleteMeetingRequestError } = this.props;
          if (!deleteMeetingRequestError) {
            this.handleModalClose();
          } else {
            this.setState({ cardActionError: deleteMeetingRequestError });
          }
        });
    }
    if (cardAction === RECOGNITION_UPDATE_METADATA) {
      const { connectedUpdateMetadata } = this.props;
      try {
        this.setState({ updateMetadataRequestOut: true });
        await connectedUpdateMetadata(
          (oldMetadata) => {
            let newMetadata = oldMetadata;
            if (!newMetadata) { newMetadata = {}; }
            if (title === '' && cardActionMeeting.metadata
                && cardActionMeeting.metadata.meeting_title) {
              newMetadata = cardActionMeeting.metadata.meeting_title;
            } else {
              newMetadata.meeting_title = title;
            }
            return newMetadata;
          },
          { updated: cardActionMeeting.updated, retries: 5 },
          cloneDeep(cardActionMeeting),
        );
        this.setState({ updateMetadataRequestOut: false });
        this.handleModalClose();
      } catch (e) {
        this.setState({ updateMetadataRequestOut: false });
        this.setState({ cardActionError: e });
      }
    }

    if (cardAction === WARNING_UNEXPECTED_FAILURE) {
      const { connectedSendFeedback } = this.props;
      connectedSendFeedback({
        jobId: cardActionMeeting.id,
        message: `Permission to investigate the error in the recording. Additional user information: ${message}`,
      });
    }
  }

  handleTitleChange = (event) => {
    const { connectedClearError } = this.props;
    event.preventDefault();
    connectedClearError();
    this.setState({
      cardActionError: undefined,
      title: event.target.value,
    });
  }

  handleModalClose = () => {
    const { connectedCloseCardAction, connectedClearError } = this.props;
    connectedClearError();
    this.setState({
      cardActionError: undefined,
      title: '',
      message: '',
      updateMetadataRequestOut: false,
      submittedFeedback: false,
    });
    connectedCloseCardAction();
  }

  renderDeleteBody = () => {
    const { cardActionMeeting } = this.props;
    const meetingTitle = getMeetingTitle(cardActionMeeting);
    return (
      <div className="re is-textAlign-center">
        Are you sure you want to delete this recording?
        <br />
        <div className="re is-marginTop-1em">
          <div className="modal-meeting-title">
            { meetingTitle }
          </div>
          <div>
            { smartDate(cardActionMeeting.indexed, true, true) }
          </div>
        </div>
      </div>
    );
  }

  renderTitleField = () => {
    const { title } = this.state;
    return (
      <Input
        fluid
        onChange={this.handleTitleChange}
        onKeyPress={this.handleKeyPress}
        ref={this.setRef}
        type="text"
        value={title}
      />
    );
  }

  renderErrorMessage = () => {
    const { cardActionError } = this.state;
    return (
      <Message error>
        <ConsoleErrorMessage error={cardActionError} showSignIn={false} />
      </Message>
    );
  }

  renderWarningMessage = (cardAction) => {
    const { cardActionMeeting } = this.props;
    const { message, submittedFeedback } = this.state;
    switch (cardAction) {
      case WARNING_OPTIMIZE_ZOOM:
        return (
          <Modal.Description>
            <ul
              className="modal-instruction-list"
            >
              <li
                className="modal-instruction-list-item"
              >
                In the Zoom app, open the
                {' '}
                <b>Settings</b>
                {' '}
                window (from the
                {' '}
                <b>Preferences...</b>
                {' '}
                menu item).
              </li>
              <li
                className="modal-instruction-list-item"
              >
                In the
                {' '}
                <b>Recording</b>
                {' '}
                section, check
                {' '}
                <em>Record a separate audio file for each participant</em>
                .
              </li>
              <li>
                This will enable more accurate transcription, labeled with participant names.
              </li>
            </ul>

            <a href="/images/configure_zoom_to_record.png" target="_blank">
              <Image
                alt="Optimal zoom settings"
                src={Settings}
                className="modal-image"
              />
            </a>
          </Modal.Description>
        );
      case WARNING_UNEXPECTED_FAILURE:
        return (
          <Modal.Description className="re is-textAlign-center">
            {
              submittedFeedback ? (
                <Message positive>
                  Thank you for granting Remeeting permission to audit your recording.
                  <br />
                  This will facilitate investigation of the error.
                </Message>
              ) : (
                <>
                  <p>
                    Sorry, an unexpected error occurred while processing this recording.
                    <br />
                    Remeeting will attempt to resolve this automatically,
                    without auditing your private data.
                  </p>
                  <hr />
                  <p>
                    Some problems might require an engineer to listen to the audio
                    or watch the video.
                    <br />
                    You can grant Remeeting permission to audit your private data
                    while investigating this error.
                  </p>
                  <div className="re is-textAlign-center">
                    <p>Do you want someone from Remeeting to audit this recording?</p>
                    <div className="re is-marginBottom-1em">
                      <div className="modal-meeting-title">
                        { getMeetingTitle(cardActionMeeting) }
                      </div>
                      <div>
                        { smartDate(cardActionMeeting.indexed, true, true) }
                      </div>
                      { getMeetingParticipants(cardActionMeeting) }
                    </div>
                  </div>
                  <TextArea
                    onChange={this.handleChange}
                    placeholder="Additional information..."
                    value={message}
                  />
                </>
              )
            }
          </Modal.Description>
        );
      case WARNING_CORRUPTED_ZOOM_RECORDING:
        return (
          <Modal.Description>
            <p>
              Unfortunately, Zoom has failed to record your meeting correctly. Remeeting is unable
              to identify participants in the separate audio files; the transcript will be presented
              without speaker labels.
            </p>
            <p>
              This is a rare bug that has been reported to Zoom.
            </p>
          </Modal.Description>
        );
      default:
    }
    return null;
  };

  render() {
    const {
      cardAction,
      cardActionOpen,
      deleteMeetingRequestOut,
      postFeedbackRequestOut,
    } = this.props;
    const { cardActionError, submittedFeedback, updateMetadataRequestOut } = this.state;
    const requestOut = (deleteMeetingRequestOut
                        || postFeedbackRequestOut
                        || updateMetadataRequestOut);
    let headerText;
    let modalButtonProps = {};
    let modalWidth = '';
    const closeContent = (cardAction.startsWith('recognitionWarning') ? 'Close' : 'Cancel');
    const modalSize = (cardAction.startsWith('recognitionWarning') ? 'small' : 'tiny');

    /**
     * NOTE(Elliot): I'm setting a ref on the button for the delete meeting modal in order to bring
     * the focus away from the underlying card/row. Otherwise if you click on the delete icon and
     * hit enter, it will take you the review page because the card/row is still focused.
     *
     * Also note that there is still a bug where if you click on either the edit title/delete
     * meeting buttons, and then hit 'Enter' in quick succession, it will take you to the meeting.
     * That seems pretty far outside of what a normal user could be expected to do though.
     */
    if (cardAction === RECOGNITION_DELETE) {
      headerText = 'Confirm deletion';
      modalButtonProps = {
        color: 'red',
        content: 'Delete',
        disabled: requestOut,
        primary: false,
        ref: this.setRef,
      };
    } else if (cardAction === RECOGNITION_UPDATE_METADATA) {
      headerText = 'Edit title';
      modalButtonProps = {
        color: '',
        content: 'Apply',
        disabled: !this.meetingTitleChanged() || requestOut,
        primary: true,
        ref: null,
      };
    } else if (cardAction === WARNING_UNEXPECTED_FAILURE) {
      headerText = this.recognitionWarningTitle[cardAction];
      modalButtonProps = {
        color: '',
        content: 'Grant permission',
        disabled: postFeedbackRequestOut || submittedFeedback,
        primary: true,
        ref: null,
      };
    } else {
      headerText = this.recognitionWarningTitle[cardAction];
      modalWidth = '720px';
    }

    return (
      <Modal
        className="autofocus"
        onClose={this.handleModalClose}
        open={cardActionOpen}
        size={modalSize}
        style={{ width: modalWidth }}
      >
        <Modal.Header content={headerText} />
        <Modal.Content>
          <Form>
            { cardAction === RECOGNITION_UPDATE_METADATA && this.renderTitleField() }
            { cardAction === RECOGNITION_DELETE && this.renderDeleteBody() }
            { cardActionError && !requestOut && this.renderErrorMessage() }
            { cardAction.startsWith('recognitionWarning') && this.renderWarningMessage(cardAction) }
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={closeContent}
            onClick={this.handleModalClose}
            type="button"
            ref={cardAction.startsWith('recognitionWarning') ? this.setRef : null}
          />
          {
            (!cardAction.startsWith(WARNING_OPTIMIZE_ZOOM)
            && !cardAction.startsWith(WARNING_CORRUPTED_ZOOM_RECORDING))
            && (
              <Button
                loading={requestOut}
                onClick={this.handleSubmit}
                type="submit"
                color={modalButtonProps.color}
                content={modalButtonProps.content}
                disabled={modalButtonProps.disabled}
                primary={modalButtonProps.primary}
                ref={modalButtonProps.ref}
              />
            )
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

CardActionModal.propTypes = {
  cardAction: PropTypes.string.isRequired,
  cardActionMeeting: meetingType.isRequired,
  cardActionOpen: PropTypes.bool.isRequired,
  connectedClearError: PropTypes.func.isRequired,
  connectedCloseCardAction: PropTypes.func.isRequired,
  connectedSendFeedback: PropTypes.func.isRequired,
  connectedUpdateMetadata: PropTypes.func.isRequired,
  deleteMeetingRequestError: errorType,
  deleteMeetingRequestOut: PropTypes.bool.isRequired,
  deleteRecognition: PropTypes.func.isRequired,
  postFeedbackError: errorType,
  postFeedbackRequestOut: PropTypes.bool.isRequired,
};

CardActionModal.defaultProps = {
  deleteMeetingRequestError: undefined,
  postFeedbackError: undefined,
};

const mapStateToProps = (state) => ({
  cardAction: state.recognitions.cardAction,
  cardActionMeeting: state.recognitions.cardActionMeeting,
  cardActionOpen: state.recognitions.cardActionOpen,
  deleteMeetingRequestError: state.meeting.deleteMeetingRequestError,
  deleteMeetingRequestOut: state.meeting.deleteMeetingRequestOut,
  postFeedbackRequestOut: state.meeting.postFeedbackRequestOut,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedClearError: clearError,
  connectedCloseCardAction: closeCardAction,
  connectedSendFeedback: sendFeedback,
  connectedUpdateMetadata: updateMetadata,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardActionModal);
