import isEmpty from 'lodash/isEmpty';

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const loadState = () => {
  try {
    const serializedAuth = localStorage.getItem('auth');

    const parsedState = {};
    if (serializedAuth) {
      parsedState.session = JSON.parse(serializedAuth).session;
    }
    return isEmpty(parsedState) ? undefined : parsedState;
  } catch (error) {
    return undefined;
  }
};
