import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { debounce as _debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Spinner,
  Button,
  UncontrolledTooltip,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';

import { Message } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faCaretDown,
  faExclamationTriangle,
  faTimes,
  faShare,
} from '@fortawesome/free-solid-svg-icons';

import RemeetingUserFriendlyDate from '../utils/remeeting-user-friendly-date';
import CardActionModal, { WARNING_OPTIMIZE_ZOOM, WARNING_CORRUPTED_ZOOM_RECORDING } from '../recognitions/cardActionModal';
import RefreshButton from '../utils/refresh-button';
import SettingsModal from './settingsModal';

import FindInput from './findInput';
import FindSettings from './findSettings';

import {
  colorWithAlpha,
  intervalsOverlap,
  sanitizeHtmlAttr,
  speakerLabelToAbbrev,
  MultiFind,
  MultiFindResult,
} from '../../modules/utils';

import {
  setFindQuery,
  setFindIsCurrent,
  setFindFocused,
  setFindResult,
  setFindResultIndex,
  setFindSettingsOpen,
} from '../../modules/find';

import { setAutohideTimeout, toggleControls } from '../../modules/player';

import {
  getMeeting,
  updateRecognition,
  getSpeakerNameAtIndex,
  getMeetingTitle,
  setRichificationEnabled,
  deleteMeeting,
  lookupWordInLexicon,
  clearLexicalLookup,
  toggleSettingsModal,
  setSettingsPane,
  openShareLinkModal,
  getMetadata,
  getMetadataHasTrackException,
} from '../../modules/meeting';

import { addWordToCustomize } from '../../modules/customize';

import { meetingType, speakerInfoType, refType } from '../types';
import { openCardAction, setCardAction } from '../../modules/recognitions';
import ShareLinkModal from '../utils/shareLinkModal';

const RECOGNITION_UPDATE_INTERVAL_MS = 3000;

const meetingProcessing = (mtg) => mtg && ['waiting', 'processing'].indexOf(mtg.status) >= 0;

class MeetingHeader extends Component {
  debouncedSetFindMatches = _debounce((query) => {
    const {
      connectedSetFindIsCurrent,
      transcriptAmLmBalance,
      multiFind,
      connectedSetFindResult,
      findSettings,
    } = this.props;

    if (query.length === 0) {
      connectedSetFindResult(undefined);
    } else {
      const { findOCR, ...restFindSettings } = findSettings;
      const multiFindResult = multiFind.find(query, {
        catsOptions: {
          amWeight: 1,
          lmWeight: transcriptAmLmBalance * 2,
          ...restFindSettings,
        },
        ocrOptions: {
          enabled: findOCR,
        },
      });

      connectedSetFindResult(multiFindResult);
      connectedSetFindIsCurrent(true);
    }

    this.searchForOOVWords();
  }, 500);

  constructor(props) {
    super(props);
    this.state = {
      closeOOVPopup: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      isFullScreen,
      isPlaying,
      findFocused,
      findQuery,
      findSettings,
      location,
      connectedSetFindIsCurrent,
      connectedSetFindSettingsOpen,
    } = this.props;

    const {
      closeOOVPopup,
    } = this.state;

    if (prevProps.loading !== loading) {
      const { q } = queryString.parse(location.search);
      if (q) {
        this.onFindChange(q);
      }
    }

    /* Prevent weirdness with where the find settings popover shows up on the page by just closing
       it whenever fullscreen is toggled. */
    if (prevProps.isFullScreen !== isFullScreen) {
      connectedSetFindSettingsOpen(false);
    }

    if (isFullScreen) {
      if (
        isPlaying !== prevProps.isPlaying
        || isFullScreen !== prevProps.isFullScreen
        || findFocused !== prevProps.findFocused
        || findQuery !== prevProps.findQuery
      ) {
        this.onMouseMoveHandler();
      }
    }

    if (findQuery === '' && closeOOVPopup) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        closeOOVPopup: false,
      });
    }

    /* Shallow diff of findSettings */
    const findKeys = Object.keys(findSettings);
    for (let i = 0; i < findKeys.length; i += 1) {
      if (findSettings[findKeys[i]] !== prevProps.findSettings[findKeys[i]]) {
        connectedSetFindSettingsOpen(false);
        connectedSetFindIsCurrent(false);
        this.debouncedSetFindMatches(findQuery.trim());
      }
    }
  }

  static getDerivedStateFromProps(props) {
    const {
      lexicalLookupResults,
      meeting,
    } = props;

    const invalidWords = Object.keys(lexicalLookupResults)
      .filter((x) => lexicalLookupResults[x].word === undefined)
      .filter((x) => {
        if (
          meeting.metadata
          && meeting.metadata.customization_words
        ) {
          if (!meeting.metadata.customization_words[x]) {
            return true;
          }
          return false;
        }
        return true;
      });

    return {
      invalidWords,
    };
  }

  jumpToNextMatch = () => {
    const { jumpToRelativeMatch } = this.props;
    jumpToRelativeMatch(1);
  }

  jumpToPrevMatch = () => {
    const { jumpToRelativeMatch } = this.props;
    jumpToRelativeMatch(-1);
  }

  openRegexHelp = () => {
    window.open('https://remeeting.atlassian.net/wiki/spaces/EN/pages/194772997/WIP+Cats+regex+search+documentation', '_blank');
  }

  onMouseMoveHandler = () => {
    const {
      isPlaying,
      isFullScreen,
      connectedSetAutohideTimeout,
      connectedToggleControls,
      findFocused,
      findQuery,
      findSettingsOpen,
      showRatePopup,
    } = this.props;

    if (isFullScreen) {
      connectedToggleControls(true);

      if (isPlaying && !(isFullScreen
          && (findFocused || findQuery.length > 0 || showRatePopup || findSettingsOpen))) {
        connectedSetAutohideTimeout();
      }
    }
  }

  onFindBlur = () => {
    const { connectedSetFindFocused } = this.props;
    connectedSetFindFocused(false);
    this.onMouseMoveHandler();
  }

  onFindFocus = () => {
    const { connectedSetFindFocused } = this.props;
    connectedSetFindFocused(true);
    this.onMouseMoveHandler();
  }

  onFindChange = (value) => {
    const {
      findQuery,
      connectedSetFindQuery,
      connectedSetFindIsCurrent,
    } = this.props;

    if (value !== findQuery) {
      connectedSetFindQuery(value);
      connectedSetFindIsCurrent(false);
      this.debouncedSetFindMatches(value.trim());
      this.onMouseMoveHandler(); /* For full-screen control auto-hide */
    }
  }

  searchForOOVWords = () => {
    const {
      findQuery: query,
      lexicalLookupResults,
      findResult,
      connectedClearLexicalLookup,
      connectedLookupWordInLexicon,
    } = this.props;

    const currWords = query.length > 0 ? query.match(/\S+/g) : [];
    const lookupCache = Object.keys(lexicalLookupResults);

    if (query.length > 0) {
      if (findResult && findResult.getMatchesByType('cats').length === 0) {
        for (let i = 0; i < currWords.length; i += 1) {
          if (currWords[i].trim().length > 0) {
            /* This may be the word that is OOV. Run a query against the lexicon */
            connectedLookupWordInLexicon(currWords[i]);
          }
        }
      }
    }

    for (let i = 0; i < lookupCache.length; i += 1) {
      if (!currWords.includes(lookupCache[i])) {
        connectedClearLexicalLookup(lookupCache[i]);
      }
    }
  }

  exitAndClearFind = () => {
    const { exitFind } = this.props;
    exitFind();

    const {
      connectedSetFindQuery,
      connectedSetFindIsCurrent,
      connectedSetFindResult,
      connectedSetFindResultIndex,
    } = this.props;

    connectedSetFindResult(undefined);
    connectedSetFindResultIndex(undefined);
    connectedSetFindQuery('');
    connectedSetFindIsCurrent(true);
    this.onMouseMoveHandler();
    this.debouncedSetFindMatches('');
  }

  onVersionChange = (e) => {
    window.location.href = `?version=${e.target.value}`;
  }

  onRichifyEnabledChange = () => {
    const {
      connectedSetRichificationEnabled,
      richificationEnabled,
    } = this.props;

    connectedSetRichificationEnabled(!richificationEnabled);
  }

  prePopulateWordsFromFind = () => {
    const {
      lexicalLookupResults,
      connectedToggleSettingsModal,
      connectedAddWordToCustomize,
      connectedSetSettingsPane,
    } = this.props;

    const {
      invalidWords,
    } = this.state;

    if (!lexicalLookupResults) {
      return;
    }

    connectedToggleSettingsModal(true);
    connectedSetSettingsPane(2);
    for (let i = 0; i < invalidWords.length; i += 1) {
      connectedAddWordToCustomize(invalidWords[i]);
    }
  }

  renderTopics = (recognition) => {
    let asrKeywords;
    if (getMetadata(recognition, 'top_keywords')) {
      asrKeywords = getMetadata(recognition, 'top_keywords');
    }

    if (getMetadata(recognition, 'top_keywords_asr')) {
      asrKeywords = getMetadata(recognition, 'top_keywords_asr');
    }

    if (asrKeywords && !isEmpty(asrKeywords)) {
      return asrKeywords.join(', ').replace(/\._/g, '.').replace(/_'/g, "'").replace(/_/g, '\u00A0');
    }

    return 'No topics found';
  }

  renderHeaderButtons = () => {
    const {
      connectedUpdateRecognition,
      connectedOpenShareLinkModal,
      meeting,
      shareKey,
      history,
      location,
    } = this.props;

    const buttons = [];
    const { snapshot } = queryString.parse(location.search);

    /* Always have a refresh button when the meeting is loading */
    if (meetingProcessing(meeting)) {
      buttons.push(
        <RefreshButton
          key="refresh-header-button"
          member={meeting}
          callback={() => { connectedUpdateRecognition(meeting.id, snapshot); }}
          delay={RECOGNITION_UPDATE_INTERVAL_MS}
          filter={meetingProcessing}
          className="transcript-header-button refresh"
          noSemantic
        />,
      );
    }

    /* Bye bye feedback modal.
    if (shareKey === undefined) {
      buttons.push(
        <FeedbackModal className="transcript-header-button" key="feedback-header-button" />,
      );
    }
    */

    /* Add a settings modal, but tell it what to render based on whether
       user is signed in or not */
    if (meeting.status === 'completed') {
      buttons.push(
        <SettingsModal
          className="transcript-header-button"
          key="settings-header-button"
          shared={shareKey !== undefined}
          history={history}
        />,
      );
    }

    if (!meeting.owner_email) {
      buttons.push(
        <ShareLinkModal key="share-link-modal" />,
        <button
          className="transcript-header-button"
          key="share-header-button"
          title="Share"
          type="button"
          onClick={() => {
            connectedOpenShareLinkModal(meeting.id);
          }}
        >
          <FontAwesomeIcon icon={faShare} />
        </button>,
      );
    }

    return buttons;
  }

  renderZoomWarning = (isZoomOptimized, hasTrackException) => {
    const { connectedOpenCardAction, connectedSetCardAction, meeting } = this.props;
    let message;
    if (hasTrackException) {
      message = ' The separate audio files were corrupted for this recording.';
    } else {
      message = ' Configure Zoom to record separate audio files.';
    }
    return (
      <>
        <CardActionModal deleteRecognition={() => null} />
        <Message
          className="transcript-header-zoom-message"
          compact
          color="orange"
          size="tiny"
          onClick={() => {
            connectedOpenCardAction();
            connectedSetCardAction(
              meeting,
              hasTrackException
                ? WARNING_CORRUPTED_ZOOM_RECORDING
                : WARNING_OPTIMIZE_ZOOM,
            );
          }}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} />
          { message }
        </Message>
      </>
    );
  }

  render = () => {
    const {
      meeting,
      date,
      speakerMetadata,
      cats,
      findQuery,
      findFocused,
      findResult,
      findResultIndex,
      findIsCurrent,
      isJumbo: jumboMode,
      isFullScreen,
      showVideoControls,
      headerRef,
      findBarRef,
      currentTime,
      lexicalLookupRequestsOut,
      findSettings,
      findSettingsOpen,
      connectedSetFindSettingsOpen,
    } = this.props;

    const {
      invalidWords,
      closeOOVPopup,
    } = this.state;

    const numMatches = findResult ? findResult.size() : 0;
    const isZoomOptimized = getMetadata(meeting, 'optimized_zoom') !== false;
    const hasTrackException = getMetadataHasTrackException(meeting);
    const isZoomExpected = isZoomOptimized && !hasTrackException;
    const isParticipantsEmpty = isEmpty(speakerMetadata);
    const isTopicsEmpty = this.renderTopics(meeting) === 'No topics found';
    const shouldShowDetails = !isParticipantsEmpty || !isTopicsEmpty;

    let findClassName = 'transcript-find-bar';
    if (findFocused || findQuery !== '') {
      findClassName += ' focused';
    }

    let findButtonSize = 'sm';
    if (isFullScreen) {
      findButtonSize = 'lg';
    }

    let matchText;
    const cn = 'match-index-text';

    const lexicalLookupLoading = Object.keys(lexicalLookupRequestsOut).some(
      (key) => lexicalLookupRequestsOut[key] === true,
    );

    const shouldRenderVocabPopover = (
      invalidWords.length > 0
      && !closeOOVPopup
      && !!findQuery
    );

    if (findQuery.trim() === '') {
      matchText = (
        <div className={cn} />
      );
    } else if (!findIsCurrent || lexicalLookupLoading) {
      matchText = (
        <div className={cn}>
          <Spinner size={findButtonSize} color="primary" />
        </div>
      );
    } else if (numMatches === 0) {
      matchText = <p className={cn}>0 matches</p>;
    } else {
      matchText = <p className={cn}>{`${findResultIndex + 1} / ${numMatches}`}</p>;
    }

    let className;
    if (isFullScreen) {
      className = 'fullscreen-header';
    } else if (!jumboMode) {
      className = 'fixed-header';
    }

    let containerClassName = 're';
    if (!showVideoControls && isFullScreen) {
      containerClassName += ' mtg-autohide';
    }

    let meetingTitle = getMeetingTitle(meeting);
    let meetingTitleProp = null;
    if (!meetingTitle && date) {
      meetingTitle = <RemeetingUserFriendlyDate date={date} />;
    } else {
      meetingTitleProp = meetingTitle;
    }

    let currentMatch;
    let visibleOcrMatch = -1;
    if (findResult) {
      currentMatch = findResult.getMatchAtIndex(findResultIndex);

      const ocrMatches = findResult.getMatchesByType('ocr');
      for (let i = 0; i < ocrMatches.length; i += 1) {
        if (intervalsOverlap(ocrMatches[i].data.interval, [currentTime - 0.1, currentTime + 0.1])) {
          visibleOcrMatch = ocrMatches[i].getIndex();
        }
      }
    }

    let subText;
    const subDateText = moment(meeting.indexed).format('MMM D[,] YYYY [at] h:mma');
    if (meeting.owner_email) {
      subText = `${subDateText} | Shared by ${meeting.owner_email}`;
    } else {
      subText = subDateText;
    }

    return (
      <div
        id="header-container"
        className={className}
        onMouseMove={this.onMouseMoveHandler}
      >
        {/* If we're in full screen, the headerRef is useless */}
        <div id="header-fluff" ref={headerRef} className={cats === undefined ? 'mini' : ''} />
        <div
          className={containerClassName}
          id="transcript-container-header"
        >
          {
            !isFullScreen && (
              <div id="transcript-container-header-left">
                {
                  !isFullScreen && (
                    <div id="transript-container-header-left-top">
                      <h1 className="meeting-title" title={meetingTitleProp}>
                        { meetingTitle }
                        &nbsp;
                      </h1>
                      { this.renderHeaderButtons() }
                    </div>
                  )
                }
                {
                  !isFullScreen && (
                    <div id="share-email-text">
                      {subText}
                    </div>
                  )
                }
              </div>
            )
          }
          {
            <div id="transcript-container-header-right">
              <div id="find-bar-container-wrapper">
                {
                  (meeting.status === 'completed') && (
                    <>
                      <div id="find-bar-container">
                        <FindInput
                          mode={findSettings.regexMatch ? 'words' : 'default'}
                          className={findClassName}
                          onFocus={this.onFindFocus}
                          onBlur={this.onFindBlur}
                          onChange={this.onFindChange}
                          onKeyDown={this.onKeyDown}
                          findBarRef={findBarRef}
                          value={findQuery}
                          placeholder="Find..."
                        />
                        <button
                          className="find-bar-button"
                          id="find-settings-button"
                          onClick={() => connectedSetFindSettingsOpen(!findSettingsOpen)}
                          title="Show find settings"
                          type="button"
                        >
                          <FontAwesomeIcon icon={faCaretDown} />
                        </button>
                        <FindSettings isFullScreen={isFullScreen} />
                        <Popover
                          target="find-settings-button"
                          isOpen={shouldRenderVocabPopover}
                          placement="bottom-end"
                        >
                          <PopoverHeader style={{ display: 'flex' }}>
                            <button
                              onClick={() => { this.setState({ closeOOVPopup: true }); }}
                              className="close-oov-popup"
                              type="button"
                            >
                              <FontAwesomeIcon icon={faTimes} size="1x" />
                            </button>
                          </PopoverHeader>
                          <PopoverBody>
                            <div id="add-words-suggestion">
                              Our global dictionary doesn&apos;t recognize the following word(s):
                              <ul>
                                { invalidWords.map((x) => <li>{x}</li>) }
                              </ul>
                              <button
                                className="popover-modal-button"
                                onClick={this.prePopulateWordsFromFind}
                                type="button"
                              >
                                <b>Add word(s) to dictionary</b>
                              </button>
                            </div>
                          </PopoverBody>
                        </Popover>
                      </div>
                        {
                          cats !== undefined && findQuery !== ''
                            ? (
                              <div className="find-bar-jump-controls">
                                <div id="transcript-find-button-bar">
                                  { matchText }
                                  <Button
                                    className="transcript-find-button"
                                    onClick={this.jumpToPrevMatch}
                                    title="Previous"
                                  >
                                    <FontAwesomeIcon icon={faArrowUp} size={findButtonSize} />
                                  </Button>
                                  <Button
                                    className="transcript-find-button"
                                    onClick={this.jumpToNextMatch}
                                    title="Next"
                                  >
                                    <FontAwesomeIcon icon={faArrowDown} size={findButtonSize} />
                                  </Button>
                                  <Button
                                    className="transcript-find-button"
                                    onClick={this.exitAndClearFind}
                                    title="Cancel"
                                  >
                                    <FontAwesomeIcon icon={faTimes} size={findButtonSize} />
                                  </Button>
                                </div>
                              </div>
                            )
                            : ''
                        }
                    </>
                  )
                }
              </div>
            </div>
          }
          {
            (
              isFullScreen
              && findIsCurrent
              && currentMatch
              && visibleOcrMatch !== -1
            ) && (
              <div
                className={
                  `fullscreen-ocr-label ${
                    currentMatch.getIndex() === visibleOcrMatch ? 'selected' : ''
                  }`
                }
              >
                Found
                &ldquo;
                {findQuery}
                &rdquo;
                in video
              </div>
            )
          }
          {
            !isFullScreen && shouldShowDetails && (
              <div id="subheader-grid">
                <div id="subheader-grid-left">
                  {
                    speakerMetadata && isZoomExpected && speakerMetadata
                      .map((speaker, index) => {
                        const label = getSpeakerNameAtIndex(meeting,
                          speakerMetadata, index);
                        const key = sanitizeHtmlAttr(label);
                        if (!label.includes('(Shared Audio)')) {
                          return [
                            <div
                              className="speaker-label-badge"
                              id={(`speaker-label-${key}-${index}`)}
                              key={`speaker-label-key-${key}`}
                              style={{
                                color: colorWithAlpha(speaker.hue, 1),
                                borderColor: colorWithAlpha(speaker.hue, 1),
                              }}
                            >
                              { speakerLabelToAbbrev(label) }
                            </div>,
                            <UncontrolledTooltip
                              delay={0}
                              placement="bottom"
                              target={sanitizeHtmlAttr(`speaker-label-${key}-${index}`)}
                              key={`speaker-label-tooltip-${key}`}
                              style={{ zIndex: 1 }}
                            >
                              <div className="speaker-label-badge-tooltip">
                                { label }
                              </div>
                            </UncontrolledTooltip>,
                          ];
                        }
                        return [];
                      })
                  }
                  {
                    !isZoomExpected
                      && this.renderZoomWarning(isZoomOptimized, hasTrackException)
                  }
                </div>
                {
                  !isTopicsEmpty && (
                    <div id="subheader-grid-right">
                      <div className="meeting-topics">
                        { this.renderTopics(meeting) }
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

MeetingHeader.propTypes = {
  meeting: meetingType,
  date: PropTypes.string,
  speakerMetadata: PropTypes.arrayOf(speakerInfoType),
  isFullScreen: PropTypes.bool,
  cats: PropTypes.shape({}),
  findQuery: PropTypes.string,
  findFocused: PropTypes.bool,
  findSettings: PropTypes.shape({
    findOCR: PropTypes.bool,
    regexMatch: PropTypes.bool,
  }).isRequired,
  findSettingsOpen: PropTypes.bool.isRequired,
  numMatches: PropTypes.number,
  currentMatchIndex: PropTypes.number,
  findIsCurrent: PropTypes.bool,
  showRatePopup: PropTypes.bool.isRequired,
  showVideoControls: PropTypes.bool,
  isPlaying: PropTypes.bool,
  richificationEnabled: PropTypes.bool.isRequired,

  connectedOpenCardAction: PropTypes.func.isRequired,
  connectedSetCardAction: PropTypes.func.isRequired,
  connectedOpenShareLinkModal: PropTypes.func.isRequired,
  connectedSetFindQuery: PropTypes.func.isRequired,
  connectedSetFindIsCurrent: PropTypes.func.isRequired,
  connectedSetFindFocused: PropTypes.func.isRequired,
  connectedSetAutohideTimeout: PropTypes.func.isRequired,
  connectedToggleControls: PropTypes.func.isRequired,
  connectedUpdateRecognition: PropTypes.func.isRequired,
  connectedSetRichificationEnabled: PropTypes.func.isRequired,
  dynamicRichificationEnabled: PropTypes.bool.isRequired,
  multiFind: PropTypes.instanceOf(MultiFind).isRequired,
  findResult: PropTypes.instanceOf(MultiFindResult),
  findResultIndex: PropTypes.number,
  connectedSetFindResult: PropTypes.func.isRequired,
  connectedSetFindResultIndex: PropTypes.func.isRequired,
  connectedSetFindSettingsOpen: PropTypes.func.isRequired,
  connectedAddWordToCustomize: PropTypes.func.isRequired,
  connectedToggleSettingsModal: PropTypes.func.isRequired,
  connectedSetSettingsPane: PropTypes.func.isRequired,
  connectedDeleteMeeting: PropTypes.func.isRequired,
  connectedPush: PropTypes.func.isRequired,

  loading: PropTypes.bool.isRequired,

  jumpToRelativeMatch: PropTypes.func.isRequired,

  segmentRefs: PropTypes.objectOf(refType).isRequired,
  headerRef: refType.isRequired,
  findBarRef: refType.isRequired,
  exitFind: PropTypes.func.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      meetingId: PropTypes.node,
    }).isRequired,
  }).isRequired,

  shareKey: PropTypes.string,

  isJumbo: PropTypes.bool.isRequired,
  currentTime: PropTypes.number,
  transcriptAmLmBalance: PropTypes.number.isRequired,
  lexicalLookupRequestsOut: PropTypes.objectOf(PropTypes.bool).isRequired,

  connectedClearLexicalLookup: PropTypes.func.isRequired,
  connectedLookupWordInLexicon: PropTypes.func.isRequired,
  lexicalLookupResults: PropTypes.shape({}),
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

MeetingHeader.defaultProps = {
  currentTime: 0,
  meeting: {},
  date: '',
  speakerMetadata: [],
  cats: undefined,
  findQuery: '',
  findFocused: false,
  findResult: undefined,
  findResultIndex: undefined,
  numMatches: 0,
  currentMatchIndex: -1,
  findIsCurrent: true,
  showVideoControls: false,
  isPlaying: false,
  isFullScreen: false,
  shareKey: undefined,
  lexicalLookupResults: undefined,
};

const mapStateToProps = (state) => ({
  meeting: state.meeting.meeting,
  date: state.meeting.date,
  speakerMetadata: state.meeting.speakerMetadata,
  duration: state.mediaElement.duration,
  cats: state.meeting.cats,
  findQuery: state.find.findQuery,
  findFocused: state.find.findFocused,
  findIsCurrent: state.find.findIsCurrent,
  findSettings: state.find.findSettings,
  findSettingsOpen: state.find.findSettingsOpen,
  showVideoControls: state.player.showVideoControls,
  isPlaying: state.mediaElement.isPlaying,
  currentTime: state.mediaElement.currentTime,
  transcriptAmLmBalance: state.meeting.transcriptAmLmBalance,
  richificationEnabled: state.meeting.richificationEnabled,
  dynamicRichificationEnabled: state.meeting.dynamicRichificationEnabled,
  shareKey: state.meeting.shareKey,
  multiFind: state.find.multiFind,
  findResult: state.find.findResult,
  findResultIndex: state.find.findResultIndex,
  lexicalLookupRequestsOut: state.meeting.lexicalLookupRequestsOut,
  lexicalLookupResults: state.meeting.lexicalLookupResults,
  showRatePopup: state.player.showRatePopup,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedOpenCardAction: openCardAction,
  connectedSetCardAction: setCardAction,
  connectedSetFindQuery: setFindQuery,
  connectedSetFindIsCurrent: setFindIsCurrent,
  connectedSetFindFocused: setFindFocused,
  connectedSetAutohideTimeout: setAutohideTimeout,
  connectedToggleControls: toggleControls,
  connectedGetMeeting: getMeeting,
  connectedUpdateRecognition: updateRecognition,
  connectedSetRichificationEnabled: setRichificationEnabled,
  connectedLookupWordInLexicon: lookupWordInLexicon,

  connectedSetFindResult: setFindResult,
  connectedSetFindResultIndex: setFindResultIndex,
  connectedSetFindSettingsOpen: setFindSettingsOpen,
  connectedDeleteMeeting: deleteMeeting,
  connectedOpenShareLinkModal: openShareLinkModal,
  connectedPush: push,
  connectedClearLexicalLookup: clearLexicalLookup,
  connectedToggleSettingsModal: toggleSettingsModal,
  connectedAddWordToCustomize: addWordToCustomize,
  connectedSetSettingsPane: setSettingsPane,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeetingHeader);
