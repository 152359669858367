/**
 * On every failed request, if it is a 401 error,assume it to be an expired session token and
 * dispatch a session-wide error. The app should then handle this  by telling the user to sign in
 * again.
 *
 * Ignore certain key actions because they require HTTP Basic authentication, and 401s resulting
 * from those aren't session expired errors.
 */
import { SESSION_ERROR } from '../modules/session';

/* Match all failed requests except for ones from modifying keys */
const RequestFailRegex = /REQUEST_FAIL/;

export default (store) => (next) => (action) => {
  if (action.type.match(RequestFailRegex)) {
    const { error } = action;

    if (error.statusCode === 401) {
      const key = store.getState().session.temporaryKey;
      if (key && key.expires < new Date().toISOString()) {
        error.error.message = 'This session has expired due to inactivity. Please sign in again.';
      } else {
        // NOTE: this is probably the correct explanation, but it could be due to some other reason.
        error.error.message = 'This session has ended because you signed out in another window. Please sign in again.';
      }
      return next({ type: SESSION_ERROR, error });
    }
  }

  return next(action);
};
