import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  Button,
  Header,
  Icon,
  Message,
  Segment,
} from 'semantic-ui-react';
import Steps from 'rsuite/lib/Steps';
import '../../stylesheets/zeroState.css';
import zoomSettings from '../../images/configure_zoom_settings.png';
import { getDaemonInformation } from '../../modules/account';
import { updateRecognitions } from '../../modules/recognitions';
import { daemonInfoType } from '../types';

const DOWNLOAD_STEP = 0;
const UPLOAD_STEP = 1;

const TIME_CHECK_SIGNIN = 7;
const TIME_CHECK_UPLOAD = 15;

/* Make sure this matches the daemon's configuration. */
const DAEMON_REGISTRATION_INTERVAL_MS = 60 * 60 * 1000;

class SignupProgress extends Component {
  uploadTimer = null;

  signInTimer = null;

  constructor(props) {
    super(props);
    const { currentStep } = props;
    if (currentStep === UPLOAD_STEP) {
      this.checkForUpload();
    }
  }

  componentDidMount() {
    this.checkDaemonSignin();
  }

  componentDidUpdate(prevProps) {
    const { currentStep: previousStep } = prevProps;
    const { currentStep } = this.props;
    if (currentStep !== previousStep) {
      if (currentStep === UPLOAD_STEP) {
        this.checkForUpload();
      } else if (currentStep === DOWNLOAD_STEP) {
        clearTimeout(this.uploadTimer);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.uploadTimer);
    clearTimeout(this.signInTimer);
  }

  checkDaemonSignin = () => {
    const { connectedGetDaemonInformation } = this.props;
    clearTimeout(this.signInTimer);
    connectedGetDaemonInformation();
    this.signInTimer = setTimeout(this.checkDaemonSignin, TIME_CHECK_SIGNIN * 1000);
  }

  checkForUpload = () => {
    const { connectedUpdateRecognitions } = this.props;
    clearTimeout(this.uploadTimer);
    connectedUpdateRecognitions();
    this.uploadTimer = setTimeout(this.checkForUpload, TIME_CHECK_UPLOAD * 1000);
  }

  renderMacOSNoticeOrWarning = (macOSVersion) => (
    <div className="re is-flex is-flexDirection-column is-alignItems-center">
      { macOSVersion.startsWith('10.15') ? (
        <Message compact>
          {
            // This will render as a gray box, just informational, not a warning or error.
            // We display it to anyone whose browser reports macOS version 10.15 because
            // that's the macOS version that is reported ever since browsers decided to
            // "freeze" this for privacy reasons.
            // See https://bugzilla.mozilla.org/show_bug.cgi?id=1679929
          }
          <p>
            This app requires macOS 11+ (Big Sur or newer).
          </p>
        </Message>
      ) : (
        <Message warning compact>
          {
            // Any other macOS version is *probably* old, thus unsupported and a yellow warning.
            // (Technically, there was a period in which some browsers would report 10.16 or 11.)
          }
          <p>
            Your current web browser does not appear to be running on a recent version of macOS.
            <br />
            You will be unable to install and run the downloaded app on this machine.
          </p>
        </Message>
      )}
    </div>
  )

  // This will render as a yellow warning -- not necessarily a red error.
  // In theory, a Windows user might want to download the DMG and then copy it to their Mac.
  renderOSWarning = () => (
    <div className="re is-flex is-flexDirection-column is-alignItems-center">
      <Message warning compact>
        <p>
          Your current web browser does not appear to be running on a Mac.
          <br />
          You will be unable to install the downloaded app on this machine.
        </p>
      </Message>
    </div>
  );

  renderDownloadStep = () => {
    const { email, userAgent: ua } = this.props;
    const isMac = ua.os && ua.os.name === 'Mac OS';
    return (
      <>
        <div className="zero-state-page-step">
          <p>
            {`Install Remeeting on your Mac and sign in as ${email}.`}
          </p>
        </div>
        <Button
          primary
          size="large"
          as="a"
          href={`https://remeeting.com/${window.location.hostname === 'remeeting.com'
            ? 'Remeeting.dmg' : 'Remeeting-dev.dmg '}`}
          type="application/x-apple-diskimage"
          className="install-btn"
          style={{ fontWeight: '100' }}
        >
          <Icon
            name="download"
            style={{
              margin: 0,
            }}
          />
          &nbsp;Download app
        </Button>
        { isMac ? this.renderMacOSNoticeOrWarning(ua.os.version) : this.renderOSWarning() }
      </>
    );
  };

  renderUploadStep = () => {
    const { daemonInfo } = this.props;
    return (
      <>
        <div className="zero-state-page-step">
          <p>
            The app is now watching this folder on your Mac:
            <br />
            📁&nbsp;
            <code style={{ fontWeight: 700, color: '#808080' }}>
              {daemonInfo.watched_folder}
            </code>
          </p>
          <p>
            Configure Zoom to store recordings at the same location,
            <br />
            with a separate audio file for each participant:
            <div className="zoom-img-container">
              <img
                alt=""
                src={zoomSettings}
                className="signin-images"
              />
            </div>
          </p>
        </div>
      </>
    );
  }

  render() {
    const { currentStep } = this.props;

    return (
      <div className="zero-state-page">
        <Segment raised>
          <div className="re is-border-bottom is-flex is-flex-justify-space-between">
            <Header
              as="h1"
              className="welcome-header"
            >
              How to use&nbsp;
              <span style={{ color: '#2daae1' }}>re</span>
              <span style={{ color: '#808080' }}>meeting</span>
            </Header>
          </div>
          <div className="re is-marginTop-16px">
            <Steps current={currentStep}>
              <Steps.Item title="Install Mac app" />
              <Steps.Item title="Record Zoom meetings" />
              <Steps.Item title="Search recordings" />
            </Steps>
          </div>
          { currentStep === DOWNLOAD_STEP && this.renderDownloadStep() }
          { currentStep === UPLOAD_STEP && this.renderUploadStep() }
        </Segment>
      </div>
    );
  }
}

SignupProgress.propTypes = {
  connectedGetDaemonInformation: PropTypes.func.isRequired,
  connectedUpdateRecognitions: PropTypes.func.isRequired,
  daemonInfo: daemonInfoType.isRequired,
  email: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  userAgent: PropTypes.shape({
    browser: PropTypes.shape({
      name: PropTypes.string,
      version: PropTypes.string,
      major: PropTypes.string,
    }),
    os: PropTypes.shape({
      name: PropTypes.string,
      version: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { account: { daemonInfo } } = state;

  let isDaemonRegistered = false;
  if (!isEmpty(daemonInfo)) {
    const registrationAge = moment(new Date()) - moment(daemonInfo.updated);
    isDaemonRegistered = daemonInfo.state !== 'EXITED'
      && daemonInfo.state !== null
      && registrationAge < DAEMON_REGISTRATION_INTERVAL_MS;
  }

  return {
    currentStep: isDaemonRegistered ? UPLOAD_STEP : DOWNLOAD_STEP,
    daemonInfo,
    email: state.session.email,
    userAgent: state.session.userAgent,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedGetDaemonInformation: getDaemonInformation,
  connectedUpdateRecognitions: updateRecognitions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupProgress);
