const { catsSearch, MatchCriteriaEnum, splitOnWhitespace } = require('cats-search');
const JSONS = require('../utils/jsons');

const DATA = Symbol('DATA');

/** Cats format support
 *
 * Cats represent a document of recognition output as a sequence of time spans with an n-best list
 * of phrase alternatives ("alts") for each span.
 *
 * For more information on cats: see cats-search package and
 * https://remeeting.atlassian.net/wiki/spaces/EN/pages/4227091/Cats
 */
class Cats {
  constructor(catsJsons, shouldParse = false, config) {
    this.extend(config);

    /* preprocess the utterance JSONs into a hashmap so that
       segment utterance id -> utterance cats list is O(1) */
    let jsonArray;
    if (shouldParse) {
      jsonArray = JSONS.parse(catsJsons);
    } else {
      jsonArray = catsJsons;
    }
    const data = {};

    for (let k = 0; k < jsonArray.length; k += 1) {
      const { utterance, ...obj } = jsonArray[k];

      /* as of Aug 2020, we renamed "acoustic" and "graph" costs
         on the cats to "am" and "lm". for backward-compatibility,
         we change acoustic -> am and graph -> lm on legacy cats. */
      if (obj.cats.length && obj.cats[0].alternatives[0].acoustic !== undefined) {
        for (let i = 0; i < obj.cats.length; i += 1) {
          for (let j = 0; j < obj.cats[i].alternatives.length; j += 1) {
            obj.cats[i].alternatives[j].am = obj.cats[i].alternatives[j].acoustic;
            obj.cats[i].alternatives[j].lm = obj.cats[i].alternatives[j].graph;

            delete obj.cats[i].alternatives[j].acoustic;
            delete obj.cats[i].alternatives[j].graph;
          }
        }
      }

      /* as of Feb 2021, we renamed .am and .lm fields to
         .bias.{am,lm}; change  */
      if (obj.cats.length && obj.cats[0].alternatives[0].am !== undefined) {
        for (let i = 0; i < obj.cats.length; i += 1) {
          for (let j = 0; j < obj.cats[i].alternatives.length; j += 1) {
            obj.cats[i].alternatives[j].bias = {
              am: obj.cats[i].alternatives[j].am,
              lm: obj.cats[i].alternatives[j].lm,
            };

            delete obj.cats[i].alternatives[j].am;
            delete obj.cats[i].alternatives[j].lm;
          }
        }
      }

      data[jsonArray[k].utterance] = obj;
    }

    this.setData(data);
  }

  /**
   * extend
   * Make the cats an extend-able object
   * @params obj Obj - source object to be copied onto recognition
   * @returns self - Cats Instance
   */
  extend(obj) {
    return Object.assign(this, obj);
  }

  /**
   * getData
   * Return the data, parsed but not processed
   * @returns Obj - this is just a plain JS obj, suitable to go into React
   */
  getData() {
    const cats = this;

    return cats[DATA];
  }

  /**
   * setData
   * takes valid js obj that matches the Cats format and sets it the the Cats data.
   */
  setData(catsObjs) {
    const cats = this;

    cats[DATA] = catsObjs;
    return cats;
  }

  /**
   * find
   * Used to search through the cats to match a word/phrase
   * @params config Obj - optional, properties to be used in the find, like AM/Graph weights
   * @returns version of self.data where all alternatives have a hit, along with more properties
   */
  find(
    query,
    config = {
      amWeight: 1,
      lmWeight: 1,
      wholeWordMatch: false,
      regexMatch: false,
      maxSilencesToSkip: 3,
      maxSilenceSecondsToSkip: 1.0,
      maxRankOfSilenceToSkip: 0,
    },
  ) {
    const catsInstance = this;
    const data = catsInstance[DATA];

    const result = {};

    Object.keys(data).forEach((uttId) => {
      const { cats } = data[uttId];

      let matchCriteria;
      if (config.regexMatch && config.wholeWordMatch) {
        matchCriteria = MatchCriteriaEnum.REGEX_WHOLE_WORD;
      } else if (config.regexMatch) {
        matchCriteria = MatchCriteriaEnum.REGEX;
      } else if (config.wholeWordMatch) {
        matchCriteria = MatchCriteriaEnum.WHOLE_WORD;
      } else {
        matchCriteria = MatchCriteriaEnum.PARTIAL_WORD;
      }

      const searchResult = catsSearch(
        splitOnWhitespace(query),
        cats,
        config.amWeight,
        config.lmWeight,
        null,
        null,
        true,
        matchCriteria,
        config.maxSilencesToSkip,
        config.maxSilenceSecondsToSkip,
        config.maxRankOfSilenceToSkip,
      );

      if (searchResult.length) {
        Object.assign(result, {
          [uttId]: searchResult,
        });
      }
    });
    return result;
  }
}

module.exports = Cats;
