import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import IntervalTree from 'node-interval-tree';

import { Icon } from 'semantic-ui-react';

import ProgressBar from './progressBar';
import VolumeSlider from './volumeSlider';
import AudioPlayer from './audioPlayer';
import MediaElement from './mediaElement';
import {
  errorType,
  speakerInfoType,
  richifiedSegmentsType,
} from '../../types';

import {
  formatHoursMinutesSeconds,
  intervalsOverlap,
  MultiFindResult,
} from '../../../modules/utils';
import {
  flushPlayer,
  togglePlay,
  toggleControls,
  toggleRatePopup,
  setAutohideTimeout,
  setPlaybackRate,
  cancelGetRecognitionVideo,
  cancelGetRecognitionAudio,
  cancelGetRecognitionMedia,
} from '../../../modules/player';
import {
  setFullScreen,
  setPlayButtonFocused,
  setShowingCaptions,
} from '../../../modules/meeting';

import { setVideoDimensions } from '../../../modules/mediaElement';

import Segment from '../segment';

import '../../../stylesheets/player.css';
import '../../../stylesheets/miniPlayer.css';

class MediaPlayer extends Component {
  constructor(props) {
    super(props);
    this.rateDisplayTimeout = undefined;
    this.state = {
      autohideTimeout: null,
      playerError: false,
      fullScreen: false,
      shouldRenderAudio: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResizeHandler, false);

    this.windowResizeHandler();

    const ratePopupEl = document.getElementById('rate-popup');
    ratePopupEl.style.display = 'none';
  }

  componentDidUpdate(prevProps) {
    const {
      isPlaying,
      isMini,
      isFullScreen,
      findFocused,
      findQuery,
      findSettingsOpen,
      showRatePopup,
      connectedSetAutohideTimeout,
      connectedToggleControls,
    } = this.props;

    if (prevProps.isPlaying !== isPlaying) {
      if (isPlaying) {
        if (!showRatePopup
            && !(isFullScreen && (findFocused || findQuery.length > 0 || findSettingsOpen))) {
          connectedSetAutohideTimeout();
        }
      } else {
        connectedToggleControls(!isPlaying);
      }
    }

    if (prevProps.showRatePopup !== showRatePopup) {
      if (isPlaying
          && !(isFullScreen && (findFocused || findQuery.length > 0 || findSettingsOpen))) {
        if (showRatePopup) {
          connectedToggleControls(true);
        } else {
          connectedSetAutohideTimeout();
        }
      }

      const el = document.getElementById('rate-popup');
      if (this.rateDisplayTimeout) clearTimeout(this.rateDisplayTimeout);
      if (showRatePopup) {
        if (el.style.display === 'none') {
          el.style.display = '';
          this.rateDisplayTimeout = setTimeout(() => { el.ariaHidden = 'false'; }, 50);
        } else {
          el.ariaHidden = 'false';
        }
      } else {
        /**
         * Use the `aria-hidden` attribute to apply the transition
         * fade out, and a `display: none` here so we the element doesn't
         * block underlying elements. Apply `display: none` with a
         * timeout for a smoother transition (otherwise it looks more
         * jarring).
         */
        el.ariaHidden = 'true';
        this.rateDisplayTimeout = setTimeout(() => { el.style.display = 'none'; }, 150);
      }
    }

    if (isFullScreen && prevProps.findSettingsOpen !== findSettingsOpen) {
      if (isPlaying && !(findFocused || findQuery.length > 0 || showRatePopup)) {
        if (findSettingsOpen) {
          connectedToggleControls(true);
        } else {
          connectedSetAutohideTimeout();
        }
      }
    }

    if (prevProps.isMini !== isMini || prevProps.isFullScreen !== isFullScreen) {
      this.windowResizeHandler();
      /* Make sure the rate popup's display and aria-hidden properties are
         correctly set because it may have been remounted (i.e. jumbo <-> mini
         player) */
      const el = document.getElementById('rate-popup');
      if (showRatePopup) {
        el.ariaHidden = 'false';
        el.style.display = '';
      } else {
        el.ariaHidden = 'true';
        el.style.display = 'none';
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      videoUrl,
      getAudioError,
      getVideoError,
      getAudioRequestOut,
      getVideoRequestOut,
      audioUrl,
      isFullScreen,
      isMini,
    } = props;

    const hasAudio = !!audioUrl && !getAudioError && !getAudioRequestOut;

    return {
      ...state,
      hasVideo: !!videoUrl && !getVideoError && !getVideoRequestOut,
      hasAudio,
      shouldRenderAudio: hasAudio && !!getVideoError,
      shouldDisplayMini: isMini && !isFullScreen,
    };
  }

  componentWillUnmount() {
    const {
      connectedFlushPlayer,
      connectedCancelGetRecognitionVideo,
      connectedCancelGetRecognitionAudio,
      connectedCancelGetRecognitionMedia,
    } = this.props;
    connectedCancelGetRecognitionVideo();
    connectedCancelGetRecognitionAudio();
    connectedCancelGetRecognitionMedia();
    connectedFlushPlayer();

    window.removeEventListener('resize', this.windowResizeHandler, false);
  }

  /*
   **************************
   * Start Event Handlers   *
   **************************
   */

  onClickHandler = (e) => {
    const { connectedTogglePlay } = this.props;
    if (e.target.tagName === 'VIDEO'
        || e.target.id === 'thumbnail-overlay') {
      connectedTogglePlay();
    }
  }

  onDoubleClickHandler = (e) => {
    const { connectedSetFullScreen, isFullScreen } = this.props;
    const { shouldRenderAudio } = this.state;
    if (e.target.tagName === 'VIDEO' && !shouldRenderAudio) {
      connectedSetFullScreen(!isFullScreen);
    }
  }

  onErrorHandler = () => {
    this.setState({ playerError: true });
  }

  onMouseMoveHandler = () => {
    const {
      isPlaying,
      isFullScreen,
      findFocused,
      findQuery,
      findSettingsOpen,
      showRatePopup,
      connectedSetAutohideTimeout,
      connectedToggleControls,
    } = this.props;

    connectedToggleControls(true);

    if (isPlaying
        && !showRatePopup
        && !(isFullScreen && (findFocused || findQuery.length > 0 || findSettingsOpen))) {
      connectedSetAutohideTimeout();
    }
  }

  onMouseLeaveHandler = () => {
    const {
      isPlaying,
      connectedToggleControls,
      isFullScreen,
      findFocused,
      findQuery,
      findSettingsOpen,
      showRatePopup,
    } = this.props;

    if (isPlaying
        && !showRatePopup
        && !(isFullScreen && (findFocused || findQuery.length > 0 || findSettingsOpen))) {
      connectedToggleControls(false);
    }
  }

  onPlayBlur = () => {
    const { connectedSetPlayButtonFocused } = this.props;

    connectedSetPlayButtonFocused(false);
  }

  onPlayClick = () => {
    const { connectedTogglePlay } = this.props;
    connectedTogglePlay();
  }

  onVideoClick = () => {
    const { findFocused } = this.props;
    if (!findFocused) {
      this.onPlayClick();
    }
  }

  onPlayFocus = () => {
    const { connectedSetPlayButtonFocused } = this.props;

    connectedSetPlayButtonFocused(true);
  }

  toggleFullScreen = () => {
    const { connectedSetFullScreen, isFullScreen } = this.props;
    connectedSetFullScreen(!isFullScreen);
  }

  /*
   **************************
   * End Event Handlers     *
   **************************
   */

  renderNoMedia = () => this.renderVideoPopup(
    'No media resource to play',
  );

  renderPlaybackError = () => this.renderVideoPopup(
    `There was an error playing back the media.
    Please refresh the page to ensure proper functionality.`,
  );

  renderVideoPopup = (message) => (
    <div className="mtg-popup-dialog">
      <div className="mtg-popup-dialog-inner-content">
        <div className="mtg-popup-title">
          {message}
        </div>
      </div>
    </div>
  );

  windowResizeHandler = () => {
    const { connectedSetVideoDimensions } = this.props;

    const videoPlayer = document.getElementById('video-player');
    const height = videoPlayer.offsetHeight;
    const width = videoPlayer.offsetWidth;

    connectedSetVideoDimensions(width, height);
  }

  mediaElementFinishedLoadingData = () => {
    this.windowResizeHandler();
  }

  toggleCaptions = () => {
    const { connectedSetShowingCaptions, showingCaptions } = this.props;
    connectedSetShowingCaptions(!showingCaptions);
  }

  toggleRatePopup = () => {
    const { connectedToggleRatePopup, showRatePopup } = this.props;
    connectedToggleRatePopup(!showRatePopup);
  }

  renderTray = (showPlaying) => {
    const {
      volumeSliderFocused,
      volumeHovering,
      currentTime,
      isFullScreen,
      duration,
      showingCaptions,
      playbackRate,
      bullsEyeHandler,
    } = this.props;

    const {
      hasVideo,
      shouldRenderAudio,
      hasAudio,
      shouldDisplayMini,
    } = this.state;

    return (
      <div
        id="meeting-tray"
        key="meeting-tray"
        className={`mtg mtg-tray-bottom ${
          volumeHovering || volumeSliderFocused
            ? 'mtg-volume-slider-active'
            : ''
        }`}
      >
        {
          (
            hasVideo
            || (shouldDisplayMini && shouldRenderAudio)
          )
          && <ProgressBar showFindMarkers={isFullScreen} />
        }
        <div className="mtg-tray-controls">
          <div className="mtg-left-controls">
            <button
              className="mtg-play-button mtg-button"
              onBlur={this.onPlayBlur}
              onClick={this.onPlayClick}
              onFocus={this.onPlayFocus}
              type="button"
              title={showPlaying ? 'Pause' : 'Play'}
            >
              <Icon
                className="mtg-button-icon"
                name={showPlaying ? 'pause' : 'play'}
                style={{ fontSize: isFullScreen ? '1.8em' : '1.25em', margin: 0 }}
              />
            </button>
            <VolumeSlider isMini={shouldDisplayMini} />
            <div className="mtg-time-display">
              <span>
                { formatHoursMinutesSeconds(currentTime, true) }
              </span>
              <span className="mtg-time-separator"> / </span>
              <span className="mtg-time-duration">
                { formatHoursMinutesSeconds(duration, true) }
              </span>
            </div>
          </div>

          <div className="mtg-right-controls">
            {
              !shouldDisplayMini && (
                <button
                  id="mtg-cc-button"
                  className="mtg-button"
                  onClick={this.toggleCaptions}
                  title="Captions"
                  type="button"
                >
                  <Icon
                    name={showingCaptions ? 'closed captioning' : 'closed captioning outline'}
                    style={{ fontSize: isFullScreen ? '1.8em' : '1.25em', margin: 0 }}
                  />
                </button>
              )
            }
            {
              <button
                id="mtg-rate-button"
                className="mtg-button mtg-rate-button"
                onClick={this.toggleRatePopup}
                title="Playback rate"
                type="button"
              >
                {`${playbackRate}x`}
              </button>
            }
            {
              !isFullScreen && (
                <button
                  id="mtg-bullseye-button"
                  className="mtg-button"
                  onClick={bullsEyeHandler}
                  title="Jump to"
                  type="button"
                >
                  <Icon
                    name="bullseye"
                    style={{ fontSize: isFullScreen ? '1.8em' : '1.25em' }}
                  />
                </button>
              )
            }
            {
              hasAudio && !shouldRenderAudio && (
                <button
                  id="mtg-fullscreen-button"
                  className="mtg-button"
                  onClick={this.toggleFullScreen}
                  title="Fullscreen"
                  type="button"
                >
                  <Icon
                    name={isFullScreen ? 'compress' : 'expand'}
                    style={{ fontSize: isFullScreen ? '1.8em' : '1.25em' }}
                  />
                </button>
              )
            }
          </div>
        </div>
      </div>
    );
  }

  renderRatePopup = () => {
    const {
      connectedSetPlaybackRate,
      connectedToggleRatePopup,
      playbackRate,
    } = this.props;
    const { shouldDisplayMini } = this.state;
    const popupStyle = {};
    const playbackRates = [0.5, 0.8, 1, 1.2, 1.5, 2];
    const classNameSuffix = shouldDisplayMini ? '-mini' : '';
    return (
      <div
        id="rate-popup"
        className={`mtg-tray-popup${classNameSuffix}`}
        style={popupStyle}
      >
        <div className={`mtg-tray-popup-panel${classNameSuffix}`}>
          <div className={`mtg-tray-panel-header${classNameSuffix}`}>
            <div className={`mtg-tray-panel-title${classNameSuffix}`}>
              Playback rate
            </div>
          </div>
          <div className={`mtg-tray-panel-menu${classNameSuffix}`}>
            {
              playbackRates.map((rate) => {
                let className = `mtg-tray-panel-menuitem${classNameSuffix}`;
                if (rate === playbackRate) className += ` mtg-selected-menuitem${classNameSuffix}`;
                return (
                  <div
                    key={`playback-rate-${rate}`}
                    className={className}
                    onClick={() => {
                      connectedSetPlaybackRate(rate);
                      connectedToggleRatePopup(false);
                    }}
                    onKeyDown={(event) => {
                      if (event.code === 'Enter') {
                        connectedSetPlaybackRate(rate);
                        connectedToggleRatePopup(false);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <div className="mtg-tray-panel-menuitem-label">{`${rate}x`}</div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

  renderTopGradient = () => <div className="fullscreen-gradient-top" />;

  renderGradient = () => (
    <div
      className="mtg-gradient-bottom"
      key="gradient"
    />
  );

  renderPlayer = () => {
    const {
      audioUrl,
      videoUrl,
      getVideoError,
      getAudioError,
      isBuffering,
    } = this.props;

    const { playerError, shouldRenderAudio, shouldDisplayMini } = this.state;

    return (
      <div>
        <div className="html5-video-container">
          {
            !!audioUrl || !!videoUrl
              ? (
                <MediaElement
                  onFinishedLoadingData={this.mediaElementFinishedLoadingData}
                  onErrorHandler={this.onErrorHandler}
                  isMini={shouldDisplayMini}
                  isAudioOnly={shouldRenderAudio}
                />
              )
              : ''
          }
          { shouldRenderAudio && <AudioPlayer audioSrc={audioUrl} isMini={shouldDisplayMini} /> }
        </div>
        { getVideoError && getAudioError && this.renderNoMedia() }
        { playerError && this.renderPlaybackError() }
        <div className={`ui massive ${isBuffering ? 'active' : ''} inverted video loader`} />
      </div>
    );
  }

  renderOneVideoCaption = (segment) => {
    const {
      currentTime,
      speakerMetadata,
      findResult,
      findResultIndex,
      richifiedSegments,
      richificationRequestsOut,
      richificationRequestsError,
      cats,
    } = this.props;

    if (
      segment === undefined
      || cats === undefined
    ) {
      return null;
    }

    const catsData = cats.getData();
    const utterance = catsData[segment.utterance];

    if (!utterance || utterance.cats.length === 0) {
      return '';
    }

    let matches;
    let currentMatchObj;

    if (findResult !== undefined) {
      matches = [];
      const catsMatches = findResult.getMatchesByType('cats');
      for (let i = 0; i < catsMatches.length; i += 1) {
        if (catsMatches[i].data.utterance === segment.utterance) {
          matches.push(catsMatches[i]);
        }
      }
    }
    /* Big performance speedup here, as segment components will see this as a non-update */
    if (matches && matches.length === 0) {
      matches = undefined;
    }

    let currentMatch;
    if (findResult) {
      currentMatch = findResult.getMatchAtIndex(findResultIndex);

      if (currentMatch !== undefined && currentMatch.data.utterance === segment.utterance) {
        currentMatchObj = currentMatch;
      }
    }

    return (
      <Segment
        speaker={speakerMetadata[segment.speaker]}
        speakerIndex={segment.speaker}
        segment={segment}
        utterance={utterance}
        findMatches={matches}
        currentMatch={currentMatchObj}
        isCaption
        inViewport
        currentTime={currentTime}
        richified={richifiedSegments[segment.utterance]}
        richificationRequestOut={richificationRequestsOut[segment.utterance]}
        richificationRequestError={richificationRequestsError[segment.utterance]}
        key={`${segment.speaker}-${segment.interval.toString()}`}
      />
    );
  }

  renderVideoCaptions = () => {
    /* first get the segment that corresponds to this time */
    const {
      segmentIntervalTree,
      currentTime: time,
      isMini,
      isFullScreen,
    } = this.props;

    if (segmentIntervalTree === undefined) return [];

    const captions = segmentIntervalTree.search(time, time + 0.1);
    if (captions.length === 0 || (isMini && !isFullScreen)) return [];

    return (
      <div id="captions-container">
        { captions.map(this.renderOneVideoCaption) }
      </div>
    );
  }

  render() {
    /* eslint-disable jsx-a11y/media-has-caption */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */

    const {
      progressBarHovering,
      shouldPlay,
      seekRequest,
      isPlaying,
      isBuffering,
      showVideoControls,
      showingCaptions,
      isFullScreen,
      videoUrl,
      findResult,
      currentTime,
      findQuery,
      findResultIndex,
      findIsCurrent,
      videoWidth: offsetWidth,
      videoHeight: offsetHeight,
    } = this.props;

    const {
      shouldRenderAudio,
      shouldDisplayMini,
    } = this.state;

    const showPlaying = isBuffering
      ? (shouldPlay || seekRequest.shouldPlay)
      : isPlaying;

    let mtgClass = shouldDisplayMini ? '' : 'mtg';
    if (shouldRenderAudio) {
      mtgClass += ' audio-only';
    }

    const shouldRenderCaptions = showingCaptions && videoUrl !== undefined && videoUrl.length;

    let visibleOcrMatch = -1;
    let visibleOcrFrame = -1;
    let currentMatch;

    if (findQuery && findResult) {
      currentMatch = findResult.getMatchAtIndex(findResultIndex);
      const ocrMatches = findResult.getMatchesByType('ocr');
      for (let i = 0; i < ocrMatches.length; i += 1) {
        if (
          intervalsOverlap(
            ocrMatches[i].data.interval,
            [currentTime - 0.1, currentTime + 0.1],
          )
        ) {
          visibleOcrMatch = ocrMatches[i].getIndex();

          for (let j = 0; j < ocrMatches[i].data.frames.length; j += 1) {
            if (
              intervalsOverlap(
                ocrMatches[i].data.frames[j].interval,
                [currentTime - 0.1, currentTime + 0.1],
              )
            ) {
              visibleOcrFrame = j;
            }
          }
        }
      }
    }

    const renderOCRBoxes = [];

    if (
      document.getElementById('video-element')
      && visibleOcrFrame !== -1
      && visibleOcrMatch !== -1
      && !shouldDisplayMini
    ) {
      /* First figure out whether the video is being squeezed horizontally or vertically */
      let tall = false;

      const vidElement = document.getElementById('video-element');
      const {
        videoHeight, videoWidth,
      } = vidElement;

      if (videoWidth / videoHeight >= offsetWidth / offsetHeight) {
        tall = true;
      }

      let trueHeight;
      let trueWidth;
      let originX = 0;
      let originY = 0;

      /* Map fractional positions to absolute full-screen positions */
      if (tall) {
        /* Video is centered vertically */
        trueWidth = offsetWidth;
        trueHeight = trueWidth * (videoHeight / videoWidth);
        originX = 0;
        originY = (offsetHeight - trueHeight) / 2;
      } else {
        /* Video is centered horizontally */
        trueHeight = offsetHeight;
        trueWidth = trueHeight * (videoWidth / videoHeight);
        originX = (offsetWidth - trueWidth) / 2;
        originY = 0;
      }

      const visibleFrame = findResult.getMatchAtIndex(
        visibleOcrMatch,
      ).data.frames[visibleOcrFrame];
      for (let i = 0; i < visibleFrame.frameWords.length; i += 1) {
        /* Calculate the left, top, width, and height for each word */
        const {
          x1, y1, x2, y2,
        } = visibleFrame.frameWords[i].box;

        const left = x1 * trueWidth + originX;
        const top = y1 * trueHeight + originY;
        const width = (x2 - x1) * trueWidth;
        const height = (y2 - y1) * trueHeight;

        renderOCRBoxes.push({
          left, top, width, height,
        });
      }
    }

    const renderOCRHighlights = renderOCRBoxes.map(({
      left, top, width, height,
    }) => (
      <div
        style={{
          left,
          top,
          width,
          height,
        }}
        className="ocr-bounding-box"
        key={`ocr-match-box-${left}-${top}-${width}-${height}`}
      />
    ));

    return (
      <div id={shouldDisplayMini ? 'mini-player' : 'player'} className={mtgClass}>
        <div id="player-container-outer" className={mtgClass}>
          <div id="player-container-inner" className={mtgClass}>
            <div id="player-container" className={mtgClass}>
              <rmtg-player
                id="rmtg-player"
                class={`${shouldRenderAudio ? 'audio-only' : ''} ${mtgClass}`.trim()}
              >
                <div id="container" className="rmtg-player">
                  { renderOCRHighlights }
                  {
                    shouldDisplayMini
                    && findIsCurrent
                    && currentMatch
                    && visibleOcrMatch !== -1
                    && (
                      <div
                        className={
                          `mini-ocr-result ${
                            currentMatch.getIndex() === visibleOcrMatch ? 'selected' : ''
                          }`
                        }
                      >
                        <div className="mini-ocr-result-text">
                          &ldquo;
                          {findQuery}
                          &rdquo;
                        </div>
                      </div>
                    )
                  }
                  <div
                    id="video-player"
                    className={`html5-video-player ${
                      progressBarHovering
                        ? 'mtg-progress-bar-hover'
                        : ''
                    } ${
                      isFullScreen
                        ? 'mtg-full'
                        : ''
                    } ${
                      showVideoControls
                        ? ''
                        : 'mtg-autohide'}`}
                    onClick={this.onClickHandler}
                    onDoubleClick={this.onDoubleClickHandler}
                    onContextMenu={(e) => { e.preventDefault(); }}
                    onMouseMove={this.onMouseMoveHandler}
                    onMouseLeave={this.onMouseLeaveHandler}
                  >
                    { this.renderPlayer() }
                    { !shouldDisplayMini && this.renderRatePopup() }
                    { !shouldDisplayMini && [this.renderGradient(), this.renderTray(showPlaying)] }
                    { isFullScreen && this.renderTopGradient() }
                  </div>
                </div>
                { shouldRenderCaptions && this.renderVideoCaptions() }
              </rmtg-player>
              { shouldDisplayMini && [this.renderRatePopup(), this.renderTray(showPlaying)] }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MediaPlayer.propTypes = {
  audioUrl: PropTypes.string.isRequired,
  connectedFlushPlayer: PropTypes.func.isRequired,
  connectedTogglePlay: PropTypes.func.isRequired,
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  isBuffering: PropTypes.bool,
  getAudioError: errorType,
  getVideoError: errorType,
  isPlaying: PropTypes.bool.isRequired,
  progressBarHovering: PropTypes.bool.isRequired,
  shouldPlay: PropTypes.bool,
  seekRequest: PropTypes.shape({
    time: PropTypes.number,
    shouldPlay: PropTypes.bool,
  }),
  playbackRate: PropTypes.number.isRequired,
  videoUrl: PropTypes.string.isRequired,
  volumeHovering: PropTypes.bool.isRequired,
  volumeSliderFocused: PropTypes.bool.isRequired,
  connectedSetVideoDimensions: PropTypes.func.isRequired,
  connectedSetFullScreen: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  connectedSetPlayButtonFocused: PropTypes.func.isRequired,
  showingCaptions: PropTypes.bool.isRequired,
  isMini: PropTypes.bool.isRequired,
  connectedSetShowingCaptions: PropTypes.func.isRequired,
  showRatePopup: PropTypes.bool.isRequired,
  showVideoControls: PropTypes.bool.isRequired,
  findFocused: PropTypes.bool.isRequired,
  connectedSetAutohideTimeout: PropTypes.func.isRequired,
  connectedSetPlaybackRate: PropTypes.func.isRequired,
  connectedToggleControls: PropTypes.func.isRequired,
  connectedToggleRatePopup: PropTypes.func.isRequired,
  speakerMetadata: PropTypes.arrayOf(speakerInfoType),
  richificationRequestsError: PropTypes.objectOf(errorType).isRequired,
  richificationRequestsOut: PropTypes.objectOf(PropTypes.bool).isRequired,
  richifiedSegments: richifiedSegmentsType.isRequired,
  segmentIntervalTree: PropTypes.instanceOf(IntervalTree),
  findResult: PropTypes.instanceOf(MultiFindResult),
  findResultIndex: PropTypes.number,
  findQuery: PropTypes.string.isRequired,
  findIsCurrent: PropTypes.bool.isRequired,
  findSettingsOpen: PropTypes.bool.isRequired,
  bullsEyeHandler: PropTypes.func.isRequired,
  videoWidth: PropTypes.number.isRequired,
  videoHeight: PropTypes.number.isRequired,
  cats: PropTypes.shape({
    getData: PropTypes.func,
  }),
  connectedCancelGetRecognitionVideo: PropTypes.func.isRequired,
  connectedCancelGetRecognitionAudio: PropTypes.func.isRequired,
  connectedCancelGetRecognitionMedia: PropTypes.func.isRequired,
  getAudioRequestOut: PropTypes.bool,
  getVideoRequestOut: PropTypes.bool,
};

MediaPlayer.defaultProps = {
  cats: undefined,
  getAudioError: undefined,
  getVideoError: undefined,
  shouldPlay: false,
  seekRequest: {
    time: 0,
    shouldPlay: false,
  },
  isBuffering: false,
  speakerMetadata: [],
  findResult: undefined,
  findResultIndex: undefined,
  segmentIntervalTree: undefined,
  getAudioRequestOut: undefined,
  getVideoRequestOut: undefined,
};

const mapStateToProps = (state) => ({
  audioUrl: state.player.audioUrl,
  getAudioError: state.player.getAudioError,
  getVideoError: state.player.getVideoError,
  shouldPlay: state.player.shouldPlay,
  playbackRate: state.player.playbackRate,
  progressBarHovering: state.player.progressBarHovering,
  videoUrl: state.player.videoUrl,
  volumeHovering: state.player.volumeHovering,
  showRatePopup: state.player.showRatePopup,
  showVideoControls: state.player.showVideoControls,
  volumeSliderFocused: state.player.volumeSliderFocused,

  isPlaying: state.mediaElement.isPlaying,
  duration: state.mediaElement.duration,
  currentTime: state.mediaElement.currentTime,
  seekRequest: state.mediaElement.seekRequest,
  isBuffering: state.mediaElement.isBuffering,
  isFullScreen: state.meeting.isFullScreen,
  segmentIntervalTree: state.meeting.segmentIntervalTree,
  showingCaptions: state.meeting.showingCaptions,
  speakerMetadata: state.meeting.speakerMetadata,
  cats: state.meeting.cats,
  richifiedSegments: state.meeting.richifiedSegments,
  richificationRequestsError: state.meeting.richificationRequestsError,
  richificationRequestsOut: state.meeting.richificationRequestsOut,
  videoWidth: state.mediaElement.videoWidth,
  videoHeight: state.mediaElement.videoHeight,
  findFocused: state.find.findFocused,
  findQuery: state.find.findQuery,
  findResult: state.find.findResult,
  findResultIndex: state.find.findResultIndex,
  findIsCurrent: state.find.findIsCurrent,
  findSettingsOpen: state.find.findSettingsOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectedFlushPlayer: flushPlayer,
  connectedTogglePlay: togglePlay,
  connectedSetVideoDimensions: setVideoDimensions,
  connectedSetFullScreen: setFullScreen,
  connectedSetPlayButtonFocused: setPlayButtonFocused,
  connectedSetShowingCaptions: setShowingCaptions,
  connectedToggleControls: toggleControls,
  connectedToggleRatePopup: toggleRatePopup,
  connectedSetAutohideTimeout: setAutohideTimeout,
  connectedSetPlaybackRate: setPlaybackRate,
  connectedCancelGetRecognitionVideo: cancelGetRecognitionVideo,
  connectedCancelGetRecognitionAudio: cancelGetRecognitionAudio,
  connectedCancelGetRecognitionMedia: cancelGetRecognitionMedia,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaPlayer);
