const call = require('../utils/call');
const g2p = require('./g2p');
const messages = require('../utils/messages');
const pronaudio = require('./pronaudio');

const BASE_OPTIONS = Symbol();
class Lexicon {
  /*
   * Create a new Lexicon. You can set lexicon_uuid or name in config
   * to restrict future operations (e.g. get, put) to that lexicon.
   */

  constructor(config, baseOptions) {
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Lexicon);
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}lexicons`;
    this.extend(config);
  }

  extend(obj) {
    return Object.assign(this, obj);
  }

  /*
   * Return a promise that will resolve a single instance of a Lexicon. Each
   * lexicon has a lexicon_uuid and a name. Name and lexicon_uuid opperate like
   * an id for the purpose of this request, though the name can change. A lexicon_uuid
   * or a name must be present.
   *
   * Examples:
   *
   *  get({ lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa') ->
   *    Lexicon { lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa', name: 'Company Names' }
   *
   *  get({name: 'Surnames'}) ->
   *    Lexicon { lexicon_uuid: '8f96e053-910b-43e4-b792-222edeb1f9ad', name: 'Surnames' }
   *
   */

  get(config) {
    const lexicon = this;
    config = config || {};
    lexicon.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexicon.lexicon_uuid && !lexicon.name) {
        reject({ message: messages.lexicon_uuidOrNameRequired });
        return;
      }
      const options = lexicon[BASE_OPTIONS].getOptions({});
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      if (lexicon.lexicon_uuid) {
        options.url += `/${lexicon.lexicon_uuid}`;
      } else if (lexicon.name) {
        options.url += `?name=${lexicon.name}`;
      }
      const rq = call(options, undefined, reject);
      rq.then((data) => {
        lexicon.extend(data[0]);
        resolve(lexicon);
      });
    });
  }

  /*
   * Create a new lexicon. The "name" property to config is
   * required. Returns a promise that will receive the newly created
   * lexicon (with the name and the lexicon_uuid).
   *
   * Example:
   *
   * post({name: 'Main Lexicon'}) ->
   *   Lexicon {
   *     name: 'Main Lexicon', lexicon_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'
   *   }
   */

  post(config) {
    const lexicon = this;
    config = config || {};
    lexicon.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexicon.name) {
        reject({ message: messages.nameRequired });
        return;
      }

      const options = lexicon[BASE_OPTIONS].getOptions({ method: 'POST' });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.body = {
        name: lexicon.name,
      };

      call(options, resolve, reject, lexicon);
    });
  }

  /*
   * Rename a lexicon. The lexicon_uuid identifies the lexicon to be
   * renamed.  The name property is the new name. Both are
   * required. Returns a promise that will receive the newly renamed
   * lexicon (with the new name and the lexicon_uuid).
   *
   * Example:
   *
   * put({name: 'Secondary Lexicon', lexicon_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'}) ->
   *  Lexicon {
   *    name: 'Secondary Lexicon', lexicon_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'
   *  }
   */

  put(config) {
    const lexicon = this;
    config = config || {};
    lexicon.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexicon.name) {
        reject({ message: messages.nameRequired });
        return;
      }
      if (!lexicon.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      const options = lexicon[BASE_OPTIONS].getOptions({ method: 'PUT', id: lexicon.lexicon_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      options.body = {
        name: lexicon.name,
      };

      call(options, resolve, reject, lexicon);
    });
  }

  /*
   * Delete a lexicon AND all its lexical entries. This operation
   * cannot be undone!  The lexicon_uuid is a required parameter of
   * config. Returns a promise that receives the deleted lexicon
   * information (lexicon_uuid only).
   *
   * Example:
   *
   * delete({lexicon_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'}) ->
   *  Lexicon {
   *    lexicon_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'
   *  }
   */

  delete(config) {
    const lexicon = this;
    config = config || {};
    lexicon.extend(config);

    return new Promise((resolve, reject) => {
      if (!lexicon.lexicon_uuid) {
        reject({ message: messages.lexicon_uuidRequired });
        return;
      }

      const options = lexicon[BASE_OPTIONS].getOptions({ method: 'DELETE', id: lexicon.lexicon_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      call(options, resolve, reject);
    });
  }
}

/*
   * Return a promise that will receive a list of lexicons. Each
   * lexicon has a lexicon_uuid and a name.
   * Examples:
   *
   *  list() -> [
   *    Lexicon { lexicon_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa', name: 'Company Names' },
   *    Lexicon { lexicon_uuid: '8f96e053-910b-43e4-b792-222edeb1f9ad', name: 'Surnames' }
   *  ]
   */
Lexicon.list = (config, baseOptions) => {
  baseOptions.setResource = Lexicon;
  baseOptions.resourceUrl = `${baseOptions.apiUrl}lexicons`;

  return new Promise((resolve, reject) => {
    const options = baseOptions.getOptions({});
    const rq = call(options, undefined, reject);
    rq.then((data) => {
      resolve(data.map((lexData) => new Lexicon(lexData, baseOptions.clone())));
    });
  });
};

/**
 * .phonemes(grapheme, config)
 *
 * phonemes takes a word (grapheme) and returns a promise that
 * resolves a list of possible phonemes.
 *
 * @param grapheme String
 * @param baseOptions BaseOptions instance
 * @parm config Obj.
 * @returns native Promise - resolves phonemes Array, rejects Error instance
 * @Notes: This is put here to clean up remeeting.js imports, and to add flexiblity in the future
 * if we don't want to return a g2p instance but just return a request function.
 */
Lexicon.getPhonemes = (
  graphemes, baseOptions, config,
) => new g2p(graphemes, baseOptions, config).get();

/** .pronunciationAudio(phoneme)
 * Return a URL appropriate for passing to Audio that will contain an artificial voice
 * pronouncing the passed string (in arpabet format).
 * @param phonemes String
 * @param baseOptions BaseOptions instance
 * @param config Obj.
 * @return url String
 * @Notes: this action is syncronous and does not make a network request of its own. Config is
 * currently ignored.
 */
Lexicon.pronunciationAudio = pronaudio;

module.exports = Lexicon;
