const call = require('../utils/call');
const messages = require('../utils/messages');

const BASE_OPTIONS = Symbol();

class Customization {
  /*
   * Create a new Customization. You can set customization_uuid or
   * name in config to restrict future operations (e.g. get, put) to
   * that customization.
   */

  constructor(config, baseOptions) {
    this[BASE_OPTIONS] = baseOptions;
    this[BASE_OPTIONS].setResource(Customization);
    this[BASE_OPTIONS].resourceUrl = `${this[BASE_OPTIONS].apiUrl}customizations`;
    this.extend(config);
  }

  extend(obj) {
    return Object.assign(this, obj);
  }

  /*
   * Return a promise that will resolve a single instance of a
   * Customization. Each customization has a customization_uuid and a
   * name that operate like an id for the purpose of this request,
   * though the name can change. A customization_uuid or a name must
   * be present.
   *
   * Examples:
   *
   *  get({ customization_uuid: 'c0136f7f-4273-46a9-a082-80da4f8f9dfd') ->
   *    Customization { customization_uuid: 'c0136f7f-4273-46a9-a082-80da4f8f9dfd',
   *                    name: 'test1', basemodel: 'en-US_NarrowbandModel', status: 'Unbuild' }
   *
   *  get({name: 'test2'}) ->
   *    Customization { customization_uuid: 'c0136f7f-4273-46a9-a082-80da4f8f9dfd',
   *                    name: 'test2', basemodel: 'janin-customizetest_03b94c60', status: 'Built' }
   *
   */

  get(config) {
    const customization = this;
    config = config || {};
    customization.extend(config);

    return new Promise((resolve, reject) => {
      if (!customization.customization_uuid && !customization.name) {
        reject({ message: messages.customization_uuidOrNameRequired });
        return;
      }
      const options = customization[BASE_OPTIONS].getOptions({});
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      if (customization.customization_uuid) {
        options.url += `/${customization.customization_uuid}`;
      } else if (customization.name) {
        options.url += `?name=${customization.name}`;
      }
      const rq = call(options, undefined, reject);
      rq.then((data) => {
        customization.extend(data[0]);
        resolve(customization);
      });
    });
  }

  /*
   * Create a new customization. The "name" property to config is
   * required. Returns a promise that will receive the newly created
   * customization (with the name, the customization_uuid, and other
   * properties).
   *
   * The basemodel is optional; it will default to en-US_NarrowbandModel.
   *
   * Example:
   *
   * post({name: 'Customization 1'}) ->
   *   Customization {
   *     name: 'Customization 1', customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781',
   *     status: 'Unbuilt', basemodel: 'en-US_NarrowbandModel
   *   }
   */

  post(config) {
    const customization = this;
    config = config || {};
    customization.extend(config);

    return new Promise((resolve, reject) => {
      if (!customization.name) {
        reject({ message: messages.nameRequired });
        return;
      }

      const options = customization[BASE_OPTIONS].getOptions({ method: 'POST' });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }
      options.body = {
        name: customization.name,
      };

      if (customization.basemodel) {
        options.body.basemodel = customization.basemodel;
      }

      call(options, resolve, reject, customization);
    });
  }

  /*
   * Rename a customization and/or reset it's basemodel. The
   * customization_uuid identifies the customization to be
   * adjusted. Returns a promise that will receive the newly renamed
   * customization (with the new values and the customization_uuid).
   */

  /*
   * Example:
   *
   * put({name: 'cust2', customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'}) ->
   *  Customization {
   *    name: 'cust2', customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'
   *  }
   */

  put(config) {
    const customization = this;
    config = config || {};
    customization.extend(config);

    return new Promise((resolve, reject) => {
      if (!customization.customization_uuid) {
        reject({ message: messages.customization_uuidRequired });
        return;
      }

      const options = customization[BASE_OPTIONS].getOptions({ method: 'PUT', id: customization.customization_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      options.body = {};
      if (customization.name) {
        options.body.name = customization.name;
      }
      if (customization.basemodel) {
        options.body.basemodel = customization.basemodel;
      }

      call(options, resolve, reject, customization);
    });
  }

  /*
   * Delete a customization and all the associations between the customization and
   * the lexicons (does NOT delete the lexicons themselves). This operation
   * cannot be undone!  The customization_uuid is a required parameter of
   * config. Returns a promise that receives the deleted customization
   * information (customization_uuid only).
   *
   * Example:
   *
   * delete({customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'}) ->
   *  Customization {
   *    customization_uuid: 'e6c333ba-cde5-4d7d-b1ad-ebc8d34a9781'
   *  }
   */

  delete(config) {
    const customization = this;
    config = config || {};
    customization.extend(config);

    return new Promise((resolve, reject) => {
      if (!customization.customization_uuid) {
        reject({ message: messages.customization_uuidRequired });
        return;
      }

      const options = customization[BASE_OPTIONS].getOptions({ method: 'DELETE', id: customization.customization_uuid });
      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      call(options, resolve, reject);
    });
  }

  /*
   * Request that a Customization be built. Return a promise that
   * receives the Customization.
   */

  build(config) {
    const customization = this;
    config = config || {};
    customization.extend(config);

    return new Promise((resolve, reject) => {
      if (!customization.customization_uuid) {
        reject({ message: messages.customization_uuidRequired });
        return;
      }

      const options = customization[BASE_OPTIONS].getOptions({ method: 'POST' });

      if (!options) {
        reject({ message: messages.missingAuth });
        return;
      }

      options.url += `/${customization.customization_uuid}/build`;

      call(options, resolve, reject, customization);
    });
  }
}

/*
 * Return a promise that will receive a list of customizations. Each
 * customization has a customization_uuid, a name, a basemodel, and a status.
 * Examples:
 *
 *  list() -> [
 *    Customization { customization_uuid: 'b2a0000d-abad-4d19-b724-ebf15845dfaa',
 *                    name: 'test1', basemodel: 'dev', status: 'Building' },
 *    Customization { customization_uuid: '8f96e053-910b-43e4-b792-222edeb1f9ad',
                      name: 'test2', basemodel: 'en-US_NarrowbandModel', status: 'Built' }
 *  ]
 */

Customization.list = (config, baseOptions) => {
  baseOptions.setResource = Customization;
  baseOptions.resourceUrl = `${baseOptions.apiUrl}customizations`;

  return new Promise((resolve, reject) => {
    const options = baseOptions.getOptions({});
    const rq = call(options, undefined, reject);
    rq.then((data) => {
      resolve(data.map((lexData) => new Customization(lexData, baseOptions.clone())));
    });
  });
};

module.exports = Customization;
