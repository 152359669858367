const ISO_8601 = require('./iso-regex');

/**
 * IsoDate
 * allows for functionality similar to moment
 */
function IsoDate(string) {
  this.string = string;
  this.valid = IsoDate.isValid(string);
}

/**
 * isValid
 * used to test is string is a valid ISO 8601 date string
 * @params - string
 * @returns - bool
 */
IsoDate.isValid = function (string) {
  if (!string || !string.match) return false;

  if (!decodeURIComponent(string).match(ISO_8601)) return false;

  return true;
};

module.exports = IsoDate;
